import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { DateTime } from 'luxon';

enum PluralForm {
  SINGLE = 'single',
  SOME = 'some',
  MANY = 'many'
}

@Pipe({
  name: 'timesAgo'
})
export class TimesAgoPipe implements PipeTransform {

  constructor(private translateService: TranslateService) {}

  transform(value: string): Observable<string> {
    const date = DateTime.fromJSDate(new Date(value));
    const now = DateTime.local();

    const diff = now.diff(date, ['years', 'months', 'days', 'hours', 'minutes']);

    const yearsDifference = Math.floor(diff.years);
    if (yearsDifference > 0) {
      return this.pluralizedString(yearsDifference, 'years');
    }

    const monthsDifference = Math.floor(diff.months);
    if (monthsDifference > 0) {
      return this.pluralizedString(monthsDifference, 'months');
    }

    const daysDifference = Math.floor(diff.days);
    if (daysDifference > 0) {
      return this.pluralizedString(daysDifference, 'days');
    }

    const hoursDifference = Math.floor(diff.hours);
    if (hoursDifference > 0) {
      return this.pluralizedString(hoursDifference, 'hours');
    }

    const minutestDifference = Math.floor(diff.minutes);
    if (minutestDifference >= 3) {
      return this.pluralizedString(minutestDifference, 'minutes');
    }

    return this.translateService.get('pipe.timesAgo.now');
  }

  private pluralizedString(timeDifference: number, interval: string) {
    const lastDigit = timeDifference % 10;
    const secondDigit = Math.floor((timeDifference % 100) / 10);

    let pluralForm = PluralForm.MANY;
    if (secondDigit !== 1 && lastDigit > 0 && lastDigit < 5) {
      pluralForm = lastDigit === 1 ? PluralForm.SINGLE : PluralForm.SOME;
    }
    return this.translateService.get(`pipe.timesAgo.${interval}`, { timeDifference, pluralForm });
  }

}
