import { Action } from '@ngrx/store';
import { MyPlan } from '@rsmu/portal-api';

export enum ActionTypes {
  LOAD_EDU_PLAN_REQUEST = '[Edu Plan] Load Edu Plan Request',
  LOAD_PLAN_WITHOUT_CYCLE_REQUEST = '[Edu Plan] Load Plan Without Cycle Request',
  LOAD_FAILURE = '[Edu Plan] Load Failure',
  LOAD_SUCCESS = '[Edu Plan] Load Success'
}

export class LoadEduPlanRequestAction implements Action {
  readonly type = ActionTypes.LOAD_EDU_PLAN_REQUEST;
  constructor(public payload: { cycleId: string }) {}
}

export class LoadPlanWithoutCycleRequestAction implements Action {
  readonly type = ActionTypes.LOAD_PLAN_WITHOUT_CYCLE_REQUEST;
}

export class LoadSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_SUCCESS;
  constructor(public payload: { eduPlan: MyPlan }) {}
}

export class LoadFailureAction implements Action {
  readonly type = ActionTypes.LOAD_FAILURE;
  constructor(public payload: { error: string }) {}
}

export type Actions =
  | LoadEduPlanRequestAction
  | LoadPlanWithoutCycleRequestAction
  | LoadSuccessAction
  | LoadFailureAction;
