import {
    AfterViewInit,
    Directive,
    ElementRef,
    EventEmitter,
    Injector,
    OnInit,
    Output,
    forwardRef
  } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, FormControlName, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, NgControl, UntypedFormControl, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';
import { environment } from 'src/environments/environment';
  
  declare global {
    interface Window {
      smartCaptcha: any;
      onloadYandexCaptchaFunction: () => void;
    }
  }

  export function captchaFormValidator(form: FormGroup): ValidationErrors {
    const captcha = form.controls['captcha'];
    if (!captcha || !captcha.value) {
        return { captchaError: true }
    }
    return null;
  } 
  
  @Directive({
    selector: '[appYandexCaptcha]',
  })
  export class YandexCaptchaDirective implements  OnInit {

  
    @Output()
    captchaToken: EventEmitter<string> = new EventEmitter<string>();

    private _captchaToken: FormControl;
  
    constructor(
      private readonly el: ElementRef,
      private readonly injector: Injector
    ) { }

  
    ngOnInit(): void {
      this.CaptchaOnLoadCallback();
      this.loadCaptchaScript();
    }

  
    private loadCaptchaScript (): void {
      const script = document.createElement('script');
      script.src = 'https://captcha-api.yandex.ru/captcha.js?render=onload&onload=onloadYandexCaptchaFunction'
      script.type = 'text/javascript';
      script.async = false;
      document.body.appendChild(script);
    }
  
    private onSuccess (token: string): void {
      this.captchaToken.emit(token);
    }
  
    private CaptchaOnLoadCallback(): void {
      window.onloadYandexCaptchaFunction = () => {
        if (window.smartCaptcha) {
          const widgetId = window.smartCaptcha.render(this.el.nativeElement, {
            sitekey: environment.yandexCaptchaSiteKey,
            hl: 'ru',
            success: (token: string) => {console.dir(token)}
          });
  
          const successSub = window.smartCaptcha.subscribe(
            widgetId,
            'success',
            (token: string) => this.onSuccess(token)
          )
        }
      }
    }
  }
  