export interface SidebarSettings {
  showAchievements: boolean;
  showOutsideCycleEdu: boolean;
  showAddCycle: boolean;
  showPsyTest: boolean;
  showTrajectory: boolean;
}

export function getSidebarSettings(isMyPlan: boolean): SidebarSettings {
  if (isMyPlan) {
    return {
      showAchievements: false,
      showAddCycle: false,
      showOutsideCycleEdu: false,
      showPsyTest: false,
      showTrajectory: false,
    };
  } else {
    return {
      showAchievements: false,
      showAddCycle: true,
      showOutsideCycleEdu: false,
      showPsyTest: true,
      showTrajectory: true,
    };
  }
}
