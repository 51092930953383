import { AfterViewInit, Component, OnInit } from '@angular/core';
import { StateService, Transition } from '@uirouter/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import _get from 'lodash-es/get';
import _keys from 'lodash-es/keys';
import _isString from 'lodash-es/isString';
import _noop from 'lodash-es/noop';

import { clearNonNumbers } from '../utils/clear-non-numbers';
import { EsiaService } from '../esia/esia.service';
import { snilsInputMask } from '../constants/snils-input-mask';
import { links } from '../../config/app-properties';
import { snilsValidation } from '../validators/snils-validator/snils-validator';
import { LoginService } from './login.service';
import { ManagedComponent } from '../app-commons/managed-component';
import { takeUntil } from 'rxjs/operators';
import {SecurityService} from '../security/security.service';


enum LoginFormControls {
  USER_NAME = 'userName',
  PASSWORD = 'password',
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends ManagedComponent implements OnInit, AfterViewInit {

  readonly loginForm: UntypedFormGroup;
  isViewInitialized = false;
  canShowEsiaLink = false;
  specificMessageKey: string | null = null;
  private _isEmailModeToggle = false;

  readonly loginFormControls = LoginFormControls;

  get toShowSpinner(): boolean {
    return !this.isViewInitialized || this.signingIn;
  }

  private get signingIn(): boolean {
    return this.loginService.isSigningIn;
  }

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly esiaService: EsiaService,
    private readonly transition: Transition,
    private readonly loginService: LoginService,
    private readonly stateService: StateService,
    private readonly securityService: SecurityService
  ) {
    super();
    this.loginForm = this.formBuilder.group({
      [this.loginFormControls.USER_NAME]: ['', [Validators.required, snilsValidation()]],
      [this.loginFormControls.PASSWORD]: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    setTimeout(() => {
      const transitionFrom = this.transition.from();
      const transitionFromParams = transitionFrom.params;

      if (this.esiaService.isEsiaCallback(transitionFrom)) {
        const errorKey = _get(transitionFromParams, 'error', null) === EsiaService.NOT_TRUSTED_USER_ERROR_KEY
          ? 'notTrustedUserEsiaError'
          : 'esiaError';
        this.processError(errorKey);
      }
    });

    this.loginService.performIdpLogin()
      .pipe(takeUntil(this.destroy$))
      .subscribe(_noop, () => this.securityService.useNewToken(null, { location: null }));
    this.loginService.canShowEsiaLink()
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => this.canShowEsiaLink = result);

    if (_isString(this.stateService.params.messageKey)) {
      this.specificMessageKey = this.stateService.params.messageKey;
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.isViewInitialized = true;
    });
  }

  onLogin(): void {
    this.specificMessageKey = null;

    if (this.loginForm.invalid) {
      this.userName.markAsDirty();
      this.password.markAsDirty();
    } else {
      this.loginService
        .performPasswordLogin(this.isEmailModeToggle ?
          this.loginForm.value.userName.toLowerCase() :
          clearNonNumbers(this.loginForm.value.userName), this.loginForm.value.password)
        .pipe(takeUntil(this.destroy$))
        .subscribe(_noop, (error) => this.processError(error));
    }
  }

  onEsiaLogin(): void {
    this.esiaService.startEsiaLogin();
  }

  setIsEmailModeToggle(isEmailModeToggle: boolean) {
    this._isEmailModeToggle = isEmailModeToggle;
    const userNameFormControl: AbstractControl = this.loginForm.get('userName');
    userNameFormControl.clearValidators();
    userNameFormControl.reset();
    if (isEmailModeToggle) {
      userNameFormControl.setValidators([Validators.required, Validators.email]);
    } else {
      userNameFormControl.setValidators([Validators.required, snilsValidation()]);
    }
  }

  get isEmailModeToggle() {
    return this._isEmailModeToggle;
  }

  get userName(): AbstractControl {
    return this.loginForm.get(this.loginFormControls.USER_NAME);
  }

  get userNameErrors(): Array<string> {
    return _keys(this.userName.errors);
  }

  get password(): AbstractControl {
    return this.loginForm.get(this.loginFormControls.PASSWORD);
  }

  get passwordErrors(): Array<string> {
    return _keys(this.password.errors);
  }

  get helpUrl(): string {
    return links.loginHelp;
  }

  get snilsMask(): Array<string | RegExp> {
    return snilsInputMask;
  }

  get formErrors(): Array<string> {
    return _keys(this.loginForm.errors);
  }

  get isShowGlobalErrors(): boolean {
    return this.loginForm.invalid && this.loginForm.errors && (this.loginForm.dirty || this.loginForm.errors['esiaError']);
  }

  private processError(error: string) {
    this.loginForm.setErrors({ [error]: true });
  }

  changeTabStyleOnMouse($event) {
    switch ($event.type) {
      case 'mouseenter': {
        $event.target.className += ' tab-group__tab_mouseover';
        break;
      }
      case 'mouseleave': {
        $event.target.className = $event.target.className.replace(' tab-group__tab_mouseover', '');
        break;
      }
    }
  }
}
