import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, of, ReplaySubject, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {ApiService} from '../../api/api.service';
import {
  AvailabilityDto,
  EducationalElements, EducationalElementsResponse,
  EducationalElementsSchema,
  EducationalElementTypeEnum,
  EntityIdAndNameSchema,
  FilterOrganization, IndividualFbRequest, MultiPlanPK,
  ProgramDTO, QuarterDto
} from '@rsmu/portal-api';
import {Award} from '@rsmu/portal-api/model/award';
import {ElementStatusEnum} from '@rsmu/portal-api/model/elementStatusEnum';
import {EduElementReviewItem} from '@rsmu/portal-api/model/eduElementReviewItem';
import {EduElementRemoteEducationInformation} from '@rsmu/portal-api/model/eduElementRemoteEducationInformation';
import {EducationalTechnologies} from '@rsmu/portal-api/model/educationalTechnologies';
import {ContactInfo} from '@rsmu/portal-api/model/contactInfo';
import {PreImplementation} from '@rsmu/portal-api/model/preImplementation';
import {EstimateObject} from '../../app-commons/like-dislike/like-dislike.component';
import {ApplicationOptionalDto, IndividualRequestDto, SimpleResponseDto} from '@rsmu/portal-api/model/models';
import {StateService} from '@uirouter/core';
import {DialogService} from '../../app-commons/dialog/dialog.service';
import {RouteService} from '../../app-routing/route.service';
import {TranslateService} from '@ngx-translate/core';

export interface EduElementProspectView {
  id?: string;
  type?: EducationalElementTypeEnum;
  name?: string;
  badge?: Array<Award>;
  estimate?: EstimateObject;
  score?: number;
  specialityName?: string;
  additionalSpecialities?: string;
  additionalSpecialitiesMaxLength?: string;
  status?: ElementStatusEnum;
  zet?: number;
  multiPlanPK: MultiPlanPK[];
  annotation?: string;
  review?: Array<EduElementReviewItem>;
  dppPkReview?: Array<EduElementReviewItem>;
  syncLearningTypes?: EduElementRemoteEducationInformation;
  asyncLearningTypes?: EduElementRemoteEducationInformation;
  attendance?: string;
  chargeTypes?: string;
  educationalTechnologies?: Array<EducationalTechnologies>;
  contactInfo?: ContactInfo;
  newCompetence?: boolean;
  newCompetenceDescription?: string;
  preImplementations?: Array<PreImplementation>;
  inputCdoLink?: string;
}

@Injectable({
  providedIn: 'root'
})
export class EduElementProspectService {

  private _isEduElementProspectAvailable: ReplaySubject<boolean> = new ReplaySubject<boolean>();
  private elementType: EducationalElementTypeEnum = EducationalElementTypeEnum.Iqp;
  private regionsFilter: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  constructor(
    private readonly apiService: ApiService,
    private readonly dialogService: DialogService,
    private readonly routeService: RouteService,
    private readonly stateService: StateService,
    private readonly translateService: TranslateService
  ) { }

  deleteOrderEduProspect (requestId: string): Observable<SimpleResponseDto> {
    return this.apiService.cancelFbIndividualRequest(requestId);
  }

  downloadOrderEduProspect (requestId: string) {
    return this.apiService.downloadFbIndividualRequest(requestId);
  }

  get isProspectAvailable() {
    return of(true);
  }

  get isEduElementProspectAvailable$(): Observable<boolean> {
    return this._isEduElementProspectAvailable.asObservable();
  }

  getActualQuarters$ (): Observable<QuarterDto[]> {
    return this.apiService.getActualQuarters()
      .pipe(catchError(err => of([])));
  }

  getAllRegions$(): Observable<EntityIdAndNameSchema[]> {
    return this.apiService.getRegions()
      .pipe(catchError(err => of([])));
  }

  getAllSpecialitiesList$ (): Observable<ProgramDTO[]> {
    return this.apiService
      .getAllPrograms()
      .pipe(
        catchError(err => of([]))
      );
  }

  getEduElementProspectIndividualRequests (): Observable<IndividualFbRequest[]> {
    return this.apiService.getFbIndividualReqeusts();
  }


  getEduElementProspectAvailability (): Observable<AvailabilityDto> {
    return this.apiService
      .isAvailability()
      .pipe(tap(availability => {
        this._isEduElementProspectAvailable.next(availability.availability);
      }));
  }

  getEduElementProspectView (eduElementProspectId: string): Observable<EduElementProspectView> {
    return this.apiService.getPreImplAggregator(eduElementProspectId)
      .pipe(map(res => ({
        id: res.id,
        type: res.type,
        name: res.name,
        badge: res.badge,
        estimate: {
          likes: res.likes,
          dislikes: res.dislikes,
          state: res.ratingInfo
        },
        score: res.score,
        specialityName: res.specialityName,
        additionalSpecialities: res.additionalSpecialities,
        additionalSpecialitiesMaxLength: (res.additionalSpecialities?.length > 200) &&
          (res.additionalSpecialities.substring(0, 200) + '...'),
        status: res.status,
        zet: res.zet,
        multiPlanPK: [],
        annotation: res.annotation,
        review: res.review,
        dppPkReview: res.review,
        syncLearningTypes: res.syncLearningTypes,
        asyncLearningTypes: res.asyncLearningTypes,
        attendance: res.attendance,
        chargeTypes: res.chargeTypes.join(', '),
        educationalTechnologies: res.educationalTechnologies,
        contactInfo: res.contactInfo,
        newCompetence: res.newCompetence,
        newCompetenceDescription: res.newCompetenceDescription,
        preImplementations: res.preImplementations.sort((a, b) => a.quarter - b.quarter)
      })));
  }

  getEduOrganizations$() {
    return this.apiService.getEducationalOrganizations({
      regionIds: this.regionsFilter.getValue(),
      filterOrganization: [this.elementType as FilterOrganization],
      searchType: 'preImpl'
    })
      .pipe(
        catchError(err => of([]))
      );
  }

  getEducationalItems$ (appliedFilters: EducationalElementsSchema): Observable<EducationalElements[]> {
    return this.apiService.searchEducationalItems(appliedFilters)
      .pipe(
        map((response: EducationalElementsResponse) => response.elements),
        catchError(err => of([]))
      );
  }

  getOrderEduProspectFormInfo (prospectOrderId: string): Observable<ApplicationOptionalDto> {
    return this.apiService
      .getFbIndividualRequestOptional (prospectOrderId)
      .pipe(
        catchError(err => of(null))
      );
  }

  goToEduElementProspectView(eduElementProspectId: string) {
    this.stateService.go('eduElementProspectView', { eduElementProspectId: eduElementProspectId })
      .then().catch(e => throwError( new Error(e)));
  }

  redirectIfEduElementProspectIsUnavailable (): void {
    this.dialogService.message(this.translateService.instant('eduElementProspect.unavailable'));
    this.stateService.go('userAccount.cycleSelect.eduTrajectoryHelper').then().catch(e => throwError(new Error(e)));
  }

  sendOrderEduProspectForm (preImplId: string, individualRequestDto: IndividualRequestDto): Observable<SimpleResponseDto> {
    return this.apiService.formFbIndividualRequest(preImplId, individualRequestDto)
      .pipe(
        catchError(err => of(null))
      );
  }

  setElementType (elementType: EducationalElementTypeEnum) {
    this.elementType = elementType;
  }

  setRegionsFilter (regions: string[]): void {
    this.regionsFilter.next(regions);
  }
}
