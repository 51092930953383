import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, UntypedFormGroup, ValidationErrors} from '@angular/forms';
import _keys from 'lodash-es/keys';
import {ValidationService} from '../validation.service';

/**
 * This component is used to display a validation error - both for global and field errors.
 *
 * Global error is an error that is not bound to any particular field. It can be cross-field validation, entity
 * uniqueness validation, etc. All global errors are usually displayed in a same place on the form.
 *
 * Field error is an error that is bound to a particular field. It shall be displayed near that field.
 *
 * Component considers the error to be a field error if fieldName is supplied, otherwise it considers it to be
 * a global error.
 */
@Component({
  selector: 'app-validation-error',
  templateUrl: './validation-error.component.html',
  styleUrls: ['./validation-error.component.scss']
})
export class ValidationErrorComponent implements OnInit {

  /**
   * Form instance
   */
  @Input() form: UntypedFormGroup;

  /**
   * Only for global errors
   */
  @Input() formName?: string;

  /**
   * Only for field errors
   */
  @Input() fieldName?: string;

  /**
   * The error message translate key is "validation.{namespace}.{errorCode}".
   * "namespace" is either the value of translateNamespaceOverride (if supplied), otherwise it's
   * formName for global errors and fieldName for field errors.
   */
  @Input() translateNamespaceOverride?: string;

  /**
   * Used when we don't want to display validation until the form is submitted. Usually used for client-side validation.
   */
  @Input() attemptedToSubmit?: boolean;

  /**
   * Modificators applicable to validation-error block (i.e. "validation-error_*") used to customize styling
   * of the error message
   */
  @Input() styleModificators = '';

  private isFieldError: boolean;
  private fieldControl: AbstractControl;

  constructor(
    private validationService: ValidationService
  ) { }

  ngOnInit() {
    this.isFieldError = !!this.fieldName;
    if (this.isFieldError) {
      this.fieldControl = this.form.get(this.fieldName);
    }
  }

  isDisplayed(): boolean {
    if (this.isFieldError) {
      return this.validationService.hasFieldErrors(this.form, this.fieldName, this.attemptedToSubmit);
    } else {
      return this.validationService.hasGlobalErrors(this.form, this.attemptedToSubmit);
    }
  }

  getErrorCodes(): string[] {
    if (this.isFieldError) {
      return _keys(this.fieldControl.errors);
    } else {
      return _keys(this.form.errors);
    }
  }

  getErrorValue(errorCode: string): string {
    if (this.isFieldError) {
      return this.fieldControl.errors[errorCode];
    } else {
      return this.form.errors[errorCode];
    }
  }

  constructTranslateKey(errorCode: string): string {
    let namespace: string;
    if (this.translateNamespaceOverride) {
      namespace = this.translateNamespaceOverride;
    } else {
      namespace = this.isFieldError ? this.fieldName : this.formName;
    }
    return `validation.${namespace}.${errorCode}`;
  }

}
