<div class="header"
     [class.header_opened]='headerNavOpened'
     [class.header_login]='isLoginPage()'>

  <nav class="header__nav header__nav_top">


    <div class="header__nav-item-group">
      <button class="header__back-mobile"
              tabindex="1"
              *ngIf="isShowBackButton"
              [title]="'header.back' | translate"
              (click)="back()" >
        <span class="figure figure_back" role="presentation"></span>
      </button>
      <div class="header__back-placeholder-mobile" *ngIf="!isShowBackButton"></div>

      <!--logo-->
      <div *ngIf="!isLoginPage()" class="header__logo-wrapper">
        <div>
          <a href="https://edu.rosminzdrav.ru/" target="_blank" rel="noopener">
            <svg-icon class="header__logo icon icon_logo"
                      [title]="'header.logo-title' | translate"
                      src='assets/icons/logo_nmfio_web.svg'
                      tourAnchor="test"></svg-icon>
          </a>
        </div>
        <div>
          <span class="header__logo-text" [innerHTML]="'header.logo' | translate"></span>
        </div>
      </div>
    </div>

    <div class="header__nav-item-group header__nav-item-group_grow">
      <app-accessible class="header__accessible" *ngIf="isActiveAccessible"></app-accessible>

      <ul class="header__menu header__menu_hidden-sm header__menu_hidden-tb">

        <li class="header__item header__item_widest">
          <span class="header__link" (click)="eduSectionSelected()"></span>
        </li>

        <!-- eduElementProspect -->

        <ng-container *ngIf="eduElementProspectsAvailability$ | async as availability">
          <li *ngIf="isLoggedIn() && !firstTime() && availability.availability"
              class="header__item header__item_10rem">
            <a uiSref="userAccount.eduElementProspect"
               uiSrefActive="header__link_active"
               class="header__link"
               [title]="'helper.eduElementProspect' | translate">
              <span class="header__link-label">{{ ('helper.eduElementProspect' | translate) + ' ' + availability.year }}</span>
            </a>
          </li>
        </ng-container>

        <!--helper-->
        <li *ngIf="isLoggedIn() && !firstTime()" class="header__item header__item_widest">
          <a uiSref="userAccount.cycleSelect.eduTrajectoryHelper"
              class="header__link"
              [class.header__link_dependent-route]="isActiveTrajectoryHelperRoute"
              [title]="'header.helper' | translate"
              (click)="eduSectionSelected()"
              [uiParams]="{ cycleId: (currentCycleId$ | async) }">
            <span class="header__link-label">{{ 'header.helper' | translate }}</span>
          </a>
        </li>

        <!--my plan-->
        <li *ngIf="isLoggedIn() && !firstTime()" class="header__item">
          <a uiSref="userAccount.cycleSelect.myPlan"
             [title]="'header.myPlan' | translate"
             [uiParams]="{ cycleId: (currentCycleId$ | async) }"
             class="header__link"
             [class.header__link_active]="isMyPlanScreen"
             tourAnchor="plan_intro"
             (click)="eduSectionSelected()">
            <span class="header__link-label" [innerHTML]="'header.myPlan.noBr' | translate"></span>
          </a>
        </li>

        <!--portfolio-->
        <li *ngIf="isLoggedIn() && !firstTime()" class="header__item">
          <a uiSref="userAccount.portfolio"
             uiSrefActive="header__link_active"
             [title]="'header.portfolio' | translate"
             class="header__link"
             (click)="eduSectionSelected()">
            <span class="header__link-label">{{ 'header.portfolio' | translate }}</span>
          </a>
        </li>

        <!--schedule-->
        <li *ngIf="isLoggedIn() && !firstTime()" class="header__item">
          <a uiSref="userAccount.schedule"
             uiSrefActive="header__link_active"
             class="header__link"
             [title]="'header.schedule' | translate"
             (click)="eduSectionSelected()">
            <span class="header__link-label">
              {{ 'header.schedule' | translate }}
            </span>
          </a>
        </li>

        <!--my cabinets not mobile-->
        <li *ngIf="isLoggedIn() && myCabinets && myCabinets.length > 1"
            [title]="'header.cabinets' | translate"
            class="header__item">
          <app-submenu [label]="currentCabinet"
                       [navItems]="myCabinets"></app-submenu>
        </li>

        <li class="header__item header__item_icon-list">
          <!-- help not mobile -->
          <div *ngIf="isLoggedIn()" class="header__item-icon text text_primary" tourAnchor="Common_Help">
            <app-submenu iconSrc="assets/icons/question.svg"
                         iconModificator="icon_28"
                         menuModificator="submenu__dropdown-menu_help"
                         title="{{ 'help.tooltip' | translate }}"
                         [navItems]="helpMenuItems"
                         (clickItem)="eduSectionSelected()">
            </app-submenu>
          </div>

          <div class="header__item-icon text text_primary">
            <button class="button button_text button_primary"
                    (click)="turnOnAccessible()"
                    [title]="'accessible.on' | translate">
              <svg-icon src="assets/icons/eye.svg"
                        class="icon icon_32_w header__accessible-icon"></svg-icon>
            </button>
          </div>

          <!--notifications bell-->
          <div *ngIf="!firstTime() && isLoggedIn() && !!(areNotificationsEnabled$ | async)" tourAnchor="Helper_Intro"
               class="header__item-icon header__item-icon_notification text text_primary">
            <app-submenu *ngIf="notificationsDataSource != null"
                         iconSrc="assets/icons/notification.svg"
                         iconModificator="icon_28"
                         title="{{ 'header.notifications' | translate }}"
                         [notifications]="true"
                         [navItemsSource]="notificationsDataSource"
                         (clickItem)="eduSectionSelected()">
            </app-submenu>
            <span class="header__item-notification-count">
              <app-notification-count></app-notification-count>
            </span>
          </div>

          <!--forum-->
          <div *ngIf="!firstTime() && isLoggedIn() && !!(isForumEnabled$ | async)"
               class="header__item-icon header__item-icon_notification text text_primary">
            <a [uiSref]="'userAccount.forum'"
               title="{{ 'header.forum' | translate }}">
              <svg-icon src="assets/icons/forum.svg" class="icon icon_28 icon_primary" role="link"></svg-icon>
            </a>
          </div>

          <!--user avatar-->
          <div *ngIf="!firstTime() && isLoggedIn()" class="header__item-icon">
            <a uiSref="titles" (click)="eduSectionSelected()">
              <app-profile-header [showIcon]="true" [showName]="false"></app-profile-header>
            </a>
          </div>

        </li>

        <!--login-->

        <li *ngIf="!isLoggedIn() && !isLoginPage()" class="header__item header__item_right">
          <a uiSref="root.login" class="header__link" (click)="eduSectionSelected()">
            <span class="header__link-label text text_primary text_bold text_bigger">{{ 'header.login' | translate }}</span>
          </a>
        </li>

        <!--3 dots-->

        <li *ngIf="isLoggedIn()" class="header__item header__item-icon header__item_permanently-active">
          <app-submenu extraOptions="true"
                       title="{{ 'common.menu' | translate }}"
                       [navItems]="extraOptions"></app-submenu>
        </li>

      </ul>
    </div>

    <!-- remove header__tablet-mobile-accessible_hide -->
    <!-- https://youtrack.haulmont.com/issue/RSMUP-3889 -->
    <div class="header__nav-item-group header__nav-item-group_show-tablet-mobile">
      <div class="header__tablet-mobile-accessible header__tablet-mobile-accessible_hide">
        <button class="button button_text button_primary header__tablet-mobile-accessable-button"
                (click)="turnOnAccessible()"
                [title]="'accessible.on' | translate">
          <svg-icon src="assets/icons/eye.svg" class="icon icon_32_w"></svg-icon>
        </button>
      </div>

      <button *ngIf="isLoggedIn()"
           class="header__sandwich-tablet-mobile"
           [title]="(headerNavOpened ? 'header.menu-close': 'header.menu-open') | translate"
           (click)="toggleHeaderNav()"
           tourAnchor="Common_Help_Mobile">
        <span class="figure figure_lines"
              [class.figure_lines_cross]='headerNavOpened'></span>
      </button>

      <div *ngIf="!isLoggedIn() && !isLoginPage()" class="header__login-tablet-mobile">
        <a uiSref="root.login" class="header__link" (click)="eduSectionSelected()">
          <span class="header__link-label">{{ 'header.login' | translate }}</span>
        </a>
      </div>
    </div>

  </nav>

  <nav *ngIf="isLoggedIn()" class="header__nav header__nav_drop" [appTrapFocus]="headerNavOpened" [hidden]="!headerNavOpened">

    <div class="header__menus">


      <div class="header__tablet-mobile-mainmenu">

        <!--tablet/mobile profile-->
        <div *ngIf="isLoggedIn() && !firstTime()" class="header__section-tablet-mobile header__section-tablet-mobile_profile">
          <a class="header__profile-link-tablet-mobile" uiSref="titles" (click)="eduSectionSelected()">
            <app-profile-header [showIcon]="true" [showName]="true" [showHint]="true"></app-profile-header>
          </a>
        </div>

        <div *ngIf="isLoggedIn() && !firstTime() && !!(areNotificationsEnabled$ | async)" class="header__section-tablet-mobile header__section-tablet-mobile_line">
          <a class="header__link header__link_with-icon-tablet-mobile" uiSref="userAccount.notifications" (click)="eduSectionSelected()">
            <div class="header__link-icon-tablet-mobile header__link-icon-tablet-mobile_notification">
              <app-notification-count countModificator="header-drop"></app-notification-count>
            </div>
            <div class="header__link-label">{{ 'header.notifications' | translate }}</div>
          </a>
        </div>

        <div *ngIf="isLoggedIn() && !firstTime()" class="header__section-tablet-mobile header__section-tablet-mobile_line">
          <ul class="header__menu">

            <!--helper mobile and tablet-->
            <li class="header__item">
              <button class="header__link header__link_with-nested"
                      tabindex="0"
                      [class.header__link_active]="isActiveTrajectoryHelperRoute"
                      [class.header__link_drop-opened]="helperClicked"
                      (click)="helperClick(); openSelectCycle(routeTrajectoryHelper);">
            <span class="header__link-label">
              {{ 'header.helper' | translate }}
            </span>
              </button>
            </li>

            <!--my plan mobile-->
            <li class="header__item">
              <button class="header__link header__link_with-nested"
                      [class.header__link_active]="isActiveRoute(routeMyPlan)"
                      [class.header__link_drop-opened]="myPlanClicked"
                      tourAnchor="plan_intro"
                      (click)="myPlanClick(); openSelectCycle(routeMyPlan)">
                <span class="header__link-label"
                      uiSrefActive="header__link-label_active">
                  {{ 'header.myPlan' | translate }}
                </span>
              </button>
            </li>

            <!--portfolio mobile and tablet-->
            <li class="header__item">
              <a uiSref="userAccount.portfolio" uiSrefActive="header__link_active" class="header__link" (click)="eduSectionSelected()">
                <span class="header__link-label">{{ 'header.portfolio' | translate }}</span>
              </a>
            </li>

            <!--schedule mobile and tablet-->
            <li class="header__item">
              <a uiSref="userAccount.schedule" uiSrefActive="header__link_active" class="header__link" (click)="eduSectionSelected()">
                <span class="header__link-label">{{ 'header.schedule' | translate }}</span>
              </a>
            </li>

            <!-- eduElementProspect -->

            <ng-container *ngIf="eduElementProspectsAvailability$ | async as availability">
              <li (click)="eduSectionSelected()" *ngIf="isLoggedIn() && !firstTime() && availability.availability"
                  class="header__item header__item_10rem">
                <a uiSref="userAccount.eduElementProspect"
                   uiSrefActive="header__link_active"
                   class="header__link"
                   [title]="'helper.eduElementProspect' | translate">
                  <span class="header__link-label">{{ ('helper.eduElementProspect' | translate) + ' ' + availability.year }}</span>
                </a>
              </li>
            </ng-container>

          </ul>
        </div>

        <div *ngIf="isLoggedIn()" class="header__section-tablet-mobile header__section-tablet-mobile_line">
          <ul class="header__menu">

            <!--my cabinets mobile and tablet -->
            <li *ngIf="myCabinets && myCabinets.length > 1" class="header__item">
              <app-submenu [label]="currentCabinet"
                           [navItems]="myCabinets" [mobile]="true" (clickItem)="eduSectionSelected()"></app-submenu>
            </li>

            <!--help mobile-->
            <li *ngIf="showHelpIcon()" class="header__item" tourAnchor="Common_Help">
              <app-submenu class="header__help-tablet-mobile"
                           iconWithLabel="true"
                           iconSrc="assets/icons/question.svg"
                           toggleModificator="submenu__toggle_small-tablet"
                           iconModificator="icon_primary icon_fit-width submenu__help-icon"
                           label="{{ 'help.f1' | translate }}"
                           [navItems]="helpMenuItems"
                           [mobile]="true"
                           (clickItem)="eduSectionSelected()">
              </app-submenu>
            </li>
          </ul>
        </div>

        <!--forum-->
        <div *ngIf="isLoggedIn() && !!(isForumEnabled$ | async)" class="header__section-tablet-mobile header__section-tablet-mobile_line">
          <ul class="header__menu">
            <li class="header__item">
              <a [uiSref]="'userAccount.forum'"
                 title="{{ 'header.forum' | translate }}"
                 class="header__link header__link_with-icon-tablet-mobile header__link_small-tablet"
                 (click)="eduSectionSelected()">
                <svg-icon
                  class="icon icon_20_w icon_primary header__link-icon-tablet-mobile header__link-icon-tablet-mobile_communication"
                  src="assets/icons/forum.svg">
                </svg-icon>
                <span class="header__link-label">{{ 'header.extraOptions.forum' | translate }}</span>
              </a>
            </li>
          </ul>
        </div>

        <div *ngIf="isLoggedIn()" class="header__section-tablet-mobile">
          <ul class="header__menu">
            <!--logout mobile-->
            <li class="header__item header__item_permanently-active">
              <button class="header__link header__link_with-icon-tablet-mobile" (click)="logout(); eduSectionSelected()">
                <svg-icon class="icon icon_20_w icon_primary header__link-icon-tablet-mobile"
                          [title]="'header.logout' | translate"
                          src="assets/icons/out.svg"></svg-icon>

                <span class="header__link-label">{{ 'header.logout' | translate }}</span>
              </button>
            </li>
          </ul>
        </div>

      </div>


      <div class="header__tablet-mobile-submenu"
           [hidden]="!selectCycleOpened"
           [class.header__tablet-mobile-submenu_open]="selectCycleOpened">

        <div class="header__mobile-submenu-back-wrapper">
          <div class="header__mobile-submenu-back" (click)="closeCycleSelect()">
            <div class="figure figure_chevron-left figure_chevron-left_primary header__mobile-submenu-back-icon"></div>
            {{ 'header.back' | translate }}
          </div>
        </div>

        <div class="text text_h3 text_bold header__mobile-submenu-current">
          {{ (selectCycleIsMyPlan ? 'header.myPlan' : 'header.helper') | translate }}
        </div>

        <app-sidebar
                [appTrapFocus]="selectCycleOpened"
                class="header__tablet-mobile-submenu-sidebar"
                menuTitle="{{ 'sidebar.titleCycles' | translate }}"
                [sidebarSettings]="sidebarSettings"
                (clickItem)="onClickSidebarItem()"
                [isMyPlanScreen]="selectCycleIsMyPlan">
          <app-cycle-management
              [allowAddCycle]="!selectCycleIsMyPlan"
              (cycleChange)="cycleChanged($event)">
          </app-cycle-management>
          <app-outside-cycles
              [active]="outsideCyclesActive"
              (selected)="outCycleSelected()">
            {{ 'sidebar.outsideCyclesEdu' | translate }}
          </app-outside-cycles>
        </app-sidebar>
      </div>
    </div>
  </nav>
</div>

<app-popup #popupHelp>
  <app-help-info
    *ngIf="popupHelp.isShow"
    [data]="popupHelp.data">
  </app-help-info>
</app-popup>
