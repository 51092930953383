import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import _keys from 'lodash-es/keys';
import {snilsStringValidateFunction, snilsValidateFunction, snilsValidation} from '../validators/snils-validator/snils-validator';
import { phoneValidation } from '../validators/phone-validator/phone-validator';
import _reduce from 'lodash-es/reduce';
import { snilsInputMask } from '../constants/snils-input-mask';
import { phoneInputMask } from '../constants/phone-input-mask';
import { clearNonNumbers } from '../utils/clear-non-numbers';
import { DateTime } from 'luxon';
import { ApiService } from '../api/api.service';
import { EntityIdAndNameSchema, PersonRegisterSchema } from '@rsmu/portal-api';
import { StateService, Transition } from '@uirouter/core';
import { links } from '../../config/app-properties';
import { CaptchaDirective } from '../captcha/captcha.directive';
import { EsiaService } from '../esia/esia.service';
import { finalize, take } from 'rxjs/operators';
import { RegistrationUserDataFromEsia } from '@rsmu/portal-api';
import { DialogService } from '../app-commons/dialog/dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { MobileAppService } from '../mobile/mobile-app.service';



enum RegistrationForm {
  EMAIL = 'email',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  MIDDLE_NAME = 'middleName',
  PHONE = 'phone',
  REGION_ID = 'regionId',
  GENDER = 'gender',
  SNILS = 'snils',
  CITIZENSHIP_ID = 'citizenshipId',
  ACCEPT_TERMS_AND_CONDITIONS = 'acceptTermsAndConditions',
  WORKING_IN_RUSSIAN_FEDERATION = 'isEmployedInRF',
  ACCEPT_READ = 'acceptRead',
  DATE_OF_BIRTH = 'dateOfBirth',
  Y_CAPTCHA_RESPONSE = 'smart_token',
  EMPLOYED_IN_RF = 'isEmployedInRF'
}

const formFieldsToEsiaDataMap: { [key: string]: { isBlocked: boolean, esiaFieldName: string } } = {
  'birthDate': {
    esiaFieldName: RegistrationForm.DATE_OF_BIRTH,
    isBlocked: true
  },
  'citizenship': {
    esiaFieldName: RegistrationForm.CITIZENSHIP_ID,
    isBlocked: false
  },
  'email': {
    esiaFieldName: RegistrationForm.EMAIL,
    isBlocked: false
  },
  'firstName': {
    esiaFieldName: RegistrationForm.FIRST_NAME,
    isBlocked: true
  },
  'lastName': {
    esiaFieldName: RegistrationForm.LAST_NAME,
    isBlocked: true
  },
  'middleName': {
    esiaFieldName: RegistrationForm.MIDDLE_NAME,
    isBlocked: true
  },
  'phone': {
    esiaFieldName: RegistrationForm.PHONE,
    isBlocked: false
  },
  'region': {
    esiaFieldName: RegistrationForm.REGION_ID,
    isBlocked: false
  },
  'gender': {
    esiaFieldName: RegistrationForm.GENDER,
    isBlocked: false
  },
  'snils': {
    esiaFieldName: RegistrationForm.SNILS,
    isBlocked: true
  },
};

const RUSSIAN_FEDERATION = {
  id: '7c05165b-5acf-09ae-a63b-25d87a1f962d',
  name: 'Российская Федерация'};

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit, OnDestroy {

  private _regions: Array<EntityIdAndNameSchema>;
  private _citizenship: Array<EntityIdAndNameSchema>;
  private fromMobile = false;
  private isEsia = false;
  private _isWorkingInRussianFederation = true;
  private _isCitizenOfRussianFederation: boolean;

  readonly minDate: NgbDateStruct;
  readonly maxDate: NgbDateStruct;
  readonly termsLink = links.loadUserTerms;

  @ViewChild(CaptchaDirective) captcha: CaptchaDirective;
  @ViewChild('phoneField') phoneField: ElementRef;

  isLoading = false;
  isLoadingRegions = false;
  isLoadingCitizenships = false;
  isLoadingEsiaData = false;
  isMobile = false;
  error = false;
  errorMsg: string;
  phoneStart = '+7 (';

  getRegionsServerError = false;
  getCitizenshipsServerError = false;
  getEsiaDataServerError = false;

  readonly registrationForm: UntypedFormGroup;
  readonly registrationFormEnum = RegistrationForm;

  get isWorkingInRussianFederation() {
    return this._isWorkingInRussianFederation;
  }

  get isCitizenOfRussianFederation() {
    return this._isCitizenOfRussianFederation;
  }

  get regions(): Array<EntityIdAndNameSchema> {
    return this._regions;
  }

  get citizenship(): Array<EntityIdAndNameSchema> {
    return this._citizenship;
  }

  get formErrors(): string[] {
    return _keys(this.registrationForm.errors);
  }

  get snilsInvalid(): boolean {
    const snils = this.registrationForm.get(this.registrationFormEnum.SNILS);
    return (
      snils.invalid && snils.touched
    );
  }

  get snilsErrors(): string[] {
    return _keys(this.registrationForm.get(this.registrationFormEnum.SNILS).errors);
  }

  get lastnameInvalid(): boolean {
    const lastname = this.registrationForm.get(this.registrationFormEnum.LAST_NAME);
    return (
      lastname.invalid && lastname.dirty
    );
  }

  get termsAndConditionsInvalid(): boolean {
    const ternsAndConditions: UntypedFormControl = this.registrationForm.get(this.registrationFormEnum.ACCEPT_TERMS_AND_CONDITIONS) as UntypedFormControl;
    return (
      ternsAndConditions.invalid && ternsAndConditions.dirty
    );
  }

  get acceptReadInvalid(): boolean {
    const acceptReadControl = this.registrationForm.get(this.registrationFormEnum.ACCEPT_READ);
    return acceptReadControl.invalid && acceptReadControl.dirty;
  }

  get captchaInvalid(): boolean {
    const captcha: UntypedFormControl = this.registrationForm.get(this.registrationFormEnum.Y_CAPTCHA_RESPONSE) as UntypedFormControl;
    return (
      captcha.invalid && captcha.dirty
    );
  }

  get lastnameErrors(): string[] {
    return _keys(this.registrationForm.get(this.registrationFormEnum.LAST_NAME).errors);
  }

  get nameInvalid(): boolean {
    const name = this.registrationForm.get(this.registrationFormEnum.FIRST_NAME);
    return (
      name.invalid && name.dirty
    );
  }

  get captchaErrors(): string[] {
    return _keys(this.registrationForm.get(this.registrationFormEnum.Y_CAPTCHA_RESPONSE).errors);
  }

  get nameErrors(): string[] {
    return _keys(this.registrationForm.get(this.registrationFormEnum.FIRST_NAME).errors);
  }

  get patronymicInvalid(): boolean {
    const patronymic = this.registrationForm.get(this.registrationFormEnum.MIDDLE_NAME);
    return (
      patronymic.invalid && patronymic.dirty
    );
  }

  get patronymicErrors(): string[] {
    return _keys(this.registrationForm.get(this.registrationFormEnum.MIDDLE_NAME).errors);
  }

  get emailInvalid(): boolean {
    const email = this.registrationForm.get(this.registrationFormEnum.EMAIL);
    return (
      email.invalid && email.touched
    );
  }

  get emailErrors(): string[] {
    return _keys(this.registrationForm.get(this.registrationFormEnum.EMAIL).errors);
  }

  get phoneNumberInvalid(): boolean {
    const phoneNumber = this.registrationForm.get(this.registrationFormEnum.PHONE);
    return (
      phoneNumber.invalid && phoneNumber.dirty
    );
  }

  get phoneNumberErrors(): string[] {
    return _keys(this.registrationForm.get(this.registrationFormEnum.PHONE).errors);
  }

  get regionInvalid(): boolean {
    const region = this.registrationForm.get(this.registrationFormEnum.REGION_ID);
    return (
      region.invalid && region.dirty
    );
  }

  get regionErrors(): string[] {
    return _keys(this.registrationForm.get(this.registrationFormEnum.REGION_ID).errors);
  }

  get citizenshipInvalid(): boolean {
    const citizenship = this.registrationForm.get(this.registrationFormEnum.CITIZENSHIP_ID);
    return (
      citizenship.invalid && citizenship.dirty
    );
  }

  get citizenshipErrors(): string[] {
    return _keys(this.registrationForm.get(this.registrationFormEnum.CITIZENSHIP_ID).errors);
  }

  get genderInvalid(): boolean {
    const gender = this.registrationForm.get(this.registrationFormEnum.GENDER);
    return (
      gender.invalid && gender.dirty
    );
  }

  get genderErrors(): string[] {
    return _keys(this.registrationForm.get(this.registrationFormEnum.GENDER).errors);
  }

  get birthDateInvalid(): boolean {
    const birthDate = this.registrationForm.get(this.registrationFormEnum.DATE_OF_BIRTH);
    return (
      birthDate.invalid && birthDate.dirty
    );
  }

  get birthDateErrors(): string[] {
    return _keys(this.registrationForm.get(this.registrationFormEnum.DATE_OF_BIRTH).errors);
  }

  get termsAndConditionsErrors(): string[] {
    return _keys(this.registrationForm.get(this.registrationFormEnum.ACCEPT_TERMS_AND_CONDITIONS).errors);
  }

  get acceptReadErrors(): string[] {
    return _keys(this.registrationForm.get(this.registrationFormEnum.ACCEPT_READ).errors);
  }

  get snilsMask(): Array<string | RegExp> {
    return snilsInputMask;
  }

  get phoneMask(): Array<string | RegExp> {
    return phoneInputMask;
  }

  get helpUrl(): string {
    return links.loginHelp;
  }

  get getIsWorkingInRussianFederation(): boolean {
    return this.isWorkingInRussianFederation;
  }

  constructor(
    private cd: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
    private apiService: ApiService,
    private stateService: StateService,
    private transition: Transition,
    private esiaService: EsiaService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private mobileAppService: MobileAppService
  ) {
    const dt = DateTime.local();
    const { day, month, year } = dt.minus({ years: 16 });

    this.minDate = {
      day: dt.day,
      month: dt.month,
      year: dt.minus({ years: 100 }).year
    };

    this.maxDate = {
      day,
      month,
      year
    };

    this.registrationForm = this.formBuilder.group({
      [this.registrationFormEnum.SNILS]: ['', [Validators.required, snilsValidation()]],
      [this.registrationFormEnum.FIRST_NAME]: ['', [Validators.required, this.validationFIO()]],
      [this.registrationFormEnum.LAST_NAME]: ['', [Validators.required, this.validationFIO()]],
      [this.registrationFormEnum.MIDDLE_NAME]: ['', [this.validationFIO()]],
      [this.registrationFormEnum.GENDER]: ['', [Validators.required]],
      [this.registrationFormEnum.REGION_ID]: [''],
      [this.registrationFormEnum.CITIZENSHIP_ID]: ['', [Validators.required]],
      [this.registrationFormEnum.EMAIL]: ['', [Validators.required, Validators.email]],
      [this.registrationFormEnum.PHONE]: ['', [Validators.required, phoneValidation(this.isWorkingInRussianFederation)]],
      [this.registrationFormEnum.ACCEPT_TERMS_AND_CONDITIONS]: [false, [Validators.requiredTrue]],
      [this.registrationFormEnum.ACCEPT_READ]: [false, [Validators.requiredTrue]],
      [this.registrationFormEnum.DATE_OF_BIRTH]: [null, [Validators.required]],
      [this.registrationFormEnum.Y_CAPTCHA_RESPONSE]: ['', [Validators.required]],
      [this.registrationFormEnum.WORKING_IN_RUSSIAN_FEDERATION]: [true, []],
      [this.registrationFormEnum.EMPLOYED_IN_RF]: [true, []]
    });
  }

  afterLoad(element: ElementRef): void {
    // sync masked value from input and form value
    const value = element.nativeElement.value;
    const snilsField = this.registrationForm.get(this.registrationFormEnum.SNILS);
    if (value !== snilsField.value) {
      snilsField.setValue(value);
    }
  }

  captchaHandler(token: string) {
    const controller = this.registrationForm.controls[this.registrationFormEnum.Y_CAPTCHA_RESPONSE];
    controller.setValue(token);
    this.cd.detectChanges();
  }

  ngOnInit() {
    this.getRegionsFormApi();
    this.getCitizenshipsFromApi();

    const { esiaDataToken, fromMobile } = this.transition.params();
    this.fromMobile = fromMobile === Boolean(true).toString();
    if (esiaDataToken) {
      this.loadEsiaData(esiaDataToken);
    }

    this.isMobile = this.mobileAppService.isMobileApp();
  }

  ngOnDestroy(): void {
    if (this.fromMobile) {
      this.mobileAppService.closeCurrentBrowser();
    }
  }

  loadEsiaData(esiaDataToken): void {
    this.isLoadingEsiaData = true;
    this.esiaService.getEsiaData(esiaDataToken)
      .pipe(finalize(() => this.isLoadingEsiaData = false))
      .subscribe((esiaData: RegistrationUserDataFromEsia) => {
        this.setEsiaInForm(esiaData);
      }, () => this.getEsiaDataServerError = true);
  }

  getRegionsFormApi(): void {
    this.isLoadingRegions = true;
    this.apiService
      .getRegions()
      .pipe(
        take(1),
        finalize(() => this.isLoadingRegions = false)
      )
      .subscribe(
        (res) => {
          this._regions = res;
        },
        (err) => {
          this.getRegionsServerError = true;
        }
      );
  }

  getCitizenshipsFromApi(): void {
    this.isLoadingCitizenships = true;
    this.apiService
      .getCitizenships()
      .pipe(
        take(1),
        finalize(() => this.isLoadingCitizenships = false)
      )
      .subscribe(
        (res) => {
          this._citizenship = res;
        },
        (err) => {
          this.getCitizenshipsServerError = true;
        }
      );
  }

  createUser(): void {
    if (this.registrationForm.invalid) {
      this.markDirtyFields();
      return;
    }

    this.isLoading = true;
    this.registrationForm.disable();

    const formValue = this.registrationForm.value;
    const formattedRegisterValue: PersonRegisterSchema = { ...formValue };
    const snilsForSend = formValue.snils ? clearNonNumbers(formValue.snils) : this.foraingersCrazySnilsGenerator();

    formattedRegisterValue.snils = snilsForSend;
    formattedRegisterValue.dateOfBirth = this.formatDate(formValue.dateOfBirth);
    formattedRegisterValue.phone = clearNonNumbers(formValue.phone);
    formattedRegisterValue.firstName = this.nameToUpperCaseAndDeleteSpaces(formValue.firstName);
    formattedRegisterValue.lastName = this.nameToUpperCaseAndDeleteSpaces(formValue.lastName);
    formattedRegisterValue.middleName = this.nameToUpperCaseAndDeleteSpaces(formValue.middleName);
    formattedRegisterValue.isEmployedInRF = this.isWorkingInRussianFederation;
    formattedRegisterValue.email = formattedRegisterValue.email.toLowerCase();

    const registerNewPerson = this.isEsia
      ? this.apiService.registerNewPerson(formattedRegisterValue)
      : this.apiService.registerNewTemporaryUser(formattedRegisterValue);

    registerNewPerson.subscribe(
      () => {
        const message = this.translateService.instant(
          'registration.successConfirmation',
          {
            email: formattedRegisterValue.email
          }
        );

        this.dialogService.message(message, 'common.popup.ok')
          .subscribe(() => {
            this.afterSuccessfulRegistration();
          });
      },
      (error) => {
        this.isLoading = false;
        this.registrationForm.enable();
        if (this.captcha) {
          this.captcha.clear();
        }
        if (error.error.globalErrors && error.status !== 500 && !error.error.globalErrors.some(e => e.code === 'USER_EXISTS')) {
          this.loadFormErrors(['formInvalid']);
        }
        if (error.error.globalErrors && error.error.globalErrors.some(e => e.code === 'captchaIsNotVerified')) {
          this.loadFormErrors(['captchaIsNotVerified']);
        }
        if (error.error.fieldErrors && error.error.fieldErrors.some(e => e.code === 'uniqueEmail')) {
          this.loadFormErrors(['uniqueEmail'], this.registrationFormEnum.EMAIL);
        }
        if (error.error.fieldErrors && error.error.fieldErrors.some(e => e.code === 'personSnils')) {
          this.loadFormErrors(['personSnils'], this.registrationFormEnum.SNILS);
        }
      }
    );
  }

  formatDate(date: DateTime): string {
    return DateTime.fromMillis(Number(date)).toFormat('yyyy-MM-dd');
  }

  nameToUpperCaseAndDeleteSpaces(name: string): string {
    if (!name) {
      return;
    }
    const string = name.trim();
    return `${string[0].toUpperCase()}${string.slice(1).toLowerCase()}`;
  }

  onEmailInputFocusOut() {
    const emailFormControl: AbstractControl = this.registrationForm.get(this.registrationFormEnum.EMAIL);
    emailFormControl.setValue(emailFormControl.value.trim());
  }

  phoneFocus() {
    if (!this.registrationForm.get(this.registrationFormEnum.PHONE).dirty
      || this.registrationForm.get(this.registrationFormEnum.PHONE).value === '') {
      this.registrationForm.get(this.registrationFormEnum.PHONE).setValue(this.phoneStart);
    }
  }

  phoneClick() {
    if (this.registrationForm.get(this.registrationFormEnum.PHONE).value === ''
      || this.registrationForm.get(this.registrationFormEnum.PHONE).value === this.phoneStart) {
      this.phoneField.nativeElement.setSelectionRange(this.phoneStart.length, this.phoneStart.length);
    }
  }

  phoneBlur() {
    if (!this.registrationForm.get(this.registrationFormEnum.PHONE).dirty
      || this.registrationForm.get(this.registrationFormEnum.PHONE).value === this.phoneStart) {
      this.registrationForm.get(this.registrationFormEnum.PHONE).setValue('');
      this.phoneField.nativeElement.setSelectionRange(this.phoneStart.length, this.phoneStart.length);
    } else if (this.registrationForm.get(this.registrationFormEnum.PHONE).value === ''
      || this.registrationForm.get(this.registrationFormEnum.PHONE).value === this.phoneStart
      && this.registrationForm.get(this.registrationFormEnum.PHONE).errors.required === true) {
      this.registrationForm.get(this.registrationFormEnum.PHONE).setErrors(null);
    }
  }

  private setEsiaInForm(esiaData: RegistrationUserDataFromEsia): void {
    this.isEsia = true;

    Object
      .keys(formFieldsToEsiaDataMap)
      .forEach((key: string) => {
        let esiaFieldData: any = esiaData[key];

        if (esiaFieldData && esiaFieldData.id) {
          esiaFieldData = esiaFieldData.id;
        }

        if (esiaFieldData) {
          if (key === 'birthDate') {
            esiaFieldData = DateTime.fromFormat(esiaFieldData, 'yyyy-MM-dd').toMillis();
          }

          this.registrationForm.get(formFieldsToEsiaDataMap[key].esiaFieldName).setValue(esiaFieldData);
          if (formFieldsToEsiaDataMap[key].isBlocked) {
            this.registrationForm.get(formFieldsToEsiaDataMap[key].esiaFieldName).disable();
          }
        }
      });
  }

  private afterSuccessfulRegistration(): void {
    if (this.isEsia) {
      if (this.fromMobile) {
        this.mobileAppService.closeCurrentBrowser();
      } else {
        this.esiaService.startEsiaLogin();
      }
    } else {
      this.stateService.go('root.login');
    }
  }

  private showErrorMessage(): void {
    this.error = true;
    this.translateService
      .get('errorMessage.switchLanguage')
      .pipe(
        take(1)
      )
      .subscribe(errorText => {
        this.errorMsg = errorText;
      });
  }

  private loadFormErrors(errors: string[], field?: string) {
    const errs = _reduce(errors, (result, val) => {
      result[val] = true;
      return result;
    }, {});
    const formErrors = field ? this.registrationForm.get(field) : this.registrationForm;
    formErrors.setErrors(errs);
  }

  private markDirtyFields(): void {
    _keys(this.registrationForm.controls)
      .forEach((key: string) => {
        const formControl: UntypedFormControl = this.registrationForm.get(key) as UntypedFormControl;
        formControl.markAsDirty();
      });
  }

  private validationFIO(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const regExp = this.isEsia ?
        /[^а-яёА-ЯЁ\-\s0-9]/g :
        /[^а-яёА-ЯЁ\-\s]/g;
      let value = control.value;
      if (!value || (
        !regExp.test(value) && value[0] !== '-' && value[0] !== ' '
      )) {
        this.error = false;
        this.errorMsg = '';
        return null;
      } else {
        if (value[0] === '-' || value[0] === ' ') {
          value = value.substr(1);
        }
        control.setValue(value.replace(regExp, ''));
        this.showErrorMessage();
        return control.errors;
      }
    };
  }

  OnCitizenshipChange($event: string) {
    if (RUSSIAN_FEDERATION.id === $event) {
      this._isCitizenOfRussianFederation = true;
      this._isWorkingInRussianFederation = true;
      this.registrationForm.get(this.registrationFormEnum.WORKING_IN_RUSSIAN_FEDERATION).setValue(true);
    } else {
      this._isCitizenOfRussianFederation = false;
    }
    this.setReactiveValidations();
  }

 OnWorkingInRFChange($event: boolean) {
    this._isWorkingInRussianFederation = $event;
    this.setReactiveValidations();
  }

  showIsWorkingInRFCheckpoint() {
    return !(this.isCitizenOfRussianFederation === undefined || this.isCitizenOfRussianFederation);
  }

  maskingForaingersPhone () {
    const  phoneFormControl: AbstractControl = this.registrationForm.get(this.registrationFormEnum.PHONE);
    this.phoneLayInputOnlyDigits(phoneFormControl);
  }

  private setSnilsValidation() {
    const snilsFormControl: AbstractControl = this.registrationForm.get(this.registrationFormEnum.SNILS);
    if (!this.isWorkingInRussianFederation && !this.isCitizenOfRussianFederation) {
      snilsFormControl.setValidators([snilsValidation()]);
    } else {
      snilsFormControl.setValidators([Validators.required, snilsValidation()]);
    }
    snilsFormControl.updateValueAndValidity();
  }

  private setRegionValidation() {
    const regionFormControl: AbstractControl = this.registrationForm.get(this.registrationFormEnum.REGION_ID);
    if (this.isWorkingInRussianFederation) {
      this.registrationForm.addControl(this.registrationFormEnum.REGION_ID, new UntypedFormControl('', Validators.required));
    } else {
      this.registrationForm.removeControl(this.registrationFormEnum.REGION_ID);
    }
  }

  private setPhoneValidation() {
    const  phoneFormControl: AbstractControl = this.registrationForm.get(this.registrationFormEnum.PHONE);
    phoneFormControl.setValidators([Validators.required, phoneValidation(this.isWorkingInRussianFederation)]);
    phoneFormControl.reset();
  }

  private setReactiveValidations() {
    this.setPhoneValidation();
    this.setSnilsValidation();
    this.setRegionValidation();
  }

  private foraingersCrazySnilsGenerator() {
    const currentDate = new Date();
    let day = '' + currentDate.getDate(),
      month = '' + (currentDate.getMonth() + 1),
      year = ('' + currentDate.getFullYear());
    day = (day.length < 2 ? '0' : '').concat(day);
    month = (month.length < 2 ? '0' : '').concat(month);
    year = year.slice(2);
    let crazySnils = '';
    let crazySnilsFormattedString = '';
    do {
      crazySnils = year + month + day + leftWithZeros(Math.floor(Math.random() * 100000), 5);
      crazySnilsFormattedString = [
        crazySnils.slice(0, 3), '-',
        crazySnils.slice(3, 6), '-',
        crazySnils.slice(6, 9), ' ',
        crazySnils.slice(9)
      ].join('');
    } while (snilsStringValidateFunction(crazySnilsFormattedString));
    return crazySnils;

    function leftWithZeros (number: number, length: number) {
       let str = '' + number;
       while (str.length < length) {
         str = '0' + str;
       }
       return str;
    }
  }

  private phoneLayInputOnlyDigits(phoneFormControl: AbstractControl) {
    if (phoneFormControl.value === null || phoneFormControl.value === '') {
      phoneFormControl.setValue('+');
    } else {
      phoneFormControl.setValue('+' + phoneFormControl.value.replace(/\D/g, ''));
    }
  }
}
