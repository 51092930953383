import { Component, OnInit } from '@angular/core';
import {MobileAppService} from '../mobile-app.service';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-have-to-update-dialog',
  templateUrl: './have-to-update-dialog.component.html',
  styleUrls: ['./have-to-update-dialog.component.scss']
})
export class HaveToUpdateDialogComponent implements OnInit {

  constructor(
    private readonly mobileAppService: MobileAppService
  ) { }

  ngOnInit(): void {
  }

  goToStore() {
    if (this.mobileAppService.isMobileIosApp()) {
      this.mobileAppService.goToOtherSite(environment.linkAppleStore);
    } else if (this.mobileAppService.isMobileAndroidApp()) {
      this.mobileAppService.goToOtherSite(environment.linkGoogleStore);
    }
  }
}
