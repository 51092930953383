import { Component, OnInit } from '@angular/core';
import {StateService} from '@uirouter/core';
import _get from 'lodash-es/get';
import {ApiService} from '../api/api.service';
import {Observable} from 'rxjs';
import {SimpleResponseDto} from '@rsmu/portal-api';

@Component({
  selector: 'app-recovery',
  templateUrl: './recovery.component.html',
  styleUrls: ['./recovery.component.scss']
})
export class RecoveryComponent implements OnInit {

  recoveryStatusResponse$: Observable<SimpleResponseDto>;

  constructor(
    private readonly stateService: StateService,
    private readonly apiService: ApiService
  ) { }

  ngOnInit() {
    this.recoveryStatusResponse$ = this.apiService.commonRecovery({param: this.codeFromState});
  }

  back() {
    this.stateService.go('root.login');
  }

  get recoveryTypeFromState(): string {
    const recoveryType = _get(this.stateService.params, 'recoveryType', null);
    if (recoveryType === 'password' || recoveryType === 'email') {
      return recoveryType;
    }
    return null;
  }

  returnButtonName(recoveryType: string) {
    if (recoveryType === 'email') {
      return 'backToPortal';
    }
    return 'backToLogin';
  }

  private get codeFromState(): string {
    const code = _get(this.stateService.params, 'code', null);
    return code;
  }
}
