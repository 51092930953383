import { Injectable } from '@angular/core';
import { CacheService } from '../cache-service/cache.service';
import { RedirectService } from '../redirect/redirect.service';
import { SecurityService } from '../security/security.service';
import { forkJoin, Observable, ReplaySubject, Subject, zip } from 'rxjs';
import { ApiService } from '../api/api.service';
import { distinctUntilChanged, filter, finalize, map, pluck, take, takeUntil, tap } from 'rxjs/operators';
import { RouteService } from '../app-routing/route.service';
import { CyclesService } from '../cycles/cycles.service';
import {
  ProfileSettings,
  VisibilityAchievementWithOptions,
  VisibilityCycleWithOptions,
  VisibilityPortfolioWithOptions,
  VisibilitySetting,
  VisibilitySettingWithOptions
} from './profile-settings';
import {
  EmployerDto,
  EntityIdAndNameSchema,
  UserSettings,
  VisibilityAchievement,
  VisibilityCycle,
  VisibilityCyclesRequestBodyDto,
  VisibilityPortfolio,
  VisibilityPortfoliosRequestBodyDto,
  VisibilityRules,
  VisibilityRulesDto,
  VisibilityTypeEnum
} from '@rsmu/portal-api';
import { DropdownTreeNode } from '../app-commons/dropdown-tree/dropdown-tree.component';
import _get from 'lodash-es/get';
import _isArray from 'lodash-es/isArray';
import _isNil from 'lodash-es/isNil';
import _sortBy from 'lodash-es/sortBy';
import { VisibilityAchievementRequestBody } from '@rsmu/portal-api';

const MOCK_SETTINGS_OPTIONS: VisibilityRules = {
  achievements: [
    {
      id: VisibilityTypeEnum.AllEmployer,
      name: 'Всем работодателям',
      availableEmployers: false
    },
    {
      id: VisibilityTypeEnum.AllMyEmployer,
      name: 'Всем моим работодателям',
      availableEmployers: false
    },
    {
      id: VisibilityTypeEnum.OnlySelected,
      name: 'Определенным работодателям',
      availableEmployers: true
    },
    {
      id: VisibilityTypeEnum.OnlyMe,
      name: 'Только мне',
      availableEmployers: false
    }
  ],
  cycles: [
    {
      id: VisibilityTypeEnum.AllEmployer,
      name: 'Всем работодателям',
      availableEmployers: false
    },
    {
      id: VisibilityTypeEnum.AllMyEmployer,
      name: 'Всем моим работодателям',
      availableEmployers: false
    },
    {
      id: VisibilityTypeEnum.OnlySelected,
      name: 'Определенным работодателям',
      availableEmployers: true
    },
    {
      id: VisibilityTypeEnum.OnlyMe,
      name: 'Только мне',
      availableEmployers: false
    }
  ],
  portfolio: [
    {
      id: VisibilityTypeEnum.AllEmployer,
      name: 'Всем работодателям',
      availableEmployers: false
    },
    {
      id: VisibilityTypeEnum.AllMyEmployer,
      name: 'Всем моим работодателям',
      availableEmployers: false
    },
    {
      id: VisibilityTypeEnum.OnlySelected,
      name: 'Определенным работодателям',
      availableEmployers: true
    },
    {
      id: VisibilityTypeEnum.AllWithoutSelected,
      name: 'Кроме выбранных работодателей',
      availableEmployers: true
    }
  ]
};

const MOCK_USER_EMPLOYERS: EntityIdAndNameSchema[] = [
  {
    id: 'pirogova',
    name: 'РНИМУ им.Пирогова'
  },
  {
    id: 'stavropol',
    name: 'ГКБ №55 г.Ставрополя'
  }
];

const MOCK_VISIBILITY_ACHIEVEMENT: VisibilityAchievement = {
  visibilityRule: {
    visibilityType: VisibilityTypeEnum.OnlySelected,
    selectedItems: [MOCK_USER_EMPLOYERS[0]]
  }
};
const MOCK_VISIBILITY_CYCLES: VisibilityCycle[] = [
  {
    cycle: {
      id: 'apdsvmgaosk[askv',
      name: 'Кардиология'
    },
    visibilityRule: {
      visibilityType: VisibilityTypeEnum.OnlySelected,
      selectedItems: [MOCK_USER_EMPLOYERS[0]]
    }
  },
  {
    cycle: {
      id: 'gsdgsdg[askv',
      name: 'Лечебное дело'
    },
    visibilityRule: {
      visibilityType: VisibilityTypeEnum.OnlyMe
    }
  }
];
const MOCK_VISIBILITY_PORTFOLIO: VisibilityPortfolio[] = [
  {
    cycle: {
      id: 'apdsvmgaosk[askv',
      name: 'Кардиология'
    },
    visibilityRule: {
      visibilityType: VisibilityTypeEnum.AllWithoutSelected,
      selectedItems: [MOCK_USER_EMPLOYERS[1]]
    }
  },
  {
    cycle: {
      id: 'gsdgsdg[askv',
      name: 'Лечебное дело'
    },
    visibilityRule: {
      visibilityType: VisibilityTypeEnum.OnlySelected
    }
  }
];

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private settings$: ReplaySubject<ProfileSettings> | null = null;
  private visibilityRules$: ReplaySubject<VisibilityRules> | null = null;
  private userEmployers$: ReplaySubject<EntityIdAndNameSchema[]> | null = null;
  private cancelLoad$ = new Subject<void>();
  private readonly settingsChanges$: ReplaySubject<ProfileSettings | null>;

  constructor(
    private cacheService: CacheService,
    private routeService: RouteService,
    private redirectService: RedirectService,
    private securityService: SecurityService,
    private apiService: ApiService,
    private cyclesService: CyclesService,
  ) {
    this.settingsChanges$ = new ReplaySubject<ProfileSettings>(1);

    securityService.getUserChangedEvent().subscribe(userData => {
      this.clearCache();
      if (userData == null) {
        this.onUserRemoved();
      } else {
        this.onNewUser();
      }
    });
  }

  onSettingChanges$(settingKey: keyof ProfileSettings): Observable<ProfileSettings[keyof ProfileSettings]> {
    return this.settingsChanges$
      .pipe(
        distinctUntilChanged(),
        filter(settings => !!settings),
        map(settings => settings[settingKey])
      );
  }

  getSettings(): Observable<ProfileSettings> {
    if (!this.settings$) {
      this.settings$ = new ReplaySubject(1);
      this.loadSettings().subscribe(null, error => {
        if (this.settings$) {
          this.settings$.error(error);
          this.settings$ = null;
        }
      });
    }
    return this.settings$.pipe(take(1));
  }

  getVisibilityRules(type: keyof VisibilityRules): Observable<Array<VisibilityRulesDto>> {
    if (!this.visibilityRules$) {
      this.visibilityRules$ = new ReplaySubject<VisibilityRules>(1);
      this.apiService.retrieveVisibilityRules()
        .subscribe(
          (resp: VisibilityRules) => {
            this.visibilityRules$.next(resp);
          },
          error => {
            if (this.visibilityRules$) {
              this.visibilityRules$.error(error);
              this.visibilityRules$ = null;
            }
          }
        );
    }
    return this.visibilityRules$.pipe(
      take(1),
      pluck(type)
    );
  }

  getUserEmployers(): Observable<EmployerDto[]> {
    if (!this.userEmployers$) {
      this.userEmployers$ = new ReplaySubject<EntityIdAndNameSchema[]>(1);
      this.apiService.retrieveEmployers()
        .subscribe(
          (resp: EmployerDto[]) => {
            this.userEmployers$.next(resp);
          },
          error => {
            if (this.userEmployers$) {
              this.userEmployers$.error(error);
              this.userEmployers$ = null;
            }
          }
        );
    }
    return this.userEmployers$.pipe(take(1));
  }

  retrieveVisibilityAchievement(): Observable<VisibilityAchievement> {
    return this.apiService.retrieveVisibilityAchievement();
  }

  retrieveVisibilityCycles(): Observable<VisibilityCycle[]> {
    return this.apiService.retrieveVisibilityCycles();
  }

  retrieveVisibilityPortfolios(): Observable<VisibilityPortfolio[]> {
    return this.apiService.retrieveVisibilityPortfolios();
  }

  loadSettings(): Observable<ProfileSettings> {
    return zip(
      this.apiService.retrieveUserSettings(),
      this.getUserEmployers(),
      zip(
        this.retrieveVisibilityCycles(),
        this.retrieveVisibilityAchievement(),
        this.retrieveVisibilityPortfolios()
      ),
      zip(
        this.getVisibilityRules('cycles'),
        this.getVisibilityRules('achievements'),
        this.getVisibilityRules('portfolio')
      )
    )
      .pipe(
        takeUntil(this.cancelLoad$),
        map((
          [
            { isNotificationByMail, isUseAssistant, acknowledgePsyTests, isEnabledIfmoSync, userTips },
            employers,
            [cyclesResp, achievementsResp, portfoliosResp],
            [cyclesOptionsResp, achievementsOptionsResp, portfolioOptionsResp]
          ]: [
            UserSettings,
            EmployerDto[],
            [VisibilityCycle[], VisibilityAchievement, VisibilityPortfolio[]],
            [Array<VisibilityRulesDto>, Array<VisibilityRulesDto>, Array<VisibilityRulesDto>]
            ]): ProfileSettings => {
          const mapper = (setting: VisibilitySetting,
                          rules: Array<VisibilityRulesDto>): VisibilitySettingWithOptions => {
            const options = rules.map((rule: VisibilityRulesDto): DropdownTreeNode => {
              const option: DropdownTreeNode = {
                id: rule.id,
                name: rule.name
              };

              if (rule.availableEmployers) {
                option.values = employers;
              }
              return option;
            });

            let value;
            const visibilityRule = setting.visibilityRule;

            if (!_isNil(visibilityRule)) {
              value = {
                id: visibilityRule.visibilityType,
                values: _isArray(visibilityRule.selectedItems)
                  ? employers.filter(
                    (employer: EmployerDto) =>
                      visibilityRule.selectedItems.some((selectedItem) => selectedItem.id === employer.id)
                  )
                  : []
              };
            } else {
              const defaultValue = {
                id: options.find(o => _isNil(o.values)).id,
                values: []
              };

              value = defaultValue;
            }

            return {
              ...setting,
              options,
              value
            };
          };

          const cycles: Array<VisibilityCycleWithOptions> = _sortBy(
            cyclesResp.map(cycleResp => mapper(cycleResp, cyclesOptionsResp)),
            [e => _get(e, 'cycle.name', '')]
          );
          const achievements: VisibilityAchievementWithOptions = mapper(achievementsResp, achievementsOptionsResp);
          const portfolio: Array<VisibilityPortfolioWithOptions> = _sortBy(
            portfoliosResp.map(portfolioResp => mapper(portfolioResp, portfolioOptionsResp)),
            [e => _get(e, 'cycle.name', '')]
          );

          return {
            cycles: cycles,
            showAchievements: achievements,
            showPortfolio: portfolio,
            isUseAssistant,
            acknowledgePsyTests,
            isNotificationByMail,
            isEnabledIfmoSync,
            userTips
          };
        }),
        tap(settings => this.setSettings(settings))
      );
  }

  saveSettings(settings: ProfileSettings): Observable<any> {
    const { acknowledgePsyTests, cycles, isNotificationByMail, isUseAssistant,
      showAchievements, showPortfolio, isEnabledIfmoSync, userTips } = settings;

    const achievementVisibility: VisibilityAchievementRequestBody = {
      visibilityRule: showAchievements.visibilityRule
    };
    const cyclesVisibility: VisibilityCyclesRequestBodyDto[] = cycles
      .map(({ cycle, visibilityRule }: VisibilityCycleWithOptions) => {
        return {
          cycleId: cycle.id,
          visibilityRule
        };
      });
    const portfolioVisibility: VisibilityPortfoliosRequestBodyDto[] = showPortfolio
      .map(({ cycle, visibilityRule }: VisibilityPortfolioWithOptions) => {
        return {
          cycleId: cycle.id,
          visibilityRule
        };
      });

    return forkJoin<[Observable<any>, Observable<any>, Observable<any>, Observable<any>]>([
      this.apiService.changeUserSettings({ acknowledgePsyTests, isUseAssistant, isNotificationByMail, isEnabledIfmoSync, userTips }),
      this.apiService.saveVisibilityAchievement(achievementVisibility),
      this.apiService.saveVisibilityCycles(cyclesVisibility),
      this.apiService.saveVisibilityPortfolios(portfolioVisibility)
    ])
      .pipe(
        tap(() => this.clearCache()),
        finalize(() => this.settingsChanges$.next(settings))
      );
  }

  clearCache(): void {
    this.cancelLoad$.next();
    this.cancelLoad$.complete();
    this.cancelLoad$ = new Subject<void>();

    this.settingsChanges$.next(null);

    if (this.settings$) {
      this.settings$.complete();
      this.settings$ = null;
    }

    if (this.visibilityRules$) {
      this.visibilityRules$.complete();
      this.visibilityRules$ = null;
    }

    if (this.userEmployers$) {
      this.userEmployers$.complete();
      this.userEmployers$ = null;
    }
  }

  private onNewUser(): void {
    this.redirectService.fromLogin();
    this.cyclesService.cleanCycles();
  }

  private setSettings(settings: ProfileSettings): void {
    this.settingsChanges$.next(settings);

    if (this.settings$) {
      this.settings$.next(settings);
    }
  }

  private onUserRemoved(): void {
    this.cacheService.clearCaches();
    if (this.routeService.requiresAuth) {
      this.redirectService.toLogin();
    }
  }
}
