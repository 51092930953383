import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {MultiSelectDialogComponent} from './multi-select-dialog/multi-select-dialog.component';
import {map, take, takeUntil, tap} from 'rxjs/operators';


@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss']
})
export class MultiSelectComponent <T> implements OnInit, OnDestroy {
  private _label = 'label';
  private _listItems$: BehaviorSubject<T[]> = new BehaviorSubject<T[]>(null);
  private _isSelectAllAvailable = true;
  private _items: any[];
  private _items$: Observable<T[]>;
  private _itemsReload$: Observable<void>;
  private _itemValue = 'id';
  private _itemName = 'name';
  private _selectedItems$: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);
  private _selectionLimit: number = null;
  private _unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    public dialog: MatDialog
  ) { }

  @Input()
  preselectedItems: string[] = null;

  @Input()
  set label (label: string) {
    this._label = label;
  }

  @Input()
  set isSelectAllAvailable(isSelectAllAvailabe: boolean) {
    this._isSelectAllAvailable = isSelectAllAvailabe;
  }

  @Input()
  set items$ (items$: Observable<T[]>) {
    this._items$ = items$;
  }

  @Input()
  set items (items: T[]) {
    this._items = items;
  }

  @Input()
  set itemName (itemName: string) {
    this._itemName = itemName;
  }

  @Input()
  set itemsReload$ (itemsReload$: Observable<void>) {
    this._itemsReload$ = itemsReload$;
  }

  @Input()
  set itemValue(itemValue: string) {
    this._itemValue = itemValue;
  }

  @Input()
  set selectionLimit (selectionLimit: number) {
    this._selectionLimit = selectionLimit;
  }

  @Output()
  readonly selectedItems = new EventEmitter <T[]> ();

  get label(): string {
    return this._label;
  }

  get selectedItemsCount$(): Observable<number> {
    return this._selectedItems$.asObservable()
      .pipe(map(items => items.length));
  }

  get selectedOneItem (): string {
    const selectedItems = this._selectedItems$.getValue();
    if (selectedItems) {
      return selectedItems && selectedItems.length && selectedItems[0][this._itemName];
    }
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  ngOnInit(): void {
    if (this._items) {
      this._listItems$.next(this._items);
    }
    if (this._itemsReload$) {
      this._itemsReload$
        .pipe(takeUntil(this._unsubscribe$))
        .subscribe(() => {
          this._selectedItems$.next([]);
          this._listItems$.next(null);
        });
    }
    if (this.preselectedItems) {
      this.preselectingElements();
    }
  }

  openDialog() {
    const dialogRef = this.dialog.open(MultiSelectDialogComponent, {
      data: {
        isSelectAllAvailable: this._isSelectAllAvailable,
        listItems$: this._listItems$,
        items$: this._items$,
        itemName: this._itemName,
        itemsReload: this._itemsReload$,
        itemValue: this._itemValue,
        selectedItems$: this._selectedItems$,
        selectionLimit: this._selectionLimit
      },
      height: '90rem',
      width: '90rem'
    });
    dialogRef.afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        this.selectedItems.emit(this._selectedItems$.getValue());
    });
  }

  private preselectingElements() {
    if (!this.preselectedItems) {
      return;
    }
    if (this._listItems$.getValue()) {
      this._listItems$.next(this._listItems$.getValue().filter(lIt => !this.preselectedItems.includes(lIt[this._itemValue])));
      this._selectedItems$.next(this._listItems$.getValue().filter(lIt => this.preselectedItems.includes(lIt[this._itemValue])));
    } else {
      this._items$.pipe(take(1)).subscribe(items => {
        this._listItems$.next(items.filter(lIt => !this.preselectedItems.includes(lIt[this._itemValue])));
        this._selectedItems$.next(items.filter(lIt => this.preselectedItems.includes(lIt[this._itemValue])));
      });
    }
  }
}
