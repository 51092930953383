<div class="multiselect-dialog">
  <div class="multiselect-dialog__search-string">
    <mat-form-field class="multiselect-dialog__search-string" appearance="outline">
      <mat-label>{{ 'eduElementProspect.search.searchByName' | translate }}</mat-label>
      <input [formControl]="dialogStringControl" matInput type="text">
      <mat-icon class="multi-selectdialog__search-string__icon" color="primary"  matSuffix svgIcon="search"></mat-icon>
    </mat-form-field>
  </div>
  <div class="multi-select-dialog__chips-list" *ngIf="preselectedItems">
    <mat-chip-listbox multiple="true">
      <mat-chip-row class="multiselect-dialog__chips-list__item text text_small" color="primary"
                    disableRipple
                    highlighted
                    (removed)="onChipRemove(preselectedItem)" *ngFor="let preselectedItem of preselectedItems">
        {{preselectedItem[data?.itemName]}}
        <button matChipRemove>
          <mat-icon svgIcon="closed"></mat-icon>
        </button>
      </mat-chip-row>
    </mat-chip-listbox>
  </div>


<div #selectionList class="multiselect-dialog__items">
  <div class="multiselect-dialog__items-list">
    <ng-container *ngIf="itemsList$ | async as items; else loader"
                  [ngTemplateOutlet]="itemsList"
                  [ngTemplateOutletContext]="{items: items}"
    >
    </ng-container>
  </div>
</div>

<div class="multiselect-dialog__actions">
  <div class="multiselect-dialog__actions__item">
    <button mat-stroked-button color="primary" (click)="onCancel()">
      {{'common.multiselect.popup.deny' | translate}}</button>
  </div>
  <div class="multiselect-dialog__actions__item">
    <button mat-raised-button color="primary" (click)="onConfirm()">
      {{'common.multiselect.popup.confirm' | translate}}</button>
  </div>
</div>

<ng-template #itemsList let-items='items'>
  <mat-selection-list
    disableRipple
    [multiple]="false">
    <ng-container *ngFor="let item of items">
      <mat-list-item [className]="'text text_base'" [disabled]="!isCheckBoxEnabled" (click)="onItemClick(item, isCheckBoxEnabled)">
        <mat-divider></mat-divider>
        <div class="multiselect-dialog__items__item">
          <div title="{{item.shortName || item.fullName}}" class="multiselect-dialog__option__name text text_medium text_bold">
            {{item.shortName || item.fullName}}
          </div>
          <div *ngIf="item.inn || item.kpp" class="multiselect-dialog__option__additional text text_light" >
            <span *ngIf="item.inn">ИНН: {{item.inn}}</span>
            <span *ngIf="item.inn">КПП: {{item.kpp}}</span>
          </div>
          <div *ngIf="item.fullAddress" class="text_small text_light text_italic">{{item.fullAddress}}</div>

        </div>
        </mat-list-item>
      <mat-divider></mat-divider>
    </ng-container>
  </mat-selection-list>
</ng-template>

<ng-template #loader>
  <div class="loader">
    <mat-spinner
      color="primary"
      [strokeWidth]="2"
    ></mat-spinner>
  </div>
</ng-template>

