import { Component, Input } from '@angular/core';
import { TooltipTrigger } from '../tooltip/tooltip.directive';

@Component({
  selector: 'app-markers-zet',
  templateUrl: './markers-zet.component.html',
  styleUrls: ['./markers-zet.component.scss']
})
export class MarkersZetComponent {

  @Input() theme: string;
  @Input() themeTooltip = 'tooltip-text_big tooltip_white tooltip_flat';
  @Input() triggers: TooltipTrigger = 'hover';
  @Input() mastered: boolean;
  @Input() label: string;
}


