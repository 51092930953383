import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { DropdownTreeComponent } from './dropdown-tree/dropdown-tree.component';
import { MultiLineTextComponent } from './multi-line-text/multi-line-text.component';
import { LuxonPipe } from './luxon.pipe';
import { SliderButtonComponent } from './slider-button/slider-button.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { PopoverComponent } from './popover/popover.component';
import { MultiselectComponent } from './select/multiselect/multiselect.component';
import { SelectComponent } from './select/select/select.component';
import { RangeSliderComponent } from './range-slider/range-slider.component';
import { AddDocumentComponent } from './add-document/add-document.component';
import { TextMaskModule } from 'angular2-text-mask';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { SelfcontrolResultComponent } from '../user-account/selfcontrol-result/selfcontrol-result.component';
import { EduElNamesPipe } from './edu-el-names.pipe';
import { LikeDislikeComponent } from './like-dislike/like-dislike.component';
import { DateRangeTextComponent } from './date-range-text/date-range-text.component';
import { SwitchSetComponent } from './switch-set/switch-set.component';
import { TextFieldComponent } from './text-field/text-field.component';
import { CheckboxGroupComponent } from '../checkbox-group/checkbox-group.component';
import { TooltipComponent } from './tooltip/tooltip.component';
import { DatepickersModule } from './datepickers/datepickers.module';
import { NotificationBubbleComponent } from './notification-bubble/notification-bubble.component';
import { HelpInfoComponent } from './help-info/help-info.component';
import { RatingComponent } from './rating/rating.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { CycleManagementComponent } from './cycle-management/cycle-management.component';
import { UIRouterModule } from '@uirouter/angular';
import { DynamicComponentDirective } from './dynamic-component/dynamic-component.directive';
import { SanitizeBypassPipe } from './sanitize-bypass/sanitize-bypass.pipe';
import { MarkersZetComponent } from './markers-zet/markers-zet.component';
import { MultiZetComponent } from './multi-zet/multi-zet.component';
import { TimerComponent } from './timer/timer.component';
import { TimesAgoPipe } from './times-ago/times-ago.pipe';
import { EduViewDropdownComponent } from './edu-view-dropdown/edu-view-dropdown.component';
import { CellIconComponent } from './cell-icon/cell-icon.component';
import { ShowMoreComponentComponent } from './show-more-component/show-more-component.component';
import { IconPopupComponent } from './icon-popup/icon-popup.component';
import { PopupModule } from './popup/popup.module';
import { AutocompleteModule } from './autocomplete/autocomplete.module';
import { ValidationErrorComponent } from '../validation/validation-error/validation-error.component';
import { FormFieldComponent } from '../validation/form-field/form-field.component';
import { AccessibleComponent } from './accessible/accessible.component';
import { SanitizeUrlPipe } from './sanitize-url/sanitize-url.pipe';
import { AutoFocusDirective } from './auto-focus.directive';
import { AccessibleToggleComponent } from './accessible-toggle/accessible-toggle.component';
import { ScrollAndFocusDirective } from './scroll-and-focus.directive';
import { RadioComponent } from './radio/radio/radio.component';
import { TrapFocusModule } from './trap-focus/trap-focus.module';
import { PasswordComponent } from './password/password.component';
import { SwipeDetectorDirective } from './swipe-detector/swipe-detector.directive';
import { ElementSizeDirective } from './element-size/element-size.directive';
import { OutsideCyclesComponent } from './outside-cycles/outside-cycles.component';
import { InputNumberMaxDirective } from './input-number-validation/input-number-max.directive';
import { InputNumberMinDirective } from './input-number-validation/input-number-min.directive';
import { SortComponent } from './sort/sort.component';
import { SanitizeStylePipe } from './sanitize-style/sanitize-style.pipe';
import { AccessibleLinkModule } from './accessible-link/accessible-link.module';
import { TooltipDirective } from './tooltip/tooltip.directive';
import { ErrorNotificationComponent } from './error-notification/component/error-notification.component';
import { DropdownModule } from './dropdown/dropdown.module';
import { LoaderModule } from './loader/loader.module';
import { SanitizeResourcePipe } from './sanitize-resource/sanitize-resource.pipe';
import { HoverClassDirective } from './hover-class/hover-class.directive';
import {MultiSelectComponent} from './material/multi-select/multi-select.component';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MultiSelectDialogComponent} from './material/multi-select/multi-select-dialog/multi-select-dialog.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatListModule} from '@angular/material/list';
import {MatDividerModule} from '@angular/material/divider';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {Multiselect2Component} from './material/multiselect2/multiselect2.component';
import { Multiselect2DialogComponent } from './material/multiselect2/multiselect2-dialog/multiselect2-dialog.component';
import { Multiselect2DialogSsfComponent } from './material/multiselect2/multiselect2-dialog-ssf/multiselect2-dialog-ssf.component';
import {AutocompleteChangeOffsetDirective} from './directives/autocomplete-change-offset.directive';
import { AutocompleteServerSideFilterOptionsDirective } from './directives/autocomplete-server-side-filter-options.directive';
import { BaseSelectOrganizationComponent } from './organizations/base-select-organization/base-select-organization.component';
import { AddDppSelectOrganizationComponent } from './organizations/add-dpp-select-organization/add-dpp-select-organization.component';
import { SearchMultiselectOrganizationsComponent } from './organizations/search-multiselect-organizations/search-multiselect-organizations.component';
import { SearchMultiselectOrganizationsDialogComponent } from './organizations/search-multiselect-organizations/search-multiselect-organizations-dialog/search-multiselect-organizations-dialog.component';
import {MatChipsModule} from '@angular/material/chips';
import {NgxSliderModule} from '@angular-slider/ngx-slider';

const DECLARATIONS = [
  AutoFocusDirective,
  DropdownTreeComponent,
  TooltipDirective,
  MultiLineTextComponent,
  LuxonPipe,
  SliderButtonComponent,
  CheckboxComponent,
  PopoverComponent,
  ProgressBarComponent,
  MultiselectComponent,
  CellIconComponent,
  ShowMoreComponentComponent,
  SelectComponent,
  RangeSliderComponent,
  EduElNamesPipe,
  AddDocumentComponent,
  LikeDislikeComponent,
  SanitizeBypassPipe,
  SanitizeUrlPipe,
  SanitizeResourcePipe,
  TextFieldComponent,
  SwitchSetComponent,
  CheckboxGroupComponent,
  TooltipComponent,
  DateRangeTextComponent,
  ValidationErrorComponent,
  FormFieldComponent,
  NotificationBubbleComponent,
  HelpInfoComponent,
  RatingComponent,
  SelfcontrolResultComponent,
  ScrollAndFocusDirective,
  SidebarComponent,
  CycleManagementComponent,
  DynamicComponentDirective,
  ErrorNotificationComponent,
  TimerComponent,
  OutsideCyclesComponent,
  TimesAgoPipe,
  MarkersZetComponent,
  MultiZetComponent,
  EduViewDropdownComponent,
  IconPopupComponent,
  SwipeDetectorDirective,
  ElementSizeDirective,
  AccessibleComponent,
  AccessibleToggleComponent,
  RadioComponent,
  PasswordComponent,
  InputNumberMaxDirective,
  InputNumberMinDirective,
  SortComponent,
  SanitizeStylePipe,
  HoverClassDirective,
  MultiSelectComponent,
  MultiSelectDialogComponent,
  BaseSelectOrganizationComponent
];

const EXPORTED = [
  ...DECLARATIONS,
  AccessibleLinkModule,
  AutocompleteModule,
  TrapFocusModule,
  PopupModule,
  DropdownModule,
  LoaderModule,
  BaseSelectOrganizationComponent
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AngularSvgIconModule,
        AccessibleLinkModule,
        NgxSliderModule,
        TextMaskModule,
        DatepickersModule,
        TranslateModule,
        UIRouterModule,
        AutocompleteModule,
        TrapFocusModule,
        PopupModule,
        DropdownModule,
        LoaderModule,
        MatButtonModule,
        MatDialogModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatProgressSpinnerModule,
        MatDividerModule,
        MatListModule,
        MatChipsModule,
        MatCheckboxModule,
        MatPaginatorModule,
        MatAutocompleteModule,
        MatChipsModule
    ],
  declarations: [
    DECLARATIONS,
    Multiselect2Component,
    Multiselect2DialogComponent,
    Multiselect2DialogSsfComponent,
    AutocompleteChangeOffsetDirective,
    AutocompleteServerSideFilterOptionsDirective,
    AddDppSelectOrganizationComponent,
    SearchMultiselectOrganizationsComponent,
    SearchMultiselectOrganizationsDialogComponent,
  ],
  exports: [
    EXPORTED,
    Multiselect2Component,
    AutocompleteChangeOffsetDirective,
    AutocompleteServerSideFilterOptionsDirective,
    AddDppSelectOrganizationComponent,
    SearchMultiselectOrganizationsComponent
  ],
})
export class AppCommonsModule {
}
