import { Inject, Injectable } from '@angular/core';
import {RedirectDto, ScopeEnum, SimpleResponseDto, UserInfo} from '@rsmu/portal-api';
import { DOCUMENT } from '@angular/common';
import { OAuth2TokenDto, TypeAuthEnum } from '@rsmu/portal-api';
import { RegistrationUserDataFromEsia } from '@rsmu/portal-api';
import { Observable } from 'rxjs';
import _isNil from 'lodash-es/isNil';
import _isString from 'lodash-es/isString';

import { RouteService } from '../app-routing/route.service';
import { ApiService } from '../api/api.service';
import { SecurityService } from '../security/security.service';
import TokenData from '../security/token-data';
import { IdpService } from '../idp/idp.service';
import {RawParams, StateDeclaration, StateService} from '@uirouter/core';
import { MobileAppService } from '../mobile/mobile-app.service';
import { EsiaError } from '../esia-error/esia-error.component';
import {first, tap} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {EsiaLoadingDataDialogComponent} from './esia-loading-data-dialog/esia-loading-data-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class EsiaService {

  static readonly NOT_TRUSTED_USER_ERROR_KEY: EsiaError = 'notTrustedUser';

  constructor(
    private readonly apiService: ApiService,
    private readonly dialog: MatDialog,
    private readonly routeService: RouteService,
    @Inject(DOCUMENT) private readonly document: HTMLDocument,
    private readonly securityService: SecurityService,
    private readonly idpService: IdpService,
    private readonly mobileAppService: MobileAppService
  ) {
  }

  startEsiaLogin(params?: RawParams): void {
    let callback = this.routeService.getStateUrl(state => this.isEsiaCallback(state), params);
    const userExistsCallback: string | null = this.getUserExistsCallback();
    const esiaUserExistsCallback: string | null = !_isNil(userExistsCallback) ? userExistsCallback : null;
    const typeAuth: TypeAuthEnum = this.idpService.isIdpActive() ? TypeAuthEnum.RedirectIdp : TypeAuthEnum.TokenIdp;
    this.apiService.authByEsia(callback, esiaUserExistsCallback, typeAuth)
      .pipe(first())
      .subscribe((redirect: RedirectDto) => {
      if (this.mobileAppService.isMobileApp()) {
        this.mobileAppService.goToAuthEsia(redirect.url);
      } else {
        this.document.location.href = redirect.url;
      }
    });
  }

  handleCallbackData(token: string, userExists: boolean, error: string) {
    if (!token || error) {
      this.handleCallbackError(error);
    } else if (userExists) {
      this.apiService.esiaGenerationOAuth2Token(token)
        .subscribe(
          (securityToken: OAuth2TokenDto) => {
            const tokenData: TokenData = {
              access_token: securityToken.accessToken,
              expires_in: securityToken.expiresIn,
              refresh_token: securityToken.refreshToken,
              scope: '',
              token_type: '',
              idp_session_id: securityToken.idpSessionId
            };

            this.securityService.useNewToken(tokenData);
          },
          () => this.handleCallbackError()
        );
    } else {
      this.routeService.go(
        (state) => state.data && state.data.hasOwnProperty('redirectForEsiaFinish'),
        { esiaDataToken: token }
      );
    }
  }

  getEsiaData(dataToken: string): Observable<RegistrationUserDataFromEsia> {
    return this.apiService.registrationUserData(dataToken);
  }

  getUpdatePersonDataFromEsiaUrl(): Observable<RedirectDto> {
    let cbUrl = this.routeService.getStateUrl(state => this.isEsiaCallback(state));
    return this.apiService.getRedirectUrl(cbUrl)
      .pipe(tap((esiaUrlResponse: RedirectDto) => {
          if (esiaUrlResponse) {
            if (this.mobileAppService.isMobileApp()) {
              this.mobileAppService.goToAuthEsia(esiaUrlResponse.url);
            } else {
              window.location.href = esiaUrlResponse.url;
            }
          }
        })
      );
  }

  // noinspection JSMethodCanBeStatic
  isEsiaCallback(state: StateDeclaration): boolean {
    return state.data && state.data.hasOwnProperty('esiaCallback');
  }


  private handleCallbackError(error?: string): void {
    if (this.mobileAppService.isMobileApp() || _isNil(error) || !_isString(error)) {
      this.routeService.go((state) => state.data && state.data.hasOwnProperty('redirectForAuth'));
    } else {
      this.routeService.go('root.esiaErrors', { key: error });
    }
  }

  private getUserExistsCallback(): string | null {
    const urlWithoutHash: string = this
      .document
      .location
      .href
      .replace('#', '');
    const userExistsCallback = new URL(urlWithoutHash)
      .searchParams
      .get('userExistsCallback');

    return !_isNil(userExistsCallback) ? encodeURI(userExistsCallback) : null;
  }

  handlePersonDataUpdateCallback(esiaPersonDataId: any): void {
    this.apiService.findById(esiaPersonDataId)
      .pipe(first())
      .subscribe((userInfo: UserInfo) => {
        const dialogRef = this.dialog.open(EsiaLoadingDataDialogComponent, {
          data: {
            ...userInfo,
            esiaPersonDataId: esiaPersonDataId
          }
        });
        dialogRef.afterClosed()
          .pipe(first()).subscribe(() => {
            this.routeService
              .go('userAccount.ifmoRedirect').then();
          });
      });
  }

  mergePersonData (personDataId, sc: Array<ScopeEnum>): Observable<SimpleResponseDto> {
    return this.apiService.mergeById(personDataId, sc);
  }
}
