import { Component, OnInit } from '@angular/core';

import { EsiaService } from '../esia.service';

@Component({
  selector: 'app-esia-start',
  templateUrl: './esia-start.component.html',
  styleUrls: ['./esia-start.component.scss']
})
export class EsiaStartComponent implements OnInit {

  constructor(private esiaService: EsiaService) { }

  ngOnInit() {
    this.esiaService.startEsiaLogin();
  }

}
