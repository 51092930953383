<app-dropdown [theme]="'icon-popup'"
              (toggled)="isOpen = $event">
  <div class="icon-popup__toggle" appDropdownToggle>
    <svg-icon
      [title]="'tips.hidden.' + !isOpen | translate "
      class="icon icon_24_w icon_primary"
      src="{{icon}}">
    </svg-icon>
  </div>
  <div class="icon-popup__content" appDropdownContent>
    {{ text }}
  </div>
</app-dropdown>
