import {Injectable, NgZone} from '@angular/core';
import {UIRouter} from '@uirouter/core';
import {Observable, Observer, Subject} from 'rxjs';
import {first, take} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {Capacitor} from '@capacitor/core';
import {FileOpener} from '@awesome-cordova-plugins/file-opener';
import {File} from '@awesome-cordova-plugins/file';
import {Browser} from '@capacitor/browser';
import {App} from '@capacitor/app';
import {ApiService} from '../api/api.service';
import {MatDialog} from '@angular/material/dialog';
import {HaveToUpdateDialogComponent} from './have-to-update-dialog/have-to-update-dialog.component';

declare let rsmuStartUrl: string;
declare let rsmuStartUrlCallback: (string) => void;

@Injectable({
  providedIn: 'root'
})
export class MobileAppService {

  private readonly WINDOW_CLOSE_FLAG = 'rsmuShouldClose';

  constructor(
    private apiService: ApiService,
    private dialog: MatDialog,
    private router: UIRouter,
    private ngZone: NgZone,
  ) {
  }

  checkMobileAppVersion() {
    this.apiService.checkMobileVersion(environment.mobileAppVersion)
      .pipe(first())
      .subscribe(isValidVersion => {
        if (!isValidVersion) {
          this.dialog.open(HaveToUpdateDialogComponent, {
            hasBackdrop: true,
            disableClose: true,
            closeOnNavigation: false,
          });
        }
      });
  }

  initMobileRedirectListener(): void {
    if (this.isMobileApp()) {
      App.addListener('appUrlOpen', ({ url }) => {
        if (url.includes('nmforsmu://mobile/') && url.includes('esia-callback')) {
          this.ngZone.run(() => {
            window.location.href = url;
            Browser.close().then();
          });
        }
      });
    }
  }

  isHigherEducationInstance (): boolean {
    return environment.cabinetInstanceType === 'HigherEducation';
  }

  processStartUrl(): void {
    if (!this.isMobileApp()) {
      return;
    }

    if (typeof rsmuStartUrl !== 'undefined' && rsmuStartUrl != null) {
      this.redirectToUrl(rsmuStartUrl);
      rsmuStartUrl = null;
    }

    if (typeof rsmuStartUrlCallback === 'undefined') {
      window['rsmuStartUrlCallback'] = (url) => {
        this.redirectToUrl(url);
      };
    }
  }

  goToAuthEsia(url: string) {
    Browser.open({url: url, presentationStyle: 'fullscreen'}).then();
  }

  goToOtherSite(url: string) {
    Browser.open({url, presentationStyle: 'fullscreen'}).then();
  }

  downloadBlob(blob, fileName: string, callback, errorCallback) {
    const directoryPath = this.getFileDirectory();
    fileName = fileName.replace('/', '-');
    const fileUrl = directoryPath + fileName;
    File.createFile(directoryPath, fileName, true)
      .then(fileEntry => {
        fileEntry.createWriter(writer => {
          writer.onwriteend = () => {
            FileOpener.showOpenWithDialog(
              fileUrl,
              this.getMimeType(fileName),
            )
              .then(() => callback && this.ngZone.run(callback()))
              .catch(err => { throw new Error(err); });
          };
          writer.seek(0);
          writer.write(blob);
        });
      })
      .catch(err => {
        throw new Error(err); });
  }

  downloadFile(url: string, fileName: string): Observable<boolean> {
    const request = new XMLHttpRequest();
    const uri = encodeURI(url);
    request.open('GET', uri, true);
    request.responseType = 'blob';
    const subject = new Subject<boolean>();
    const errorCallback = () => this.ngZone.run(() => subject.next(false));
    request.onload = () => {
      const blob: Blob = request.response;
      // from cordova-plugin-file
      this.downloadBlob(blob, fileName, () => subject.next(true), errorCallback);
    };
    request.send(null);
    return subject.asObservable().pipe(take(1));
  }

  redirectToOutsideUrl(url: string): Observable<boolean> {
    return Observable.create((observer: Observer<boolean>) => {
      if (!this.isMobileApp()) {
        observer.next(false);
        observer.complete();
      } else {
        Browser.open({url, presentationStyle: 'fullscreen'}).then(); }
    });
  }

  isMobileApp(): boolean {
    return Capacitor.isNativePlatform();
  }

  isMobileAndroidApp(): boolean {
    return Capacitor.getPlatform() === 'android';
  }

  isMobileIosApp(): boolean {
    return Capacitor.getPlatform() === 'ios';
  }

  closeCurrentBrowser(): void {
    Browser.close().then();
  }

  getVoAppRedirectUrl(): string {
    if (this.isMobileIosApp()) {
      return 'nmforsmu://mobile/index.html';
    } else {
      return '../index.html';
    }
  }

  getSpoAppRedirectUrl(): string {
    if (this.isMobileIosApp()) {
      return 'nmforsmu://mobile/spo/index.html';
    } else {
      return (!this.isHigherEducationInstance() && '../' || '') + 'spo/index.html';
    }
  }

  getNmdAppRedirectUrl(): string {
    if (this.isMobileIosApp()) {
      return 'nmforsmu://mobile/nmd/index.html';
    } else {
      return (!this.isHigherEducationInstance() && '../' || '') + 'nmd/index.html';
    }
  }

  redirectToDifferentCabinetLink(): string {
    switch (environment.cabinetInstanceType) {
      case 'HigherEducation':
        return this.getSpoAppRedirectUrl();
      case 'ProfessionalEducation':
        return this.getVoAppRedirectUrl();
      case 'nonMedEducation':
        return this.getNmdAppRedirectUrl();
      default:
        return '';
    }
  }

  getRedirectCabinetMobileLink(cabinetUrl: string): string {
    return  cabinetUrl.includes('nmfo-nmd') ? this.getNmdAppRedirectUrl() :
      cabinetUrl.includes('nmfo-spo') ? this.getSpoAppRedirectUrl() :
      this.getVoAppRedirectUrl();
  }


  replaceSchemeForMobileUrl (url: string): string {
    return url;
  }

  private getFileDirectory(): string {
    return File.documentsDirectory ? File.documentsDirectory : File.externalApplicationStorageDirectory;
  }

  private getMimeType(fileName: string): string {
    const ext = fileName.split('.').pop();
    switch (ext) {
      case 'pdf':
        return 'application/pdf';

      case 'xlsx':
        return 'application/vnd.ms-excel';
    }
  }

  private redirectToUrl(url: string): void {
    this.ngZone.run(() => {
      this.router.urlService.url(url);
    });
  }
}
