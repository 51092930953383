import { Injectable } from '@angular/core';
import { ApiService } from '../../api/api.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { FeatureProperty } from '@rsmu/portal-api';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FeatureService {

  private readonly featureProperties$ = new BehaviorSubject<Array<FeatureProperty>>([]);

  private isInitialized = false;

  constructor(private readonly apiService: ApiService) {
  }

  isFeatureEnabled$(featureKey: string): Observable<boolean> {
    return this.getFeatureProperties$().pipe(
      map(
        (featuresArray: Array<FeatureProperty>) => featuresArray.some(
          ({ key, value }: FeatureProperty) => key === featureKey && value === Boolean(true).toString()
        )
      )
    );
  }

  private getFeatureProperties$(): Observable<Array<FeatureProperty>> {
    if (!this.isInitialized) {
      this.isInitialized = true;
      this.apiService.getFeatureProperties().subscribe((featureProperties: Array<FeatureProperty>) => {
        this.featureProperties$.next(featureProperties);
      });
    }

    return this.featureProperties$.asObservable();
  }

}
