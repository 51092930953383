import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { eduPlanReducer } from './reducer';
import { EffectsModule } from '@ngrx/effects';
import { EduPlanEffects } from './effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('eduPlan', eduPlanReducer),
    EffectsModule.forFeature([EduPlanEffects])
  ],
  declarations: []
})
export class EduPlanStoreModule { }
