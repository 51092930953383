import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import _map from 'lodash-es/map';
import _get from 'lodash-es/get';
import _filter from 'lodash-es/filter';
import _find from 'lodash-es/find';
import _isEqual from 'lodash-es/isEqual';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, of, Subscription } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { ManagedOverlayConnected } from '../dropdown/managed-overlay-connected';
import { KeyListenerService } from '../../key-listener/key-listener.service';
import { LayoutService } from '../layout/layout.service';
import { Overlay, OverlayConfig } from '@angular/cdk/overlay';

enum View {
  MOBILE,
  DESKTOP
}

export interface IAutocompleteItem {
  label: string;
  value: any;
  groupName?: string;
}

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteComponent),
      multi: true
    }
  ],
  encapsulation: ViewEncapsulation.None,
})
export class AutocompleteComponent extends ManagedOverlayConnected implements OnInit, OnChanges, ControlValueAccessor, OnDestroy {

  private _value: any;
  private _filteredItems: IAutocompleteItem[] = [];

  fieldModel = '';
  autocompleteItems: IAutocompleteItem[] = [];
  sortedByGroupsItems: IAutocompleteItem[] = [];

  isShowedTooltip = false;
  isButtonFocus = false;

  get filteredItems() {
    return this._filteredItems;
  }
  set filteredItems(newFilteredItems: IAutocompleteItem[]) {
    this._filteredItems = newFilteredItems;
    if (this.enableSelectEmpty) {
      this._filteredItems.unshift({
        label: this.placeholder,
        value: ''
      });
    }
  }
  selectedId: number;

  get isShowedOverlay(): boolean {
    return this.isOpen;
  }

  set isShowedOverlay(isOpen: boolean) {
    isOpen ? this.open() : this.close();
  }

  get isMobile(): boolean {
    return this.layoutService.isMobile;
  }

  isPressedUpDown = false;

  @Input() label = 'Field';
  @Input() requiredMark: string;
  @Input() placeholder;
  @Input() itemLabel;
  @Input() itemValue;
  @Input() itemGroupName;
  @Input() items;
  @Input() dataSource: (fieldModel: string) => Observable<any>;
  @Input() filterFunction: (items: IAutocompleteItem[], title: string, value?: string) => IAutocompleteItem[];
  @Input() updateFilterEvent: Observable<void>;
  @Input() helpText: string;
  @Input() searchDisabled = true;
  @Input() iconPin = false;
  @Input() isBold = false;
  @Input() isBig = false;
  @Input() isSmall = false;
  @Input() leftLabel = false;
  @Input() disabled;
  @Input() enableSelectEmpty = false;
  @Input() markEmptyValue = true;
  @Input() theme = '';
  @Input() noBorder = false;
  @Input() bigChevron = false;
  @Input() bigText = false;
  @Input() byGroups = false;
  @Input() orderGroups: Array<string>;
  @Input() customWidth = false;
  @Input() selectInputTextOnClick = false;
  @Input() skipFocus = false;
  @Input()
  get value(): any {
    return this._value;
  }

  set value(newValue: any) {
    this._value = newValue;

    if (this.onChange) {
      this.onChange(newValue);
    }
  }

  @Output() valueChange: EventEmitter<any> = new EventEmitter();

  private eventSubscription: Subscription;
  private previousView: View;

  private readonly keyTab = 9;
  private readonly keyArrowDown = 40;
  private readonly keyArrowUp = 38;
  private readonly keyEnter = 13;
  private readonly countItemMobileShowLoader = 30;

  @ViewChild('input', { static: true }) private input: ElementRef;

  @HostBinding('class.autocomplete-host') hostClass = true;
  onChange = (value) => { };
  onTouched = () => { };

  constructor(
    protected readonly injector: Injector,
    protected readonly keyListener: KeyListenerService,
    protected readonly layoutService: LayoutService,
    protected readonly overlay: Overlay,
    protected readonly viewContainer: ViewContainerRef,
  ) {
    super(injector, keyListener, layoutService, overlay, viewContainer);
  }

  ngOnInit() {
    const itemsObservable = this.dataSource ? this.loadFromDataSource() : of(this.items);

    this.layoutService
      .sizeChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.updateSizeOrReopenOverlay());

    itemsObservable.subscribe(items => {
      this.autocompleteItems = this.transformToAutocompleteItems(items);
      if (this.byGroups && this.orderGroups) {
        this.sortGroups();
        this.autocompleteItems = this.sortedByGroupsItems;
      }
      this.filteredItems = this.getPreFilteredItems();
      if (this.value && !this.value.length) {
        this.loadInitialValues(this.value);
      }
    });

    if (this.updateFilterEvent && this.filterFunction) {
      this.eventSubscription = this.updateFilterEvent.subscribe(() => {
        this.filteredItems = this.filterFunction(this.filteredItems, this.fieldModel, this.value);
      });
    }
  }

  ngOnChanges(change) {
    if (change.items) {
      this.autocompleteItems = this.transformToAutocompleteItems(this.items);
      this.filteredItems = this.getPreFilteredItems();
      this.loadInitialValues(this.value);
    }
    if (change.value) {
      this.loadInitialValues(this.value);
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe();
    }
  }

  onFocus(): void {
    if (this.skipFocus) {
      this.skipFocus = false;
    } else if (!this.isOpen) {
      this.open();
    }
  }

  close(): void {
    if (this.isMobile) {
      this.skipFocus = true;
    }
    super.close();
  }

  open(): void {
    if (this.isMobile && this.filteredItems.length > this.countItemMobileShowLoader) {
      const items = this.filteredItems;
      this._filteredItems = null;
      super.open();
      setTimeout(() => {
        this._filteredItems = items;
        this.updatePosition();
      });
    } else {
      super.open();
    }
  }

  keypressHandler(event: KeyboardEvent): void {

    // keycode is deprecated, but key a non-standard way on IE11 https://github.com/angular/material2/issues/12672
    // tslint:disable-next-line:deprecation
    const keyCode = event.keyCode;

    if (keyCode === this.keyTab) {
      if (!this.isMobile) {
        this.close();
      }
    }

    if (keyCode === this.keyArrowDown) {
      event.preventDefault();
      if (!this.isShowedOverlay) {
        this.open();
      }
      if (this.selectedId < this.filteredItems.length - 1) {
        this.selectedId++;
      }
    }

    if (keyCode === this.keyArrowUp) {
      event.preventDefault();
      if (this.selectedId > 0) {
        this.selectedId--;
      }
    }

    if (keyCode === this.keyEnter) {
      this.select(this.selectedId);
      this.selectedId = 0;
      if (!this.searchDisabled) {
        this.fieldModelChanged();
      }
      this.input.nativeElement.blur();
    }

    if (keyCode === this.keyArrowDown || keyCode === this.keyArrowUp) {
      this.isPressedUpDown = true;
    } else {
      this.selectedId = 0;
      this.isPressedUpDown = false;
    }
  }

  selectInputText($event: MouseEvent) {
    const target = $event.target as HTMLTextAreaElement;
    if (this.selectInputTextOnClick) {
      this.filteredItems = this.getPreFilteredItems();
      target.select();
    }
  }

  sortGroups() {
    if (this.orderGroups) {
      this.orderGroups.forEach((groupName: string) => {
        this.autocompleteItems.forEach((item: IAutocompleteItem) => {
          if (groupName === item.groupName) {
            this.sortedByGroupsItems.push(item);
          }
        });
      });
    }
  }

  btnClick() {
    if (this.isShowedOverlay) {
      this.isShowedOverlay = false;
    } else {
      this.input.nativeElement.focus();
    }
  }

  onLabelClick(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
    if (!this.isOpen) {
      this.open();
    }
  }

  writeValue(obj: any): void {
    this.value = obj;
    setTimeout(() => {
      this.loadInitialValues(this.value);
    });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  select(index: number): void {
    const selected = this.filteredItems[index];
    if (selected) {
        this.selectedId = index;
        this.fieldModel = selected.label;
        this.value = selected.value;
        this.valueChange.emit(this.value);
    }
    this.isShowedOverlay = false;
    this.isPressedUpDown = false;
  }

  fieldModelChanged(): void {
    const itemsUpdateObservable = this.dataSource
      ? this.loadFromDataSource()
      : of(this.searchDisabled ? this.autocompleteItems : this.filteredItems = this.filterByFieldModel());
    itemsUpdateObservable.subscribe(() => {
      const result = this.searchItemByLabel(this.fieldModel);

      if (result) {
        this.value = result.value;
      } else {
        this.value = null;
      }

      this.valueChange.emit(this.value);
    });

  }

  filterByFieldModel(): IAutocompleteItem[] {
    return _filter(this.autocompleteItems, item => item.label.toLowerCase().includes(this.fieldModel.toLowerCase()));
  }

  loadInitialValues(value: any) {
    this.selectedId = this.searchItemIndexByValue(value);

    if (this.selectedId === -1) {
      this.fieldModel = null;
    } else {
      this.fieldModel = this.autocompleteItems[this.selectedId].label;
    }
  }

  isClassEmpty(index: number): boolean {
    return this.enableSelectEmpty && index === 0 && this.markEmptyValue;
  }

  toggleTooltip(event?: Event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.isShowedTooltip = !this.isShowedTooltip;
  }

  protected getOverlayConfig(): OverlayConfig {
    return {
      ...super.getOverlayConfig(),
      width: this.isMobile ? null : this.getWidthParent()
    };
  }

  private getWidthParent(): number {
    return this.overlayOrigin && this.overlayOrigin.nativeElement.offsetWidth;
  }

  private getPreFilteredItems(): IAutocompleteItem[] {
    return this.filterFunction ? this.filterFunction(this.autocompleteItems, this.fieldModel) : this.autocompleteItems;
  }

  private loadFromDataSource(): Observable<any> {
    return this.dataSource(this.fieldModel).pipe(tap((newItems) => {
      this.filteredItems = this.transformToAutocompleteItems(newItems);
    }));
  }

  private searchItemByLabel(value: string): any {
    return _find(this.filteredItems, (item: IAutocompleteItem) => {
      return item.label === value;
    });
  }

  private searchItemIndexByValue(value: any): number {
    let res = -1;
    _find(this.autocompleteItems, (item: IAutocompleteItem, index: number) => {
      if (_isEqual(item.value, value)) {
        res = index;
      }
    });

    return res;
  }

  private transformToAutocompleteItems(items): IAutocompleteItem[] {
    return _map(items, item => {
      if (this.byGroups) {
        return {
          label: this.getProperty(item, this.itemLabel),
          value: this.getProperty(item, this.itemValue),
          groupName: this.getProperty(item, this.itemGroupName)
        };
      } else {
        return {
          label: this.getProperty(item, this.itemLabel),
          value: this.getProperty(item, this.itemValue)
        };
      }
    });
  }

  private getProperty(item, property) {
    return property ? _get(item, property) : item;
  }

  private updateSizeOrReopenOverlay(): void {
    const currentView: View = this.layoutService.isMobile
      ? View.MOBILE
      : View.DESKTOP;

    if (currentView !== this.previousView) {
      this.previousView = currentView;

      if (this.isOpen) {
        this.close();
        this.open();
      }
    } else {
      if (this.isOpen && currentView === View.DESKTOP) {
        const baseElement: HTMLElement = this
          .viewContainer
          .element
          .nativeElement
          .querySelector('.autocomplete');

        this.updateSize({width: `${baseElement.clientWidth}px`});
      }
    }
  }
}
