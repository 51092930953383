<div class="loader">
  <div class="loader__container">
    <svg xmlns="http://www.w3.org/2000/svg"
         focusable="false"
         preserveAspectRatio="xMidYMid meet"
         viewBox="0 0 100 100"
         [attr.width]="size"
         [attr.height]="size"
         class="loader__svg"
         svgThemes="font-sized"
         [ngClass]="composeNgClass()">
      <defs>
        <linearGradient id="loader-gradient-up-{{ index }}" x1="1" x2="0" y1="0" y2="0">
          <stop class="loader__stop" offset="0" stop-opacity="1"></stop>
          <stop class="loader__stop" offset="100%" stop-opacity="0.5"></stop>
        </linearGradient>
        <linearGradient id="loader-gradient-down-{{ index }}">
          <stop class="loader__stop" offset="0" stop-opacity="0.5"></stop>
          <stop class="loader__stop" offset="100%" stop-opacity="0"></stop>
        </linearGradient>
      </defs>
      <g class="loader__g" [attr.stroke-width]="getStrokeWidth()">
        <path class="loader__path" [attr.stroke]="'url(#loader-gradient-up-' + index + ')'" d="M95 50A45 45 0 1 0 5 50"></path>
        <path class="loader__path" [attr.stroke]="'url(#loader-gradient-down-' + index + ')'" d="M5 50A45 45 0 1 0 95 50"></path>
      </g>
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg"
         focusable="false"
         preserveAspectRatio="xMidYMid meet"
         viewBox="0 0 100 100"
         [attr.width]="size"
         [attr.height]="size"
         class="loader__progress">
      <text *ngIf="showProgress" x="30" y="58" textLength="44" class="loader__text">{{ progress }}%</text>
    </svg>
  </div>

  <div *ngIf="showLabelKey" class="loader__label">
    <span>{{ translatedLabel | async }}</span>
  </div>
</div>
