import {
  AfterContentInit,
  Component,
  ContentChild,
  ContentChildren,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  QueryList,
  Renderer2,
  SimpleChanges,
  ViewContainerRef
} from '@angular/core';
import { ManagedOverlayConnected } from './managed-overlay-connected';
import { KeyListenerService } from '../../key-listener/key-listener.service';
import { LayoutService } from '../layout/layout.service';
import { Overlay } from '@angular/cdk/overlay';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import _isNil from 'lodash-es/isNil';
import { merge, Observable, of } from 'rxjs';
import { DropdownContextDirective } from './dropdown-context.directive';

interface IDropdownComponent<T> {
  overlayOrigin: ElementRef<HTMLElement>;
  toggleWithItem(item?: T): void;
}

@Directive({
  selector: '[appDropdownToggle]',
})
export class DropdownToggleDirective<T> {

  @Input() dropdownItem: T;

  @Input() dropdownHost: IDropdownComponent<T>;

  @HostListener('click') onClick(): void {
    if (this.dropdownHost) {
      this.dropdownHost.overlayOrigin = this.elementRef;
      this.dropdownHost.toggleWithItem(this.dropdownItem);
    }
  }

  constructor(
    private elementRef: ElementRef
  ) {
  }

}


@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: [ './dropdown.component.scss' ],
  exportAs: 'dropdownComponent'
})
export class DropdownComponent<T> extends ManagedOverlayConnected implements AfterContentInit, IDropdownComponent<T>, OnChanges, OnDestroy {

  @Input() disabled = false;
  @Input() theme: string;
  @Input() useGlobalForMobile = true;

  @Output() readonly toggled = new EventEmitter<boolean>();
  @ContentChildren(DropdownToggleDirective, { read: ElementRef }) dropdownToggle: QueryList<ElementRef<HTMLElement>>;

  get enabled(): boolean {
    return !this.disabled;
  }

  private toggleClickListenerDeregFunc: (() => void) | null = null;
  @ContentChild(DropdownContextDirective, { static: true }) private dropdownContext: DropdownContextDirective<T>;

  constructor(protected readonly injector: Injector,
              protected readonly keyListener: KeyListenerService,
              protected readonly layoutService: LayoutService,
              protected readonly overlay: Overlay,
              protected readonly viewContainer: ViewContainerRef,
              private readonly renderer: Renderer2) {
    super(injector, keyListener, layoutService, overlay, viewContainer);
  }

  ngAfterContentInit(): void {
    const firstToggle$: Observable<ElementRef<HTMLElement> | null> = merge(
      of(this.dropdownToggle.first),
      this.dropdownToggle
        .changes
        .pipe(
          map((): ElementRef<HTMLElement> => this.dropdownToggle.first)
        )
    );

    firstToggle$
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          this.close();
          this.deregisterToggleListener();
        }),
        filter((toggle: ElementRef<HTMLElement>): boolean => !_isNil(toggle)),
        map(({ nativeElement }: ElementRef<HTMLElement>): HTMLElement => nativeElement)
      )
      .subscribe((toggleElement: HTMLElement) => {
        this.toggleClickListenerDeregFunc = this.renderer.listen(toggleElement, 'click', () => this.toggle());
      });
  }

  ngOnChanges({ disabled }: SimpleChanges): void {
    if (!_isNil(disabled) && !disabled.firstChange) {
      super.close();
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.deregisterToggleListener();
  }

  close(): void {
    if (this.enabled) {
      super.close();
      this.emitToggled();
    }
  }

  open(): void {
    if (this.enabled) {
      super.open();
      this.emitToggled();
    }
  }

  toggleWithItem(item?: T): void {
    if (this.isOpen) {
      this.close();
      if (this.dropdownContext) {
        this.dropdownContext.clearContext();
      }
    } else {
      if (this.dropdownContext) {
        this.dropdownContext.setContext(item);
      }
      this.open();
    }
  }

  protected isUseGlobalPosition(): boolean {
    return this.layoutService.isMobile && this.useGlobalForMobile;
  }

  private deregisterToggleListener(): void {
    if (!_isNil(this.toggleClickListenerDeregFunc)) {
      this.toggleClickListenerDeregFunc();
      this.toggleClickListenerDeregFunc = null;
    }
  }

  private emitToggled(): void {
    this.toggled.emit(this.isOpen);
  }

}
