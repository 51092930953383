import { LayoutService } from '../layout/layout.service';
import {
  ConnectionPositionPair,
  Overlay,
  OverlayConfig,
  OverlayRef,
  OverlaySizeConfig,
  PositionStrategy,
  ScrollStrategy
} from '@angular/cdk/overlay';
import { ManagedComponent } from '../managed-component';
import { deepCopy } from '../../utils/copy';
import { POSITION_LIST } from './placement';
import { ComponentRef, ElementRef, EmbeddedViewRef, Injector, OnDestroy, TemplateRef, ViewChild, ViewContainerRef, Directive } from '@angular/core';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import { KeyListenerService } from '../../key-listener/key-listener.service';

@Directive()
export abstract class AbstractManagedOverlayConnected<C> extends ManagedComponent implements OnDestroy {

  isOpen = false;

  protected positions: ConnectionPositionPair[] = deepCopy(POSITION_LIST);

  private destroyOverlay$ = new Subject<void>();
  private keyListenerId: number;
  private overlayRef: OverlayRef;

  abstract get overlayOrigin(): ElementRef<HTMLElement>;
  abstract get portal(): TemplatePortal<C> | ComponentPortal<C>;

  get isClosed(): boolean {
    return !this.isOpen;
  }

  protected get overlayViewRef(): EmbeddedViewRef<C> | ComponentRef<C> | null {
    return this._overlayViewRef;
  }
  private _overlayViewRef: EmbeddedViewRef<C> | ComponentRef<C> | null = null;

  protected constructor(
    protected readonly injector: Injector,
    protected readonly keyListener: KeyListenerService,
    protected readonly layoutService: LayoutService,
    protected readonly overlay: Overlay,
    protected readonly viewContainer: ViewContainerRef,
  ) {
    super();
  }

  open(): void {
    this.isOpen = true;
    this.createOverlay();
  }

  close(): void {
    this.isOpen = false;
    this.dispose();
  }

  toggle(): void {
    this.isOpen ? this.close() : this.open();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.dispose();
  }

  updatePosition(): void {
    this.overlayRef.updatePosition();
  }

  updateSize(newSize: OverlaySizeConfig): void {
    this.overlayRef.updateSize(newSize);
  }

  protected getBackdropClass(): string {
    return this.layoutService.isMobile ? 'cdk-overlay-primary' : 'cdk-overlay-opacity';
  }

  protected getOverlayConfig(): OverlayConfig {
    return new OverlayConfig({
      hasBackdrop: true,
      backdropClass: this.getBackdropClass(),
      scrollStrategy: this.getScrollStrategy(),
      positionStrategy: this.getPositionStrategy(),
    });
  }

  protected getScrollStrategy(): ScrollStrategy {
    return this.layoutService.isMobile
      ? this.overlay.scrollStrategies.block()
      : this.overlay.scrollStrategies.reposition();
  }

  protected getPositionStrategy(): PositionStrategy {
    if (this.isUseGlobalPosition()) {
      return this.overlay.position()
        .global()
        .centerHorizontally()
        .centerVertically();
    } else {
      return this.overlay.position()
        .flexibleConnectedTo(this.overlayOrigin)
        .withPositions(this.positions)
        .withFlexibleDimensions(false)
        .withPush(false)
        .withLockedPosition(true);
    }
  }

  protected isUseGlobalPosition(): boolean {
    return this.layoutService.isMobile;
  }

  private createOverlay(): void {
    this.overlayRef = this.overlay.create(this.getOverlayConfig());
    this.destroyOverlay$ = new Subject<void>();
    this.keyListenerId = this.keyListener.addEscapeListener(() => this.close());

    this.overlayRef
      .backdropClick()
      .pipe(
        take(1),
        takeUntil(this.destroyOverlay$)
      )
      .subscribe(() => this.close());

    this._overlayViewRef = this.overlayRef.attach(this.portal);
  }

  private dispose(): void {
    this.destroyOverlay$.next();
    this.destroyOverlay$.complete();
    this.keyListener.removeEscapeListener(this.keyListenerId);
    this.keyListenerId = null;
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this._overlayViewRef = null;
    }
  }

}

@Directive()
export class ManagedOverlayConnected extends AbstractManagedOverlayConnected<{}> {
  @ViewChild('overlayBox', { static: true }) overlayBox: TemplateRef<{}>;
  @ViewChild('overlayOrigin', { static: true }) overlayOrigin: ElementRef<HTMLElement>;

  constructor(
    protected readonly injector: Injector,
    protected readonly keyListener: KeyListenerService,
    protected readonly layoutService: LayoutService,
    protected readonly overlay: Overlay,
    protected readonly viewContainer: ViewContainerRef,
  ) {
    super(injector, keyListener, layoutService, overlay, viewContainer);
  }

  get portal(): TemplatePortal<{}> {
    return new TemplatePortal<{}>(this.overlayBox as TemplateRef<{}>, this.viewContainer, this.injector);
  }
}
