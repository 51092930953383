import { Component, Input } from '@angular/core';
import { QuestionaryResultResponseSchema } from '@rsmu/portal-api';

@Component({
  selector: 'app-psy-test-highest-results-transcript',
  templateUrl: './psy-test-highest-results-transcript.component.html',
  styleUrls: ['./psy-test-highest-results-transcript.component.scss']
})
export class PsyTestHighestResultsTranscriptComponent {

  @Input() testResults: QuestionaryResultResponseSchema[];
  @Input() maxScore: number;

  constructor() { }

  highestResults(): QuestionaryResultResponseSchema[] {
    return this.testResults.filter((result: QuestionaryResultResponseSchema): boolean => {
      return result.score === this.maxScore;
    });
  }

}
