import {AfterViewChecked, ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {map, startWith, switchMap, tap} from 'rxjs/operators';
import {UntypedFormControl} from '@angular/forms';
import {MatCheckboxChange} from '@angular/material/checkbox';

@Component({
  selector: 'app-multi-select-dialog',
  templateUrl: './multi-select-dialog.component.html',
  styleUrls: ['./multi-select-dialog.component.scss']
})
export class MultiSelectDialogComponent <T> implements OnInit, AfterViewChecked {

  public dialogStringControl = new UntypedFormControl('');
  public filteredItems$: Observable<T[]>;
  public preselectedItems$: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);
  private _asyncQueryComplete$: Observable<boolean>;

  constructor(
    private cdr: ChangeDetectorRef,
    public dialogRef: MatDialogRef<T>,
    @Inject(MAT_DIALOG_DATA) public data: {
      isSelectAllAvailable: boolean,
      listItems$?: BehaviorSubject<T[]>,
      items$?: Observable<T[]>
      itemName: string,
      itemsReload: Observable<void>,
      itemValue: string,
      selectedItems$: BehaviorSubject<T[]>,
      selectionLimit: number
    }
  ) {  }

  get asyncQueryComplete$() {
    return this._asyncQueryComplete$;
  }

  get isSelectedAllChecked(): boolean {
    return this.data.listItems$.getValue()?.length === 0 && this.preselectedItems$.getValue().length !== 0;
  }

  get isCheckBoxEnabled(): boolean {
    if (!this.data.selectionLimit) {
      return true;
    }
    return this.preselectedItems$.getValue()?.length < this.data.selectionLimit;
  }

  initDialog(): void {
    if (this.data.listItems$.getValue() === null) {
      this._asyncQueryComplete$ = this.data.items$.pipe(
        tap(items => {
          this.data.listItems$.next(items);
        }),
        map(() => true)
      );
    } else {
      this._asyncQueryComplete$ = of(true);
    }
  }

  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    this.preselectedItems$.next(this.data.selectedItems$.getValue().map(it => it));
    this.initDialog();
    this.filteredItems$ = this.dialogStringControl.valueChanges
      .pipe(
        startWith(''),
        switchMap(value => this.filterByString$(value || ''))
      );
  }

  OnCancel() {
    this.dialogRef.close();
  }

  onChipRemove(preselectedItem: T) {
    const preselectedItems = this.preselectedItems$.getValue();
    const listItems = this.data.listItems$.getValue();
    this.preselectedItems$.next(preselectedItems.filter(it => it !== preselectedItem));
    listItems.push(preselectedItem);
    this.data.listItems$.next(listItems.sort((a, b) => a[this.data.itemName].localeCompare(b[this.data.itemName])));
  }

  onConfirm() {
    this.data.selectedItems$.next(this.preselectedItems$.getValue().map(it => it));
    this.dialogRef.close();
  }

  onItemClick(item: T) {
    const listItems = this.data.listItems$.getValue();
    const preselectedItems = this.preselectedItems$.getValue();
    this.data.listItems$.next(listItems.filter(it => it !== item));
    this.cdr.detectChanges();
    preselectedItems.push(item);
    this.preselectedItems$.next(preselectedItems);
    this.cdr.detectChanges();
  }

  onSelectAllChange($event: MatCheckboxChange) {
    if ($event.checked) {
      this.data.listItems$.getValue().forEach(it => this.onItemClick(it));
    } else {
      this.preselectedItems$.getValue().forEach(it => this.onChipRemove(it));
    }
  }

  private filterByString$(value: string) {
    const filterValue = value.toLowerCase();
    return this.data.listItems$.asObservable()
      .pipe(map(items => items.filter(item => item[this.data.itemName].toLowerCase().includes(filterValue))));
  }
}
