import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, Validators } from '@angular/forms';

@Directive({
  selector: '[appMinNumber]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: InputNumberMinDirective,
      multi: true
    }
  ]
})
export class InputNumberMinDirective implements Validator {

  @Input() min: number;

  validate(control: AbstractControl): ValidationErrors | null {
    return Validators.min(this.min)(control);
  }

}
