<div class="popup-header">
  <div class="popup-header__text">
    <ng-content></ng-content>
  </div>

  <button *ngIf="showClose"
          type="button"
          class="button button_text popup-header__close"
          title="{{ 'tips.close' | translate }}"
          (click)="hide()">
    <span class="figure figure_close" role="presentation"></span>
  </button>
</div>
