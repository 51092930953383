import { Inject, Injectable } from '@angular/core';
import { LocalStorage } from 'ngx-store';
import { Theme } from './theme';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../../environments/environment';

declare global {
  interface Window {
    reloadTheme: () => void;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ThemeManagerService {

  @LocalStorage() private currentTheme: Theme = Theme.BASE;

  private version = 0;
  private readonly classLink = 'theme-link';

  constructor(
    @Inject(DOCUMENT) private document,
  ) {
    this.selectTheme(this.currentTheme);
    if (!environment.production) {
      window.reloadTheme = this.reloadTheme.bind(this);
    }
  }

  selectTheme(theme: Theme): void {
    if (this.currentTheme !== theme) {
      this.currentTheme = theme;
    }
    if (theme !== Theme.BASE) {
      this.setTheme(this.currentTheme);
    } else {
      this.unsetTheme();
    }
  }

  resetTheme(): void {
    this.currentTheme = Theme.BASE;
    this.unsetTheme();
  }

  reloadTheme(): void {
    this.version ++;
    if (this.currentTheme !== Theme.BASE) {
      this.setTheme(this.currentTheme);
    }
  }

  private setTheme(theme: Theme): void {
    this.findLink()
      .setAttribute('href', this.getThemeHref(theme));
  }

  private findLink(): HTMLElement {
    return this.getLink() || this.createLink();
  }

  private getLink(): HTMLElement {
    return this.document.head.querySelector(`link[rel="stylesheet"].${this.classLink}`);
  }

  private unsetTheme(): void {
    const link = this.getLink();
    if (link) {
      this.document.head.removeChild(link);
    }
  }

  private createLink(): HTMLElement {
    const linkEl = this.document.createElement('link');
    linkEl.setAttribute('rel', 'stylesheet');
    linkEl.classList.add(this.classLink);
    this.document.head.appendChild(linkEl);
    return linkEl;
  }

  private getThemeHref(theme: Theme): string {
    return `/${theme}.css?${this.version}`;
  }

}
