import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appScrollAndFocus]'
})
export class ScrollAndFocusDirective {

  readonly SCROLL_DURATION: number = 200;
  readonly INVALID_FIELD_CLASS: string = '.ng-invalid';

  constructor(private elementRef: ElementRef) {
  }

  @HostListener('submit')
  onFormSubmit(): void {
    const form: HTMLFormElement = this.elementRef.nativeElement;
    const invalidElement: HTMLElement = form.querySelector(this.INVALID_FIELD_CLASS);

    if (invalidElement) {
      const field: HTMLElement = invalidElement.parentElement;
      const viewportOffset: ClientRect = field.getBoundingClientRect();
      const offsetTop: number = (
          viewportOffset.top + window.pageYOffset
        ) - viewportOffset.height;

      this.scrollToTarget(offsetTop);
      invalidElement.focus();
    }
  }

  private scrollToTarget(elementOffset: number) {
    const startingY: number = window.pageYOffset;
    const diff: number = elementOffset - startingY;
    let startTime: number;

    const step: Function = (innerTime: number) => {
      startTime = startTime || innerTime;

      const time: number = innerTime - startTime;
      const percent: number = Math.min(time / this.SCROLL_DURATION, 1);

      window.scrollTo(0, startingY + diff * percent);

      if (time < this.SCROLL_DURATION) {
        window.requestAnimationFrame((timestamp: number) => step(timestamp));
      }
    };

    window.requestAnimationFrame((timestamp: number) => step(timestamp));
  }
}
