import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output
} from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'app-text-field',
  templateUrl: './text-field.component.html',
  styleUrls: ['./text-field.component.scss']
})
export class TextFieldComponent implements OnInit, ControlValueAccessor {

  @Input()
  get value() { return this._value; }
  set value(value) {
    this._value = value;
  }
  private _value: any;

  @Input()
  disabled = false;

  @Input()
  placeholder = '';

  @Input()
  label = '';

  @Input()
  iconSearch = false;

  @Output() valueChange = new EventEmitter();
  @Output() valueInput = new EventEmitter();

  onChange = (value) => {};
  onTouched = () => {};

  constructor() { }

  ngOnInit() {
  }

  changed() {
    this.valueChange.emit(this.value);
  }

  input() {
    this.valueInput.emit(this.value);
  }

  writeValue(obj: any): void {
    this.value = obj;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

}
