<div class="dropdown {{ theme }}" #overlayOrigin>
  <div class="dropdown__toggle"
       [class.dropdown__toggle-disabled]="disabled">
    <ng-content select="[appDropdownToggle]"></ng-content>
  </div>

  <ng-template #overlayBox>
    <div class="dropdown__content">
      <ng-content select="[appDropdownContent]"></ng-content>
    </div>
  </ng-template>
</div>







