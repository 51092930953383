<div class="show-more-component {{theme}}">
  <div
    tabindex="0"
    appAccessibleLink
    role="checkbox"
    [attr.aria-checked]="show"
    [title]="'tips.hidden.' + !show | translate"
    class="show-more-component-heading"
    (click)="toggle($event)">
    <h4 class="show-more-component-heading__text">{{ heading }}</h4>
    <div role="presentation">
      <span
        class="figure"
        [class.figure_arrow-bottom]="!show"
        [class.figure_arrow-top]="show"></span>
    </div>
  </div>
  <div
    [class.show-more-component__hide]="!show">
    <ng-content></ng-content>
  </div>
</div>
