import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import _isNil from 'lodash-es/isNil';
import _range from 'lodash-es/range';
import { roundNumbers } from '../../utils/round-numbers';
import { DropdownComponent } from '../dropdown/dropdown.component';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss'],
})
export class RatingComponent {

  @Output() readonly confirmRatingEvent = new EventEmitter<number>();
  @Output() readonly closeRatingEvent = new EventEmitter<void>();

  @Input() inner = false;
  @Input() isDisabled = false;
  @Input()
  set selectedElem(selectedElem: number) {
    this._selectedElem = selectedElem;
    this.selectedRating = selectedElem ? selectedElem : 0;
  }
  get selectedElem(): number {
    return this._selectedElem;
  }
  private _selectedElem = 0;

  @Input() set totalRating(rating: number) {
    this.rating = roundNumbers(rating);
  }

  @ViewChild(DropdownComponent) private dropdownComponent: DropdownComponent<{}>;

  readonly range = 10;
  readonly ratingList: Array<number> = _range(1, this.range + 1);

  dataRating = 0;
  rating: number;
  selectedRating = 0;

  get isSelectedRating(): boolean {
    return this.selectedElem > 0;
  }

  writeRatingValue(rating: number): void {
    this.dataRating = rating;
    this.selectedRating = rating;
    this.confirmRating();
    if (!this.inner) {
      this.close();
    }
  }

  close(): void {
    if (!_isNil(this.dropdownComponent)) {
      this.dropdownComponent.close();
    }
  }

  ratingOver(rating: number): void {
    this.dataRating = this.selectedRating;
    this.selectedRating = rating;
  }

  ratingOut(): void {
    this.selectedRating = this.dataRating;
  }

  ratingToggled(isOpen: boolean): void {
    if (!isOpen) {
      this.closeRating();
    }
  }

  private confirmRating(): void {
    this.confirmRatingEvent.emit(this.selectedRating);
  }

  private closeRating(): void {
    this.closeRatingEvent.emit();
  }
}
