<div class="esia-user-info-dialog">
  <h3 class="heading_1-smaller-sm text_light">{{'profile.snilsNumber' | translate: { snils: data.snils } }}</h3>
  <h2 class="heading_2">
    {{'esiaUpdate.userInfo.title' | translate}}
  </h2>
  <mat-card-content>
    <div class="esia-user-info-dialog__user" *ngIf="data">
      <form
        (ngSubmit)="submitForm()"
        [formGroup]="userInfoForm">
        <ng-container *ngFor="let item of userInfoConfig">
          <div class="esia-user-info-dialog__user__item" *ngIf="data[item.fieldName]">
            <div class="esia-user-info-dialog__user__item__data">
              <div class="text_big text_bolder esia-user-info-dialog__user__item__data__key">{{('esiaUpdate.userInfo.' + item.fieldName | translate) + ': '}}</div>
              <div *ngIf="item.fieldName !== 'gender'" class="text_big esia-user-info-dialog__user__item__data__value">{{data[item.fieldName]}}</div>
              <div *ngIf="item.fieldName === 'gender'" class="text_big esia-user-info-dialog__user__item__data__value">{{('esiaUpdate.userInfo.' + data[item.fieldName]) | translate}}</div>
            </div>
            <div class="esia-user-info-dialog__user__item__slider">
              <mat-slide-toggle
                [formControlName]="item.fieldName"
                [disabled]="item.disabled"
                color="primary"
              >
              </mat-slide-toggle>
            </div>
          </div>
        </ng-container>
        <div class="esia-user-info-dialog__user__actions">
          <button
            type="button"
            (click)="cancel()"
            class="esia-user-info-dialog__user__actions__item" mat-stroked-button>{{'dialog.cancel' | translate}}
          </button>
          <button
            [disabled]="disableSubmitButton"
            class="esia-user-info-dialog__user__actions__item"
            type="submit" mat-raised-button color="primary">
            {{'profile.settings.toSave' | translate}}
          </button>
        </div>
      </form>
    </div>
  </mat-card-content>
</div>


