import {Injectable, InjectionToken} from '@angular/core';

export function ConfigFactory(configLoader: ConfigLoader, file: string) {
  return configLoader.loadJSON(file);
}

export const IMG_LINKS_CONFIG = new InjectionToken('IMG_LINKS_CONFIG');

@Injectable()
export class ConfigLoader {
  public config: any;
  constructor() {
  }

  loadJSON(filePath) {
    if (!filePath) { return null; }
    const json = this.loadTextFileAjaxSync(filePath, 'application/json');
    return JSON.parse(json);
  }

  loadTextFileAjaxSync(filePath, mimeType) {
    const xmlhttp = new XMLHttpRequest();
    xmlhttp.open('GET', filePath, false);
    if (mimeType != null) {
      if (xmlhttp.overrideMimeType) {
        xmlhttp.overrideMimeType(mimeType);
      }
    }
    xmlhttp.send();
    if (xmlhttp.status === 200) {
      return xmlhttp.responseText;
    } else {
      return null;
    }
  }
}
