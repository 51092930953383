import { ChangeDetectionStrategy, Component, Input, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabComponent {

  @Input()
  get label() { return this._label; }
  set label(label) {
    this._label = label;
  }
  private _label = '';

  @Input()
  get icon() { return this._icon; }
  set icon(icon) {
    this._icon = icon;
  }
  private _icon = '';

  @Input() mainText: string;

  @ViewChild(TemplateRef, { static: true }) content: TemplateRef<any>;

}
