import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { DatePickerErrors } from './datepicker-errors';
import { DateUtils } from './date-utils';
import _isDate from 'lodash-es/isDate';
import _isNil from 'lodash-es/isNil';

export class DatepickerValidators {

  static max(maxDate?: Date | NgbDateStruct): ValidatorFn {
    return (control: AbstractControl): { [DatePickerErrors.REQUIRED_BEFORE]: string } | null =>
      DatepickerValidators.validateMaxTimestamp(maxDate, control.value as number);
  }

  static min(minDate?: Date | NgbDateStruct): ValidatorFn {
    return (control: AbstractControl): { [DatePickerErrors.REQUIRED_AFTER]: string } | null =>
      DatepickerValidators.validateMinTimestamp(minDate, control.value as number);
  }

  static validateMaxTimestamp(maxDate?: Date | NgbDateStruct, timestampToCheck?: number):
    { [DatePickerErrors.REQUIRED_BEFORE]: string } | null {
    if (_isNil(maxDate) || _isNil(timestampToCheck) || timestampToCheck <= DateUtils.getTimeDate(maxDate)) {
      return null;
    }
    const dateToFormat: NgbDateStruct = _isDate(maxDate)
      ? DateUtils.dateStructFromDate(maxDate as Date)
      : maxDate as NgbDateStruct;
    const formattedDate: string = DateUtils.formatDateStruct(dateToFormat);

    return { [DatePickerErrors.REQUIRED_BEFORE]: formattedDate };
  }

  static validateMinTimestamp(minDate?: Date | NgbDateStruct, timestampToCheck?: number):
    { [DatePickerErrors.REQUIRED_AFTER]: string } | null {
    if (_isNil(minDate) || _isNil(timestampToCheck) || timestampToCheck >= DateUtils.getTimeDate(minDate)) {
      return null;
    }
    const dateToFormat: NgbDateStruct = _isDate(minDate)
      ? DateUtils.dateStructFromDate(minDate as Date)
      : minDate as NgbDateStruct;
    const formattedDate: string = DateUtils.formatDateStruct(dateToFormat);

    return { [DatePickerErrors.REQUIRED_AFTER]: formattedDate };
  }

}
