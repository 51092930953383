import { Inject, Injectable } from '@angular/core';
import { LocalStorage } from 'ngx-store';

import { RouteService } from '../app-routing/route.service';
import { RouteState } from '../global-dto/routeState';
import { CabinetRedirect, CabinetsService } from '../cabinets/cabinets.service';
import { DOCUMENT } from '@angular/common';
import { ProfileService } from '../profile/profile.service';
import { StateObject, StateService } from '@uirouter/core';
import { UserInfo } from '@rsmu/portal-api';
import { MobileAppService } from '../mobile/mobile-app.service';
import { LoginService } from '../login/login.service';
import { GlobalLoadingService } from '../global-loading/global-loading.service';
import { finalize } from 'rxjs/operators';
import _isNil from 'lodash-es/isNil';
import _isFunction from 'lodash-es/isFunction';
import { environment } from '../../environments/environment';
import { Logger } from '../app-commons/logger/logger.service';

const log: Logger = new Logger('RedirectService');

export interface RedirectState extends RouteState {
  createTs: number;
}

@Injectable({
  providedIn: 'root'
})
export class RedirectService {

  private readonly REDIRECT_EXPIRATION_TIME = 1000 * 60 * 60 * 24;

  @LocalStorage({ mutate: false }) redirectState: RedirectState = null;
  @LocalStorage({ mutate: false }) userSnils: string = null;

  constructor(
    private routeService: RouteService,
    private profileService: ProfileService,
    private stateService: StateService,
    private cabinetsService: CabinetsService,
    private loginService: LoginService,
    @Inject(DOCUMENT) private document: HTMLDocument,
    private mobileAppService: MobileAppService,
    private globalLoadingService: GlobalLoadingService,
  ) {
  }

  toLogin(reload: boolean = false): void {
    log.warn('Redirecting to login..');

    this.loginService.prepareLoginPage();

    const data = this.routeService.getCurrentStateData();
    if (data && data.requiresAuth) {
      this.redirectState = { ...this.routeService.getCurrentState(), createTs: Date.now() };
    }

    if (this.mobileAppService.isMobileApp() && environment.cabinetInstanceType !== 'HigherEducation') {
      window.location.href = this.mobileAppService.getVoAppRedirectUrl();
    } else {
      this.routeService.go(
        state => state.data && state.data.hasOwnProperty('redirectForAuth'),
        null,
        { reload }
      );
    }
  }

  fromLogin(): void {
    this.routeService.clear();
    this.redirectToCorrectCabinet(() => this.localRedirect());
  }

  redirectToCorrectCabinet(currentCabinetCallback: () => void,
                           afterCabinetStateRedirectCallback?: (redirectState: StateObject) => void): void {
    this.globalLoadingService.startLoading();
    this.cabinetsService.getRedirectUrlForPriorityCabinet().subscribe((redirect: CabinetRedirect) => {
      this.globalLoadingService.stopLoading();
      if (redirect) {
        this.cabinetRedirect(redirect)
          .then((state: StateObject | null) => {
            if (_isFunction(afterCabinetStateRedirectCallback) && !_isNil(state)) {
              afterCabinetStateRedirectCallback(state);
            }
          });
      } else {
        currentCabinetCallback();
      }
    });
  }

  redirectByWizardCompleted(response: UserInfo): Promise<StateObject> {
    if (response.wizardCompleted) {
      return this.routeService.stateServiceGo('userAccount');
    } else {
      return this.routeService.stateServiceGo('userInit');
    }
  }

  private async cabinetRedirect(redirect: CabinetRedirect): Promise<StateObject | null> {
    if (redirect.url) {
      const url = this.cabinetsService.convertCabinetUrlForNavigation(redirect.url, !!redirect.isSpecialist);

      if (this.mobileAppService.isMobileApp()) {
        window.location.href = url;
      } else {
        this.document.location.href = url;
      }
      return null;
    } else if (redirect.state) {
      return this.routeService.stateServiceGo(redirect.state);
    }
    return null;
  }

  private localRedirect() {
    this.globalLoadingService.startLoading();
    this.profileService.getProfile()
      .pipe(finalize(() => this.globalLoadingService.stopLoading()))
      .subscribe((profile: UserInfo) => {
        if (this.redirectStateAvailable() && (this.userSnils == null || this.userSnils === profile.snils)) {
          this.routeService.goToState(this.redirectState);
        } else {
          this.redirectByWizardCompleted(profile);
        }
        this.userSnils = profile.snils;
      });
  }

  private redirectStateAvailable(): boolean {
    if (this.redirectState == null || this.redirectState.name == null || this.redirectState.name.length === 0) {
      return false;
    }
    const now = Date.now();
    const creteTs = this.redirectState.createTs || 0;
    const timePassed = now - creteTs;
    return timePassed < this.REDIRECT_EXPIRATION_TIME;
  }
}
