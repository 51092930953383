import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-show-more-component',
  templateUrl: './show-more-component.component.html',
  styleUrls: ['./show-more-component.component.scss']
})
export class ShowMoreComponentComponent {

  @Input() heading: string;
  @Input() show: boolean;
  @Input() theme: string;

  toggle($event: Event): void {
    this.show = !this.show;
    $event.stopImmediatePropagation();
  }

}
