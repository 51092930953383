import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

/*
*  TODO check, non used component
* */

@Component({
  selector: 'app-slider-button',
  templateUrl: './slider-button.component.html',
  styleUrls: ['./slider-button.component.scss']
})
export class SliderButtonComponent implements OnInit, ControlValueAccessor {
  private _isChecked;

  @Input() public label;
  @Input() disabled;

  @Input()
  get isChecked() {
    return this._isChecked;
  }

  set isChecked(value) {
    const firstInit = this._isChecked == null;
    this._isChecked = value;

    if (this.onChange) {
      this.onChange(value);
    }

    if (!firstInit) {
      this.isCheckedChange.emit(value);
    }
  }

  @Output()
  isCheckedChange = new EventEmitter<boolean>();

  onChange = (value) => {};
  onTouched = () => {};

  constructor() { }

  ngOnInit() {
  }

  writeValue(obj: any): void {
    this.isChecked = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
