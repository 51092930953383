import { AfterViewInit, Component, ElementRef, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { ApiService } from 'src/app/api/api.service';
import { HelpInformationPage } from '@rsmu/portal-api';

@Component({
  selector: 'app-help-info',
  templateUrl: './help-info.component.html',
  styleUrls: ['./help-info.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HelpInfoComponent implements AfterViewInit {

  @Input() data: {helpId: string};

  @ViewChild('content', { read: ElementRef, static: true }) content: ElementRef;


  constructor(private apiService: ApiService) {}

  ngAfterViewInit(): void {
    this.apiService.retrieveHelpPageById(this.data.helpId)
      .subscribe((res: HelpInformationPage) => {
        const { pageHtml } = res;
        if (!!pageHtml) {
          this.content.nativeElement.insertAdjacentHTML('beforeend', pageHtml);
        }
      });
  }
}
