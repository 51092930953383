import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { NotificationService } from '../service/notification.service';

@Component({
  selector: 'app-notification-count',
  templateUrl: './notification-count.component.html',
  styleUrls: ['./notification-count.component.scss']
})
export class NotificationCountComponent implements OnInit, OnDestroy {

  @Input() countModificator: string;
  notificationCount = 0;

  constructor(private notificationService: NotificationService) { }

  ngOnInit(): void {
    this.notificationService.getNotificationsAmountDataSource().subscribe((amount: number) => {
      this.notificationCount = amount;
    });
  }

  ngOnDestroy(): void {
    this.notificationService.stopObserving();
  }

}
