<div class="recovery recovery_result">
  <img role="presentation" class="iam-background-left" alt="" src='assets/icons/Background_1.svg'/>
  <img role="presentation" class="iam-background-right" alt="" src='assets/icons/Background_2.svg'/>
  <div class="iam-screen">
    <header class="recovery__header">
      <div *ngIf="recoveryTypeFromState" class="recovery__title-wrapper">
        <h2 class="heading heading_1 heading_light heading_1-smaller-sm heading_bold-sm">
          {{ 'recovery.title.' + recoveryTypeFromState | translate }}
        </h2>
      </div>

      <div class="recovery__text-result" *ngIf="recoveryStatusResponse$ | async as recoveryStatus; else loader">
        <span class="text text_big text_base-sm">{{ recoveryStatus.descriptionRu }}</span>
      </div>
      <div
        class="recovery__actions-section recovery__actions-section_right recovery__actions-section_left-tablet">
        <button
          (click)="back()"
          class="recovery__button button button_primary">
          {{ ('passwordRecoveryResult.' + returnButtonName(recoveryTypeFromState)) | translate }}
        </button>
      </div>
    </header>
  </div>
</div>

<ng-template #loader>
  <div class="recovery__loader">
    <app-loader></app-loader>
  </div>
</ng-template>
