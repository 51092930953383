import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IAutocompleteItem } from '../autocomplete.component';

@Component({
  selector: 'app-autocomplete-list',
  templateUrl: './autocomplete-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutocompleteListComponent {

  @Input() isOpen = false;
  @Input() isMobile = false;
  @Input() searchDisabled = false;
  @Input() fieldModel: string;
  @Input() customWidth = false;
  @Input() isPressedUpDown = false;
  @Input() selectedId: number;
  @Input() byGroups: boolean;
  @Input() filteredItems: IAutocompleteItem[] = [];
  @Input() isShowClassEmpty: boolean;

  @Output() fieldModelUpdate = new EventEmitter<string>();
  @Output() selectUpdate = new EventEmitter<number>();
  @Output() closeEvent = new EventEmitter<void>();
  @Output() keypressHandlerChange = new EventEmitter<KeyboardEvent>();

  clearFilter(): void {
    this.fieldModelUpdate.emit('');
  }

  close(): void {
    this.closeEvent.emit();
  }

  keypressHandler(event: KeyboardEvent): void {
    this.keypressHandlerChange.emit(event);
  }

  updateFieldModel(value: string): void {
    this.fieldModelUpdate.emit(value);
  }

  showGroupName(itemIndex: number) {
    return itemIndex === 0 || this.filteredItems[itemIndex].groupName !== this.filteredItems[itemIndex - 1].groupName;
  }

  select(index: number): void {
    this.selectUpdate.emit(index);
  }

  isClassEmpty(index: number): boolean {
    return this.isShowClassEmpty && index === 0;
  }

  trackByFn(index: number, item: IAutocompleteItem): any {
    return item.value;
  }

}
