import {ChangeDetectorRef, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {BehaviorSubject, isObservable, Observable, Subject} from 'rxjs';
import {map, startWith, switchMap, take} from 'rxjs/operators';

export interface Multiselect2DialogData <T> {
  dataSource: Observable<Array<T>> | ((offset, limit, esOrg) => Observable<Array<T>>);
  itemName: string;
  itemValue: string;
  selectionLimit: number;
  serverSideFilter: boolean;
  selectedItems$: BehaviorSubject<Array<T>>;
}

@Component({
  selector: 'app-multiselect2-dialog',
  templateUrl: './multiselect2-dialog.component.html',
  styleUrls: ['./multiselect2-dialog.component.scss']
})
export class Multiselect2DialogComponent <T> implements OnInit, OnDestroy {

  get asyncQueryComplete$() {
    return this._asyncQueryComplete$;
  }
  private _asyncQueryComplete$: Observable<boolean>;

  get isCheckBoxEnabled(): boolean {
    return !this.data.selectionLimit || this.preselectedItems.length < this.data.selectionLimit;
  }

  dialogStringControl: UntypedFormControl = new UntypedFormControl('');
  filteredItems$: Observable<Array<T>> | BehaviorSubject<Array<T>>;
  preselectedItems: Array<T> = [];

  protected _itemsList$: BehaviorSubject<Array<T>> = new BehaviorSubject<Array<T>>(null);
  protected _unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    protected cdr: ChangeDetectorRef,
    public dialogRef: MatDialogRef<T>,
    @Inject(MAT_DIALOG_DATA) public data: Multiselect2DialogData <T>
  ) {
  }


  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  ngOnInit(): void {
    if (isObservable(this.data.dataSource)) {
      this._asyncQueryComplete$ = this.data.dataSource
        .pipe(
          map((result: Array<T>) => {
            const selectedItems = this.data.selectedItems$.getValue();
            if (selectedItems?.length > 0) {
              selectedItems.forEach(sIt => {
                this.preselectedItems.push(sIt);
                const index = result.indexOf(sIt);
                if (index > -1) {
                  result.splice(index, 1);
                }
              });
            }
            this._itemsList$.next(result);
            return true; })
        );
    }
    this.initFilteredItems();
    this.dialogRef.backdropClick()
      .pipe(take(1))
      .subscribe(() => this.onCancel());
  }


  onCancel() {
    this.dialogRef.close(this._itemsList$.getValue());
  }

  onChipRemove(preselectedItem: T) {
    this.preselectedItems = this.preselectedItems.filter(it => it !== preselectedItem);
    this.cdr.detectChanges();
    const itemsList = this._itemsList$.getValue() || [];
    itemsList.push(preselectedItem);
    itemsList.sort((a, b) => a[this.data.itemName].localeCompare(b[this.data.itemName]));
    this._itemsList$.next(itemsList);
    this.cdr.detectChanges();
  }

  onConfirm() {
    this.data.selectedItems$.next(this.preselectedItems);
    const itemsList = this._itemsList$.getValue() || [];
    this.dialogRef.close({confirm: true, itemsList: itemsList});
  }

  onItemClick(item: T, isEnabled: boolean) {
    if (!isEnabled) {
      return;
    }
    const itemsList = this._itemsList$.getValue() || [];
    this._itemsList$.next(itemsList.filter(it => it !== item));
    this.preselectedItems.push(item);
    this.cdr.detectChanges();
  }

  private filterByString (value: string): Observable<Array<T>> {
    const filterValue = value.toLowerCase();
    return this._itemsList$.asObservable()
      .pipe(
        map(itemsList => itemsList.filter(it => it[this.data.itemName].toLowerCase().includes(filterValue))));
  }

  private initFilteredItems() {
    if (this.data.serverSideFilter) {
      this.filteredItems$ = this._itemsList$;
    } else {
      this.filteredItems$ = this.dialogStringControl.valueChanges
        .pipe(
          startWith(''),
          switchMap((value) => {
            return this.filterByString(value || '');
          }));
    }
  }
}
