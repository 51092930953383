import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { MultiPlanPK } from '@rsmu/portal-api';
import { MultiZetSize } from './multi-zet-size';
import _isNil from 'lodash-es/isNil';
import { TooltipDirective, TooltipTrigger } from '../tooltip/tooltip.directive';

@Component({
  selector: 'app-multi-zet',
  templateUrl: './multi-zet.component.html',
  styleUrls: ['./multi-zet.component.scss']
})
export class MultiZetComponent implements OnInit {

  @Input() programs: MultiPlanPK[];
  @Input() size: MultiZetSize = MultiZetSize.NORMAL;
  @Input() text: string;
  @Input() label: string;
  @Input() theme = '';
  @Input() themeText = '';
  @Input() themeTooltip = 'tooltip-text tooltip_white tooltip_flat tooltip_big';
  @Input() triggers: TooltipTrigger = 'hover';
  @ViewChild(TooltipDirective) private appTooltip: TooltipDirective;

  multiplier: number;
  show = true;

  get isBigSize(): boolean {
    return this.size === MultiZetSize.BIG;
  }

  ngOnInit(): void {
    this.multiplier = this.programs && this.programs.length;
    this.show = this.programs && this.programs.length > 1;
  }

  toggle() {
    if (this.show && !_isNil(this.appTooltip)) {
      this.appTooltip.toggle();
    }
  }
}


