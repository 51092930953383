import { Injectable } from '@angular/core';
import { QuestionaryService } from '../questionary.service';
import { EntityIdAndNameSchema, PsychologicalQuestionary, QuestionaryResult, QuestionaryResultRequestSchema } from '@rsmu/portal-api';
import { Observable, Subject } from 'rxjs';
import { QuestionaryPsychologicalTest } from '@rsmu/portal-api';
import { tap, takeUntil } from 'rxjs/operators';
import { QuestionaryPhase } from '../questionary-interface/questionary-phase';
import _remove from 'lodash-es/remove';

@Injectable()
export class PsyQuestionaryService extends QuestionaryService<PsychologicalQuestionary, QuestionaryResultRequestSchema, string> {

  results: QuestionaryResult;
  private updateSubject = new Subject<void>();

  get numberOfQuestions(): number {
    return this.psyQuestionary.questions.length;
  }

  get activeQuestion(): PsychologicalQuestionary {
    return this.psyQuestionary.questions[this.activeQuestionIndex];
  }

  get changesAnswers(): Observable<void> {
    return this.updateSubject
      .asObservable()
      .pipe(takeUntil(this.destroy$));
  }

  get instruction(): string {
    return this.psyQuestionary.instruction;
  }

  nextQuestion(): void {
    this.setAnswers(
      this.displayAnswers
    );
    this.activeQuestionIndex++;
    if (this.activeQuestionIndex === this.numberOfQuestions) {
      this.sendAnswersToApi();
    } else {
      this.activeQuestion$.next(this.activeQuestion);
    }
  }

  previousQuestion(): void {
    if (this.activeQuestionIndex > 0) {
      this.activeQuestionIndex--;
      this.activeQuestion$.next(this.activeQuestion);
    }
  }

  startTest(): void {
    this.activePhase = QuestionaryPhase.QUESTIONS;
  }

  finishTest(): void {
    this.activePhase = QuestionaryPhase.REWARDS;
  }

  isChecked(answer: EntityIdAndNameSchema): boolean {
    return answer && this.displayAnswers
      && !!this.displayAnswers.find(value => value === answer.id);
  }

  addNewAutocomplete(): void {
    this.displayAnswers.push(null);
  }

  writeSingleAnswer(currentAnswer: EntityIdAndNameSchema): void {
    this.isDisabled = false;
    this.displayAnswers[0] = currentAnswer.id;
  }

  writeMultipleAnswer(checked: boolean, currentAnswer: EntityIdAndNameSchema) {
    this.isDisabled = false;
    if (checked) {
      this.displayAnswers.push(currentAnswer.id);
    } else {
      _remove(this.displayAnswers, answer => answer === currentAnswer);
    }
  }

  deleteAutocomplete(index: number): void {
    this.displayAnswers.splice(index, 1);
  }

  changeValueAnswer(value: string, index: number): void {
    if (value) {
      this.isDisabled = false;
      this.displayAnswers[index] = value;
      this.updateSubject.next();
    }
  }

  protected getQuestionaryFromApi(): Observable<any> {
    return this.getPsychologicalQuestionaryFromApi();
  }

  protected initListeners(): void {
    this.activeQuestion$
      .asObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.displayAnswers = this.getActiveQuestionChoices();
        this.isDisabled = !this.displayAnswers || !this.displayAnswers.length;
        if (!this.displayAnswers || !this.displayAnswers.length) {
          this.displayAnswers.push(null);
        }
      });
    this.activeQuestion$.next(this.activeQuestion);
  }

  protected sendAnswersToApi(): void {
    this.sendProcess = true;
    (this.dryRun
        ? this.apiService.sendAnswersAndGetResultsPsychologicalTestDryRun(this.questionaryId, this.allAnswers)
        : this.apiService.sendAnswersAndGetResultsPsychologicalTest(this.questionaryId, this.allAnswers)
    )
      .subscribe(
        (res) => {
          this.results = res;
          this.activePhase = QuestionaryPhase.RESULTS;
          this.sendProcess = false;
        },
        (err) => {
          this.isErrorSend = true;
          this.sendProcess = false;
        }
      );
  }

  private setAnswers(answers: string[]): void {
    for (let i = 0; i < this.allAnswers.length; i++) {
      if (this.allAnswers[i].questionId &&
          this.activeQuestion.questionId &&
          this.allAnswers[i].questionId === this.activeQuestion.questionId) {
        this.allAnswers[i] = {
          questionId: this.activeQuestion.questionId,
          selectedChoices: answers
        };
        return;
      }
    }
    this.saveAnswers(answers);
  }

  private getActiveQuestionChoices(): string[] {
    const questionId = this.activeQuestion.questionId;
    const answerSchema = this.allAnswers && this.allAnswers.find(answer => answer.questionId === questionId);
    return answerSchema && answerSchema.selectedChoices ? answerSchema.selectedChoices : [];
  }

  private saveAnswers(answers: Array<string>): void {
    this.allAnswers.push({
      questionId: this.activeQuestion.questionId,
      selectedChoices: answers
    });
  }

  private getPsychologicalQuestionaryFromApi(): Observable<QuestionaryPsychologicalTest> {
    return (this.dryRun
        ? this.apiService.retrievePsychologicalTestByIdDryRun(this.questionaryId)
        : this.apiService.retrievePsychologicalTestById(this.questionaryId)
    ).pipe(
      tap(res => {
        this.psyQuestionary = res;
        this.checkInstructions();
      })
    );
  }

  private checkInstructions(): void {
    if (this.psyQuestionary.instruction) {
      this.activePhase = QuestionaryPhase.INSTRUCTIONS;
      return;
    }
    this.activePhase = QuestionaryPhase.QUESTIONS;
  }

}
