import { Directive, ElementRef, EventEmitter, OnInit, Output } from '@angular/core';

@Directive({
  selector: '[appElementCreated]'
})
export class ElementCreatedDirective implements OnInit {

  @Output('appElementCreated') initEvent: EventEmitter<ElementRef> = new EventEmitter();

  constructor(private element: ElementRef) { }

  ngOnInit(): void {
    this.initEvent.emit(this.element);
  }
}
