import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { CyclesStoreActions, CyclesStoreSelectors, RootStoreState } from '../root-store';
import {combineLatest, Observable, Subject} from 'rxjs';
import {CyclesResponseSchema, MyCycleZetDto} from '@rsmu/portal-api';
import { CurrentCycleStoreActions, CurrentCycleStoreSelectors } from '../root-store/current-cycle-store';
import {debounceTime, distinctUntilChanged, filter, map, mergeMap, switchMap, tap} from 'rxjs/operators';
import { ApiService } from '../api/api.service';
import {SecurityService} from '../security/security.service';

@Injectable({
  providedIn: 'root'
})
export class CyclesService {

  /**
   * Indicates when cycles loading from server
   */
  public readonly isLoading: Observable<boolean> = this.store$.pipe(
    select(CyclesStoreSelectors.selectCyclesIsLoading)
  );

  private _cyclesLength: number = null;
  private readonly cacheActualTime = 10 * 60 * 1000;
  private myCycleZetSubject$: Subject<void> = new Subject<void>();

  get cyclesLength(): number {
    return this._cyclesLength;
  }

  constructor(
    private store$: Store<RootStoreState.RootState>,
    private apiService: ApiService,
    private readonly securityService: SecurityService
  ) { }

  /**
   * Loads data about cycles from server
   */
  loadCycles(): void {
    this.store$.dispatch(
      new CyclesStoreActions.LoadRequestAction()
    );
  }

  getIsFirstLoading(): Observable<boolean> {
    return this.store$.pipe(
      select(CyclesStoreSelectors.selectCyclesIsFirstLoading)
    );
  }

  getCacheTime(): Observable<number> {
    return this.store$.pipe(
      select(CyclesStoreSelectors.selectCyclesCacheTime)
    );
  }

  /**
   * @returns basic cycles data from server
   */
  getCycles(): Observable<CyclesResponseSchema[]> {
    return combineLatest<[boolean, boolean, number]>([
      this.isLoading,
      this.getIsFirstLoading(),
      this.getCacheTime()
    ]).pipe(
      debounceTime(0),
      map(([isLoading, isFirstLoading, cacheTime]) => ({isLoading, isFirstLoading, cacheTime})),
      tap(process => {
        if (process.isFirstLoading && !process.isLoading) {
          this.loadCycles();
        } else if (!process.isLoading && this.isExpired(+process.cacheTime)) {
          this.loadCycles();
        }
      }),
      filter(process => !process.isFirstLoading && !process.isLoading),
      mergeMap(() => this.selectCycles())
    );
  }

  cleanCycles(): void {
    this._cyclesLength = null;
    this.store$.dispatch(new CyclesStoreActions.CleanCyclesAction());
  }

  /**
   * Sets current cycle for cycle management
   * @param currentCycle - id of selected cycle
   */
  setCurrentCycle(currentCycle: string) {
    this.store$.dispatch(
      new CurrentCycleStoreActions.SetCurrentCycleAction({ currentCycle })
    );
  }

  /**
   * Removes selected cycle id
   */
  removeCurrentCycle() {
    this.store$.dispatch(
      new CurrentCycleStoreActions.RemoveCurrentCycleAction()
    );
  }

  /**
   * @returns id of selected cycle
   */
  getCurrentCycle(): Observable<string | null> {
    return this.store$
      .pipe(
        select(CurrentCycleStoreSelectors.selectCurrentCycle)
      );
  }

  /**
   * @returns boolean value which enables displaying of active cycle in sidebar menu
   */
  getShowCurrentCycle(): Observable<boolean> {
    return this.store$.pipe(
        select(CurrentCycleStoreSelectors.selectShowCurrentCycle)
      );
  }

  /**
   * This method displays css for selected cycle in sidebar
   */
  displayCurrentCycle() {
    this.store$.dispatch(
      new CurrentCycleStoreActions.UpdateShowCurrentCycle({ showCurrentCycle: true })
    );
  }

  loadPrevCycleToCurrent() {
    this.store$.dispatch(
      new CurrentCycleStoreActions.LoadLastToCurrentCycleAction()
    );
  }

  /**
   * This method hide css for selected cycle in sidebar
   */
  hideCurrentCycle() {
    this.store$.dispatch(
      new CurrentCycleStoreActions.UpdateShowCurrentCycle({ showCurrentCycle: false })
    );
  }

  /**
   * @returns information about selected cycle
   */
  getCurrentCycleInfo(): Observable<CyclesResponseSchema> {
    return this.getCurrentCycle()
      .pipe(
        filter(cycleId => cycleId !== undefined),
        switchMap((cycleId: string) => this.store$.pipe(
          select(CyclesStoreSelectors.selectCycleById(cycleId))
        )
      )
    );
  }

  getLastUsedCycle(): Observable<string> {
    return this.store$.pipe(
      select(CurrentCycleStoreSelectors.selectLastUsedCycle)
    );
  }

  getMyCycleZet(manualUpdate?: boolean): Observable<MyCycleZetDto> {
    const getCycleZetFunc = this
      .getCurrentCycle().pipe(
        filter(cycleId => !!cycleId),
        switchMap((cycleId: string) => this.apiService.retrieveMyCycleZet(cycleId)));
    return manualUpdate ? this.myCycleZetSubject$.asObservable()
      .pipe(
        switchMap(() => getCycleZetFunc)) : getCycleZetFunc;
  }

  activateCycle(cycleId: string): Observable<void> {
    return this.apiService.activateCycle(cycleId)
      .pipe(
        tap(() => this.loadCycles())
      );
  }

  updateMyCycleZet() {
    this.myCycleZetSubject$.next();
  }

  private selectCycles(): Observable<CyclesResponseSchema[]> {
    return this.store$.pipe(
      select(CyclesStoreSelectors.selectAllCycles),
      tap((items: CyclesResponseSchema[]) => this._cyclesLength = items.length || 0)
    );
  }

  private isExpired(time: number): boolean {
    return new Date().getTime() - time > this.cacheActualTime;
  }
}
