import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { UIRouter } from '@uirouter/core';

import { QuestionaryService } from './questionary.service';
import { QuestionaryRouteParams } from './questionary-interface/questionary-route-params';
import { QuestionaryType } from './questionary-interface/questionary-type';
import { QuestionaryPsychologicalTest } from '@rsmu/portal-api';
import { PsyQuestionaryService } from './psy-questionary/psy-questionary.service';
import { ProfQuestionaryService } from './prof-questionary/prof-questionary.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-questionary',
  templateUrl: './questionary.component.html',
  styleUrls: ['./questionary.component.scss'],
  providers: [
    PsyQuestionaryService,
    ProfQuestionaryService,
  ]
})
export class QuestionaryComponent implements OnInit, OnDestroy {

  loading = false;
  error: string;

  private questionaryService: QuestionaryService<any, any, any>;

  constructor(
    private route: UIRouter,
    private injector: Injector,
  ) {
  }

  ngOnInit(): void {
    this.loading = true;

    const config = this.route.stateService.params as QuestionaryRouteParams;
    if (config.questionaryType === QuestionaryType.PSYCHOLOGICAL) {
      this.questionaryService = this.injector.get(PsyQuestionaryService);
    } else {
      this.questionaryService = this.injector.get(ProfQuestionaryService);
    }

    this.questionaryService
      .init(config)
      .pipe(finalize(() => this.loading = false))
      .subscribe(
        () => {},
        error => this.error = error
      );
  }

  get isReadonly(): boolean {
    return this.questionaryService.isReadonly;
  }

  get questionaryKeyTitle(): string {
    return this.questionaryService.questionaryKeyTitle;
  }

  get typeIsPsychological(): boolean {
    return this.questionaryType === QuestionaryType.PSYCHOLOGICAL;
  }

  get questionaryType(): QuestionaryType {
    return this.questionaryService.questionaryType;
  }

  get topicId(): string {
    return this.questionaryService.topicId;
  }

  get psyQuestionary(): QuestionaryPsychologicalTest {
    return this.questionaryService.psyQuestionary;
  }

  ngOnDestroy(): void {
    this.questionaryService.destroy();
  }
}
