<div class="multiselect">
  <div class="multiselect__column">
    <button class="multiselect__button multiselect__button__raised" mat-raised-button
            (click)="disabled ? nothing() : openDialog()" *ngIf="selectedItemsCount$ | async as selectedItemsCount"
            [ngClass]="{'multiselect__button__fixed': disabled}"
    >
      <ng-container *ngIf="selectedItemsCount === 1">
        <span class="button_size-sm multiselect__button__text button">{{ selectedOneItem$ | async }}</span>
      </ng-container>
      <ng-container *ngIf="selectedItemsCount > 1 ">
        <span class="button_size-sm multiselect__button__text">{{label}} - {{selectedItemsCount}}</span>
      </ng-container>
    </button>
    <button [disabled]="disabled" class="multiselect__button multiselect__button__stroked" mat-stroked-button
            [ngClass]="{'multiselect__button__stroked__disabled': disabled}"
            (click)="openDialog()" *ngIf="!(selectedItemsCount$ | async)">
      <span class="button_size-sm multiselect__button__text">{{label}}</span>
    </button>
  </div>
</div>

