import ErrorData from '../errors/error-data';

export function hasGlobalError(err: ErrorData, options: ErrorOptions): boolean {
  return err &&
         err.status === options.status &&
         err.error &&
         err.error.globalErrors &&
         err.error.globalErrors.some(globalError => globalError.code === options.code);
}

export function hasGlobalErrors(error: ErrorData, options: ErrorOptions[]): boolean {
  return options.some((option: ErrorOptions) => hasGlobalError(error, option));
}

export interface ErrorOptions {
  code: string;
  status: number;
}
