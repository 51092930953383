import { Component, EventEmitter, Input, Output } from '@angular/core';

export type PopoverPosition = 'bottom' | 'top' | 'right' | 'bottom-left' | 'top-left';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss']
})
export class PopoverComponent {

  @Input() position: PopoverPosition = 'bottom';
  @Input() hasArrow = false;
  @Input() theme: string;

  @Output() popoverClose = new EventEmitter<void>();

  close(): void {
    this.popoverClose.next();
  }

}
