import {Action} from "@ngrx/store";

export enum ActionTypes {
  EXIT_TOUR = '[Tours] Exit tour',
}

export class ExitTourAction implements Action {
  readonly type = ActionTypes.EXIT_TOUR;
  constructor(public payload: { tourId: string, stepNumber: number }) {}
}

export type Actions =
  | ExitTourAction;
