<div *ngIf="cases" class="switch-set">
  <button *ngFor="let curCase of cases"
          tabindex="0"
          class="button button_primary button_larger-radius switch-set__button"
          [class.button_stroked]="curCase !== activeCase"
          [title]="(curCase.title ? (curCase.title | translate) : '')"
          [disabled]="disabled"
          (click)="select(curCase)">
    {{ curCase.label }}
  </button>
</div>
