import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CyclesService } from '../../cycles/cycles.service';
import { Observable } from 'rxjs';
import { CyclesResponseSchema, CycleStatusInfoResponseSchema } from '@rsmu/portal-api';
import { StateService } from '@uirouter/core';
import { PopupComponent } from '../popup/popup.component';
import { ApiService } from 'src/app/api/api.service';
import { DialogService } from '../dialog/dialog.service';
import { PopupAction } from '../popup/interfaces/popup.dto';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-cycle-management',
  templateUrl: './cycle-management.component.html',
  styleUrls: ['./cycle-management.component.scss']
})
export class CycleManagementComponent implements OnInit {

  currentCycle: string;
  cycles$: Observable<CyclesResponseSchema[]> = this.cyclesService.getCycles();
  createdCycle: CycleStatusInfoResponseSchema;
  loading = false;

  get cyclesLoading() {
    return this.cyclesService.isLoading;
  }

  @Input() allowAddCycle = false;
  @Input() showSelectedCycle = true;
  @Input() useForTour = false;

  @ViewChild('popupInfoAboutCreatedCycle', { static: true }) private popupInfoAboutCreatedCycle: PopupComponent<void>;
  @ViewChild('popupDocumentAdded', { static: true }) private popupDocumentAdded: PopupComponent<void>;

  @Output() cycleChange = new EventEmitter<string>();

  constructor(
    private cyclesService: CyclesService,
    private stateService: StateService,
    private apiService: ApiService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private datePipe: DatePipe
  ) { }

  ngOnInit() {
    this.cyclesService
      .getCurrentCycle()
      .subscribe((cycleInfo: string) => {
        this.currentCycle = cycleInfo;
      });
  }

  formatDate(date: string) {
    return this.datePipe.transform(date, 'dd.MM.yyyy');
  }

  select(id: string) {
    this.cyclesService.setCurrentCycle(id);
    this.cycleChange.emit(id);
  }

  onCreateCyclePopupHide(cycleId: string) {
    this.cyclesService.loadCycles();
    this.select(cycleId);
  }

  openInfoCycle(cycleId: string) {
    this.popupInfoAboutCreatedCycle.show();
    this.loading = true;
    this.apiService.getCycleStatusInfo(cycleId).subscribe(
      (res) => {
        this.createdCycle = res;
        this.loading = false;
      },
      (err) => this.loading = false
    );
  }

  openConfirmChangeCycle(event) {
    const cycleId = event.cycleId;
    this.apiService.getCycleStatusInfo(cycleId).subscribe(
      (res) => {
        this.createdCycle = res;
        this.loading = false;
        this.openDialog(cycleId);
      },
      (err) => this.loading = false
    );
  }

  openDialog(cycleId: string) {
    let confirmMsg: string;
    this.translateService.get('addCycle.hasAnotherCycle', {
      programName: this.createdCycle.activeCycle ? this.createdCycle.activeCycle.programName : null,
      startDate: this.createdCycle.activeCycle ? this.formatDate(this.createdCycle.activeCycle.startDate) : null,
      endDate: this.createdCycle.activeCycle ? this.formatDate(this.createdCycle.activeCycle.endDate) : null,
      newStartDate: this.formatDate(this.createdCycle.startDate),
      newEndDate: this.formatDate(this.createdCycle.endDate)
    }).subscribe(
      text => confirmMsg = text
    );
    this.dialogService
      .confirm(confirmMsg)
      .subscribe((action) => {
        if (action === PopupAction.CONFIRM) {
          this.cyclesService.activateCycle(cycleId).subscribe(
            res => this.onCreateCyclePopupHide(cycleId)
          );
          this.popupInfoAboutCreatedCycle.show();
        } else {
          this.popupDocumentAdded.show();
        }
      });
  }

}
