import {
  AfterViewInit,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Inject,
  Injector,
  Input,
  OnInit,
  Optional,
  QueryList,
  Self,
  ViewChild,
  ViewChildren,
  ViewContainerRef
} from '@angular/core';
import {
  NgbDate,
  NgbDateParserFormatter,
  NgbDatepicker,
  NgbDatepickerConfig,
  NgbDatepickerI18n,
  NgbDateStruct
} from '@ng-bootstrap/ng-bootstrap';
import { AbstractControl, ControlValueAccessor, NgControl, NgModel, ValidationErrors, ValidatorFn } from '@angular/forms';
import { DateTime } from 'luxon';
import { CustomDatepickerI18n, DATEPICKER_TITLES, NgbDatepickerI18nTitles } from '../datepicker-i18n';
import { DatepickerParserFormatter } from '../datepicker-parser-formatter.';
import { datepickerInputMask } from '../../../constants/datepicker-input-mask';
import { DatePickerErrors } from '../datepicker-errors';
import { Overlay } from '@angular/cdk/overlay';
import { LayoutService } from '../../layout/layout.service';
import { takeUntil } from 'rxjs/operators';
import _isEmpty from 'lodash-es/isEmpty';
import _isString from 'lodash-es/isString';
import _isNumber from 'lodash-es/isNumber';
import { DateUtils } from '../date-utils';
import { DatepickerValidators } from '../datepicker-validators';
import { KeyListenerService } from '../../../key-listener/key-listener.service';
import { ManagedOverlayConnected } from '../../dropdown/managed-overlay-connected';
import { Placement, POSITION_MAP } from '../../dropdown/placement';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  providers: [
    { provide: DATEPICKER_TITLES, useClass: CustomDatepickerI18n },
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
    { provide: NgbDateParserFormatter, useClass: DatepickerParserFormatter }
  ]
})
export class DatepickerComponent extends ManagedOverlayConnected implements AfterViewInit, ControlValueAccessor, OnInit {

  @Input() set maxDate(maxDate: NgbDateStruct) {
    this._maxDate = maxDate;
    this.updateValueAndValidity();
  }

  get maxDate(): NgbDateStruct {
    return this._maxDate;
  }

  private _maxDate: NgbDateStruct | null = null;

  @Input() set minDate(minDate: NgbDateStruct) {
    this._minDate = minDate;
    this.updateValueAndValidity();
  }

  get minDate(): NgbDateStruct {
    return this._minDate;
  }

  private _minDate: NgbDateStruct = DateUtils.DEFAULT_MIN_DATE;

  @Input() label;
  @Input() placeholder;
  @Input() disabled = false;
  @Input() isBold = false;
  @Input() isBig = false;
  @Input() leftLabel = false;
  @Input() canReset = false;
  @Input() placement = Placement.BOTTOM_RIGHT;
  @Input() hideLabelOnMobile = false;
  @Input() theme = '';

  @Input() set value(newVal: number | null) {
    if (!_isNumber(newVal)) {
      this.resetDate();
    } else if (!this.selectedDate || DateUtils.datetimeFromDateStruct(this.selectedDate).valueOf() !== newVal) {
      this.selectedDate = (({ day, month, year }) => ({ day, month, year }))(DateTime.fromMillis(newVal));
    }
  }

  get value(): number {
    return this.selectedDate ? DateUtils.datetimeFromDateStruct(this.selectedDate).valueOf() : null;
  }

  @ViewChildren(NgbDatepicker, { read: ElementRef }) private ngbDatepicker: QueryList<ElementRef<HTMLElement>>;
  @ViewChild('ngbDatepickerModel') private ngbDatepickerModel: NgModel;

  @HostBinding('class.datepicker-host') hostClass = true;

  readonly datepickerMask = datepickerInputMask;
  fieldModel = '';

  private _selectedDate: NgbDateStruct | null;

  get selectedDate(): NgbDateStruct | null {
    return this._selectedDate;
  }

  set selectedDate(newVal: NgbDateStruct | null) {
    this._selectedDate = newVal;
    this.setFieldModel(this.selectedDate);
    this._onChange(this.value);
    this.close();
  }

  get config(): NgbDatepickerConfig & { maxDate: NgbDateStruct | null, minDate: NgbDateStruct | null } {
    return {
      minDate: this.minDate,
      maxDate: this.maxDate
    } as NgbDatepickerConfig;
  }

  get control(): AbstractControl | null {
    return this.controlDir ? this.controlDir.control : null;
  }

  private _onChange = (value) => {};
  @HostListener('blur') onTouched = () => {};

  constructor(
    @Optional() @Self() private readonly controlDir: NgControl,
    protected readonly injector: Injector,
    protected readonly layoutService: LayoutService,
    protected readonly overlay: Overlay,
    protected readonly viewContainer: ViewContainerRef,
    protected readonly keyListener: KeyListenerService,
    @Inject(DATEPICKER_TITLES) private readonly datepickerI18nTitles: NgbDatepickerI18nTitles
  ) {
    super(
      injector,
      keyListener,
      layoutService,
      overlay,
      viewContainer
    );
    if (controlDir) {
      controlDir.valueAccessor = this;
    }
  }

  ngAfterViewInit(): void {
    this.ngbDatepicker
      .changes
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.reassignDatepickerTitles();
      });
  }

  ngOnInit() {
    if (this.control) {
      const validators: ValidatorFn | ValidatorFn[] = this.control.validator
        ? [ this.control.validator, this.dateValidator() ]
        : this.dateValidator();

      this.control.setValidators(validators);
      this.control.updateValueAndValidity();

      const cached = this.control.markAsDirty;
      this.control.markAsDirty = (opts?) => {
        cached.apply(this.control, opts);
        if (this.ngbDatepickerModel) {
          this.ngbDatepickerModel.control.markAsDirty(opts);
        }
      };
    }

    this.positions.unshift(
      POSITION_MAP.get(this.placement)
    );
  }

  writeValue(obj: any): void {
    this.value = obj as number;
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  inputChange(event: string) {
    let dateState = false;

    for (let i = 0; i < event.length; i++) {
      dateState = RegExp(datepickerInputMask[i]).test(event[i]);
      if (!dateState) {
        break;
      }
    }

    if (dateState) {
      this.selectedDate = DateUtils.getNgbDateFromString(event);
    } else {
      this.resetDate();
    }
  }

  resetDate() {
    this.selectedDate = null;
  }

  private dateValidator(): ValidatorFn {
    return (): ValidationErrors | null => {
      let resultErrors: ValidationErrors | null = null;
      const errors = {
        ...(this.ngbDatepickerModel && this.ngbDatepickerModel.control.errors ? this.ngbDatepickerModel.control.errors.ngbDate : null),
        ...this.getErrorDate(this.selectedDate)
      };

      if (!_isEmpty(errors)) {
        resultErrors = Object.entries(errors)
          .reduce(
            (prevErrors: ValidationErrors, [ key, value ]: [ string, string | NgbDate ]) => {
              const keyFiled: DatePickerErrors = this.getKeyError(key);

              prevErrors[keyFiled] = this.formatError(keyFiled, value);
              return prevErrors;
            },
            {} as ValidationErrors
          );
      }

      return !_isEmpty(resultErrors) ? resultErrors : null;
    };
  }

  private formatError(key: DatePickerErrors, value: string | NgbDate): string {
    switch (key) {
      case DatePickerErrors.REQUIRED_BEFORE:
      case DatePickerErrors.REQUIRED_AFTER:
        return _isString(value) ? value as string : DateUtils.formatDateStruct(value as NgbDate);

      case DatePickerErrors.INVALID_FORMAT:
      default:
        return value as string;
    }
  }

  private getErrorDate(inputDate?: NgbDateStruct): ValidationErrors | null {
    if (!inputDate) {
      return null;
    }

    const { minDate, maxDate } = this.config;

    if (!DateUtils.isDateStructValid(inputDate)) {
      return { [DatePickerErrors.INVALID_FORMAT]: true };
    }
    if (minDate) {
      const errors: ValidationErrors | null = DatepickerValidators.validateMinTimestamp(minDate, DateUtils.getTimeDate(inputDate));

      if (errors) {
        return errors;
      }
    }
    if (maxDate) {
      const errors: ValidationErrors | null = DatepickerValidators.validateMaxTimestamp(maxDate, DateUtils.getTimeDate(inputDate));

      if (errors) {
        return errors;
      }
    }

    return null;
  }

  private getKeyError(key: string): DatePickerErrors {
    return key === 'invalid' ? DatePickerErrors.INVALID_FORMAT : key as DatePickerErrors;
  }

  private reassignDatepickerTitles(): void {
    this.ngbDatepicker.forEach((item: ElementRef<HTMLElement>) => {
      const selectMonthTitled: NodeListOf<Element> = item.nativeElement.querySelectorAll('[title=\'Select month\']');
      const selectYearTitled: NodeListOf<Element> = item.nativeElement.querySelectorAll('[title=\'Select year\']');

      for (let i = 0; i < selectMonthTitled.length; i++) {
        const selectMonthTitledElement: Element = selectMonthTitled[i];

        selectMonthTitledElement.setAttribute('aria-label', this.datepickerI18nTitles.getSelectMonthTitle());
        selectMonthTitledElement.setAttribute('title', this.datepickerI18nTitles.getSelectMonthTitle());
      }

      for (let i = 0; i < selectYearTitled.length; i++) {
        const selectYearTitledElement: Element = selectYearTitled[i];

        selectYearTitledElement.setAttribute('aria-label', this.datepickerI18nTitles.getSelectYearTitle());
        selectYearTitledElement.setAttribute('title', this.datepickerI18nTitles.getSelectYearTitle());
      }
    });
  }

  private updateValueAndValidity(): void {
    if (this.control) {
      this.control.updateValueAndValidity({ onlySelf: true });
    }
  }

  private setFieldModel(field: NgbDateStruct | null) {
    this.fieldModel = field != null ? DateUtils.formatDateStruct(field) : '';
  }
}
