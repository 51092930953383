import { Directive, ElementRef, HostListener } from '@angular/core';

const KEY_CODE_SPACE = 32;
const KEY_CODE_ENTER = 13;

@Directive({
  selector: '[appAccessibleLink]'
})
export class AccessibleLinkDirective {

  private readonly firedKeys = [KEY_CODE_SPACE, KEY_CODE_ENTER];

  @HostListener('keypress', ['$event']) onKeyPress(event: KeyboardEvent) {
    // tslint:disable-next-line:deprecated
    if (this.firedKeys.indexOf(event.keyCode) !== -1) {
      event.preventDefault();
      event.stopPropagation();
      this.element.nativeElement.click();
    }
  }

  constructor(
    private element: ElementRef,
  ) {
  }

}
