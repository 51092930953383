import { Injectable, Injector } from '@angular/core';
import { ApiService } from '../api/api.service';
import { Observable, of, ReplaySubject, Subject } from 'rxjs';
import { GenderEnum, UserFile, UserInfo } from '@rsmu/portal-api';
import { catchError, map, mapTo, switchMap, take } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { constructImageDataUrl } from '../utils/image-utils';
import { FeatureKeys } from '../app-commons/feature/feature-keys';
import { FeatureService } from '../app-commons/feature/feature.service';
import { DialogService } from '../app-commons/dialog/dialog.service';
import { RouteService } from '../app-routing/route.service';
import _noop from 'lodash-es/noop';
import {RetrievingUserPostInformation} from '@rsmu/portal-api';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  private profile: UserInfo;
  private profile$: ReplaySubject<UserInfo>;
  private userAvatar$: ReplaySubject<string>;

  get deactiavteProfileDate (): string {
    return this.profile.deactivationProfileDate;
  }
  get dialogService(): DialogService {
    return this.injector.get(DialogService);
  }

  get isNMOSyncEnabled$(): Observable<boolean> {
    return this.featureService.isFeatureEnabled$(FeatureKeys.NMO_SYNC);
  }

  get getFirstName() {
    return this.profile && this.profile.firstName;
  }

  get getMiddleName() {
    return this.profile && this.profile.middleName;
  }

  get userLastName () {
    return this.profile && this.profile.lastName;
  }

  get userFullName () {
    return this.profile && (this.profile.firstName + ' ' + this.profile.middleName + this.profile.lastName);
  }

  constructor(private readonly apiService: ApiService,
              private readonly featureService: FeatureService,
              private readonly routeService: RouteService,
              private readonly injector: Injector) {
  }

  getProfile(): Observable<UserInfo> {
    if (!this.profile$) {
      this.profile$ = new ReplaySubject<UserInfo>(1);
      this.refreshProfile().subscribe(_noop);
    }
    return this.profile$.asObservable().pipe(take(1));
  }

  refreshProfile(): Observable<boolean> {
    return this.apiService
      .getUserInfo()
      .pipe(
        map((userInfo) => {
          if (this.profile$) {
            this.profile$.next(userInfo);
            this.profile = userInfo;
            this.profile.positions = userInfo.positions.sort(this.userInfoTitlesSortingRules);
          }
          return true;
        }),
        catchError((error) => {
          if (this.profile$) {
            this.profile$.error(error);
            this.profile$ = null;
          }
          return of(false);
        })
      );
  }

  isMale(): boolean {
    return this.profile && this.profile.gender === GenderEnum.Male;
  }

  getUserInfoTitles () {
    return this.profile && this.profile.positions;
  }

  getUserAvatar(): Observable<string> {
    if (!this.userAvatar$) {
      this.loadUserAvatar();
    }
    return this.userAvatar$.asObservable();
  }

  setUserAvatar(file: UserFile): Observable<void> {
    const loadAvatarEvent$: Subject<void> = new Subject();
    const request$ = file == null ? this.apiService.deleteUserImage() : this.apiService.loadingImage(file);
    request$.subscribe(() => {
        if (this.userAvatar$) {
          this.userAvatar$.next(this.constructImageDataUrl(file));
        }
        loadAvatarEvent$.next();
        loadAvatarEvent$.complete();

      }, (error: HttpErrorResponse) => {
        loadAvatarEvent$.error(error);
        loadAvatarEvent$.complete();
      });

    return loadAvatarEvent$.asObservable();
  }

  clearProfile(): void {
    if (this.profile$ != null) {
      this.profile = null;
      this.profile$.complete();
      this.profile$ = null;
    }

    if (this.userAvatar$ != null) {
      this.userAvatar$.complete();
      this.userAvatar$ = null;
    }
  }

  syncNMOProfile(): Observable<boolean> {
    return this.apiService
      .createNmoProfileAssociation({
        redirectUrl: this.routeService.getStateUrl('userAccount.nmoProfileSyncRedirect')
      })
      .pipe(
        mapTo(true)
      );
  }

  syncNMOPortfolio(): Observable<boolean> {
    return this.apiService
      .updateProfileActivitiesFromNmo({
        redirectUrl: this.routeService.getStateUrl('settings')
      })
      .pipe(
        switchMap(() => this.dialogService.messageTranslate('profile.settings.nmoSyncPortfolioWarning')),
        mapTo(true)
      );
  }

  private loadUserAvatar(): void {
    this.userAvatar$ = new ReplaySubject(1);
    this.apiService
      .downloadImage()
      .subscribe((userPicture: UserFile) => {
        if (this.userAvatar$) {
          (userPicture && userPicture.src)
            ? this.userAvatar$.next(this.constructImageDataUrl(userPicture))
            : this.userAvatar$.next(null);
        }
      }, () => {
        if (this.userAvatar$) {
          this.userAvatar$.next(null);
        }
      });
  }

  private userInfoTitlesSortingRules (a: RetrievingUserPostInformation,
                               b: RetrievingUserPostInformation) {
    return booleanSortingRules(a.actual, b.actual) || stringSortingRules(a.workplace, b.workplace) || stringSortingRules(a.type, b.type);
    function booleanSortingRules(boolA: boolean, boolB: boolean) {
      return boolA === boolB ? 0 : boolA > boolB ? -1 : 1;
    }
    function stringSortingRules(strA: string, strB: string) {
      return strA.localeCompare(strB);
    }
  }

  private constructImageDataUrl(userPicture: UserFile) {
    return userPicture == null ? null : constructImageDataUrl(userPicture);
  }
}
