import { State, cyclesAdapter } from './state';
import { MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store';
import { CyclesResponseSchema } from '@rsmu/portal-api';

export const getError = (state: State): any => state.error;

export const getIsLoading = (state: State): boolean => state.isLoading;

export const getIsFirstLoading = (state: State): boolean => state.isFirstLoading;

export const getCacheTime = (state: State): number => state.cacheTime;

export const selectCyclesState: MemoizedSelector<object, State> =
  createFeatureSelector<State>('cycles');

export const selectAllCycles: (state: object) => CyclesResponseSchema[] =
  cyclesAdapter.getSelectors(selectCyclesState).selectAll;

export const selectCycleById = (id: string) =>
  createSelector(selectAllCycles, (cycles: CyclesResponseSchema[]) => {
    if (cycles) {
      return cycles.find((cycle: CyclesResponseSchema) => cycle.id === id);
    } else {
      return null;
    }
  });

export const selectCyclesError: MemoizedSelector<object, any> =
  createSelector(selectCyclesState, getError);

export const selectCyclesIsLoading: MemoizedSelector<object, boolean> =
  createSelector(selectCyclesState, getIsLoading);

export const selectCyclesIsFirstLoading: MemoizedSelector<object, boolean> =
  createSelector(selectCyclesState, getIsFirstLoading);

export const selectCyclesCacheTime: MemoizedSelector<object, number> =
  createSelector(selectCyclesState, getCacheTime);
