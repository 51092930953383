import { Injectable } from '@angular/core';
import { ProfileService } from '../profile/profile.service';
import { CabinetsService } from '../cabinets/cabinets.service';
import { EducationalLevelListService } from '../user-init/educational-level-list.service';
import { CyclesService } from '../cycles/cycles.service';
import { FrmrDocumentsService } from '../user-init/frmr-documents.service';

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  constructor(
    private cyclesService: CyclesService,
    private profileService: ProfileService,
    private cabinetsService: CabinetsService,
    private educationLevelListService: EducationalLevelListService,
    private frmrDocumentsService: FrmrDocumentsService,
  ) { }

  clearCaches(): void {
    this.cyclesService.cleanCycles();
    this.profileService.clearProfile();
    this.cabinetsService.clearCabinets();
    this.educationLevelListService.clearCache();
    this.frmrDocumentsService.clearCachedData();
  }
}
