import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

export interface CustomListener {
  id: number;
  callback: () => void;
}

export type CustomListenerCallback = () => void;

@Injectable({
  providedIn: 'root'
})
export class KeyListenerService {

  private escapeListeners: CustomListener[] = [];

  private isInit = false;

  private readonly ESCAPE_KEY = 27;

  private lastId = 0;

  constructor(
    @Inject(DOCUMENT) private document: Document,
  ) {
  }

  generateId(): number {
    this.lastId ++;
    return this.lastId;
  }

  addEscapeListener(callback: CustomListenerCallback): number {
    if (!this.isInit) {
      this.isInit = true;
      this.document.addEventListener('keydown', this.keyboardListener);
    }

    const id = this.generateId();
    this.escapeListeners.push({
      id,
      callback
    });
    return id;
  }

  removeEscapeListener(id: number): void {
    const index = this.escapeListeners.findIndex(it => it.id === id);
    if (index !== -1) {
      this.escapeListeners.splice(index, 1);

      if (!this.escapeListeners.length) {
        this.isInit = false;
        this.document.removeEventListener('keydown', this.keyboardListener);
      }
    }
  }

  escapeKeyClick(): void {
    if (this.escapeListeners.length) {
      this.escapeListeners[this.escapeListeners.length - 1].callback();
    }
  }

  private keyboardListener = (event: KeyboardEvent) => {
    // tslint:disable-next-line:deprecated
    if (event.keyCode === this.ESCAPE_KEY) {
      this.escapeKeyClick();
    }
  }

}
