<app-dropdown [theme]="'dropdown-tree'" #treeDrop>
  <div class="dropdown-tree__toggle" appDropdownToggle>
    <div class="dropdown-tree__icon-lock">
      <svg-icon
        [title]="'dropdownTree.label' | translate"
        class="text-field__svg icon icon_12 icon_primary"
        src="assets/icons/lock.svg"></svg-icon>
    </div>
    <p class="dropdown-tree__toggle-text">
      {{ selectedNode.name }}
    </p>
  </div>
  <div
    class="dropdown-tree__content"
    appDropdownContent>
    <ul class="dropdown-tree__list">
      <li
        *ngFor="let item of items; index as i; trackBy: trackByIdFn"
        role="menuitemradio"
        class="dropdown-tree__list-element"
        [title]="'dropdownTree.' + (hasValues(item) ? 'select-add' : 'select') | translate"
        [attr.aria-checked]="i === 0"
        [class.dropdown-tree__list-element_active]="i === 0"
        [class.dropdown-tree__list-element_add]="hasValues(item)"
        (click)="select(item)">
        <button class="dropdown-tree__list-element-button">
          {{ item.name }}
        </button>
        <span *ngIf="hasValues(item)"
              role="presentation"
              class="figure figure_add dropdown-tree__list-element_add-icon"></span>
      </li>
    </ul>
  </div>
</app-dropdown>

<app-popup #popupAdd [popupContent]="popupContentAdd" [showClose]="false"></app-popup>
<ng-template #popupContentAdd>
  <div class="dropdown-tree__popup">
    <div class="dropdown-tree__popup-checkbox">
      <app-checkbox-group itemLabel="name"
                          itemValue="id"
                          theme="checkbox-group_border-bottom"
                          [values]="valuesToSelect"
                          [preselected]="preselectedValues"
                          (selected)="onCheckboxSelect($event)"></app-checkbox-group>
    </div>
    <p *ngIf="selectedError"
       class="dropdown-tree__popup-under"
       [class.dropdown-tree__popup-under_error]="selectedError">
      <span>{{ 'dropdownTree.error' | translate }}</span>
    </p>
    <div class="dropdown-tree__popup-buttons">
      <button
        class="button button_stroked button_primary dropdown-tree__popup-buttons-cancel"
        (click)="closePopup()">
        {{ 'dropdownTree.close' | translate }}
      </button>
      <button
        class="button button_primary dropdown-tree__popup-buttons-save"
        [disabled]="selectedError"
        (click)="save()">
        {{ 'dropdownTree.save' | translate }}
      </button>
    </div>
  </div>
</ng-template>







