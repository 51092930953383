import { Injectable } from '@angular/core';
import { Observable, of, ReplaySubject } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import {
  DocumentCreationInfoDTO, SimpleResponseDto,
  SpecialityDocumentsRefreshedResponseSchema,
  SpecialityWithDocumentsDTO
} from '@rsmu/portal-api';
import { CycleDocumentStatusSchema } from '@rsmu/portal-api';

import { ApiService } from '../api/api.service';


export interface SpecialitiesWithDocuments {
  specialityName: string;
  documentsQuantity: number;
}

@Injectable({
  providedIn: 'root'
})
export class FrmrDocumentsService {

  private documentsData: SpecialityDocumentsRefreshedResponseSchema;
  private documentsDataSubject$: ReplaySubject<SpecialityDocumentsRefreshedResponseSchema>;

  constructor(private apiService: ApiService) { }

  getDocuments(): Observable<SpecialityDocumentsRefreshedResponseSchema> {
    let result = of(this.documentsData);
    if (!this.documentsData) {
      result = this.loadData();
    }
    return result;
  }

  getSpecialitiesWithDocuments(): Observable<Array<SpecialitiesWithDocuments>> {
    return this.getDocuments().pipe(map((data: SpecialityDocumentsRefreshedResponseSchema) => {
      return data
        .specialities
        .filter((speciality: SpecialityWithDocumentsDTO) => {
          return speciality.documents && speciality.documents.length;
        })
        .map(speciality => ({
          specialityName: speciality.specialityName,
          documentsQuantity: speciality.documents.length
        }));
    }));
  }

  deleteDocument(documentId: string): Observable<void> {
    return this.apiService.deleteDocumentManually(documentId).pipe(tap(() => {
      this.clearCachedData();
    }));
  }

  addDocument(params: { document: DocumentCreationInfoDTO,
                        preferredEduOrganizationId: string,
                        createCycle: boolean }): Observable<CycleDocumentStatusSchema> {
    const request$: Observable<CycleDocumentStatusSchema> = params.createCycle
      ? this.apiService.createCycleByDocument(
        { document: params.document, preferredEduOrganizationId: params.preferredEduOrganizationId }
        )
      : this.apiService.createDocument(params.document);
    return request$.pipe(tap(() => this.clearCachedData()));
  }

  clearCachedData(): void {
    this.documentsData = null;
    this.documentsDataSubject$ = null;
  }

  syncWithFrmr(): Observable<SimpleResponseDto> {
    return this.apiService.syncWithFrmr();
  }

  private loadData(): Observable<SpecialityDocumentsRefreshedResponseSchema> {
    if (!this.documentsDataSubject$) {
      this.documentsDataSubject$ = new ReplaySubject<SpecialityDocumentsRefreshedResponseSchema>();
      this.apiService.getSpecialityDocumentsRefreshed().subscribe((data: SpecialityDocumentsRefreshedResponseSchema) => {
        if (this.documentsDataSubject$) {
          this.documentsData = data;
          this.documentsDataSubject$.next(data);
        }
      });
    }
    return this.documentsDataSubject$.pipe(take(1));
  }
}
