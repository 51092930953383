import { NgModule } from '@angular/core';
import { Ng2StateDeclaration, RootModule, Transition, UIRouterModule } from '@uirouter/angular';

import { RootComponent } from '../root/root.component';
import { LoginComponent } from '../login/login.component';
import { RouteService } from './route.service';
import { PasswordRecoveryComponent } from '../password-recovery/password-recovery.component';
import { RegistrationComponent } from '../registration/registration.component';
import { EsiaCallbackComponent } from '../esia/esia-callback/esia-callback.component';
import { EsiaStartComponent } from '../esia/esia-start/esia-start.component';
import { environment } from '../../environments/environment';
import { SecurityService } from '../security/security.service';
import { RedirectService } from '../redirect/redirect.service';
import { TranslateService } from '@ngx-translate/core';
import { QuestionaryComponent } from '../app-commons/questionary/questionary.component';
import { Title } from '@angular/platform-browser';
import { UIRouter } from '@uirouter/core';
import { tap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { IdpService } from '../idp/idp.service';
import { EsiaErrorComponent } from '../esia-error/esia-error.component';
import { RegistrationResultComponent } from '../registration-result/registration-result.component';
import { PasswordRecoveryResultComponent } from '../password-recovery-result/password-recovery-result.component';
import {IfmoCallbackComponent} from '../ifmo/ifmo-callback/ifmo-callback.component';
import {RecoveryComponent} from '../recovery/recovery.component';

const updatePageTitle = (translateService: TranslateService, titleService: Title, uiRouter: UIRouter): void => {
  const currentStateName: string = uiRouter.stateService.$current.name;
  forkJoin(
    translateService.get(`title.${currentStateName}`),
    translateService.get('title.site'),
    translateService.get('title.higherEducation'),
    translateService.get('title.professionalEducation'),
    translateService.get('title.nonMedicalEducation')
  ).subscribe((res) => {
    const [pageNameTranslated, titlePrefix, cabinetVo, cabinetSpo, cabinetNmd] = res;
    const pageName: string = currentStateName ? pageNameTranslated : '';

    if (pageName) {
      let cabinet = '';
      if (environment.cabinetInstanceType === 'HigherEducation') {
        cabinet = cabinetVo;
      } else if (environment.cabinetInstanceType === 'ProfessionalEducation') {
        cabinet = cabinetSpo;
      } else if (environment.cabinetInstanceType === 'nonMedEducation') {
        cabinet = cabinetNmd;
      }
      const pageTitle = `${titlePrefix} ${cabinet} - ${pageName}`;
      titleService.setTitle(pageTitle);
    }
  });
};

export function initApp(translateService: TranslateService, titleService: Title, uiRouter: UIRouter): Promise<void> {
  return translateService.get(' ').pipe(
    tap(() => {
      updatePageTitle(translateService, titleService, uiRouter);
      uiRouter.transitionService.onSuccess({ entering: true }, () => {
        updatePageTitle(translateService, titleService, uiRouter);
      });
    })
  ).toPromise();
}

const devModuleState: Ng2StateDeclaration = {
  name: 'dev.**',
  url: '/dev',
  loadChildren: () => import('../dev/dev.module').then(m => m.DevModule)
};
const devModuleRouting: Ng2StateDeclaration[] = environment.production ? [] : [devModuleState];

const rootModule: RootModule = {
  states: [
    {
      url: '/',
      name: 'toLoginSlash',
      redirectTo: 'root.login'
    },
    {
      name: 'root',
      url: '',
      redirectTo: 'root.login',
      abstract: true,
      component: RootComponent,
      data: {
        iamComponent: false,
        requiresAuth: false
      },
      resolve: [
        {
          token: 'discarded',
          deps: [TranslateService, Title, UIRouter],
          resolveFn: initApp
        }
      ]
    },
    {
      name: 'redirectUrl',
      url: '',
      redirectTo: 'root.login'
    },
    {
      name: 'root.login',
      url: '/login?messageKey',
      component: LoginComponent,
      data: {
        redirectForAuth: false,
        iamComponent: true
      },
      onEnter: redirectIfUserLoggedIn
    },
    {
      name: 'root.registration',
      url: '/registration?esiaDataToken&fromMobile',
      component: RegistrationComponent,
      data: {
        iamComponent: true,
        redirectForEsiaFinish: true,
        unavailableForMobile: true,
      },
      onEnter: redirectIfUserLoggedIn
    },
    {
      name: 'root.registrationResult',
      url: '/registration-result?type',
      component: RegistrationResultComponent,
      data: {
        iamComponent: true,
      },
      onEnter: redirectIfUserLoggedIn
    },
    {
      name: 'root.passwordRecovery',
      url: '/password-recovery?fromMobile',
      component: PasswordRecoveryComponent,
      data: {
        iamComponent: true,
        unavailableForMobile: true,
      },
      onEnter: redirectIfUserLoggedIn
    },
    {
      name: 'root.passwordRecoveryResult',
      url: '/password-recovery-result?type',
      component: PasswordRecoveryResultComponent,
      data: {
        iamComponent: true,
      },
      onEnter: redirectIfUserLoggedIn
    },
    {
      name: 'root.recovery',
      url: '/recovery?code&recoveryType',
      component: RecoveryComponent,
      data: {
        iamComponent: true,
      }
    },
    {
      name: 'root.esiaCallBack',
      url: '/esia-callback?token&userExists&error&esiaPersonDataId',
      component: EsiaCallbackComponent,
      data: {
        esiaCallback: true,
        iamComponent: true
      }
    },
    {
      name: 'root.ifmoCallback',
      url: '/ifmo/link-user?state&session_state&code',
      component: IfmoCallbackComponent,
    },
    {
      name: 'root.esiaStart',
      url: '/esia-start',
      component: EsiaStartComponent,
      data: {
        iamComponent: true
      }
    },
    {
      name: 'root.esiaErrors',
      url: '/esia-errors?key',
      component: EsiaErrorComponent,
      data: {
        iamComponent: true
      }
    },
    {
      name: 'root.questionaries',
      url: '/questionaries/{questionaryType}/{questionaryId}',
      component: QuestionaryComponent,
      params: {
        dryRun: true
      }
    },
    {
      name: 'userInit.**',
      url: '/user-init',
      loadChildren: () => import('../user-init/user-init.module').then(m => m.UserInitModule)
    },
    {
      name: 'userAccount.**',
      url: '/user-account',
      loadChildren: () => import('../user-account/user-account.module').then(m => m.UserAccountModule)
    },
    ...devModuleRouting
  ],
  useHash: true,
  otherwise: '/login'
};

@NgModule({
  imports: [
    UIRouterModule.forRoot(rootModule),
  ],
  exports: [UIRouterModule],
  declarations: [],
  providers: [RouteService]
})
export class AppRoutingModule { }

export function redirectIfUserLoggedIn(transition: Transition) {
  if (transition.injector().get(SecurityService).isLoggedIn()) {
    const idpService: IdpService = transition.injector().get(IdpService);
    const observable = idpService.isIdpActive() ? idpService.checkIdpSession() : of(true);
    observable.subscribe(() => {
      transition.injector().get(RedirectService).fromLogin();
    });
  }
}
