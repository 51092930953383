import {Directive, OnDestroy} from '@angular/core';
import { Subject } from 'rxjs';

@Directive()
export class ManagedComponent implements OnDestroy {

  protected readonly destroy$: Subject<void> = new Subject<void>();

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
