import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { snilsInputMask } from '../constants/snils-input-mask';
import { snilsValidation } from '../validators/snils-validator/snils-validator';
import { RecoveryPasswordResponseSchema, RecoveryPasswordSchema } from '@rsmu/portal-api';
import { ApiService } from '../api/api.service';
import { clearNonNumbers } from '../utils/clear-non-numbers';
import { ValidationService } from '../validation/validation.service';
import { CaptchaDirective } from '../captcha/captcha.directive';
import { StateService } from '@uirouter/core';
import { MobileAppService } from '../mobile/mobile-app.service';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ManagedComponent } from '../app-commons/managed-component';
import { hasGlobalError } from '../utils/http-error-utils';
import { captchaFormValidator } from '../captcha/yandex-captcha.directive';

@Component({
  selector: 'app-password-recovery',
  templateUrl: './password-recovery.component.html',
  styleUrls: ['./password-recovery.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PasswordRecoveryComponent extends ManagedComponent implements OnInit, OnDestroy {


  passwordRecoveryForm: UntypedFormGroup;
  isRequestSuccess: boolean | null = null;
  isMobile = false;
  user: RecoveryPasswordResponseSchema;

  private readonly formSubmission$ = new Subject<void>();
  private fromMobile = false;
  private isEmailModeToggle = false;

  constructor(
    private cd: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
    private validationService: ValidationService,
    private apiService: ApiService,
    private stateService: StateService,
    private mobileAppService: MobileAppService
  ) {
    super();
  }

  ngOnInit() {
    this.passwordRecoveryForm = this.formBuilder.group({
      userName: ['', [Validators.required, snilsValidation()]],
      captcha: ['', Validators.required]
    });

    this.isMobile = this.mobileAppService.isMobileApp();

    const { fromMobile } = this.stateService.params;
    this.fromMobile = fromMobile === Boolean(true).toString();

    this.formSubmission$
      .pipe(
        takeUntil(this.destroy$),
        distinctUntilChanged() // (komarov@haulmont.com) to prevent fast submit click
      )
      .subscribe(() => {
        this.doFormSubmit();
      });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.fromMobile) {
      this.mobileAppService.closeCurrentBrowser();
    }
  }

  onFormSubmit(): void {
    this.formSubmission$.next();
  }

  get userName(): AbstractControl {
    return this.passwordRecoveryForm.get('userName');
  }

  // noinspection JSMethodCanBeStatic
  get snilsMask(): Array<string | RegExp> {
    return snilsInputMask;
  }

  get title(): string {
    if (this.isRequestSuccess) {
      return 'passwordRecovery.successTitle';
    }
    if (this.isRequestSuccess === null) {
      return 'passwordRecovery.title';
    }
    if (!this.isRequestSuccess) {
      return 'passwordRecovery.unsuccessTitle';
    }
  }

  captchaHandler(token: string) {
    this.passwordRecoveryForm.patchValue({captcha: token}, {onlySelf: false});
    this.cd.detectChanges();
  }

  toLogin(): void {
    this.stateService.go('root.login');
  }

  maskEmail(email: string): string {
    if (email) {
      const emailString = email.split('@');
      const emailStringLength = emailString[0].length;
      if (emailStringLength > 4) {
        return emailString[0].substring(0, emailStringLength - 4) + '****@' + emailString[1];
      } else {
        return emailString[0].substring(0, 1) + '***@'.substring(4 - emailStringLength, 4) + emailString[1];
      }
    }
  }

  private doFormSubmit(): void {
    const dto: RecoveryPasswordSchema = {
      snils: this.getIsEmailModeToggle ?
        this.passwordRecoveryForm.value.userName.toLowerCase() :
        clearNonNumbers(this.passwordRecoveryForm.value.userName),
        smart_token: this.passwordRecoveryForm.value.captcha
    };

    this.apiService
      .recoverPassword(dto)
      .subscribe(
        (res: RecoveryPasswordResponseSchema) => {
          this.user = {
            email: this.maskEmail(res.email)
          };
          this.isRequestSuccess = true;
        },
        (error) => {
          if (this.passwordRecoveryForm.controls['captcha']) {
            this.passwordRecoveryForm.controls['captcha'].reset();
          }
          this.validationService.setApiErrors(this.passwordRecoveryForm, error.error);
          if (hasGlobalError(error, { status: 422, code: 'notFound' })) {
            this.isRequestSuccess = false;
          }
        }
      );
  }

  get getIsEmailModeToggle() {
    return this.isEmailModeToggle;
  }

  setIsEmailModeToggle(isEmailModeToggle: boolean) {
    this.isEmailModeToggle = isEmailModeToggle;
    const passwordRecoveryUserNameFormControl: AbstractControl = this.passwordRecoveryForm.get('userName');
    passwordRecoveryUserNameFormControl.clearValidators();
    passwordRecoveryUserNameFormControl.reset();
    if (isEmailModeToggle) {
      passwordRecoveryUserNameFormControl.setValidators([Validators.required, Validators.email]);
    } else {
      passwordRecoveryUserNameFormControl.setValidators([Validators.required, snilsValidation()]);
    }
  }

  changeTabStyleOnMouse($event) {
    switch ($event.type) {
      case 'mouseenter': {
        $event.target.className += ' tab-group__tab_mouseover';
        break;
      }
      case 'mouseleave': {
        $event.target.className = $event.target.className.replace(' tab-group__tab_mouseover', '');
        break;
      }
    }
  }
}
