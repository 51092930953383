import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-questionary-progress-bar',
  templateUrl: './questionary-progress-bar.component.html',
  styleUrls: ['./questionary-progress-bar.component.scss']
})
export class QuestionaryProgressBarComponent {

  @Input() public percentageOfCompletion: number;

  constructor() { }

}
