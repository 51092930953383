import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function phoneValidation(isEmployedInRF: boolean): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const phoneRegExp: RegExp = isEmployedInRF ?  /^([+]?[0-9\s-\(\)]{3,25})*$/i : /^[+]\d+$/,
      controlValue: string = control.value || '',
      parsedString: RegExpMatchArray | null = controlValue.match(phoneRegExp);

    return parsedString ? null : { 'phoneNumberInvalid': controlValue };
  };
}
