import { Component } from '@angular/core';
import { EntityIdAndNameSchema, PsychologicalQuestionary, QuestionaryPsychologicalTest, TypeQuestion } from '@rsmu/portal-api';
import { IAutocompleteItem } from '../../../autocomplete/autocomplete.component';
import { Observable } from 'rxjs';
import { PsyQuestionaryService } from '../psy-questionary.service';

@Component({
  selector: 'app-psy-questionary-questions-container',
  templateUrl: './psy-questionary-questions-container.component.html',
  styleUrls: ['./psy-questionary-questions-container.component.scss']
})
export class PsyQuestionaryQuestionsContainerComponent {

  private readonly minItemsCountAutocomplete = 13;

  constructor(
    private questionaryService: PsyQuestionaryService,
  ) {
  }

  get activeQuestionAnswers(): Array<EntityIdAndNameSchema> {
    if (this.questionaryService.activeQuestion) {
      return this.questionaryService.activeQuestion.answers;
    }
  }

  get displayAnswers(): string[] {
    return this.questionaryService.displayAnswers;
  }

  get isShowAutocomplete(): boolean {
    return this.activeQuestionAnswers && this.activeQuestionAnswers.length >= this.minItemsCountAutocomplete;
  }

  get activeQuestionIndex(): number {
    return this.questionaryService.activeQuestionIndex;
  }

  get psyQuestionary(): QuestionaryPsychologicalTest {
    return this.questionaryService.psyQuestionary;
  }

  get percentageOfCompletion(): number {
    return this.questionaryService.getPercentageOfCompletion;
  }

  get activeQuestion(): PsychologicalQuestionary {
    return this.questionaryService.activeQuestion;
  }

  get numberOfQuestions(): number {
    return this.questionaryService.numberOfQuestions;
  }

  get changesAnswers(): Observable<void> {
    return this.questionaryService.changesAnswers;
  }

  get disabledStatus(): boolean {
    return this.questionaryService.isDisabled;
  }

  optionsFilter = (options: IAutocompleteItem[], currentTitle: string, currentValue: string): IAutocompleteItem[] => {
    return options.filter(option => !this.displayAnswers.some(selectedOption => {
      return selectedOption === option.value && selectedOption !== currentValue;
    }));
  }

  isChecked(answer: EntityIdAndNameSchema): boolean {
    return this.questionaryService.isChecked(answer);
  }

  addNewAutocomplete() {
    this.questionaryService.addNewAutocomplete();
  }

  nextQuestion(): void {
    this.questionaryService.nextQuestion();
  }

  previousQuestion(): void {
    this.questionaryService.previousQuestion();
  }

  isMultipleChoice(): boolean {
    return this.questionaryService.activeQuestion.typeQuestion === TypeQuestion.Multiple;
  }

  writeSingleAnswer(currentAnswer: EntityIdAndNameSchema): void {
    this.questionaryService.writeSingleAnswer(currentAnswer);
  }

  writeMultipleAnswer(checked: boolean, currentAnswer: EntityIdAndNameSchema) {
    this.questionaryService.writeMultipleAnswer(checked, currentAnswer);
  }

  changeValueAnswer(value: string, index: number): void {
    this.questionaryService.changeValueAnswer(value, index);
  }

  deleteAutocomplete(index: number): void {
    this.questionaryService.deleteAutocomplete(index);
  }

}
