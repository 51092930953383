import { Injectable, OnDestroy } from '@angular/core';
import { ErrorNotification } from '../error-notification-dto';
import { ReplaySubject, Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ErrorNotificationService implements OnDestroy {

  private currentNotification: ErrorNotification;

  private notificationChange$ = new ReplaySubject<ErrorNotification>(1);

  constructor() { }

  ngOnDestroy(): void {
    this.notificationChange$.complete();
  }

  displayNotification(newNotification: ErrorNotification): void {
    newNotification.priority = newNotification.priority || 0;
    if (!this.currentNotification || this.currentNotification.priority < newNotification.priority) {
      this.changeCurrent(newNotification);
    }
  }

  hideNotification(): void {
    this.changeCurrent(null);
  }

  subscribeToNotifications(): Subject<ErrorNotification> {
    return this.notificationChange$;
  }

  private changeCurrent(newNotification: ErrorNotification) {
    this.currentNotification = newNotification;
    this.notificationChange$.next(newNotification);
  }
}
