import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { Observable, ReplaySubject } from 'rxjs';
import { ApiService } from '../api/api.service';
import { EducationStuffDTO } from '@rsmu/portal-api';

@Injectable({
  providedIn: 'root'
})
export class EducationalLevelListService {

  private levelList$: ReplaySubject<EducationStuffDTO[]>;

  constructor(private apiService: ApiService) {
  }

  getLevelList(): Observable<EducationStuffDTO[]> {
    if (!this.levelList$) {
      this.levelList$ = new ReplaySubject<EducationStuffDTO[]>(1);
      this.loadLevelList().subscribe(
        list => {
          this.levelList$.next(list);
        },
        error => {
          this.levelList$.error(error);
          this.levelList$ = null;
        }
      );
    }

    return this.levelList$.asObservable().pipe(take(1));
  }

  clearCache(): void {
    this.levelList$ = null;
  }

  private loadLevelList(): Observable<EducationStuffDTO[]> {
    return this.apiService.getEducationalLevels();
  }
}
