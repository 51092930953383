import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/api/api.service';
import { Actions } from '@ngrx/effects';

@Injectable()
export class EduPlanEffects {
  constructor(
    private apiService: ApiService,
    private actions$: Actions
  ) {
  }

  // @Effect()
  // loadEduPlanEffect$: Observable<Action> = this.actions$.pipe(
  //   ofType<eduPlanActions.LoadEduPlanRequestAction | eduPlanActions.LoadPlanWithoutCycleRequestAction>(
  //     eduPlanActions.ActionTypes.LOAD_EDU_PLAN_REQUEST,
  //     eduPlanActions.ActionTypes.LOAD_PLAN_WITHOUT_CYCLE_REQUEST
  //   ),
  //   switchMap((action: eduPlanActions.LoadEduPlanRequestAction | eduPlanActions.LoadPlanWithoutCycleRequestAction) => {
  //     const myPlan$: Observable<MyPlan> = action.type === eduPlanActions.ActionTypes.LOAD_EDU_PLAN_REQUEST
  //       ? this.apiService.getMyPlanByCycleId(action.payload.cycleId)
  //       : this.apiService.getMyPlanOutOfCycle();
  //
  //     return myPlan$
  //       .pipe(
  //         map(
  //           (eduPlan: MyPlan) => {
  //             return new eduPlanActions.LoadSuccessAction({
  //               eduPlan
  //             });
  //           }
  //         ),
  //         catchError(error => of(new eduPlanActions.LoadFailureAction({ error })))
  //       );
  //   })
  // );

}
