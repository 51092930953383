<div class="select"
     #overlayOrign
     [class.select_size-sm]="size === 'sm'"
     [class.select_size-md]="size === 'md'"
     [class.select_size-lg]="size === 'lg'">
  <button
    class="select__grid button button_primary button_size-sm button_block button_larger-radius {{ isPreselected ? preselectedMods : '' }}"
    type="button"
    [class.button_stroked]="committed?.length === 0 && !disableSelection && !filterWarning"
    [class.button_warn-shade]="disableSelection || filterWarning"
    [disabled]="disabled"
    (click)="openPopup()">
    <span class="select__grid-item select-label">
      {{ committed?.length === 1 ? committed[0].label : label }}
    </span>
    <span class="select__grid-item select__grid-item_fix select-label-count"
          *ngIf="committed?.length > 1"> - {{committed.length}}</span>
  </button>
  <div *ngIf="filterWarningText" class="error-message error-message_color_alert">
    <div class="select_warn">{{ filterWarningText }}</div>
  </div>
</div>

<app-popup #optionsPopup
           (popupHide)="onOptionsPopupHide($event)"
           [themeList]="popupThemes"
           [showClose]="false">
  <ul class="select-list">
    <li *ngIf="selectAllLabelKey" class="select-list__item" [class.select-list__item_disabled]="disableSelection">
      <app-checkbox [label]="selectAllLabelKey | translate: {filterLength: filterValue ? 2 : 1}"
                    [modifications]="disableSelection ? 'checkbox_theme_disabled-select' : ''"
                    [isBold]="true"
                    [disabled]="disableSelection"
                    [(ngModel)]="showAllChecked"
                    (ngModelChange)="selectAll($event)"></app-checkbox>
    </li>
    <li class="select-list__item" *ngIf="showFilter && !disableSelection">
      <div class="select__filter">
        <input #filterInput
               class="text-field"
               type="text"
               [(ngModel)]="filterValue"
               placeholder="{{'common.search' | translate}}"
               (input)="filter(filterInput.value)"/>
        <button type="button"
                [title]="'tips.clear' | translate"
                class="button select__filter-clear-button"
                (click)="clearFilter()">
          <span class="figure figure_close" role="presentation"></span>
        </button>
      </div>
    </li>

    <li class="select-list__item select-list__selected-items-list" *ngIf="!disableSelection && selected?.length > 0">
      <button *ngFor="let selectedItem of (selected | slice:0:maxDisplaySelectedItemsCount); trackBy: listTrackBy"
              class="select-list__selected-item button button_primary button_stroked"
              (click)="deselectItemClick($event, selectedItem)">
        <span class="select-list__selected-item-value">{{selectedItem.label}}</span>
        <span class="figure figure_close-xsm figure_close-xsm_primary select-list__selected-item-cross"
              role="presentation"></span>
      </button>
      <span *ngIf="selected?.length > maxDisplaySelectedItemsCount">
            {{ 'common.multiselect.additionalSelectedItems'| translate:{count: selected.length - maxDisplaySelectedItemsCount} }}
          </span>
    </li>

    <li class="select-list__item text text_small text_muted-lighter"
        *ngIf="limitItems && !disableSelection">
      {{ limitItemsText | translate: {limitItems: limitItems} | uppercase }}
    </li>

    <li class="select-list__item select-list__item_bordered-bottom"
        [class.select-list__item_disabled]="disableSelection || (maxSelected && !item.selected)"
        *ngFor="let item of displayItems; let i = index; trackBy: listTrackBy">
      <app-checkbox
        [label]="item.label"
        [modifications]="'checkbox_align_top ' + (disableSelection || (maxSelected && !item.selected) ? 'checkbox_theme_disabled-select' : 'checkbox_success')"
        [(ngModel)]="item.selected"
        (ngModelChange)="onItemSelectionChange($event)"
        [smallerCheckbox]="true"
        [disabled]="disableSelection || (maxSelected && !item.selected)"></app-checkbox>
    </li>
  </ul>
  <div class="select-list__popup-actions">
    <button class="button button_primary select-list__popup-action-button"
            (click)="confirmOptions()">
      {{ 'common.multiselect.popup.confirm' | translate }}
    </button>
    <button class="button button_primary select-list__popup-action-button"
            (click)="denyOptions()">
      {{ 'common.multiselect.popup.deny' | translate }}
    </button>
  </div>
</app-popup>
