import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotificationBubbleService } from './notification-bubble.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { ManagedComponent } from '../managed-component';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import _isNil from 'lodash-es/isNil';

@Component({
  selector: 'app-notification-bubble',
  templateUrl: './notification-bubble.component.html',
  styleUrls: [ './notification-bubble.component.scss' ]
})
export class NotificationBubbleComponent extends ManagedComponent implements OnInit {

  @Input() bubbleId: string | null = null;
  @Input() closeButton = true;
  @Input() theme = 'secondary';
  @Input() modificator: string;

  @Output() close = new EventEmitter<void>();

  get isOpen(): boolean {
    return this._isOpen;
  }

  private _isOpen = true;

  constructor(private readonly bubbleService: NotificationBubbleService) {
    super();
  }

  ngOnInit(): void {
    this.bubbleService.registerBubble(this.bubbleId);

    const isOpen$: Observable<boolean> | null = this.bubbleService.isOpen(this.bubbleId);

    if (!_isNil(isOpen$)) {
      isOpen$
        .pipe(
          takeUntil(this.destroy$),
          distinctUntilChanged()
        )
        .subscribe((isOpen: boolean): void => {
          if (isOpen) {
            this.open();
          } else {
            this.hide();
          }
        });
    }
  }

  hideAndPersist() {
    this.hide();
    this.persist();
  }

  openAndPersist() {
    this.open();
    this.persist();
  }

  private persist(): void {
    this.bubbleService.setToggleState(this.bubbleId, this._isOpen);
  }

  private hide(): void {
    this._isOpen = false;
    this.close.emit();
  }

  private open(): void {
    this._isOpen = true;
  }

}
