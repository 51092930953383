import { AfterViewInit, Directive, ElementRef, EventEmitter, Output } from '@angular/core';
import { ElementSize } from './element-size.dto';

@Directive({
  selector: '[appElementSize]'
})
export class ElementSizeDirective implements AfterViewInit {

  @Output('appElementSize') sizeMeasured = new EventEmitter<ElementSize>();

  constructor(private hostElement: ElementRef) { }

  ngAfterViewInit(): void {
    const element = this.hostElement.nativeElement as HTMLElement;
    const size: ElementSize = {
      width: element.clientWidth,
      height: element.clientHeight
    };
    this.sizeMeasured.emit(size);
  }
}
