<div class="multi-zet {{ theme }}">

  <span class="multi-zet__text text text_regular {{ themeText }}" *ngIf="text">
    {{ text }}
  </span>

  <span class="multi-zet__label text text_regular text_tiny" *ngIf="label">
    {{ label }}
  </span>

  <div *ngIf="show">
    <div class="multi-zet__text-multiplier"
         [class.multi-zet__text-multiplier_size-big]="isBigSize"
         [appTooltip]="multiZetHint"
         [trigger]="triggers"
         (click)="$event.stopPropagation();">
      {{ 'multiZet.multiplier' | translate }}{{ multiplier }}
    </div>
  </div>
</div>

<ng-template #multiZetHint>
  <div [ngClass]="themeTooltip">
    <ng-content></ng-content>
    <ul *ngIf="programs"
        class="multi-zet__list">
      <li *ngFor="let item of programs"
          class="multi-zet__item text text_bold">
        {{ item.name }}
      </li>
    </ul>
  </div>
</ng-template>
