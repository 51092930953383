import { Component, ContentChildren, EventEmitter, Input, OnInit, Output, QueryList, ViewEncapsulation, } from '@angular/core';
import { TabComponent } from '../tab/tab.component';

@Component({
  selector: 'app-tab-group',
  templateUrl: './tab-group.component.html',
  styleUrls: ['./tab-group.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TabGroupComponent implements OnInit {

  currentTab = 0;

  @Input() theme: string;

  @Input()
  get startTab() { return this._startTab; }
  set startTab(startTab) {
    this._startTab = startTab - 1;
  }
  private _startTab;

  @Output() change = new EventEmitter<number>();

  @ContentChildren(TabComponent) _tabs: QueryList<TabComponent>;

  ngOnInit() {
    this.currentTab = this.startTab;
  }

  get tabs(): TabComponent[] {
    return this._tabs.toArray();
  }

  selectTab(index: number) {
    this.currentTab = index;
    this.change.emit(this.currentTab);
  }

}
