import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
  HostBinding
} from '@angular/core';

@Component({
  selector: 'app-outside-cycles',
  templateUrl: './outside-cycles.component.html',
  styleUrls: ['./outside-cycles.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OutsideCyclesComponent implements OnInit {

  @HostBinding('class.outside-cycles') readonly baseCss = true;

  @Input() theme = '';

  @Input()
  get active(): boolean {
    return this._active;
  }
  set active(value: boolean) {
    this._active = value;
  }
  protected _active = false;

  @Output() selected = new EventEmitter();
  @Output() activeChange: EventEmitter<boolean> = new EventEmitter();

  constructor() { }

  onClick() {
    this.active = true;
    this.activeChange.emit(this.active);

    this.selected.emit();
  }

  ngOnInit() {
  }

}
