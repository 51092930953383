import { Component, OnInit } from '@angular/core';
import { QuestionaryPhase } from '../../questionary-interface/questionary-phase';
import { ProfQuestionaryService } from '../prof-questionary.service';
import { QuestionaryType } from '../../questionary-interface/questionary-type';
import { UIRouter } from '@uirouter/core';

@Component({
  selector: 'app-prof-questionary-container',
  templateUrl: './prof-questionary-container.component.html',
  styleUrls: ['./prof-questionary-container.component.scss']
})

export class ProfQuestionaryContainerComponent implements OnInit {

  questionaryPhase = QuestionaryPhase;
  firstTime: boolean;

  constructor(
    private route: UIRouter,
    private questionService: ProfQuestionaryService,
  ) {
  }

  ngOnInit() {
    this.firstTime = this.route.stateService.params.firstTime;
  }

  get getPercentageOfCompletion(): number {
    return this.questionService.getPercentageOfCompletion;
  }

  get questionaryType(): QuestionaryType {
    return this.questionService.questionaryType;
  }

  get errorSendCode(): string {
    return `questionary.error.send.${this.questionaryType}`;
  }

  get showTimer(): boolean {
    return this.questionaryType === QuestionaryType.SELF_CONTROL;
  }

  get isShowLabelCyclesFormation(): boolean {
    return !this.isSelfControl2level
      && (this.questionaryType === QuestionaryType.PROFESSIONAL || this.questionaryType === QuestionaryType.SELF_CONTROL);
  }

  get isReadonly(): boolean {
    return !!this.questionService.isReadonly;
  }

  get isSelfControl2level(): boolean {
    return !!this.questionService.topicId;
  }

  get showRewards(): boolean {
    return this.questionService.showRewards;
  }

  get testRewards(): number {
    return this.questionService.testRewards;
  }

  get dryRun(): boolean {
    return this.questionService.dryRun;
  }

  get timeIsOver(): boolean {
    return this.questionService.timeIsOver;
  }

  get activePhase(): QuestionaryPhase {
    return this.questionService.activePhase;
  }

  get initialTime(): number {
    return Math.round(this.questionService.questionary.time / 1000);
  }

  get loading(): boolean {
    return this.questionService.sendProcess;
  }

  get isErrorSend(): boolean {
    return this.questionService.isErrorSend;
  }

  timeIsOverEvent(): void {
    this.questionService.timeIsOverEvent();
  }

  switchInstructionsDisplay() {
    this.questionService.switchInstructionsDisplay();
  }
}
