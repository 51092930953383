import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SignMultiply } from '../../checkbox-group/checkbox-group.component';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true
    }
  ]
})
export class CheckboxComponent implements ControlValueAccessor {

  @Input() disabled: boolean;
  @Input() smallerCheckbox = false;
  @Input() label: string;
  @Input() modifications: string;
  @Input() modificationsWrapper: string;
  @Input() isBold = false;
  @Input() smallText = false;
  @Input() bigText = false;
  @Input() addSign: SignMultiply = {
    sign: '',
    multiply: null
  };
  @Input() itemValue: string;
  @Input() allowHtmlText = false;

  @Input()
  get value(): boolean {
    return this._value;
  }
  set value(newValue: boolean) {
    this.lastEditedValue = newValue;
    this._value = newValue;
  }

  private _value: boolean;
  private lastEditedValue: boolean = null;
  private readonly keyCodeSpace = 'Space';

  @Output() valueChange: EventEmitter<any> = new EventEmitter();

  onChange = (value) => {};
  onTouched = () => {};

  updateValue(newValue: boolean): void {
    if (newValue !== this.lastEditedValue) {
      this.value = newValue;
      this.valueChange.emit(newValue);
      if (this.onChange) {
        this.onChange(newValue);
      }
    }
  }

  onKeyDown(event: KeyboardEvent): void {
    if (event.code === this.keyCodeSpace) {
      event.preventDefault();
      event.stopPropagation();
      this.updateValue(!this.value);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(isChecked: boolean): void {
    this.value = isChecked;
  }
}
