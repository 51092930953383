import { Component, HostBinding, Input } from '@angular/core';
import { AbstractQuestionOption } from '../abstract-question-option';

@Component({
  selector: 'app-free-text-question',
  templateUrl: './free-text-question.component.html',
  styleUrls: ['./free-text-question.component.scss']
})
export class FreeTextQuestionComponent extends AbstractQuestionOption {

  @Input() filterRegExp: RegExp;

  get title(): string {
    return this.answer.enteredText;
  }
  set title(newValue: string) {
    this.answer.enteredText = newValue;
    this.emitChanges();
  }

  @HostBinding('class.free-text-question') isMainCss = true;

  constructor() {
    super();
  }

  filterValue() {
    const match: RegExpMatchArray | null = this.title.match(this.filterRegExp);
    this.title = match ? match.join('') : '';
  }
}
