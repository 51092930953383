import {Injectable} from '@angular/core';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class PaginatorIntl implements MatPaginatorIntl {
  readonly changes: Subject<void> = new Subject<void>();
  firstPageLabel = this.translateService.instant('pagination.buttons.firstPage');
  itemsPerPageLabel = this.translateService.instant('pagination.pageSize.title');
  lastPageLabel = this.translateService.instant('pagination.buttons.lastPage');
  nextPageLabel = this.translateService.instant('pagination.buttons.nextPage');
  previousPageLabel = this.translateService.instant('pagination.buttons.previousPage');

  constructor(private readonly translateService: TranslateService) { }

  getRangeLabel(page: number, pageSize: number, length: number): string {
    return '';
  }
}
