<div class="sort__group" *ngIf="sortingSelections?.length > 0">
  <div class="sort__select">
    <p class="sort__select-label"
       translate="common.sort.label"></p>
    <app-select
      [useGlobalPosition]="isMobile"
      [disabled]="disabled"
      [label]="'common.sort.selectLabel' | translate"
      [showFilter]="false"
      [values]="sortingSelections"
      [preselectedItems]="selectedSort?.sortBy"
      (select)="sortOptionSelected($event)"
      [trackBy]="'value'"></app-select>
  </div>
  <div class="sort__header-buttons">
    <div>
      <div class="sort__legend__helper"
           role="tooltip"
           [appTooltip]="searchResultsLegend"
           (click)="$event.stopPropagation();"
           *ngIf="!isMobile"
      >
        <svg-icon class="icon icon_19 icon_primary"
                  [title]="'tips.question' | translate"
                  src="assets/icons/question.svg"
        >
        </svg-icon>
      </div>
    </div>
    <button class="button button_text sort__legend__settings"
            role="tooltip"
            [appTooltip]="columnSettingsTooltip"
            [trigger]="'click'"
            (click)="$event.stopPropagation()">
      <svg-icon class="icon icon_19 icon_primary"
                [title]="'tips.question' | translate"
                src="assets/icons/settings.svg">
      </svg-icon>
    </button>
  <button
    class="sort__direction"
    [disabled]="!isSortDirectionToggleEnabled"
    [class.sort__direction_disabled]="!isSortDirectionToggleEnabled"
    (click)="toggleSortDirection()">
    <span *ngIf="isAccessible">{{ ('common.sort.direction.' + selectedSort.orderBy) | translate }}</span>
    <svg-icon
      class="icon icon_14"
      role="presentation"
      src="assets/icons/sorting.svg"
      *ngIf="!isAccessible && !!selectedSort.orderBy"
      [ngClass]="'sort__direction-icon_' + selectedSort.orderBy"
      [title]="('common.sort.direction.' + selectedSort.orderBy) | translate"></svg-icon>
  </button>
  </div>
</div>

<ng-template #columnSettingsTooltip>
  <div class="sort__settings-checkbox-container"
       [ngClass]="themeTooltip">
    <app-checkbox-group
      class="sort__checkbox-group"
      itemLabel="labelTranslated"
      itemValue="name"
      [preselected]="preselectedColumnsToShow"
      [searchField]="false"
      [values]="resultsCellsToDisplay"
      (selected)="onCheckboxSelect($event)">
    </app-checkbox-group>
    </div>
</ng-template>

<ng-template #searchResultsLegend>
  <div [ngClass]="themeTooltip">
    <app-help-info
      [data]="legendTooltipData">
    </app-help-info>
  </div>
</ng-template>
