<div>
  <ng-content></ng-content>
</div>
<div>
  <app-validation-error [form]="form"
                        [fieldName]="fieldName"
                        [attemptedToSubmit]="attemptedToSubmit"
                        [translateNamespaceOverride]="translateNamespaceOverride">
  </app-validation-error>
</div>
