import { EventEmitter, Injector, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewContainerRef, Directive } from '@angular/core';
import _get from 'lodash-es/get';
import _isNil from 'lodash-es/isNil';
import _sortBy from 'lodash-es/sortBy';
import { merge, Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import { ManagedOverlayConnected } from '../../dropdown/managed-overlay-connected';
import { LayoutService } from '../../layout/layout.service';
import { Overlay } from '@angular/cdk/overlay';
import { KeyListenerService } from '../../../key-listener/key-listener.service';
import { Placement, POSITION_LIST, POSITION_MAP } from '../../dropdown/placement';
import { deepCopy } from '../../../utils/copy';

export type MultiselectValueType = any;

export interface MultiselectValueInput<T> {
  label: string;
  value: T;
}

export interface IMultiselectDisplayItem extends MultiselectValueInput<any> {
  selected: boolean;
  preselected: boolean;
}

export enum DisplayItemsUpdateSource {
  FILTER,
  HARD_UPDATE
}

@Directive()
export abstract class BaseselectComponent<S, V> extends ManagedOverlayConnected implements OnInit, OnDestroy, OnChanges {

  protected static readonly MAX_DISPLAY = 100;

  @Input() delayedSelect = false;
  @Input() disabled = false;
  @Input() disableSelection = false;
  @Input() itemLabel = 'label';
  @Input() itemValue = 'value';
  @Input() label: string;
  @Input() preselectedItems: V;
  @Input() preselectedMods = '';
  @Input() showFilter = true;
  @Input() size: 'sm' | 'md' | 'lg';
  @Input() sortable = false;
  @Input() sortBy = 'label';
  @Input() trackBy: string | 'label' | 'value' = 'value';
  @Input() values: (MultiselectValueType | IMultiselectDisplayItem)[];
  @Output() select = new EventEmitter<V>();

  displayItems: IMultiselectDisplayItem[] = [];
  isPreselected = false;
  filterValue: string | null = null;

  protected positions = deepCopy([
    POSITION_MAP.get(Placement.BOTTOM),
    ...POSITION_LIST
  ]);

  private readonly filterTerms = new Subject<string | null>();
  private readonly hardUpdateDisplayItems = new Subject<void>();

  get isFilterNotBlank(): boolean {
    return !_isNil(this.filterValue) && this.filterValue.trim() !== '';
  }

  // noinspection JSMethodCanBeStatic
  get maxDisplayItemsCount(): number {
    return BaseselectComponent.MAX_DISPLAY;
  }

  protected abstract get committed(): S;

  protected abstract set committed(committed: S);

  protected abstract get selected(): S;

  protected abstract set selected(selected: S);

  constructor(
    protected readonly injector: Injector,
    protected readonly keyListener: KeyListenerService,
    protected readonly layoutService: LayoutService,
    protected readonly overlay: Overlay,
    protected readonly viewContainer: ViewContainerRef,
  ) {
    super(
      injector,
      keyListener,
      layoutService,
      overlay,
      viewContainer
    );
  }

  ngOnChanges({ values, preselectedItems }: SimpleChanges): void {
    if (values && values.currentValue && !values.firstChange) {
      this.reinitializeValuesWithPreselection(values.currentValue);
    }

    if (preselectedItems && preselectedItems.currentValue && !preselectedItems.firstChange) {
      this.handlePreselected(preselectedItems.currentValue);
    }
  }

  ngOnInit(): void {
    merge(
      this.filterTerms.pipe(
        takeUntil(this.destroy$),
        debounceTime(50),
        distinctUntilChanged(),
        map((filterString: string) => [ filterString, DisplayItemsUpdateSource.FILTER ])
      ),
      this.hardUpdateDisplayItems.pipe(
        takeUntil(this.destroy$),
        withLatestFrom(this.filterTerms),
        map(([ , filterString ]) => [ filterString, DisplayItemsUpdateSource.HARD_UPDATE ])
      )
    )
      .pipe(
        switchMap(
          ([ value, source ]: [ string | null, DisplayItemsUpdateSource ]) =>
            (this.isOpen ? this.getFilteredValues(value) : of([]))
              .pipe(
                map((items: IMultiselectDisplayItem[]) => [ items, source ])
              )
        )
      )
      .subscribe(([ displayItems, source ]: [ IMultiselectDisplayItem[], DisplayItemsUpdateSource ]) => {
        this.displayItems = displayItems;
        this.afterDisplayedItemsUpdate(source);
      });

    this.reinitializeValuesWithPreselection(this.values || []);
  }

  clearFilter(): void {
    this.filter(this.filterValue = null);
  }

  filter(value: string | null): void {
    this.filterTerms.next(value);
  }

  listTrackBy(index: number, item: IMultiselectDisplayItem): any {
    return _get(item, this.trackBy) || index;
  }

  selectItem(preselected?: boolean): void {
    if (this.delayedSelect && !preselected) {
      this.refreshSelectedItems();
      return;
    }

    this.commitSelect(preselected);
    this.isPreselected = this.values.every(
      item => (item.selected && item.preselected) || (!item.selected && !item.preselected)
    );
  }

  protected abstract doCommitSelect(preselected?: boolean): void;

  protected abstract doHandlePreselected(preselectedItems: V): void;

  protected abstract fetchSelected(): S;

  protected afterDisplayedItemsUpdate(source: DisplayItemsUpdateSource): void {
  }

  protected commitSelect(preselected?: boolean): void {
    this.refreshSelectedItems();
    if (preselected || !this.disableSelection) {
      this.refreshCommitedItems();
    }
    this.doCommitSelect(preselected);
  }

  protected handlePreselected(preselectedItems: V): void {
    this.doHandlePreselected(preselectedItems);
    this.selectItem(true);
  }

  protected refreshDisplayedItems() {
    this.hardUpdateDisplayItems.next();
  }

  private checkPreselectItem(value: any): boolean {
    if (_isNil(this.preselectedItems)) {
      return false;
    }
    if (Array.isArray(this.preselectedItems)) {
      return (this.preselectedItems as Array<any>).some(it => it === value);
    }
    return this.preselectedItems === value;
  }

  private getFilteredValues(value: string | null = null): Observable<IMultiselectDisplayItem[]> {
    return of(value)
      .pipe(
        map((filterString: string | null) => {
          const filteredValues = [];

          for (let i = 0; i < this.values.length; i++) {
            const item = this.values[i];

            if (_isNil(filterString) || item.label.toLowerCase().trim().includes(filterString.toLowerCase().trim())) {
              filteredValues.push(item);
            }
            if (filteredValues.length === this.maxDisplayItemsCount) {
              break;
            }
          }

          return filteredValues;
        })
      );
  }

  private refreshCommitedItems() {
    this.committed = this.selected;
  }

  private refreshSelectedItems() {
    this.selected = this.fetchSelected();
  }

  private reinitializeValuesWithPreselection(values: MultiselectValueType[] = []): void {
    this.values = this.transformIntoDisplayItems(values);
    this.handlePreselected(this.preselectedItems);
    this.clearFilter();
    this.refreshDisplayedItems();
  }

  private transformIntoDisplayItems(values: MultiselectValueType[] = []): IMultiselectDisplayItem[] {
    const arrayToMap = this.sortable ? _sortBy(values, item => _get(item, [ this.sortBy ])) : values;

    return arrayToMap.map((item: MultiselectValueType): IMultiselectDisplayItem => {
      const label = this.itemLabel ? _get(item, this.itemLabel) : item;
      const value = this.itemValue ? _get(item, this.itemValue) : item;

      return {
        label,
        value,
        selected: this.checkPreselectItem(value),
        preselected: false
      };
    });
  }

}
