import {AfterViewInit, Directive, ElementRef, forwardRef, Injector, NgZone, OnInit} from '@angular/core';
import {ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR, NgControl, Validators} from '@angular/forms';
import {environment} from '../../environments/environment';

declare const grecaptcha: any;

declare global {
  interface Window {
    grecaptcha: any;
    onCaptchaLoad: () => void;
  }
}

@Directive({
  selector: '[appCaptcha]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => CaptchaDirective)
    }
  ]
})
export class CaptchaDirective implements OnInit, AfterViewInit, ControlValueAccessor {
  private captcha: UntypedFormControl;

  constructor(
    private element: ElementRef, private  ngZone: NgZone, private injector: Injector) { }

  ngOnInit() {
    // TODO: fix captcha for mobile
    this.registerReCaptchaCallback();
    this.loadReCaptchaScript();
  }

  ngAfterViewInit() {
    // TODO deprecated
    // tslint:disable-next-line
    this.captcha = this.injector.get(NgControl).control as UntypedFormControl;
    this.setValidators();
  }

  onSuccess(token: string) {
    this.ngZone.run(() => {
      this.onChange(token);
      this.onTouched(token);
    });
  }

  onExpired() {
    this.ngZone.run(() => {
      this.onChange(null);
      this.onTouched(null);
    });
  }


  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onChange(token: string) {
    this.captcha.setValue(token);
  }

  onTouched(token: string) {
    this.captcha.setValue(token);
  }

  writeValue(obj: any): void {}

  clear() {
    grecaptcha.reset();
  }

  private registerReCaptchaCallback(): void {
    window.onCaptchaLoad = () => {
      const config = {
        theme: 'light',
        type: 'image',
        size: 'normal',
        sitekey: environment.reCaptchaSiteKey,
        callback: this.onSuccess.bind(this),
        'expired-callback': this.onExpired.bind(this)
      };
      grecaptcha.render(this.element.nativeElement, config);
    };
  }

  // noinspection JSMethodCanBeStatic
  private loadReCaptchaScript(): void {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?onload=onCaptchaLoad&render=explicit&hl=ru`;
    script.async = true;
    document.body.appendChild(script);
  }

  private setValidators(): void {
    this.captcha.setValidators(Validators.required);
    setTimeout(() => {
      this.captcha.updateValueAndValidity();
    }, 0);
  }

}
