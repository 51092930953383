<div class="progress-bar">
  <div class="progress-bar__count">
    <span class="text text_small progress-bar__label">{{masteredHours | number:'1.0-3':'ru'}} {{ 'unit.zet' | translate }} {{ 'progressBar.received' | translate}}</span>
    <span class="progress-bar__separator">|</span>
    <span class="text text_small progress-bar__label">{{plannedHours | number:'1.0-3': 'ru'}} {{ 'unit.zet' | translate }} {{ 'progressBar.planned' | translate}}</span>
    <span class="progress-bar__separator">|</span>
    <span class="text text_small progress-bar__label">{{availableHours | number:'1.0-3':'ru'}} {{ 'unit.zet' | translate }} {{ 'progressBar.necessary' | translate}}</span>
  </div>
  <div class="progress-bar__line">
    <div class="progress-bar__planned" [style.width.%]='getPlannedHoursInPercent'></div>
    <div class="progress-bar__completed" [style.width.%]='getMasteredHoursInPercent'></div>
  </div>
</div>
