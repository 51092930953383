<form class="add-doc"
      [formGroup]="addDocForm"
      (ngSubmit)="add()">

  <app-loader class="add-doc__data-loader" *ngIf="!dataLoaded"></app-loader>
  <div *ngIf="dataLoaded" class="add-doc__fields">
    <header>
      <h2 class="heading heading_2 add-doc__heading" translate="addDocument.heading"></h2>
    </header>

    <section class="add-doc__section">
      <div class="add-doc__autocomplete">
        <app-form-field [form]="addDocForm"
                        [attemptedToSubmit]="attemptedToSubmit">
          <app-autocomplete [formControlName]='addDocFormControls.SPECIALITY'
                            [items]='specialityList'
                            [searchDisabled]='false'
                            [isBold]="true"
                            [selectInputTextOnClick]="true"
                            label="{{ 'addDocument.speciality' | translate }}"
                            itemLabel="specialiti.name"
                            itemValue="specialiti.id"
                            placeholder="{{ 'autocomplete.placeholder' | translate }}"
                            theme="autocomplete_theme_modal"
                            (valueChange)="specialityChanged($event)">
          </app-autocomplete>
        </app-form-field>
      </div>
    </section>

    <ng-container *ngIf="addDocForm.get(addDocFormControls.SPECIALITY).value">
      <section class="add-doc__section">
        <div class="add-doc__autocomplete">
          <app-form-field [form]="addDocForm"
                          [attemptedToSubmit]="attemptedToSubmit">
            <app-autocomplete [items]='documentTypes'
                              [(value)]='documentType'
                              (valueChange)="docTypeChanged()"
                              [searchDisabled]='true'
                              [isBold]="true"
                              [formControlName]='addDocFormControls.DOCUMENT_TYPE'
                              label="{{ 'addDocument.type' | translate }}"
                              placeholder="{{ 'autocomplete.placeholder' | translate }}"
                              theme="autocomplete_theme_modal"
                              itemLabel="label"
                              itemValue="value">
            </app-autocomplete>
          </app-form-field>
        </div>
      </section>

      <ng-container *ngIf="addDocForm.get(addDocFormControls.DOCUMENT_TYPE).value">
        <section *ngIf="documentTypeIsLicence" class="add-doc__section">
          <div class="add-doc__autocomplete">
            <app-form-field [form]="addDocForm"
                            [attemptedToSubmit]="attemptedToSubmit">
              <app-autocomplete [items]='procedureList'
                                [searchDisabled]='false'
                                [formControlName]="addDocFormControls.PROCEDURE"
                                (valueChange)="procedureChanged($event)"
                                label="{{ 'addDocument.procedure' | translate }}"
                                placeholder="{{ 'autocomplete.placeholder' | translate }}"
                                theme="autocomplete_theme_modal"
                                isBold="true"
                                itemValue="id"
                                itemLabel="name">
              </app-autocomplete>
            </app-form-field>
          </div>
        </section>

        <section *ngIf="documentTypeIsLicence" class="add-doc__section">
          <div class="add-doc__autocomplete">
            <app-form-field [form]="addDocForm"
                            [attemptedToSubmit]="attemptedToSubmit">
              <app-autocomplete [items]='professionalStandardList'
                                [searchDisabled]='false'
                                [formControlName]="addDocFormControls.PROFESSIONAL_STANDARD"
                                label="{{ 'addDocument.professionalStandard' | translate }}"
                                isBold="true"
                                placeholder="{{ 'autocomplete.placeholder' | translate }}"
                                theme="autocomplete_theme_modal"
                                itemValue="id"
                                itemLabel="name">
              </app-autocomplete>
            </app-form-field>
          </div>
        </section>
        <section class="add-doc__section">
          <div class="add-doc__exam-data-container">
            <div class="add-doc__exam-data add-doc__exam-data_date">
              <app-form-field [form]="addDocForm"
                              [attemptedToSubmit]="attemptedToSubmit"
                              [translateNamespaceOverride]="'datepicker'">
                <app-datepicker [formControlName]="addDocFormControls.DATE_OF_EXAM"
                                [minDate]="minExamDate"
                                [maxDate]="currentDate"
                                (ngModelChange)="onExamDateChange($event)"
                                label="{{ (documentTypeIsLicence ?
                                  'addDocument.dateOfPass' : 'addDocument.dateOfExam') | translate }}"
                                placeholder="{{ 'addDocument.selectDate' | translate }}"
                                isBold="true"
                                placement="top-right">
                </app-datepicker>
              </app-form-field>
            </div>

            <ng-container *ngIf="showSerialNumber">
              <div class="add-doc__exam-data add-doc__exam-data_series">
                <app-form-field [form]="addDocForm"
                                [attemptedToSubmit]="attemptedToSubmit">
                  <label class="form-control">
                    <span class="form-control__label text text_bold" translate="addDocument.series"></span>
                    <input [attr.disabled]="isDisabledSeriesAndNumber ? true : null"
                           [formControlName]="addDocFormControls.SERIES"
                           [textMask]="seriesMask ? { mask: seriesMask } : {}"
                           (input)="serialNumberFocusTabulating($event)"
                           #inputSeriesField
                           type="tel"
                           class="text-field add-doc__exam-input add-doc__exam-input_series"
                           placeholder="{{ 'addDocument.series' | translate }}"/>

                  </label>
                </app-form-field>
              </div>
              <div class="add-doc__exam-data add-doc__exam-data_number">
                <div class="add-doc__info-note-icon__container">
                <span class="add-doc__info-note-icon">
                <span>
                <svg-icon class="icon icon_16 icon_primary"
                          [title]="'common.info' | translate"
                          src="assets/icons/iinfo.svg"></svg-icon>
                </span>
                  <div *ngIf="!!certImg" class="add-doc__tooltip add-doc__tooltip__img__container" [ngStyle]="customCertImageSizing(certImg)">
                    <img src="{{certImg['src']}}" class="add-doc__tooltip__img" (error)="certImgError()">
                  </div>
                  <div *ngIf="!certImg" class="text text_small text_primary add-doc__tooltip add-doc__tooltip__text">
                    <div>
                      <svg-icon class="icon icon_10 icon_primary"
                                [title]="'common.info' | translate"
                                src="assets/icons/iinfo.svg"></svg-icon>
                    </div>
                    <div *ngIf="documentTypeIsCertificate" class="add-doc__note">{{ 'frmrCertConfirm.helpSerialNumberSpecialist' | translate }}</div>
                    <div *ngIf="documentTypeIsLicence" class="add-doc__note">{{ 'frmrCertConfirm.helpSerialNumberLicence' | translate }}</div>
                  </div>
                </span>
                </div>
                <app-form-field [form]="addDocForm"
                                [attemptedToSubmit]="attemptedToSubmit">
                  <label class="form-control">
                    <span class="form-control__label text text_bold" translate="addDocument.number"></span>
                    <input [attr.disabled]="isDisabledSeriesAndNumber ? true : null"
                           [textMask]="numberMask ? { mask: numberMask } : {}"
                           [formControlName]="addDocFormControls.NUMBER"
                           (input)="serialNumberFocusTabulating($event)"
                           #inputNumberField
                           type="tel"
                           class="text-field add-doc__exam-input add-doc__exam-input_number"
                           placeholder="{{ 'addDocument.number' | translate }}"/>
                  </label>
                </app-form-field>
              </div>
              <div class="add-doc__exam-data add-doc__exam-data_date">
                <app-form-field [form]="addDocForm"
                                [attemptedToSubmit]="attemptedToSubmit"
                                [translateNamespaceOverride]="'datepicker'">
                  <app-datepicker [disabled]="getDisableIssue()"
                                  [maxDate]="currentDate"
                                  [minDate]="minDateIssue"
                                  label="{{ 'addDocument.dateOfIssue' | translate }}"
                                  [formControlName]="addDocFormControls.DATE_OF_ISSUE"
                                  placeholder="{{ 'addDocument.selectDate' | translate }}"
                                  isBold="true"
                                  placement="top-right">
                  </app-datepicker>
                </app-form-field>
              </div>
            </ng-container>
          </div>
        </section>

        <section class="add-doc__section" *ngIf="showExamPLace">
          <div class="add-doc__autocomplete" *ngIf="!manuallyAddEduOrganization">
            <app-base-select-organization
              class="add-doc__autocomplete"
              [formCtrl]="addDocForm.get(addDocFormControls.EDU_ORGANIZATION)"
              [label]="'addDocument.eduOrganization' | translate"
              [orgsType]="'all'"
              [required]="true"
            ></app-base-select-organization>
            <mat-error
              *ngIf="addDocForm.get(addDocFormControls.EDU_ORGANIZATION).invalid && addDocForm.get(addDocFormControls.EDU_ORGANIZATION).dirty"
            >
              {{'validation.required' | translate}}
            </mat-error>
          </div>
          <app-form-field *ngIf="manuallyAddEduOrganization"
                          [form]="addDocForm"
                          [attemptedToSubmit]="attemptedToSubmit">
            <label class="form-control">
              <span class="form-control__label text text_bold" translate="addDocument.eduOrganization"></span>
              <input [formControlName]="addDocFormControls.NEW_EDU_ORGANIZATION"
                     type="text"
                     class="text-field"
                     placeholder="{{ 'addDocument.eduOrganization' | translate }}"/>

            </label>
          </app-form-field>
          <div class="add-doc__add-edu-org-cont">
            <span *ngIf="!manuallyAddEduOrganization">{{ 'addDocument.addEduOrganizationLabel' | translate }}</span>
            <button type="button"
                    class="button button_text button_primary"
                    [class.add-doc__add-edu-org-cont-button]="!manuallyAddEduOrganization"
                    (click)="toggleManuallyAddEduOrganization()">
        <span class="text text_bold text_small-sm text_primary add-doc__add-edu-org-tex">
          {{ 'addDocument.' + (manuallyAddEduOrganization ? 'selectEduOrganization' : 'addEduOrganization') | translate}}
        </span>
            </button>
          </div>
        </section>

        <section *ngIf="createCycle" class="add-doc__section">
          <div class="add-doc__autocomplete">
            <app-base-select-organization
              class="add-doc__autocomplete"
              [formCtrl]="addDocForm.get(addDocFormControls.PREFERRED_EDU_ORGANIZATION)"
              [label]="'profile.documents.choosePriorityOrg' | translate"
              [orgsType]="'dpp'"
              [required]="false"
            ></app-base-select-organization>
          </div>
        </section>
      </ng-container>
    </ng-container>

    <section class="add-doc__section">
      <app-form-field [form]="addDocForm" class="add-doc__confirm">
        <app-checkbox [formControlName]="addDocFormControls.CONFIRM_DATA_ACTUAL"
                      [label]="'addDocument.confirm-data-actual' | translate"
                      modifications="checkbox_primary checkbox_align_top">
        </app-checkbox>
      </app-form-field>

      <app-form-field [form]="addDocForm" class="add-doc__confirm">
        <app-checkbox [formControlName]="addDocFormControls.CONFIRM_WARNED"
                      [label]="'addDocument.confirm-warned-change-by-support' | translate:{isMale: isMale}"
                      modifications="checkbox_primary checkbox_align_top">
        </app-checkbox>
      </app-form-field>
    </section>

    <app-validation-error [form]="addDocForm"
                          [formName]="'addDocForm'"
                          [attemptedToSubmit]="attemptedToSubmit"
                          [styleModificators]="'validation-error_at-form-bottom'">
    </app-validation-error>

  </div>

  <div class="add-doc__section add-doc__section_footer">
    <app-loader *ngIf="isLoading"
                class="add-doc__loader"
                [size]="20"
                [showLabelKey]=false
                [strokeWidth]="10"></app-loader>
    <button type="submit"
            *ngIf="!isLoading"
            class="button button_primary button_mobile-wide button_mobile-large add-doc__button">{{ 'addDocument.add' | translate }}
    </button>
  </div>
</form>
