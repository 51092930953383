<aside class="sidebar">
  <div *ngIf="shouldShowTrajectory()" class="sidebar__item">
    <a uiSref="userAccount.cycleSelect.fullTrajectory"
       [uiParams]="{ cycleId: null }"
       tabindex="0"
       class="sidebar__custom-link text text_bold text_small"
       (click)="onClick()"
       uiSrefActive="sidebar__custom-link_active">
      {{ 'sidebar.trajectory' | translate }}
    </a>
  </div>

  <div class="sidebar__item sidebar__item_title-container"
    [class.sidebar__item_separator-top]="!isMyPlanScreen">
    <span class="sidebar__title text text_bold text_small">{{ menuTitle }}</span>
  </div>

  <div class="sidebar__item">
    <ng-content select="app-cycle-management, [app-cycle-management]"></ng-content>
  </div>

  <div class="sidebar__item sidebar__item_separator-top" [attr.tourAnchor]="useForTour ? 'Helper_OutOfTopic' : undefined">
    <ng-content select="app-outside-cycles, [app-outside-cycles]"></ng-content>
  </div>


  <div class="sidebar__item sidebar__item_separator-top"
       *ngIf="sidebarSettings.showAchievements"
       [attr.tourAnchor]="useForTour ? 'Common_Achievements' : undefined">
  </div>

  <div class="sidebar__item sidebar__item_separator-top"
       [attr.tourAnchor]="useForTour ? 'Helper_Psy' : undefined"
       *ngIf="sidebarSettings.showPsyTest">
    <a
      (click)="onClick()"
      [title]="'sidebar.psyTest' | translate"
      uiSref="userAccount.cycleSelect.psyTests"
      [uiParams]="{ cycleId: null }"
      uiSrefActive="sidebar__custom-link_active"
      class="sidebar__custom-link text text_bold text_small">
      {{ 'sidebar.psyTest' | translate }}
    </a>
  </div>
  <div *ngIf="currentCycleInfo$ | async as currentCycleInfo" class="sidebar__item sidebar__item_separator-top"
       [attr.tourAnchor]="useForTour ? 'GoToRp' : undefined"
  >
    <a
      (click)="onClick()"
      [title]="'sidebar.goToSearchRp' | translate"
      uiSref="userAccount.search"
      [uiParams]="{
        eduElementType: eduElementTypes.Rp,
        cycleId: currentCycleInfo?.id,
        preselectedProgram: currentCycleInfo?.programId
      }"
      uiSrefActive="sidebar__custom-link_active"
      class="sidebar__custom-link text text_bold text_small">
      {{ 'sidebar.goToSearchRp' | translate }}
    </a>
  </div>
</aside>

<ng-template #loader>
  <div class="loader">
    <app-loader></app-loader>
  </div>
</ng-template>
