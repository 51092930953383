import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ProfileService } from '../profile/profile.service';
import { UserInfo } from '@rsmu/portal-api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-profile-header',
  templateUrl: './profile-header.component.html',
  styleUrls: ['./profile-header.component.scss']
})
export class ProfileHeaderComponent implements OnInit, OnDestroy {

  @Input() showIcon = true;
  @Input() showName = false;
  @Input() showHint = false;

  currentUser: UserInfo;
  userAvatar: string;
  destroyed$: Subject<void>;

  constructor(
    private profileService: ProfileService,
  ) {
    this.destroyed$ = new Subject();
  }

  ngOnInit() {
    this.profileService.getProfile()
      .subscribe((userInfo: UserInfo) => this.currentUser = userInfo);

    this.profileService
      .getUserAvatar()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((userAvatar: string) => {
        if (userAvatar) {
          this.userAvatar = userAvatar;
        }
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

}
