import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

import _get from 'lodash-es/get';
import _filter from 'lodash-es/filter';
import _map from 'lodash-es/map';
import _cloneDeep from 'lodash-es/cloneDeep';
import _includes from 'lodash-es/includes';

type ISelectListItemValueType = any;

export interface ISelectListItem {
  label: string;
  value: ISelectListItemValueType;
  selected: boolean;
}

export interface SignMultiply {
  sign: string;
  multiply: number;
}

@Component({
  selector: 'app-checkbox-group',
  templateUrl: './checkbox-group.component.html',
  styleUrls: [ './checkbox-group.component.scss' ]
})
export class CheckboxGroupComponent implements OnInit, OnChanges {

  @Input() values: (object | string)[] = [];
  @Input() itemLabel: string;
  @Input() itemValue: string;
  @Input() theme: string;
  @Input() translateLabel = false;
  @Input() preselected: string[];
  @Input() searchField = true;
  @Input() addSign: SignMultiply = {
    sign: '',
    multiply: null
  };

  @Output() selected = new EventEmitter<ISelectListItemValueType[]>();

  fieldModel: string;

  displayItems: ISelectListItem[] = [];
  selectedItems: ISelectListItem[] = [];
  filteredItems: ISelectListItem[] = [];

  ngOnInit(): void {
    if (this.values) {
      this.displayItems = this.transformIntoDisplayItems(this.values);
      this.loadFilteredItems();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { values, searchField, preselected } = changes;

    if (values) {
      const { firstChange, currentValue } = values;

      if (!!values && !firstChange) {
        this.displayItems = !!currentValue ? this.transformIntoDisplayItems(this.values) : [];
        this.loadFilteredItems();
      }
    }

    if (searchField) {
      const { previousValue, currentValue } = searchField;

      if (currentValue !== previousValue) {
        this.fieldModel = '';
        this.fieldModelChanged();
      }
    }

    if (!!preselected && !!this.values) {
      if (preselected.currentValue != null) {
        this.displayItems = this.transformIntoDisplayItems(this.values);
        this.loadFilteredItems();
      }
    }
  }

  fieldModelChanged(): void {
    this.filteredItems = _filter(this.displayItems, item => item.label.toLowerCase().includes(this.fieldModel.toLowerCase()));
  }

  selectItem(): void {
    this.displayItems = _cloneDeep(this.filteredItems);
    this.selectedItems = _filter(this.filteredItems, { selected: true });
    this.selected.emit(_map(this.selectedItems, 'value'));
  }

  private loadFilteredItems(): void {
    this.filteredItems = _cloneDeep(this.displayItems);
  }

  private transformIntoDisplayItems(values: (object | string)[]): ISelectListItem[] {
    return values.map(
      (item: object | string) => {
        const label: string = this.itemLabel ? _get(item, this.itemLabel) : item;
        const value: string = this.itemValue ? _get(item, this.itemValue) : item;
        const result: ISelectListItem = {
          label,
          value,
          selected: this.preselected && _includes(this.preselected, value)
        };

        return result;
      }
    );
  }

}
