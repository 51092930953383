import { Injectable } from '@angular/core';
import { QuestionaryService } from '../questionary.service';
import { concat, forkJoin, Observable, of } from 'rxjs';
import { QuestionaryType } from '../questionary-interface/questionary-type';
import {ProfQuestionaryResultRequestSchema, SimpleResponseDtoAndUrlSchema} from '@rsmu/portal-api';
import {
  CycleStatusInfoResponseSchema,
  ProfessionalQuestionarResult,
  ProfOptionAnswerValueSchema,
  ProfQuestionaryResponseSchema,
  ProfQuestionResponseDTO,
  ProfQuestionTypeEnumSchema,
  ProfSectionResponseDTO,
  ProfTransitionResponseDTO,
  SatisfQuestionaryByEETypeRequestSchema,
  SelfAssessmentUrl
} from '@rsmu/portal-api';
import { finalize, last, map, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { LocalAnswerValue } from '../questionary-interface/local-answer-value';
import { TransitionQuestion } from '../questionary-interface/transaction-question';
import _find from 'lodash-es/find';
import _isEmpty from 'lodash-es/isEmpty';
import _isNil from 'lodash-es/isNil';
import { QuestionaryPhase } from '../questionary-interface/questionary-phase';
import { AnswerForQuestion } from '../question-options/question.dto';
import { ApiService } from '../../../api/api.service';
import { StateService } from '@uirouter/core';
import { QuestionaryRouteParams } from '../questionary-interface/questionary-route-params';
import { HttpClient, HttpResponse } from '@angular/common/http';
import {DialogService} from '../../dialog/dialog.service';
import {RouteService} from '../../../app-routing/route.service';

@Injectable()
export class ProfQuestionaryService extends QuestionaryService<ProfQuestionResponseDTO, AnswerForQuestion, ProfOptionAnswerValueSchema> {

  showRewards: boolean;
  testRewards: number;
  timeIsOver = false;

  private allQuestions: Array<ProfQuestionResponseDTO> = [];
  private eduElementId: string;
  private iterationId: string;
  private helperArrayWithEveryFirstQuestionId: Array<string> = [];
  private helperArrayWithTransitionQuestion: Array<TransitionQuestion> = [];
  private indexStack = [0];
  private lastResult: ProfQuestionaryResultRequestSchema[];
  private sectionStartIndex: Array<number>;

  constructor(
    protected apiService: ApiService,
    private readonly dialogService: DialogService,
    private httpClient: HttpClient,
    private stateService: StateService,
  ) {
    super(apiService);
  }

  init(params: QuestionaryRouteParams): Observable<any> {
    this.eduElementId = this.stateService.params.eduElementId;
    this.iterationId = params.iterationId;
    return super.init(params);
  }

  get numberOfQuestions(): number {
    return this.allQuestions.length;
  }

  get activeQuestion(): ProfQuestionResponseDTO {
    return this.allQuestions[this.activeQuestionIndex];
  }

  get activeQuestionAnswer(): AnswerForQuestion {
    return this.allAnswers[this.activeQuestionIndex];
  }

  set activeQuestionAnswer(newValue: AnswerForQuestion) {
    this.allAnswers[this.activeQuestionIndex] = newValue;
  }

  // @ts-ignore
  get isDisabled(): boolean {
    return this.timeIsOver || (this.activeQuestion.required && !this.isAnsweredActiveQuestion);
  }

  get isReadonly(): boolean {
    return !_isNil(this.iterationId);
  }

  get instruction(): string {
    return this.questionary.instruction;
  }

  nextQuestion(): void {
    this.checkIsClosingFieldAndOpenNextQuestion();
    if (this.activeQuestionIndex === this.numberOfQuestions) {
      this.activeQuestionIndex--;
      this.sendAnswersToApi();
    } else {
      this.activeQuestion$.next(this.activeQuestion);
      this.addIndexToStack();
    }
  }

  previousQuestion(): void {
    if (this.activeQuestionIndex > 0) {
      this.indexStack.pop();
      this.activeQuestionIndex = this.indexStack[this.indexStack.length - 1];
      this.activeQuestion$.next(this.activeQuestion);
    }
  }

  timeIsOverEvent(): void {
    this.timeIsOver = true;
  }

  startTest(): void {
    this.activePhase = QuestionaryPhase.QUESTIONS;
  }

  finishTest(): void {
    this.sendAnswersToApi();
  }

  loadAttachedFile(downloadUrl: string, questionId: string): Observable<File> {
    return this.httpClient.get(downloadUrl, { params: { questionId: questionId }, responseType: 'arraybuffer', observe: 'response' })
      .pipe(map((response: HttpResponse<ArrayBuffer>) => {
        const fileName = response.headers.get('content-disposition').split('"')[1];
        return new File([response.body], decodeURI(fileName));
      }));
  }

  protected getQuestionaryFromApi(): Observable<any> {
    if (this.questionaryType === QuestionaryType.PROFESSIONAL) {
      return this.getProfessionalQuestionaryFromApi();
    }
    if (this.questionaryType === QuestionaryType.SATISFACTION) {
      return this.getSatisfactionQuestionaryFromApi();
    }
    if (this.questionaryType === QuestionaryType.SELF_CONTROL) {
      return this.getSelfAssessmentQuestionaryFromApi();
    }
  }

  protected initListeners(): void {
    this.activeQuestion$.asObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.displayAnswers = this.activeQuestion.optionAnswers.values;
        this.initActiveQuestion();
      });

    this.activeQuestion$.next(this.activeQuestion);
  }

  protected sendAnswersToApi(): void {
    this.sendProcess = true;
    const dto: Array<ProfQuestionaryResultRequestSchema> = this.formQuestionaryAnswer();
    if (this.questionaryType === QuestionaryType.PROFESSIONAL) {
      if (this.dryRun) {
        this.handleResult({showResult: true});
        this.sendProcess = false;
      } else {
        this.sendProfAnswersToApi(dto);
      }
      return;
    }
    if (this.questionaryType === QuestionaryType.SATISFACTION) {
      if (!this.isReadonly) {
        this.sendSatisfactionAnswersToApi(dto);
      } else {
        this.activePhase = QuestionaryPhase.REWARDS;
        this.activeQuestionIndex++;
        this.sendProcess = false;
      }
    }
    if (this.questionaryType === QuestionaryType.SELF_CONTROL) {
      this.sendSelfControlAnswersToApi(dto);
      this.activeQuestionIndex++;
    }
  }

  private get isAnsweredActiveQuestion(): boolean {
    return this.activeQuestionAnswer
      && (this.activeQuestionAnswer.selectedOptions.length !== 0 ||
          !!this.activeQuestionAnswer.enteredText);
  }

  private checkIsClosingFieldAndOpenNextQuestion(): void {
    const answer = this.activeQuestionAnswer;
    this.activeQuestionIndex++;
    const redirectIndex = this.getAnswerRedirectIndex(answer);
    if (redirectIndex) {
      this.activeQuestionIndex = redirectIndex;
    }
  }

  private addIndexToStack(): void {
    this.indexStack.push(this.activeQuestionIndex);
  }

  private initActiveQuestion(): void {
    if (this.activeQuestionAnswer == null) {
      this.activeQuestionAnswer = {enteredText: '', selectedOptions: []};
    }
  }

  private sendSatisfactionAnswersToApi(dto: Array<ProfQuestionaryResultRequestSchema>) {
    this.apiService.sendSatisfactionQuestionaryAnswers(this.questionary.id, this.eduElementId, dto)
      .pipe(
        mergeMap((satisfatcionUrlResponse: SimpleResponseDtoAndUrlSchema) => {
          if (satisfatcionUrlResponse.url) {
            return this.uploadAttachedFiles(satisfatcionUrlResponse.url);
          } else {
            this.dialogService.message(satisfatcionUrlResponse.simpleResponse.descriptionRu ||
              satisfatcionUrlResponse.simpleResponse.descriptionEn);
          }
        }),
        finalize(() => this.sendProcess = false),
      )
      .subscribe(
        () => {
          this.isErrorSend = false;
          this.testRewards = this.questionary.volumeZet;
          this.activePhase = QuestionaryPhase.REWARDS;
          this.activeQuestionIndex++;
        },
        () => {
          this.isErrorSend = true;
        }
      );
  }

  private uploadAttachedFiles(uploadUrl: string): Observable<string> {
    const uploadRequests: Observable<string>[] = this.allAnswers
      .filter((answer: AnswerForQuestion) => !!answer.attachedFile)
      .map((answer: AnswerForQuestion) => {
        const formData = new FormData();
        formData.append('file', answer.attachedFile.userFile, answer.attachedFile.userFile.name);
        return this.httpClient.post(
          uploadUrl,
          formData,
          {
            params: { questionId: answer.attachedFile.questionId, fileName: answer.attachedFile.userFile.name },
            reportProgress: true
          }
        ).pipe(map(() => uploadUrl));
    });
    return uploadRequests.length ? concat(...uploadRequests) : of(null);
  }

  private handleResult(res: ProfessionalQuestionarResult): void {
    this.showRewards = res.showResult;
    this.testRewards = this.questionary.volumeZet;
    this.activePhase = QuestionaryPhase.REWARDS;
    this.activeQuestionIndex++;
  }

  private sendProfAnswersToApi(dto: Array<ProfQuestionaryResultRequestSchema>): void {
    this.apiService.sendProfessionalQuestionaryAnswers(this.questionary.id, this.cycleId, dto)
      .pipe(finalize(() => this.sendProcess = false))
      .subscribe(
        (res) => this.handleResult(res),
        () => {
          this.isErrorSend = true;
        }
      );
  }

  private clearAnswersBySkipSections(answers: AnswerForQuestion[]): AnswerForQuestion[] {
    const skipList: { from: number; to: number }[] = [];

    answers.forEach((answer, index) => {
      const redirectIndex = this.getAnswerRedirectIndex(answer);
      if (redirectIndex && redirectIndex !== index + 1) {
        skipList.push({from: index + 1, to: redirectIndex});
      }
    });

    skipList.forEach(skip => {
      for (let index = skip.from; index < skip.to; index++) {
        answers[index] = null;
      }
    });

    return answers;
  }

  private getAnswerRedirectIndex(answer: AnswerForQuestion): number {
    let redirectIndex = null;
    if (answer.selectedOptions) {
      answer.selectedOptions.forEach((option: LocalAnswerValue) => {
        if (option.redirectToSectionIndex) {
          if (!this.sectionStartIndex[option.redirectToSectionIndex]) {
            redirectIndex = this.allQuestions.length;
            return;
          }
          redirectIndex = this.sectionStartIndex[option.redirectToSectionIndex];
          return;
        }
      });
      return redirectIndex;
    }
  }

  private formQuestionaryAnswer(): Array<ProfQuestionaryResultRequestSchema> {
    const formattedAnswer: Array<ProfQuestionaryResultRequestSchema> = [];

    const answers = this.clearAnswersBySkipSections(this.allAnswers);

    answers.forEach((userAnswer: AnswerForQuestion, index: number) => {
      if (userAnswer) {
        const question = this.allQuestions[index];
        const answerValues = this.formAnswer(userAnswer, question);
        formattedAnswer.push({
          id: question.id,
          title: this.clearTextFromHtml(question.title),
          questionType: question.questionType,
          optionAnswers: {
            entityType: question.optionAnswers.entityType,
            values: answerValues,
          }
        });
      }
    });
    return formattedAnswer;
  }

  private formAnswer(userAnswer: AnswerForQuestion, question: ProfQuestionResponseDTO): Array<ProfOptionAnswerValueSchema> {
    const finalAnswer: Array<ProfOptionAnswerValueSchema> = [];
    userAnswer.selectedOptions.forEach((answer: LocalAnswerValue) => {
      if (answer.id) {
        finalAnswer.push({
          id: answer.id,
          title: question.questionType === ProfQuestionTypeEnumSchema.Boolean
            ? this.convertStringToBoolean(answer.title) + ''
            : this.clearTextFromHtml(answer.title)
        });
      }
    });
    const enteredText = userAnswer.enteredText;
    if (enteredText) {
      finalAnswer.push({id: null, title: enteredText});
    }
    return finalAnswer;
  }

  private clearTextFromHtml(html: string): string {
    return html.replace(/(<([^>]+)>)/ig, '').replace(/↵/g, '').trim();
  }

  private initAnswersFromLastResult(result: ProfQuestionaryResultRequestSchema[]): void {
    if (result) {
      result.forEach(resultSchema => {
        const indexQuestion = this.allQuestions.findIndex(item => item.id === resultSchema.id);
        if (indexQuestion !== -1) {
          this.allAnswers[indexQuestion] = this.getAnswersForQuestion(resultSchema, this.allQuestions[indexQuestion]);
        }
      });
    }
  }

  private getAnswersForQuestion(
    resultSchema: ProfQuestionaryResultRequestSchema,
    question: ProfQuestionResponseDTO
  ): AnswerForQuestion {
    let answer: AnswerForQuestion;
    switch (question.questionType) {
      case ProfQuestionTypeEnumSchema.Date:
      case ProfQuestionTypeEnumSchema.Decimal:
      case ProfQuestionTypeEnumSchema.Integer:
      case ProfQuestionTypeEnumSchema.Text:
        answer = {
          enteredText: resultSchema.optionAnswers.values[0].title,
          selectedOptions: []
        };
        break;

      case ProfQuestionTypeEnumSchema.Boolean:
        answer = {
          enteredText: null,
          selectedOptions: this.filteredAnswerValues(
            question.optionAnswers.values,
            [ this.convertBooleanToString(resultSchema.optionAnswers.values[0]) ]
          )
        };
        break;

      default:
        answer = {
          enteredText: null,
          selectedOptions: this.filteredAnswerValues(
            question.optionAnswers.values,
            resultSchema.optionAnswers.values.map(it => it.title)
          )
        };
    }

    if (
      answer &&
      resultSchema &&
      resultSchema.optionAnswers &&
      resultSchema.optionAnswers.fileName
    ) {
      answer.fileDownloadData = {
        downloadUrl: resultSchema.optionAnswers.downloadUrl,
        fileName: resultSchema.optionAnswers.fileName
      };
    }
    return answer;
  }

  private sendSelfControlAnswersToApi(dto: Array<ProfQuestionaryResultRequestSchema>): void {
    this.apiService.sendSelfAssessmentAnswers(this.questionary.id, dto)
      .pipe(finalize(() => this.sendProcess = false))
      .subscribe(
        () => {
          this.stateService.go('userAccount.cycleSelect.eduTrajectoryHelper.selfAssessmentResults',
            {
              cycleId: this.cycleId,
              topicId: this.topicId
            });
        },
        () => {
          this.isErrorSend = true;
        }
      );
  }

  private convertStringToBoolean(isTrue: string): boolean {
    return isTrue === 'Да';
  }

  private convertBooleanToString(answer: ProfOptionAnswerValueSchema): string {
    return answer.title === 'true' ? 'Да' : 'Нет';
  }

  private filteredAnswerValues(options: ProfOptionAnswerValueSchema[], values: string[]): ProfOptionAnswerValueSchema[] {
    // TODO mapping by id RSMUP-3158
    return options.filter(value => !!values.find(item => item === value.title));
  }

  private getProfessionalQuestionaryFromApi(): Observable<[ProfQuestionaryResponseSchema, ProfQuestionaryResultRequestSchema[]]> {
    return forkJoin(
      this.loadProfessionalQuestionary(this.questionaryId, this.cycleId),
      this.loadLastResultProfQuestionary(this.questionaryId, this.cycleId)
    ).pipe(
      tap(data => {
        [this.questionary, this.lastResult] = data;
        this.processQuestionary();
        this.checkInstructions();
        if (this.lastResult && !_isEmpty(this.lastResult)) {
          this.initAnswersFromLastResult(this.lastResult);
        }
      })
    );
  }

  private getSatisfactionQuestionaryFromApi(): Observable<[ProfQuestionaryResponseSchema, ProfQuestionaryResultRequestSchema[] | null]> {
    const dto: SatisfQuestionaryByEETypeRequestSchema = {
      elementType: this.eduElementType,
      educationalElementId: this.eduElementId
    };
    if (_isNil(this.iterationId)) {
      return this.apiService
        .getSatisfactionQuestionaryByEEType(dto)
        .pipe(
          tap((questionary: ProfQuestionaryResponseSchema) => {
            if (questionary?.simpleResponse?.responseType === 'ERROR') {
              this.stateService.go('userAccount.portfolio');
              this.dialogService.message(questionary.simpleResponse.descriptionRu);
            }
          }),
          map((questionary: ProfQuestionaryResponseSchema): [ProfQuestionaryResponseSchema, null] => {
            this.questionary = questionary;
            this.processQuestionary();
            this.checkInstructions();

            return [this.questionary, null];
          })
        );
    }

    return forkJoin(
      this.apiService.getSatisfactionQuestionaryByEEType(dto),
      this.apiService.retrieveProfessionalSurveyAnswers(this.iterationId)
    ).pipe(
      tap((data: [ProfQuestionaryResponseSchema, ProfQuestionaryResultRequestSchema[]]) => {
        [this.questionary, this.lastResult] = data;
        this.processQuestionary();
        this.checkInstructions();
        if (this.lastResult && !_isEmpty(this.lastResult)) {
          this.initAnswersFromLastResult(this.lastResult);
        }
      })
    );
  }

  private getSelfAssessmentQuestionaryFromApi(): Observable<ProfQuestionaryResponseSchema> {
    const dto: SelfAssessmentUrl = {
      cycleId: this.cycleId,
      topicId: this.topicId,
      redirectUrl: this.selfAssessmentUrl
    };
    return this.apiService
      .getSelfAssessment(dto)
      .pipe(
        tap((questionary: ProfQuestionaryResponseSchema) => {
          this.questionary = questionary;
          this.processQuestionary();
          this.checkInstructions();
        })
      );
  }

  private processQuestionary(): void {
    if (this.questionary.transitions) {
      this.createArrayWithTransitionId();
    }
    this.questionary.sections.forEach((section: ProfSectionResponseDTO, sectionIndex) => {
      this.concatAllQuestions(section);
      if (section.questions && sectionIndex !== 0) {
        this.createHelperArrayWithId(section);
      }
      section.questions.forEach((question: ProfQuestionResponseDTO) => {
        this.createRedirectToSectionIndexFieldByTransitionId(question, sectionIndex);
        if (question.optionAnswers && question.optionAnswers.values) {
          question.optionAnswers.values.forEach((value: LocalAnswerValue) => {
            if (value.isClosing) {
              this.createRedirectToSectionIndexFieldByIsClosing(value, sectionIndex);
            }
          });
        }
      });
    });
    this.createSectionStartIndex();
  }

  private loadProfessionalQuestionary(questionaryId: string, cycleId: string): Observable<ProfQuestionaryResponseSchema> {
    return this.dryRun
      ? this.apiService.getProfessionalQuestionaryDryRun(questionaryId)
      : this.apiService.getProfessionalQuestionary(questionaryId, cycleId);
  }

  private loadLastResultProfQuestionary(questionaryId: string, cycleId: string): Observable<ProfQuestionaryResultRequestSchema[]> {
    return cycleId
      ? this.apiService.getCycleStatusInfo(cycleId).pipe(
          mergeMap((statusInfo: CycleStatusInfoResponseSchema): Observable<ProfQuestionaryResultRequestSchema[]> => {
            return statusInfo.professionalAssessmentCompleted === true
              ? this.apiService.retrieveLastResultProfessionalSurvey(questionaryId) as Observable<ProfQuestionaryResultRequestSchema[]>
              : of(null);
          })
        )
      : of(null);
  }

  private createArrayWithTransitionId(): void {
    this.questionary.transitions.forEach((transition: ProfTransitionResponseDTO) => {
      this.helperArrayWithTransitionQuestion.push({
        questionId: transition.questionId,
        answerId: transition.answer.id,
        transitionSectionId: transition.transitionSectionId,
        transitionType: transition.transitionType
      });
    });
  }

  private createRedirectToSectionIndexFieldByIsClosing(value: LocalAnswerValue, sectionIndex: number): void {
    value.redirectToSectionIndex = sectionIndex + 1;
  }

  private createRedirectToSectionIndexFieldByTransitionId(question: ProfQuestionResponseDTO, sectionIndex: number): void {
    if (this.checkMatchTransitionId(question)) {
      if (question.optionAnswers && question.optionAnswers.values) {
        question.optionAnswers.values.forEach((value: LocalAnswerValue) => {
          this.checkMatchValue(value, sectionIndex);
        });
      }
    }
  }

  private checkMatchTransitionId(question: ProfQuestionResponseDTO) {
    return _find(this.helperArrayWithTransitionQuestion, (transitionQuestion: TransitionQuestion) => {
      return transitionQuestion.questionId === question.id;
    });
  }

  private createHelperArrayWithId(section: ProfSectionResponseDTO): void {
    if (section.questions.length !== 0) {
      this.helperArrayWithEveryFirstQuestionId.push(section.questions[0].id);
    }
  }

  private concatAllQuestions(section: ProfSectionResponseDTO): void {
    this.allQuestions = [...this.allQuestions, ...section.questions];
  }

  private createSectionStartIndex(): void {
    this.sectionStartIndex = [0];
    this.allQuestions.forEach((question: ProfQuestionResponseDTO, index) => {
      this.helperArrayWithEveryFirstQuestionId.forEach((questionId: string) => {
        if (question.id === questionId) {
          this.sectionStartIndex.push(index);
        }
      });
    });
  }

  private checkMatchValue(value: LocalAnswerValue, sectionIndex: number): void {
    this.helperArrayWithTransitionQuestion.forEach((transitionQuestion: TransitionQuestion) => {
      if (transitionQuestion.answerId === value.id) {
        if (transitionQuestion.transitionSectionId) {
          value.redirectToSectionIndex = this.findSectionIndex(transitionQuestion.transitionSectionId);
        } else if (transitionQuestion.transitionType === 'closingSurvey') {
          value.redirectToSectionIndex = this.questionary.sections.length;
        } else {
          value.redirectToSectionIndex = sectionIndex + 1;
        }
      }
    });
  }

  private findSectionIndex(transitionSectionId: string): number {
    let redirectToSectionIndex: number;
    this.questionary.sections.forEach((section, sectionIndex) => {
      if (section.id === transitionSectionId) {
        redirectToSectionIndex = sectionIndex;
      }
    });
    return redirectToSectionIndex;
  }

  private checkInstructions(): void {
    if (this.questionary.instruction && !this.lastResult) {
      this.activePhase = QuestionaryPhase.INSTRUCTIONS;
      return;
    }
    this.activePhase = QuestionaryPhase.QUESTIONS;
  }
}
