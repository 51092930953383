import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {StoreModule} from "@ngrx/store";
import {toursReducer} from "./reducer";

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('tours', toursReducer),
  ],
  declarations: []
})
export class ToursStoreModule {}
