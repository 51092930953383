import { Component, OnInit } from '@angular/core';
import { ERROR_NOTIFICATION_ANIMATIONS } from './error-notification-animation';
import { Subject } from 'rxjs';
import { ErrorNotificationService } from '../service/error-notification.service';
import { ErrorNotification } from '../error-notification-dto';

@Component({
  selector: 'app-error-notification',
  templateUrl: './error-notification.component.html',
  styleUrls: ['./error-notification.component.scss'],
  animations: ERROR_NOTIFICATION_ANIMATIONS
})
export class ErrorNotificationComponent implements OnInit {

  hidden = true;
  messageKey = '';
  showCloseButton = false;

  private closed$: Subject<void>;

  constructor(private errorNotificationService: ErrorNotificationService) { }

  ngOnInit() {
    this.errorNotificationService.subscribeToNotifications().subscribe((notification: ErrorNotification) => {
      this.hidden = !notification;
      if (!this.hidden) {
        this.messageKey = notification.messageKey;
        this.showCloseButton = notification.showCloseButton === true;
        this.closed$ = notification.closed$;
      }
    });
  }

  closeNotification() {
    this.errorNotificationService.hideNotification();
    if (this.closed$) {
      this.closed$.next();
    }
  }
}
