<div class="accessible">
  <button class="button button_stroked button_primary accessible__big-button"
          tabindex="2"
          (click)="turnOff()">
    {{ 'accessible.off' | translate }}
  </button>

  <button class="button button_text button_primary accessible__small-button"
          tabindex="2"
          (click)="turnOff()"
          [title]="'accessible.off' | translate">
    <svg-icon src="assets/icons/eye.svg" class="icon icon_32_w"></svg-icon>
  </button>

  <app-switch-set class="accessible__switch"
                  (activeCaseChange)="onChangeMode($event)"
                  [activeCase]="accessibleCase"
                  [cases]="cases"></app-switch-set>
</div>
