import { Directive, ViewContainerRef, Component, TemplateRef, HostBinding } from '@angular/core';

@Directive({
  selector: '[appHeaderRowOutlet]'
})
export class HeaderRowOutletDirective {
  constructor(public viewContainer: ViewContainerRef) {}
}
@Directive({
  selector: '[appRowOutlet]'
})
export class DataRowOutletDirective {
  constructor(public viewContainer: ViewContainerRef) {}
}

@Directive({
  selector: '[appRowHeaderDef]'
})
export class RowHeaderDefDirective {
  constructor(public template: TemplateRef<any>) {}
}

@Directive({
  selector: '[appRowDef]'
})
export class RowDefDirective {
  constructor(public template: TemplateRef<any>) {}
}

@Directive({
  selector: '[appCellOutlet]'
})
export class CellOutletDirective {
  static cellOutlet: CellOutletDirective | null = null;
  constructor(public viewContainer: ViewContainerRef) {
    CellOutletDirective.cellOutlet = this;
  }
}

@Component({
  selector: 'app-row-header, tr[app-row-header]',
  template: `<ng-container appCellOutlet></ng-container>`
})
export class RowHeaderComponent {
  // Add default style for row header
  @HostBinding('class.grid__header-row') headerRowCss = true;

  constructor() { }
}

@Component({
  selector: 'app-row, tr[app-row]',
  template: `<ng-container appCellOutlet></ng-container>`
})
export class RowComponent {
  // Add default style for row
  @HostBinding('class.grid__row') rowCss = true;

  constructor() { }
}
