import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, distinctUntilChanged, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, ReplaySubject, throwError } from 'rxjs';
import { LocalStorage, LocalStorageService } from 'ngx-store';

import TokenData from './token-data';
import { IdpLogoutRequest, IdpLogoutResponse } from '../idp/idp.service';
import { objectToFormData } from '../utils/http-utils';
import { GlobalLoadingService } from '../global-loading/global-loading.service';
import { environment } from '../../environments/environment';
import { ApiService } from '../api/api.service';
import _get from 'lodash-es/get';
import { Logger } from '../app-commons/logger/logger.service';
import {RouteService} from '../app-routing/route.service';
import {MobileAppService} from '../mobile/mobile-app.service';
import {StateService} from '@uirouter/angular';
import {readWorkspace} from '@angular-devkit/core/src/workspace';

@Injectable({
  providedIn: 'root'
})
export class SecurityService {

  private readonly logger = new Logger('SecurityService');
  private readonly userLoginSubject = new ReplaySubject<TokenData>(1);
  private readonly logoutDataSubject = new ReplaySubject<IdpLogoutResponse>(1);
  private readonly isLoggedIn$: BehaviorSubject<boolean>;

  @LocalStorage({ key: environment.idpSessionIdDataKey }) idpSessionId: string | null = null;
  @LocalStorage({ key: environment.tokenDataKey }) tokenData: TokenData | null = null;

  constructor(
    private readonly http: HttpClient,
    private readonly storageService: LocalStorageService,
    private readonly globalLoadingService: GlobalLoadingService,
    private readonly apiService: ApiService,
    private readonly routeService: RouteService,
    private readonly mobileAppService: MobileAppService,
    private readonly stateService: StateService
  ) {
    this.saveToken(this.tokenData);
    this.isLoggedIn$ = new BehaviorSubject<boolean>(this.isLoggedIn());

    storageService.observe(environment.tokenDataKey).subscribe(event => {
      if (!event.isInternal) {
        this.saveToken(event.newValue);
      }
    });

    this.saveIdpSessionId(this.idpSessionId);
    storageService.observe(environment.idpSessionIdDataKey).subscribe(event => {
      if (!event.isInternal) {
        this.saveIdpSessionId(event.newValue);
      }
    });
  }

  static getRequestOptions(): { headers: HttpHeaders } {
    const clientId = environment.cubaClientId;
    const secret = environment.cubaSecret;
    const encodedAuthData = btoa(`${ clientId }:${ secret }`);

    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': `Basic ${ encodedAuthData }`
      })
    };
  }

  static isWrongCredentialsError(error: HttpErrorResponse): boolean {
    return error.status === 400 && error.error && error.error.error_description.indexOf('Bad credentials') !== -1;
  }

  getUserChangedEvent(): Observable<TokenData> {
    return this.userLoginSubject;
  }

  getLogoutDataEvent(): Observable<IdpLogoutResponse> {
    return this.logoutDataSubject;
  }

  isUserLoggedIn(): Observable<boolean> {
    return this
      .isLoggedIn$
      .asObservable()
      .pipe(
        distinctUntilChanged()
      );
  }

  logout() {
    if (this.isLoggedIn()) {
      localStorage.removeItem('rsmu_selectedActivities');
      localStorage.removeItem('rsmu_preselectedColumns');
      const url = environment.baseAuthUrl + '/oauth/revoke';
      const body: IdpLogoutRequest = { token: this.tokenData.access_token };
      const bodyString: string = objectToFormData(body);
      const requestOptions = SecurityService.getRequestOptions();
      this.globalLoadingService.startLoading();
      this.http.post<any>(url, bodyString, requestOptions)
        .subscribe(
          (response: IdpLogoutResponse) => {
            this.useNewToken(null, response);
          },
          () => {
            this.useNewToken(null, { location: null });
          },
          () => this.globalLoadingService.stopLoading()
        );
    }
  }

  isLoggedIn(): boolean {
    return this.tokenData != null && this.tokenData.access_token != null;
  }

  isIdpActive(): boolean {
    return !this.mobileAppService.isMobileApp() &&
      !environment.disableSso &&
      !(this.stateService.params.fromMobile === Boolean(true).toString());
  }

  refreshToken(isMobile?: boolean): Observable<TokenData> {
    if (this.tokenData == null || this.tokenData.refresh_token == null) {
      return throwError('No refresh token');
    }

    return this.http.post<TokenData>(
      `${ environment.baseAuthUrl }/oauth/token?grant_type=refresh_token&refresh_token=${ this.tokenData.refresh_token }`,
      {},
      SecurityService.getRequestOptions()
    ).pipe(
      catchError(this.handleRefreshError(isMobile)),
      tap((result: TokenData) => {
        this.saveTokenWithIdpSessionId(result);
      })
    );
  }

  getAuthHeader(): string {
    return this.tokenData ? `Bearer ${ this.tokenData.access_token }` : '';
  }

  useNewToken(securityToken: TokenData, logoutData?: IdpLogoutResponse): void {
    this.saveTokenWithIdpSessionId(securityToken);
    if (this.isIdpActive()) {
      if (logoutData) {
        this.idpLogout(logoutData);
      } else {
        this.emitUserChanged();
      }
    } else {
      if (this.mobileAppService.isMobileApp()) {
        this.clearTwinCabinetToken();
      }
      this.emitUserChanged();
    }
  }

  idpLogout(logoutData: IdpLogoutResponse) {
    if (logoutData.location) {
      window.location.href = logoutData.location;
    } else {
      window.location.href = `${environment.baseAuthUrl.replace(/\/$/, '')}/idp/login`;
    }
  }


  getToken(): TokenData {
    return this.tokenData;
  }

  saveTokenWithIdpSessionId(securityToken: TokenData): void {
    this.saveToken(securityToken);
    this.saveIdpSessionId(_get(securityToken, 'idp_session_id', null));
  }

  clearTwinCabinetToken(): void {
    this.storageService.remove(environment.cabinetInstanceType === 'HigherEducation' ? 'tokenDataSpo' : 'tokenData');
  }

  private emitUserChanged(): void {
    this.isLoggedIn$.next(true);
    this.userLoginSubject.next(this.tokenData);
  }

  private handleRefreshError(isMobile?: boolean) {
    return (err: HttpErrorResponse): Observable<TokenData> => {
      if (!isMobile) {
        this.logout();
      }
      return throwError(err);
    };
  }

  private saveIdpSessionId(idpSessionId: string | null) {
    this.idpSessionId = idpSessionId;
    this.logger.info(`Saved IDP session id = ${ this.idpSessionId }`);
  }

  private saveToken(tokenData: TokenData) {
    this.tokenData = tokenData;
    this.logger.info(`Saved tokenData = ${ this.tokenData }`);
  }
}
