import { Component, HostBinding, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractQuestionOption } from '../abstract-question-option';
import { ProfOptionAnswerValueSchema } from '@rsmu/portal-api';
import { UIRouter } from '@uirouter/core';
import { QuestionaryType } from '../../questionary-interface/questionary-type';

@Component({
  selector: 'app-limited-select-question',
  templateUrl: './limited-select-question.component.html',
  styleUrls: ['./limited-select-question.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LimitedSelectQuestionComponent extends AbstractQuestionOption implements OnInit {
  questionaryType: QuestionaryType;

  @HostBinding('class.limited-select-question') isHostClass = true;

  constructor(private route: UIRouter) {
    super();
  }

  ngOnInit() {
    this.questionaryType = this.route.stateService.params.questionaryType;
  }

  setSingleAnswer(answer: ProfOptionAnswerValueSchema) {
    const newAnswer = [];
    newAnswer.push(answer);
    this.answer.selectedOptions = newAnswer;
    this.emitChanges();
  }

  setAnswer(selected: boolean, answer: ProfOptionAnswerValueSchema) {
    if (selected && !this.answer.selectedOptions.includes(answer)) {
      this.answer.selectedOptions.push(answer);
    }

    if (!selected) {
      this.answer.selectedOptions = this.answer.selectedOptions.filter(value => value !== answer);
    }
    this.emitChanges();
  }

  optionSelected(option: ProfOptionAnswerValueSchema): boolean {
    return this.answer.selectedOptions.some(answer => answer.id === option.id);
  }

  isSelfControl(): boolean {
    return this.questionaryType === QuestionaryType.SELF_CONTROL;
  }
}
