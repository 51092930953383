import { Directive, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[appDropdownContext]'
})
export class DropdownContextDirective<T> {
  constructor(
    private template: TemplateRef<{$implicit: T}>,
    private viewContainer: ViewContainerRef,
  ) {
  }

  setContext(context: T): void {
    this.clearContext();
    this.viewContainer.createEmbeddedView(this.template, {
      $implicit: context
    });
  }

  clearContext(): void {
    this.viewContainer.clear();
  }
}
