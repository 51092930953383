import { Component, Injector, Input, ViewContainerRef } from '@angular/core';
import { BaseselectComponent, IMultiselectDisplayItem } from '../baseselect/baseselect.component';
import _find from 'lodash-es/find';
import _forEach from 'lodash-es/forEach';
import _isNil from 'lodash-es/isNil';
import { KeyListenerService } from '../../../key-listener/key-listener.service';
import { LayoutService } from '../../layout/layout.service';
import { Overlay } from '@angular/cdk/overlay';


@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: [
    '../baseselect/baseselect.component.scss',
    './select.component.scss'
  ],
})
export class SelectComponent extends BaseselectComponent<IMultiselectDisplayItem | null, any> {

  @Input() useGlobalPosition: boolean = null;

  get committed(): IMultiselectDisplayItem | null {
    return this._commitedItem;
  }
  set committed(value: IMultiselectDisplayItem | null) {
    this._commitedItem = value;
  }
  private _commitedItem: IMultiselectDisplayItem | null = null;

  get selected(): IMultiselectDisplayItem | null {
    return this._selected;
  }
  set selected(selected: IMultiselectDisplayItem | null) {
    this._selected = selected;
  }
  private _selected: IMultiselectDisplayItem | null = null;

  constructor(
    protected readonly injector: Injector,
    protected readonly keyListener: KeyListenerService,
    protected readonly layoutService: LayoutService,
    protected readonly overlay: Overlay,
    protected readonly viewContainer: ViewContainerRef,
  ) {
    super(
      injector,
      keyListener,
      layoutService,
      overlay,
      viewContainer
    );
  }

  close(): void {
    super.close();
    if (this.delayedSelect) {
      this.commitSelect();
    }
    this.refreshDisplayedItems();
  }

  open(): void {
    super.open();
    this.refreshDisplayedItems();
  }

  onSelect(item: IMultiselectDisplayItem): void {
    _forEach(this.values, i => {
      i.selected = (i === item) && !i.selected;
    });
    this.selectItem();
    if (!this.delayedSelect) {
      this.close();
    }
  }

  protected isUseGlobalPosition(): boolean {
    return this.useGlobalPosition !== null ? this.useGlobalPosition : super.isUseGlobalPosition();
  }

  protected doCommitSelect(preselected?: boolean): void {
    if (!preselected && !this.disableSelection) {
      this.select.emit(this.committed ? this.committed.value : null);
    }
  }

  protected doHandlePreselected(preselectedItem: any): void {
    if (!_isNil(preselectedItem)) {
      _forEach(this.values, displayItem => {
        if (displayItem.value === preselectedItem) {
          displayItem.selected = true;
          displayItem.preselected = true;
        }
      });
    }
  }

  protected fetchSelected(): IMultiselectDisplayItem | null {
    const foundSelectedItem: IMultiselectDisplayItem = _find(this.values, { selected: true });

    return _isNil(foundSelectedItem) ? null : foundSelectedItem;
  }

}
