import { AfterViewInit, Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { SwipeFrom } from './swipe-detector.dto';

@Directive({
  selector: '[appSwipeDetector]'
})
export class SwipeDetectorDirective implements AfterViewInit {

  private readonly START_SWIPE_ERROR_MARGIN_PERCENTAGE = 50;

  private errorMargin: number;
  private leftCoordinate: number;
  private rightCoordinate: number;

  private swipeStarted = false;
  private startingPosition: number;

  @Input('appSwipeDetector') swipeFrom: SwipeFrom;

  @Output() swipePositionChanged = new EventEmitter<number>();
  @Output() swipeCompleted = new EventEmitter<boolean>();

  constructor(private hostElement: ElementRef) { }

  ngAfterViewInit(): void {
    if (!this.swipeFrom) {
      this.swipeFrom = SwipeFrom.LEFT;
    }
    const clientRect = (this.hostElement.nativeElement as HTMLElement).getBoundingClientRect();
    this.leftCoordinate = clientRect.left;
    this.rightCoordinate = clientRect.right;
    this.errorMargin = (this.rightCoordinate - this.leftCoordinate) * this.START_SWIPE_ERROR_MARGIN_PERCENTAGE / 100;
  }

  @HostListener('touchstart', ['$event']) onTouchStart($event: TouchEvent | UIEvent) {
    const touchPosition = this.handleEvent($event);
    this.startingPosition = null;
    if (touchPosition - this.leftCoordinate <= this.errorMargin && this.swipeFrom === SwipeFrom.LEFT) {
      this.startingPosition = this.leftCoordinate;
    } else if (this.rightCoordinate - touchPosition <= this.errorMargin && this.swipeFrom === SwipeFrom.RIGHT) {
      this.startingPosition = this.rightCoordinate;
    }

    if (this.startingPosition != null) {
      this.swipeStarted = true;
      this.emitNewPosition(touchPosition);
    }
  }

  @HostListener('touchmove', ['$event']) onTouchMove($event: TouchEvent | UIEvent) {
    if (this.swipeStarted) {
      const touchPosition = this.handleEvent($event);
      if (touchPosition > this.leftCoordinate && touchPosition < this.rightCoordinate) {
        this.emitNewPosition(touchPosition);
      }
    }
  }

  @HostListener('touchend', ['$event']) onTouchEnd($event: TouchEvent | UIEvent) {
    if (this.swipeStarted) {
      const touchPosition = this.handleEvent($event);
      let isSuccess = false;
      if (this.startingPosition === this.leftCoordinate) {
        isSuccess = (touchPosition + this.errorMargin) >= this.rightCoordinate;
      } else {
        isSuccess = (touchPosition - this.errorMargin) <= this.leftCoordinate;
      }
      this.swipeStarted = false;
      this.swipeCompleted.emit(isSuccess);

      if (isSuccess) {
        this.swipeFrom = this.swipeFrom === SwipeFrom.LEFT ? SwipeFrom.RIGHT : SwipeFrom.LEFT;
      }
    }
  }

  private handleEvent($event: TouchEvent | UIEvent) {
    $event.preventDefault();
    return ($event as TouchEvent).changedTouches[0].clientX;
  }

  private emitNewPosition(touchPosition: number) {
    const positionInsideElement = touchPosition - this.leftCoordinate;
    this.swipePositionChanged.emit(positionInsideElement);
  }
}
