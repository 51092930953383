import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {Multiselect2DialogSsfComponent} from '../../../material/multiselect2/multiselect2-dialog-ssf/multiselect2-dialog-ssf.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Multiselect2DialogData} from '../../../material/multiselect2/multiselect2-dialog/multiselect2-dialog.component';
import {EsOrg} from '@rsmu/es-rest-client/model/esOrg';

@Component({
  selector: 'app-search-multiselect-organizations-dialog',
  templateUrl: './search-multiselect-organizations-dialog.component.html',
  styleUrls: ['./search-multiselect-organizations-dialog.component.scss']
})
export class SearchMultiselectOrganizationsDialogComponent extends Multiselect2DialogSsfComponent<EsOrg> implements OnInit {

  constructor(
    protected cdr: ChangeDetectorRef,
    public dialogRef: MatDialogRef<EsOrg>,
    @Inject(MAT_DIALOG_DATA) public data: Multiselect2DialogData <EsOrg>
  ) {
    super(cdr, dialogRef, data);
  }
}
