import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrapFocusDirective } from './trap-focus.directive';
import { A11yModule } from '@angular/cdk/a11y';

@NgModule({
  imports: [
    CommonModule,
    A11yModule
  ],
  declarations: [
    TrapFocusDirective,
  ],
  exports: [
    TrapFocusDirective,
  ]
})
export class TrapFocusModule {
}
