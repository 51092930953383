import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { AppCommonsModule } from '../app-commons.module';
import { DatepickersModule } from '../datepickers/datepickers.module';

import { QuestionaryComponent } from './questionary.component';
import {
  PsyQuestionaryContainerComponent
} from './psy-questionary/psy-questionary-container/psy-questionary-container.component';
import {
  PsyQuestionaryQuestionsContainerComponent
} from './psy-questionary/psy-questionary-questions-container/psy-questionary-questions-container.component';
import {
  ProfQuestionaryContainerComponent
} from './prof-questionary/prof-questionary-container/prof-questionary-container.component';
import { QuestionaryProgressBarComponent } from './questionary-progress-bar/questionary-progress-bar.component';
import {
  QuestionaryInstructionsComponent
} from './psy-questionary/questionary-instructions/questionary-instructions.component';
import { QuestionaryRewardsComponent } from './questionary-rewards/questionary-rewards.component';
import { QuestionaryResultsComponent } from './psy-questionary/result/questionary-results/questionary-results.component';
import {
  QuestionaryResultsItemComponent
} from './psy-questionary/result/questionary-results-item/questionary-results-item.component';
import {
  ProfQuestionaryQuestionsContainerComponent
} from './prof-questionary/prof-questionary-questions-container/prof-questionary-questions-container.component';
import { LimitedSelectQuestionComponent } from './question-options/limited-select-question/limited-select-question.component';
import { SearchSelectQuestionComponent } from './question-options/search-select-question/search-select-question.component';
import { FreeTextQuestionComponent } from './question-options/free-text-question/free-text-question.component';
import { DateQuestionComponent } from './question-options/date-question/date-question.component';
import { TextAreaQuestionComponent } from './question-options/text-area-question/text-area-question.component';
import { QuestionOptionsComponent } from './question-options/question-options.component';
import { IntegerQuestionComponent } from './question-options/integer-question/integer-question.component';
import { DecimalQuestionComponent } from './question-options/decimal-question/decimal-question.component';
import {
  PsyTestHighestResultsTranscriptComponent
} from './psy-questionary/result/psy-test-highest-results-transcript/psy-test-highest-results-transcript.component';


const IMPORTED_MODULES = [
  CommonModule,
  FormsModule,
  AngularSvgIconModule,
  AppCommonsModule,
  DatepickersModule,
  TranslateModule,
];

const EXPORTED_DECLARATIONS = [
  QuestionaryComponent,
  PsyQuestionaryContainerComponent,
  PsyQuestionaryQuestionsContainerComponent,
  ProfQuestionaryContainerComponent,
  QuestionaryProgressBarComponent,
  QuestionaryInstructionsComponent,
  QuestionaryRewardsComponent,
  QuestionaryResultsComponent,
  QuestionaryResultsItemComponent,
  PsyTestHighestResultsTranscriptComponent,
  ProfQuestionaryQuestionsContainerComponent,
  LimitedSelectQuestionComponent,
  SearchSelectQuestionComponent,
  SearchSelectQuestionComponent,
  FreeTextQuestionComponent,
  DateQuestionComponent,
  TextAreaQuestionComponent,
  QuestionOptionsComponent,
  IntegerQuestionComponent,
  DecimalQuestionComponent
];

@NgModule({
    imports: [
        IMPORTED_MODULES,
        ReactiveFormsModule
    ],
    declarations: EXPORTED_DECLARATIONS,
    exports: EXPORTED_DECLARATIONS
})
export class QuestionaryModule {}
