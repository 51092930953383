import {Directive, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {MatAutocomplete} from '@angular/material/autocomplete';
import {Subject} from 'rxjs';
import {takeUntil, tap} from 'rxjs/operators';

export interface AutocompleteOffsetEvent {
  autoComplete: MatAutocomplete;
  offset: number;
}

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: 'mat-autocomplete[changeOffset]',
  exportAs: 'mat-autocomplete[changeOffset]'
})
export class AutocompleteChangeOffsetDirective implements  OnDestroy {

  @Input()
  threshold = 0.8;

  @Output()
  changeOffset: EventEmitter<AutocompleteOffsetEvent> = new EventEmitter <AutocompleteOffsetEvent> ();

  private _fn: Function;
  private _offset = 0;
  private _unsubscribe$: Subject<void> = new Subject<void>();

  private static _atBottom = (event: Event) => {
    const scrollTop = (event.target as HTMLElement)
      .scrollTop;
    const scrollHeight = (event.target as HTMLElement)
      .scrollHeight;
    const elementHeight = (event.target as HTMLElement)
      .clientHeight;
    return scrollHeight <= scrollTop + elementHeight + 1;
  }


  constructor(public autocomplete: MatAutocomplete) {
    this._fn = this._onScroll.bind(this);
    this.autocomplete.opened
      .pipe(
        takeUntil(this._unsubscribe$),
        tap(() => {
          setTimeout(() => {
            if (this.autocomplete?.panel?.nativeElement) {
              this._removeScrollListener(this._fn);
              this.autocomplete.panel.nativeElement.addEventListener(
                'scroll', this._fn
              );
            }
          }, 0);
        })).subscribe();
  }

  clearOffset(): void {
    this._offset = 0;
    this.changeOffset.emit({offset: this._offset, autoComplete: this.autocomplete});
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }
  private _onScroll (event: Event) {
    if (event && AutocompleteChangeOffsetDirective._atBottom(event)) {
      this.changeOffset.emit({offset: ++this._offset, autoComplete: this.autocomplete});
    }
  }

  private _removeScrollListener (func: Function) {
    this.autocomplete.panel.nativeElement.removeEventListener(
      'scroll', func);
    }
}
