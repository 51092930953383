import { Injectable } from '@angular/core';
import { HtmlDocumentService } from '../../html-document/html-document.service';
import { ThemeManagerService } from '../theme-manager/theme-manager.service';
import { Theme } from '../theme-manager/theme';
import { catchError, debounceTime, filter, first, switchMap } from 'rxjs/operators';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { ApiService } from '../../api/api.service';
import { AccessibleMode, UserPreferences } from '@rsmu/portal-api';
import { SecurityService } from '../../security/security.service';
import _constant from 'lodash-es/constant';
import _identity from 'lodash-es/identity';
import _noop from 'lodash-es/noop';
import { MobileAppService } from '../../mobile/mobile-app.service';

@Injectable({
  providedIn: 'root'
})
export class AccessibleService {

  private useAccessible = false;
  private accessibleMode = AccessibleMode.White;
  private localUseAccessible: boolean = null;
  private localAccessibleMode: AccessibleMode = null;

  private readonly switchOn$ = new Subject<boolean>();
  private readonly changeMode$ = new Subject<AccessibleMode>();

  private readonly accessibleClass = 'accessible-version';
  private readonly mobileClass = 'mobile-version';

  get changesSwitchOn(): Observable<boolean> {
    return this.switchOn$.pipe(debounceTime(0));
  }

  get changesMode(): Observable<AccessibleMode> {
    return this.changeMode$.pipe(debounceTime(0));
  }

  get isAccessibleToggleEnabled(): boolean {
    return !this.mobileAppService.isMobileApp();
  }

  get isActive(): boolean {
    return this.useAccessible;
  }

  constructor(
    private readonly htmlDocumentService: HtmlDocumentService,
    private readonly themeManagerService: ThemeManagerService,
    private readonly apiService: ApiService,
    private readonly securityService: SecurityService,
    private readonly mobileAppService: MobileAppService
  ) {
    // (komarov) this timeout is reasonable to construct service correctly
    // https://github.com/angular/angular/issues/25590#issuecomment-414954876
    setTimeout(() => {
      of(this.isAccessibleToggleEnabled)
        .pipe(
          filter(_identity),
          switchMap(() => {
            return securityService
              .isUserLoggedIn()
              .pipe(
                filter(event => event),
                first(),
                switchMap(
                  (): Observable<UserPreferences> => apiService.retrieveUserPreferences().pipe(
                    catchError(
                      (ignored): Observable<UserPreferences> => of({
                        accessibleMode: AccessibleMode.White,
                        useAccessible: false
                      } as UserPreferences)
                    )
                  )
                )
              );
          })
        )
        .subscribe((userPreferences: UserPreferences) => {
          this.useAccessible = userPreferences.useAccessible;

          this.useAccessible ?
            this.turnOn() :
            this.turnOff();

          this.setMode(userPreferences.accessibleMode);
        });

      of(this.isAccessibleToggleEnabled)
        .pipe(
          filter(_identity),
          switchMap(() => {
            return combineLatest<[boolean, AccessibleMode, boolean]>(
              [this.switchOn$, this.changeMode$, securityService.isUserLoggedIn()]
            )
              .pipe(
                filter(([
                          useAccessible,
                          accessibleMode,
                          isLoggedIn
                        ]: [
                  boolean,
                  AccessibleMode,
                  boolean
                  ]): boolean => isLoggedIn),
                switchMap(
                  ([useAccessible, accessibleMode, ignoredIsLoggedIn]: [boolean, AccessibleMode, boolean]) =>
                    this.apiService
                      .changeUserPreferences({
                        accessibleMode,
                        useAccessible
                      })
                      .pipe(
                        catchError(_constant(of(null)))
                      )
                ),
              );
          })
        )
        .subscribe(_noop);
    }, 0);
  }

  getMode(): AccessibleMode {
    return this.accessibleMode;
  }

  turnOn(): void {
    this.useAccessible = true;
    this.switchOn$.next(true);
    this.localUseAccessible = true;
    this.htmlDocumentService.addClass(this.mobileClass);
    this.htmlDocumentService.addClass(this.accessibleClass);

    this.selectTheme();
  }

  turnOff(): void {
    this.useAccessible = false;
    this.switchOn$.next(false);
    this.localUseAccessible = false;
    this.htmlDocumentService.removeClass(this.mobileClass);
    this.htmlDocumentService.removeClass(this.accessibleClass);

    this.selectTheme();
  }

  setMode(mode: AccessibleMode): void {
    this.accessibleMode = mode;
    this.changeMode$.next(mode);
    this.selectTheme();
  }

  private selectTheme(): void {
    if (this.useAccessible) {
      switch (this.accessibleMode) {
        case AccessibleMode.White:
          this.localAccessibleMode = AccessibleMode.White;
          this.themeManagerService.selectTheme(Theme.ACCESSIBLE_WHITE);
          break;

        case AccessibleMode.Black:
          this.localAccessibleMode = AccessibleMode.Black;
          this.themeManagerService.selectTheme(Theme.ACCESSIBLE_BlACK);
          break;
      }
    } else {
      this.themeManagerService.resetTheme();
    }
  }
}
