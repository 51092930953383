import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-date-range-text',
  templateUrl: './date-range-text.component.html',
  styleUrls: ['./date-range-text.component.scss']
})
export class DateRangeTextComponent implements OnInit {
  @Input() public startDate;
  @Input() public endDate;
  @Input() public dateRangeTitle;
  @Input() public titleSize;
  @Input() public dateSize;
  @Input() public dateWeight;

  constructor() {
  }

  ngOnInit() {
  }

}
