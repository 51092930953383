<label class="switch-button" [class.switch-button__disabled]="disabled">
  <input
    [class.switch-button__input_checked]="isChecked"
    class="switch-button__input"
    type="checkbox"
    [(ngModel)]="isChecked"
    [disabled]="disabled">
  <div class="switch-button__label text text_regular"></div>
  <span class="switch-button__text text text_regular">
      {{label}}
  </span>
</label>
