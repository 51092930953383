import { Component, OnInit } from '@angular/core';
import {Multiselect2Component} from '../../material/multiselect2/multiselect2.component';
import {EsOrg} from '@rsmu/es-rest-client/model/esOrg';
import {MatDialog} from '@angular/material/dialog';
import {
  SearchMultiselectOrganizationsDialogComponent
} from './search-multiselect-organizations-dialog/search-multiselect-organizations-dialog.component';

@Component({
  selector: 'app-search-multiselect-organizations',
  templateUrl: './search-multiselect-organizations.component.html',
  styleUrls: ['./search-multiselect-organizations.component.scss']
})
export class SearchMultiselectOrganizationsComponent extends Multiselect2Component<EsOrg> implements OnInit {
  constructor(
    protected readonly matDialog: MatDialog
  ) {
    super(matDialog);
  }
  ngOnInit() {
    this._dialogComponent = SearchMultiselectOrganizationsDialogComponent;
    this._initComponent();
  }
}
