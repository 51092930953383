import { AfterContentInit, Component, ContentChild, Input, } from '@angular/core';
import { FormControlName, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss']
})
export class FormFieldComponent implements AfterContentInit {

  @Input() form: UntypedFormGroup;
  @Input() attemptedToSubmit: boolean;
  @Input() translateNamespaceOverride: string;

  fieldName: string;

  @ContentChild(FormControlName, { static: false })
  private formControlName: FormControlName;

  ngAfterContentInit() {
    this.fieldName = this.formControlName.name as string;
  }

}
