<label class="radio"
       tabindex="0"
       (keydown)="onKeyDown($event)"
       [class.radio_disabled]="disabled"
       [class.radio_selfcontrol]="selfControl">
  <input [name]="name"
         [disabled]="disabled"
         #model
         [value]="value"
         [checked]="isChecked"
         (change)="check()"
         class="radio__input"
         type="radio"/>
  <span class="radio__icon radio__icon_{{ theme }}"></span>
  <span class="radio__text">
    <ng-content></ng-content>
  </span>
  <span class="radio__inner-html"
        *ngIf="html"
        [innerHTML]="html | sanitizeBypass"></span>
</label>
