import { animate, state, style, transition, trigger } from '@angular/animations';

export const TIME_POPUP_TOGGLE = 250;

export const POPUP_ANIMATE = [
  trigger('isOpen', [

    state('void', style({ opacity: 0, transform: 'scale(0,0)' })),
    state('open', style({ opacity: 1, transform: 'scale(1,1)' })),
    state('false', style({ opacity: 0, transform: 'scale(0,0)' })),

    transition('void => *, true => false, false => true', [
      animate(`${TIME_POPUP_TOGGLE}ms ease-out`)
    ])

  ])
];
