<div class="select"
     [class.select_size-sm]="size === 'sm'"
     [class.select_size-md]="size === 'md'"
     [class.select_size-lg]="size === 'lg'"
     #overlayOrigin>
  <button class="select__button button button_primary button_size-sm button_block button_larger-radius {{ isPreselected ? preselectedMods : '' }}"
          type="button"
          tabindex="0"
          [class.button_stroked]="!committed"
          [class.button_warn-shade]="disableSelection"
          [disabled]="disabled"
          (click)="open()">
    <span class="select-label">{{ committed ? committed.label : label }}</span>
  </button>

  <ng-template #overlayBox>
    <div class="select__dropdown-menu" [appTrapFocus]="isOpen">
      <ul class="select-list">
        <li class="select-list__item" *ngIf="showFilter && !disableSelection">
          <div class="select__filter">
            <input #filterInput
                   class="text-field"
                   type="text"
                   [(ngModel)]="filterValue"
                   placeholder="{{'common.search' | translate}}"
                   (input)="filter(filterInput.value)"/>
            <button type="button"
                    class="button select__filter-clear-button"
                    [title]="'tips.clear' | translate"
                    (click)="clearFilter()">
              <span class="figure figure_close" role="presentation"></span>
            </button>
          </div>
        </li>
        <li class="select-list__item select-list__item_onlyone" *ngFor="let item of displayItems; trackBy: listTrackBy">
          <button
            class="select-list__item-button button"
            type="button"
            [disabled]="disableSelection"
            [class.select-list__item-button_active]='item.selected'
            (click)="onSelect(item)">{{ item.label }}
          </button>
        </li>
      </ul>
    </div>
  </ng-template>
</div>
