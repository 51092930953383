<section class="tab-group {{ theme }}">
  <header class="tab-group__header">
    <button class="button tab-group__tab"
            type="button"
            *ngFor="let tab of tabs; let i = index"
            [class.tab-group__tab_active]="currentTab === i"
            [appHoverClass]="'tab-group__tab_active-on-hover'"
            (click)="selectTab(i)">
      <ng-container *ngIf="tab.mainText">
        {{ tab.mainText }}
      </ng-container>
      <ng-container *ngIf="!tab.mainText">
        <svg-icon *ngIf="tab.icon"
                  [title]="tab.label"
                  class="tab-group__tab-icon tab-group__tab-icon_{{ tab.icon }} icon icon_fit-height"
                  src="assets/icons/{{ tab.icon }}.svg"></svg-icon>
        <span class="tab-group__tab-label">{{ tab.label }}</span>
      </ng-container>
    </button>

  </header>
  <div class="tab-group__body">
    <app-tab-body
      *ngFor="let tab of tabs; let i = index"
      [active]="currentTab === i"
      [content]="tab.content"></app-tab-body>
  </div>
</section>
