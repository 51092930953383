import { EntityAdapter, createEntityAdapter, EntityState } from '@ngrx/entity';
import { MyPlan } from '@rsmu/portal-api';

export const eduPlanAdapter: EntityAdapter<MyPlan> =
  createEntityAdapter<MyPlan>({
    selectId: (model: MyPlan) => model.id
  });

export interface State {
  eduPlan: MyPlan | null;
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = {
  eduPlan: null,
  isLoading: false,
  error: null
};
