<div class="multi-select-dialog">
  <mat-checkbox *ngIf="data.isSelectAllAvailable"
                (change)="onSelectAllChange($event)"
                [checked]="isSelectedAllChecked"
                class="multi-select-dialog__check-all" color="primary">
    {{'eduElementProspect.search.selectAll' | translate}}</mat-checkbox>
  <mat-form-field appearance="outline" class="multi-select-dialog__search-by-name">
    <mat-label>{{ 'eduElementProspect.search.searchByName' | translate }}</mat-label>
    <input [formControl]="dialogStringControl" matInput type="text">
    <mat-icon class="multi-select-dialog__search-by-name__icon" color="primary"  matSuffix svgIcon="search"></mat-icon>
  </mat-form-field>
  <div class="multi-select-dialog__chips-list" *ngIf="preselectedItems$ | async as preselectedItems">
    <mat-chip-listbox multiple="true">
      <mat-chip-option [selected]="true" color="primary" (removed)="onChipRemove(preselectedItem)" *ngFor="let preselectedItem of preselectedItems">
        {{preselectedItem[data?.itemName]}}
        <button matChipRemove>
          <mat-icon svgIcon="closed"></mat-icon>
        </button>
      </mat-chip-option>
    </mat-chip-listbox>
  </div>
  <div class="multi-select-dialog__items-list">
    <ng-container *ngIf="asyncQueryComplete$ | async else loader">
      <ng-container *ngIf="this.filteredItems$ | async as items"
        [ngTemplateOutlet]="itemsList"
        [ngTemplateOutletContext]="{items: items}"
      ></ng-container>
    </ng-container>
  </div>
  <div class="multi-select-dialog__actions">
    <div class="multi-select-dialog__actions__item">
      <button mat-stroked-button color="primary" (click)="OnCancel()">
        {{'common.multiselect.popup.deny' | translate}}</button>
    </div>
    <div class="multi-select-dialog__actions__item">
      <button mat-raised-button color="primary" (click)="onConfirm()">
        {{'common.multiselect.popup.confirm' | translate}}</button>
    </div>
  </div>
</div>

<ng-template #itemsList let-items='items'>
  <mat-selection-list [multiple]="false">
    <div class="multi-select-dialog__item" *ngFor="let item of items">
      <mat-list-option [disabled]="!isCheckBoxEnabled" (click)="onItemClick(item)">
        {{item[data.itemName]}}
        <mat-divider></mat-divider>
      </mat-list-option>
    </div>
  </mat-selection-list>
</ng-template>

<ng-template #loader>
  <div class="loader">
    <mat-spinner
      color="primary"
      [strokeWidth]="2"
    ></mat-spinner>
  </div>
</ng-template>
