<ng-template #modalContent>
  <div class="popup"
       [appTrapFocus]="isOpenAnimationCompleted"
       [class.popup_auto-width-mobile]="isAutoWidthMobile"
       [class.popup_auto-width]="isAutoWidth">
    <div class="popup__container container container_raised"
         [@isOpen]="isOpen"
         (@isOpen.done)="onAnimationEnd($event)"
         [class.popup__container_accent]="isAccent"
         [class.popup__container_auto-height]="isAutoHeight"
         [class.popup__container_full-screen]="isFullScreen"
         [class.popup__container_indent]="isIndent">

      <ng-content select="app-popup-header"></ng-content>
      <app-popup-header *ngIf="useDefaultHeader" [hideDefaultHeader]="false" [showClose]="showClose"></app-popup-header>

      <div class="popup__body"
           [class.popup__body_after-text-header]="!useDefaultHeader"
           [class.popup__body_title-in]="titleInBody">
        <ng-container class="popup__content"
                      *ngTemplateOutlet="popupContent; context: useDataAsPopupContentContext?data:null"></ng-container>
        <div class="popup__content" *ngIf="!popupContent">
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </div>
</ng-template>
