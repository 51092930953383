import { Component, EventEmitter, Injector, Input, OnDestroy, Output, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { PopupAction, PopupHideData } from './interfaces/popup.dto';
import { Overlay, OverlayConfig, OverlayRef, PositionStrategy } from '@angular/cdk/overlay';
import { Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { TemplatePortal } from '@angular/cdk/portal';
import { POPUP_ANIMATE } from './popup-animate';
import { PopupTheme } from './interfaces/popup-theme';
import { AnimationEvent } from '@angular/animations';
import { KeyListenerService } from '../../key-listener/key-listener.service';
import { TourService } from '../../tour/tour.service';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
  animations: POPUP_ANIMATE,
  exportAs: 'popup'
})
export class PopupComponent<T> implements OnDestroy {

  @Input() closeOnBackdropClick = false;
  @Input() showClose = true;
  @Input() themeList: PopupTheme[] = [];

  @Input() popupContent: TemplateRef<any>;
  @Input() useDataAsPopupContentContext: boolean;

  @Output() popupShow = new EventEmitter<T>();
  @Output() popupHide = new EventEmitter<PopupHideData<T>>();

  isOpen = false;
  isShow = false;
  isOpenAnimationCompleted = false;
  data: T;
  useDefaultHeader = true;

  private destroy$ = new Subject<void>();
  private overlayRef: OverlayRef;
  private keyListenerId: number;
  private savedAction: PopupAction;

  @ViewChild('modalContent', { static: true }) private modalContent: TemplateRef<{}>;

  constructor(
    private injector: Injector,
    private overlay: Overlay,
    private tourService: TourService,
    private viewContainer: ViewContainerRef,
    private keyListener: KeyListenerService,
  ) {
  }

  get isFullScreen(): boolean {
    return this.checkTheme(this.themeList, PopupTheme.FULL_SCREEN);
  }

  get isAutoWidth(): boolean {
    return this.checkTheme(this.themeList, PopupTheme.AUTO_WIDTH);
  }

  get isAutoWidthMobile(): boolean {
    return this.checkTheme(this.themeList, PopupTheme.AUTO_WIDTH_MOBILE);
  }

  get isAutoHeight(): boolean {
    return this.checkTheme(this.themeList, PopupTheme.AUTO_HEIGHT);
  }

  get isIndent(): boolean {
    return this.checkTheme(this.themeList, PopupTheme.INDENT);
  }

  get titleInBody(): boolean {
    return this.checkTheme(this.themeList, PopupTheme.TITLE_IN_BODY);
  }

  get isAccent(): boolean {
    return this.checkTheme(this.themeList, PopupTheme.ACCENT);
  }

  show(data?: T): void {
    if (data) {
      this.data = data;
    }
    this.isOpenAnimationCompleted = false;
    this.isShow = true;
    this.isOpen = true;
    this.createOverlay();
    this.tourService.exitTourSkipMeta();
  }

  hide(action: PopupAction = PopupAction.DENY): void {
    this.isOpen = false;
    this.savedAction = action;
  }

  forceClose(): void {
    this.dispose();
  }

  onAnimationEnd(event: AnimationEvent): void {
    if (!event.toState) {
      this.isShow = false;
      this.dispose();

      this.popupHide.emit({
        data: this.data,
        action: this.savedAction
      });
      this.savedAction = null;
    } else {
      this.isOpenAnimationCompleted = true;
    }
  }

  hideDefaultHeader(): void {
    this.useDefaultHeader = false;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();

    this.dispose();
  }

  private checkTheme(themeList: PopupTheme[], theme: PopupTheme): boolean {
    return themeList.indexOf(theme) !== -1;
  }

  private createOverlay(): void {
    this.overlayRef = this.overlay.create(this.overlayConfig);
    this.keyListenerId = this.keyListener.addEscapeListener(() => this.hide());

    this.overlayRef
      .backdropClick()
      .pipe(
        filter(() => this.closeOnBackdropClick),
        take(1),
        takeUntil(this.destroy$)
      )
      .subscribe(() => this.hide());

    this.overlayRef.attach(
      new TemplatePortal(this.modalContent, this.viewContainer, this.injector)
    );
  }

  private dispose(): void {
    this.keyListener.removeEscapeListener(this.keyListenerId);
    if (this.overlayRef) {
      this.overlayRef.dispose();
    }
  }

  private get positionStrategy(): PositionStrategy {
    return this.overlay.position()
      .global()
      .centerHorizontally()
      .centerVertically();
  }

  private get overlayConfig(): OverlayConfig {
    return new OverlayConfig({
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-primary',
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy: this.positionStrategy,
    });
  }
}
