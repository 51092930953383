import { PopupAction } from '../popup/interfaces/popup.dto';

export const DEFAULT_CONFIRM_BUTTONS = [
  {
    key: 'component.confirmationPopup.confirm',
    action: PopupAction.CONFIRM,
    style: 'button_primary'
  },
  {
    key: 'component.confirmationPopup.deny',
    action: PopupAction.DENY,
    style: 'button_primary'
  }
];
