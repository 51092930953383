import { Component, OnInit } from '@angular/core';
import {Transition} from '@uirouter/core';
import {IfmoService} from '../ifmo.service';

@Component({
  selector: 'app-ifmo-callback',
  templateUrl: './ifmo-callback.component.html',
  styleUrls: ['./ifmo-callback.component.scss']
})
export class IfmoCallbackComponent implements OnInit {

  constructor(
    private readonly transition: Transition,
    private readonly ifmoService: IfmoService
  ) { }

  ngOnInit() {
    let state: string = null;
    let session_state: string = null;
    let code: string = null;
    if (this.transition.params()) {
      state = this.transition.params().state;
      session_state = this.transition.params().session_state;
      code = this.transition.params().code;
    }
    this.ifmoService.linkIfmoUser(state, session_state, code);
  }
}
