import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownComponent, DropdownToggleDirective } from './dropdown.component';
import { DropdownContentDirective } from './dropdown-content.directive';
import { DropdownContextDirective } from './dropdown-context.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    DropdownComponent,
    DropdownContentDirective,
    DropdownToggleDirective,
    DropdownContextDirective,
  ],
  exports: [
    DropdownComponent,
    DropdownContentDirective,
    DropdownToggleDirective,
    DropdownContextDirective,
  ]
})
export class DropdownModule { }
