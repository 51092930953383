import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appAutocompleteFocused]'
})
export class AutocompleteFocusedDirective {
  @Input()
  set appAutocompleteFocused(value: boolean) {
    if (value) {
      this.elementRef.nativeElement.scrollIntoView();
    }
  }

  constructor(private elementRef: ElementRef) {
  }
}
