import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  static readonly LOADER_SVG_CLASS = 'loader__svg';

  static INDEX = 0;

  private _progress = 0;

  index: number;
  @Input() showProgress = false;
  @Input() size = 100;
  @Input() strokeWidth = 3;
  @Input() labelKey = 'common.loading';
  @Input() showLabelKey = true;
  /**
   * Space separated list of modificators for "loader__svg" CSS class
   */
  @Input() svgThemes: string;

  constructor(private translateService: TranslateService) {}

  ngOnInit() {
    this.index = LoaderComponent.INDEX++;
  }

  @Input()
  get progress(): number {
    return this._progress;
  }

  set progress(progress: number) {
    if (progress < 0) {
      progress = 0;
    }
    if (progress > 100) {
      progress = 100;
    }
    this._progress = progress;
  }

  get translatedLabel(): Observable<string> {
    return this.translateService.get(this.labelKey).pipe(map((res => res === this.labelKey ? '' : res)));
  }

  getStrokeWidth(): string {
    return this.strokeWidth + '%';
  }

  composeNgClass() {
    const ngClass = {};
    if (this.svgThemes) {
      this.svgThemes
        .split(' ')
        .forEach(svgTheme => ngClass[LoaderComponent.LOADER_SVG_CLASS + '_' + svgTheme] = true);
    }
    return ngClass;
  }
}
