import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { StateService, UIRouter, TransitionService, Transition, StateDeclaration } from '@uirouter/core';
import { GlobalLoadingService } from '../global-loading/global-loading.service';
import { Subscription } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { MobileAppService } from '../mobile/mobile-app.service';
import { environment } from '../../environments/environment';
import { takeUntil } from 'rxjs/operators';
import { ManagedComponent } from '../app-commons/managed-component';
import { EsiaService } from '../esia/esia.service';

@Component({
  selector: 'app-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss']
})
export class RootComponent extends ManagedComponent implements OnInit, OnDestroy {

  isLoading = false;

  constructor(
    private readonly router: UIRouter,
    private readonly stateService: StateService,
    private readonly transitionService: TransitionService,
    private readonly globalLoadingService: GlobalLoadingService,
    @Inject(DOCUMENT) private readonly document: HTMLDocument,
    private readonly mobileAppService: MobileAppService,
    private readonly esiaService: EsiaService
  ) {
    super();
  }

  ngOnInit(): void {
    this.globalLoadingService.getLoadingState()
      .pipe(takeUntil(this.destroy$))
      .subscribe((state) => {
        this.isLoading = state;
      });

    if (this.mobileAppService.isMobileApp()) {
      this.configureMobileApp();
    }

    // fix mobile 100vh
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  iamState(): boolean {
    return this.router.stateService.$current.data && this.router.stateService.$current.data.iamComponent;
  }

  isLoginPage(): boolean {
    return this.stateService.is('root.login');
  }

  private configureMobileApp(): void {
    this.document.addEventListener('deviceready', () => {
      const statusBar = window['StatusBar'];

      if (statusBar != null) {
        statusBar.overlaysWebView(false);
        statusBar.backgroundColorByHexString('#fff');
        statusBar.styleDefault();
      }
      return this.mobileAppService.processStartUrl();
    }, false);

    this.mobileAppService.initMobileRedirectListener();

    this.transitionService.onBefore({}, (transition: Transition) => {
      const to: StateDeclaration = transition.to();

      if (to.data.unavailableForMobile) {
        const params: { [paramName: string]: any } = { ...transition.params(), fromMobile: true };
        const url: string = this.stateService.href(to.name, params);
        const outsideUrl: string = environment.baseWebUrl + url;

        this.mobileAppService.redirectToOutsideUrl(outsideUrl)
          .pipe(takeUntil(this.destroy$))
          .subscribe((isMobileBrowserClosed: boolean) => {
            if (isMobileBrowserClosed && to.data.redirectForEsiaFinish) {
              this.esiaService.startEsiaLogin({
                token: null,
                userExists: null,
                error: null
              });
            }
          });
        transition.abort();
      }
    });
  }
}
