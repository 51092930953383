<div
  class="notification-bubble notification-bubble_theme_{{ theme }}"
  *ngIf="isOpen">
  <svg-icon class="notification-bubble__icon notification-bubble__icon_desktop icon icon_fit-width icon_54_h-limit"
            [title]="'help.tooltip' | translate"
            src="assets/icons/helper.svg"></svg-icon>
  <svg-icon class="notification-bubble__icon notification-bubble__icon_mobile icon icon_fit-width icon_46_h-limit {{ modificator }}"
            [title]="'help.tooltip' | translate"
            src="assets/icons/helper-mute.svg"></svg-icon>
  <div class="notification-bubble__bubble notification-bubble__bubble_theme_{{ theme }}">
    <button *ngIf="closeButton" type="button"
      class="button notification-bubble__close-button"
      title="{{ 'tips.close' | translate }}"
      (click)="hideAndPersist()">
      <span role="presentation" class="figure figure_close notification-bubble__close"></span>
    </button>
    <div class="text text_italic notification-bubble__text">
      <ng-content></ng-content>
    </div>
  </div>
</div>
<div class="notification-bubble-toggle">
  <button *ngIf="!isOpen"
      (click)="openAndPersist()"
      class="notification-bubble-toggle__button notification-bubble-toggle__button_fixed_size">
    <svg-icon class="icon icon_19 icon_primary icon_fit-sizes"
              [title]="'tips.question' | translate"
              src="assets/icons/question.svg"></svg-icon>
  </button>
</div>
