import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { ApiError, ApiErrorFieldErrorsInner, ApiErrorGlobalErrorsInner } from '@rsmu/portal-api';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  // noinspection JSMethodCanBeStatic
  hasFieldErrors(form: UntypedFormGroup, fieldName: string, attemptedToSubmit: boolean): boolean {
    const fieldControl: AbstractControl = form.get(fieldName);
    return fieldControl.invalid && (fieldControl.dirty || attemptedToSubmit) && !!fieldControl.errors;
  }

  // noinspection JSMethodCanBeStatic
  hasGlobalErrors(form: UntypedFormGroup, attemptedToSubmit: boolean): boolean {
    return form.invalid && (form.dirty || attemptedToSubmit) && !!form.errors;
  }

  setApiErrors(form: UntypedFormGroup, errors: ApiError): void {
    if (errors.globalErrors) {
      this.setGlobalErrors(form, errors);
    } else if (errors.fieldErrors) {
      this.setFieldsErrors(form, errors);
    } else {
      form.setErrors({ unknown: true });
    }
  }

  setGlobalErrors(form: UntypedFormGroup, errors: ApiError): void {
    const formErrors: ValidationErrors = {};
    errors.globalErrors.forEach((globalError: ApiErrorGlobalErrorsInner) => {
      formErrors[globalError.code] = true;
    });
    form.setErrors(formErrors);
  }

  setFieldsErrors(form: UntypedFormGroup, errors: ApiError): void {
    errors.fieldErrors.forEach((fieldError: ApiErrorFieldErrorsInner) => {
      const control: AbstractControl = form.controls[fieldError.fieldName];
      let controlErrors = {};
      controlErrors[fieldError.code] = true;
      controlErrors = { ...controlErrors, ...control.errors };
      form.controls[fieldError.fieldName].setErrors(controlErrors);
    });
  }

}
