import { Action } from '@ngrx/store';

export enum ActionTypes {
  SET_CURRENT_CYCLE = '[Current Cycle] Set Current Cycle',
  REMOVE_CURRENT_CYCLE = '[Current Cycle] Remove Current Cycle',
  UPDATE_SHOW_CURRENT_CYCLE = '[Current Cycle] Update Show Current Cycle',
  LOAD_LAST_TO_CURRENT_CYCLE = '[Current Cycle] Load Last To Current Cycle'
}

export class SetCurrentCycleAction implements Action {
  readonly type = ActionTypes.SET_CURRENT_CYCLE;
  constructor(public payload: { currentCycle: string; }) {}
}

export class RemoveCurrentCycleAction implements Action {
  readonly type = ActionTypes.REMOVE_CURRENT_CYCLE;
}

export class UpdateShowCurrentCycle implements Action {
  readonly type = ActionTypes.UPDATE_SHOW_CURRENT_CYCLE;
  constructor(public payload: { showCurrentCycle: boolean }) {}
}

export class LoadLastToCurrentCycleAction implements Action {
  readonly type = ActionTypes.LOAD_LAST_TO_CURRENT_CYCLE;
}

export type Actions =
  | SetCurrentCycleAction
  | RemoveCurrentCycleAction
  | UpdateShowCurrentCycle
  | LoadLastToCurrentCycleAction;
