<ul class="sidebar-menu">
  <div [attr.tourAnchor]="useForTour ? 'Common_Programs' : undefined">
    <ng-container *ngIf="cycles$ | async as cycles; else loader">
      <li class="sidebar-menu__item"
          *ngFor="let item of cycles">
        <button class="button button_text sidebar-menu__button"
                type="button"
                (click)="select(item.id)">
        <span class="text text_small sidebar-menu__link-text"
              [ngClass]="{'text_bold text_primary sidebar-menu__link-text_active': item.id === currentCycle && showSelectedCycle}">
          {{item.name}}
        </span>
        </button>
      </li>
    </ng-container>
  </div>
  <li class="sidebar-menu__item sidebar-menu__item_add"
      *ngIf="allowAddCycle"
      [attr.tourAnchor]="useForTour ? 'Helper_AddCycle' : undefined">
    <button class="button button_text sidebar-menu__button sidebar-menu__button_add"
            [title]="'sidebar.addCycle.title' | translate"
            type="button"
            (click)="popupAddDoc.show()">
      <span>
        <svg-icon
          class="icon icon_primary icon_24"
          src="assets/icons/ic-add.svg"></svg-icon>
      </span>
      <span class="text text_small text_primary sidebar-menu__link-text sidebar-menu__link-text_add">
        {{ 'sidebar.addCycle' | translate }}
      </span>
    </button>
  </li>
</ul>
<app-popup #popupAddDoc>
  <app-add-document
      *ngIf="popupAddDoc.isShow"
      (cycleCreated)="onCreateCyclePopupHide($event)"
      [createCycle]="true"
      (informationPopupOnHelper)="openInfoCycle($event)"
      (confirmChangeCycle)="openConfirmChangeCycle($event)"
      (addDocument)="popupAddDoc.hide()">
  </app-add-document>
</app-popup>

<app-popup #popupInfoAboutCreatedCycle>
  <div class="text text_big text_bold" *ngIf="!loading && createdCycle">
    {{ 'addCycle.success' | translate: {cycleName: createdCycle.cycleName, startDate: formatDate(createdCycle.startDate), endDate: formatDate(createdCycle.endDate) } }}
  </div>
  <div class="info-popup-btn">
    <button class="button button_primary" (click)="popupInfoAboutCreatedCycle.hide()">{{ 'common.popup.close' | translate}}</button>
  </div>
</app-popup>

<app-popup #popupDocumentAdded>
  <div class="text text_big text_bold" *ngIf="!loading && createdCycle">
    {{ 'addCycle.documentAdded' | translate: {programName: createdCycle.cycleName} }}
  </div>
  <div class="info-popup-btn">
    <button class="button button_primary" (click)="popupDocumentAdded.hide()">{{ 'common.popup.close' | translate}}</button>
  </div>
</app-popup>

<ng-template #loader>
  <div class="loader">
    <app-loader></app-loader>
  </div>
</ng-template>

