<div
  class="edu-element-dropdown">
  <h2 class="heading heading_2 heading_2-smaller-sm heading_light heading_bold-sm edu-element-dropdown__header">
    <button class="collapse-toggle edu-element-dropdown__toggle text text_bold text_big"
            role="checkbox"
            [attr.aria-checked]="isShowed"
            [title]="'tips.hidden.' + !isShowed | translate"
            (click)="toggleIsShowed()">
      <span class="collapse-toggle__icon"
            role="presentation"
            [class.collapse-toggle__icon_opened]='isShowed'>
        <span class="figure figure_plus"></span>
        <span class="figure figure_minus"></span>
      </span>
      {{ title }}
    </button>
  </h2>
  <div class="edu-element-dropdown__content"
       @hiddenLeave
       *ngIf="isShowed">
    <ng-content></ng-content>
  </div>
</div>
