import { Injectable } from '@angular/core';
import {Observable, of, Subject, throwError} from 'rxjs';
import { PopupAction } from '../popup/interfaces/popup.dto';
import { DialogEvent } from './dialog-event';
import { DialogType } from './dialog-type';
import { TranslateService } from '@ngx-translate/core';
import { filter, map, mergeMap, take, tap } from 'rxjs/operators';
import { DialogOptions } from './dialog-options';
import { MobileAppService } from '../../mobile/mobile-app.service';

type windowTarget = '_blank' | '_parent' | '_self' | '_top';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  private dialog$ = new Subject<DialogEvent>();

  private dialogComplete$ = new Subject<PopupAction>();
  private onDialogDestoroy$: Subject<void> = new Subject<void>();

  constructor(
    private readonly translateService: TranslateService,
    private readonly mobileAppService: MobileAppService,
  ) {
  }

  get changes(): Observable<DialogEvent> {
    return this.dialog$.asObservable();
  }

  get onDestroyDialog(): Observable<void> {
    return this.onDialogDestoroy$.asObservable();
  }

  confirm(message: string, options?: DialogOptions): Observable<PopupAction> {
    this.dialog$.next({
      message,
      options,
      type: DialogType.CONFIRM
    });
    return this.dialogComplete$.asObservable().pipe(take(1));
  }

  confirmActionTranslate(key: string | Array<string>, keyConfirm = 'dialog.continue', interpolateParams?: Object): Observable<PopupAction> {
    return this.confirmTranslate(key, {
      buttons: [
        {
          key: 'dialog.cancel',
          style: 'button_flat button_base-size',
          action: PopupAction.DENY
        },
        {
          key: keyConfirm,
          style: 'button_primary button_base-size confirm-popup__button_no-margin',
          action: PopupAction.CONFIRM
        }
      ],
      showClose: false,
      theme: 'minimal'
    }, interpolateParams);
  }

  confirmTranslate(key: string | Array<string>, options?: DialogOptions, interpolateParams?: Object): Observable<PopupAction> {
    return this.translateService
      .get(key, interpolateParams)
      .pipe(
        mergeMap(message => this.confirm(message, options))
      );
  }

  dialogComplete(action: PopupAction): void {
    this.dialogComplete$.next(action);
  }

  message(message: string, buttonMessage?: string): Observable<PopupAction> {
    const actualButtonMessage = buttonMessage || 'common.popup.close';
    this.dialog$.next({
      message,
      options: {
        buttons: [
          {
            key: actualButtonMessage,
            style: 'button_primary button_base-size confirm-popup__button_no-margin',
            action: PopupAction.DENY
          },
        ]
      },
      type: DialogType.CONFIRM
    });
    return this.dialogComplete$.asObservable().pipe(take(1));
  }

  messageTranslate(key: string, interpolateParams?: Object, buttonMessage?: string): Observable<PopupAction> {
    return this.translateService
      .get(key, interpolateParams)
      .pipe(
        mergeMap(message => this.message(message, buttonMessage))
      );
  }

  downloadBlob(base64string: string, fileName: string) {
    const contentType = fileName.split('.').pop();
    const blob = this.b64toBlob(base64string,
      contentType === 'pdf' ? 'application/pdf' : contentType === 'xlsx' || contentType === 'xls' ? 'application/vnd.ms-excel' : null);
    if (this.mobileAppService.isMobileApp()) {
      this.mobileAppService.downloadBlob(blob, fileName, () => {}, (e) => throwError(e));
    } else {
      const objectUrl = URL.createObjectURL(blob);
      const blobLink: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
      blobLink.href = objectUrl;
      blobLink.download = fileName;
      document.body.appendChild(blobLink);
      blobLink.click();
      document.body.removeChild(blobLink);
      URL.revokeObjectURL(objectUrl);
    }
  }

  downloadUrl(urlToDownload: string | Observable<string>,
              codeFileName: string,
              messageKey = 'dialog.downloadWarning',
              interpolateParams?: Object,
              target: windowTarget = '_blank'): Observable<string | null> {
    let resultUrl: string;
    let fileName: string;
    return this.translateService.get(codeFileName)
      .pipe(
        mergeMap(translatedFileName => {
          fileName = translatedFileName;
          return typeof urlToDownload === 'string' ? of(urlToDownload) : urlToDownload;
        }),
        tap(url => resultUrl = url),
        mergeMap(() => this.tryDownloadLink(resultUrl, fileName)),
        mergeMap(success => {
          return success
            ? of(resultUrl)
            : this.openUrlFromDialog(resultUrl, target, messageKey, interpolateParams).pipe(
              mergeMap(res => {
                if (res === PopupAction.CONFIRM) {
                  return this.tryDownloadLink(resultUrl, fileName);
                } else {
                  return of(res);
                }
              }),
              map(() => resultUrl)
            );
        })
      );
  }

  openURL(urlToDownload: string | Observable<string>,
          messageKey = 'dialog.openURLWarning',
          interpolateParams?: Object,
          target: windowTarget = '_blank'): Observable<string | null> {
    return (typeof urlToDownload === 'string' ? of(urlToDownload) : urlToDownload)
      .pipe(
        filter(url => url != null),
        mergeMap(url => {
          return this.tryOpenLink(url, target)
            ? of(url)
            : this.openUrlFromDialog(url, target, messageKey, interpolateParams).pipe(
              tap(res => {
                if (res === PopupAction.CONFIRM) {
                  this.tryOpenLink(url, target);
                }
              }),
              map(() => url)
            );
        })
      );
  }

  openUrlFromDialog(urlToDownload: string,
                    target: windowTarget,
                    key: string | Array<string>,
                    interpolateParams: Object): Observable<PopupAction> {
    return this.confirmTranslate(key, {
      buttons: [
        {
          key: 'dialog.cancel',
          style: 'button_flat button_base-size',
          action: PopupAction.DENY
        },
        {
          key: 'dialog.continue',
          style: 'button_primary button_base-size confirm-popup__button_no-margin',
          action: PopupAction.CONFIRM
        }
      ],
      showClose: false,
      theme: 'minimal'
    }, interpolateParams);
  }

  destroyDialog() {
    this.onDialogDestoroy$.next();
  }

  private tryDownloadLink(url: string, fileName: string): Observable<boolean> {
    if (this.mobileAppService.isMobileApp()) {
      return this.mobileAppService.downloadFile(url, fileName);
    } else {
      return of(this.tryOpenLink(url)).pipe(take(1));
    }
  }

  private tryOpenLink(url: string, target: windowTarget = '_blank'): boolean {
    // TODO not correct working in IE (not return ref to window)
    if (this.mobileAppService.isMobileApp()) {
      this.mobileAppService.goToOtherSite(url);
      return true;
    }
    const newWin = window.open();
    newWin.location.assign(url);
    return !(!newWin || newWin.closed || typeof newWin.closed === 'undefined');
  }

  private b64toBlob (b64Data: string, fileType: string) {
    const byteArray = new Uint8Array(atob(b64Data).split('').map(char => char.charCodeAt(0)));
    return new Blob([byteArray], {type: fileType});
  }
}
