export function stringCompare(a: string, b: string): number {
  const firstString = a ? a.toLowerCase() : '';
  const secondString = b ? b.toLowerCase() : '';
  return firstString.localeCompare(secondString, 'ru');
}

export function baseCompare(a: any, b: any): number {
  return a === b ? 0
    : (a > b ? 1 : -1);
}
