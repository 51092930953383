import { animate, AnimationMetadata, state, style, transition, trigger } from '@angular/animations';

const ANIMATION_TIMINGS = `300ms ease-out`;

export const ERROR_NOTIFICATION_ANIMATIONS: AnimationMetadata[] = [
  trigger(
    'isHidden',
    [
      state('true', style({ height: '0' })),
      state('false', style({ height: '*' })),
      transition('* => *', animate(ANIMATION_TIMINGS))
    ]
  )
];
