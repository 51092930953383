import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractQuestionOption } from '../abstract-question-option';
import { IAutocompleteItem } from '../../../autocomplete/autocomplete.component';
import { ProfOptionAnswerValueSchema, ProfQuestionTypeEnumSchema } from '@rsmu/portal-api';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'app-search-select-question',
  templateUrl: './search-select-question.component.html',
  styleUrls: ['./search-select-question.component.scss']
})
export class SearchSelectQuestionComponent extends AbstractQuestionOption implements OnInit, OnDestroy {

  displayAnswers = [];
  optionsFilter: (items: IAutocompleteItem[], value: string) => IAutocompleteItem[];
  updateSubject = new ReplaySubject<void>(1);

  constructor() {
    super();
  }

  ngOnInit() {
    this.initDisplayAnswers();
    this.optionsFilter = (options: IAutocompleteItem[], currentOption: string) => {
      return options.filter(option => !this.answer.selectedOptions.some(selectedOption => {
        return selectedOption === option.value && selectedOption.title !== currentOption;
      }));
    };
  }

  get isShowDelete(): boolean {
    return this.questionType === ProfQuestionTypeEnumSchema.MultipleEntity ||
           this.questionType === ProfQuestionTypeEnumSchema.MultipleCustom;
  }

  ngOnDestroy(): void {
    this.updateSubject.complete();
  }

  trackByIndex(index) {
    return index;
  }

  answerChanged(newAnswer, index: number) {
    this.answer.selectedOptions[index] = newAnswer;
    this.displayAnswers[index] = newAnswer;
    this.updateSubject.next();
  }

  addNewAnswerOption() {
    this.displayAnswers.push({} as ProfOptionAnswerValueSchema);
  }

  initDisplayAnswers() {
    if (this.answer.selectedOptions.length === 0) {
      this.addNewAnswerOption();
    } else {
      this.answer.selectedOptions.forEach((option) => this.displayAnswers.push(option));
    }
  }

  deleteAutocomplete(index: number): void {
    this.answer.selectedOptions.splice(index, 1);
    this.displayAnswers.splice(index, 1);
  }
}
