<div class="questionary-rewards">
  <div class="questionary-rewards__accrued-zet">
    <div *ngIf="showZet()">
      <svg-icon class="icon icon_188_w icon_gift-open-luminous"
                [title]="'questionary.rewards.icon-title' | translate : {count: testRewards}"
                src="assets/icons/gift-open-luminous.svg"></svg-icon>
      <p class="text text_h3 text_accent text_bold questionary-rewards__tablet-thanks">
        {{ 'questionary.thanks' | translate }}
      </p>
      <p class="text text_big text_bold text_accent questionary-rewards__you-accrued">
        {{ 'questionary.rewards.youAccrued' | translate }}
      </p>
      <div class="questionary-rewards__accrued-zet-container">
        <div class="questionary-rewards__star-icon-left">
          <svg-icon class="icon icon_16 icon_star" src="assets/icons/star.svg"></svg-icon>
        </div>
        <div class="questionary-rewards__zet">
          <span class="text text_h1 text_accent questionary-rewards__zet-count">
            {{ testRewards }} {{ 'questionary.rewards.zet' | translate }}
          </span>
        </div>
        <div class="questionary-rewards__star-icon-right">
          <svg-icon class="icon icon_16 icon_star" src="assets/icons/star.svg"></svg-icon>
        </div>
      </div>
    </div>
    <div *ngIf="testType === 'satisfaction'" class="questionary-rewards__feedback-thanks">
      <span class="text text_h1 text_accent text_bold questionary-rewards__feedback-thanks-text">
        {{ 'questionary.thanksForFeedback' | translate }}
      </span>
    </div>
    <p
      *ngIf="showThanksText || testIsCompleted"
      class="text text_h2 questionary-rewards__thanks"
      translate="questionary.rewards.{{testType}}.thankYouForCompleting"></p>
    <div *ngIf="testType === 'professional'" class="text text_big" [innerHtml]="'questionary.rewards.topics' | translate"></div>
  </div>
  <div class="questionary-rewards__buttons-container">
    <div class="questionary-rewards__first-self-control" *ngIf="selfControlBtn && testType !== 'satisfaction' && getShowSelfControl">
      <p class="text questionary-rewards__first-self-control-recomendation">
        {{ 'questionary.rewards.morePersonalizedRecommendations' | translate }}
      </p>
      <button class="button button_warn questionary-rewards__btn-self-control" (click)="redirectToSelfControl()">
        <svg-icon class="icon icon_18 questionary-rewards__btn-self-control-icon"
                  src="assets/icons/gift.svg"></svg-icon>
        <span class="questionary-rewards__btn-text text text_contrast text_big">
          {{ 'questionary.rewards.levelOneSelfControl' | translate }}
        </span>
      </button>
    </div>
    <div class="questionary-rewards__end-btn"
         [class.questionary-rewards__end-btn_center]="!getShowSelfControl">
      <button class="button button_text button_primary questionary-rewards__finish-link"
              (click)="back()">
        <span class="questionary-rewards__finish-link-label text text_bold text_big">
          {{ 'questionary.rewards.complete' | translate }}
        </span>
      </button>
    </div>
  </div>
</div>
