import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutocompleteComponent } from './autocomplete.component';
import { FormsModule } from '@angular/forms';
import { OverlayModule } from '@angular/cdk/overlay';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AutocompleteFocusedDirective } from './autocomplete-focused.directive';
import { TrapFocusModule } from '../trap-focus/trap-focus.module';
import { AutocompleteListComponent } from './autocomplete-list/autocomplete-list.component';
import { LoaderModule } from '../loader/loader.module';

@NgModule({
  imports: [
    AngularSvgIconModule,
    CommonModule,
    FormsModule,
    OverlayModule,
    TranslateModule,
    TrapFocusModule,
    LoaderModule,
  ],
  declarations: [
    AutocompleteComponent,
    AutocompleteFocusedDirective,
    AutocompleteListComponent,
  ],
  exports: [
    AutocompleteComponent
  ]
})
export class AutocompleteModule {
}
