import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import _isNil from 'lodash-es/isNil';
import _range from 'lodash-es/range';

const DEFAULT_PAGE_SIZE = 10;

export type PaginationType = 'page' | 'offset';

export interface IPageChangeEvent {
  pageIndex?: number;
  pageSize?: number;
}

export class PageChangeEvent implements IPageChangeEvent {
  readonly pageIndex;
  readonly pageSize;

  constructor({
                pageIndex = 0,
                pageSize = DEFAULT_PAGE_SIZE
              }: IPageChangeEvent = {}) {
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;
  }

  get limit(): number {
    return this.pageSize;
  }

  get offset(): number {
    return this.pageSize * this.pageIndex;
  }
}

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: [ './pagination.component.scss' ]
})
export class PaginationComponent implements OnChanges, OnDestroy, OnInit {

  @Input() length = 0;
  @Input() pageIndex = 0;
  @Input() pageIndexOptionsCount = 5;
  @Input() pageSize = 0;
  @Input() pageSizeOptions: number[] = [];
  @Input() paginationType: PaginationType = 'page';
  @Input() stopOffsetSearch = false;

  @Output() readonly pageChange = new EventEmitter<PageChangeEvent>();

  get canGoToNextPage(): boolean {
    switch (this.paginationType) {
      case 'page':
        return this.hasMorePagesToRight;
      case 'offset':
        return !this.stopOffsetSearch;
      default:
        return false;
    }
  }

  get canGoToPreviousPage(): boolean {
    switch (this.paginationType) {
      case 'page':
        return this.hasMorePagesToLeft;
      case 'offset':
        return this.pageIndex > 0;
      default:
        return false;
    }
  }

  get hasMorePagesToLeft(): boolean {
    return this.displayedPageIndicesOptions.length > 0 &&
      this.displayedPageIndicesOptions[0] > 0;
  }

  get hasMorePagesToRight(): boolean {
    return this.displayedPageIndicesOptions.length > 0 &&
      this.displayedPageIndicesOptions[this.displayedPageIndicesOptions.length - 1] < this.totalPagesCount - 1;
  }

  get totalPagesCount(): number {
    return !this.length ? 0 : Math.ceil(this.length / this.pageSize);
  }

  displayedPageIndicesOptions: number[] = [];
  displayedPageSizeOptions: number[] = [];

  // FIXME (komarov@haulmont.com) hack to handle undefined order of 'pageIndex' and 'paginationType' @Input setters call
  private _lastPageIndexSetting = 0;

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!_isNil(changes.paginationType)) {
      const value: PaginationType = changes.paginationType.currentValue;

      this.paginationType = value || 'page';
      this.changePageIndex(this._lastPageIndexSetting);
    }
    if (!_isNil(changes.length)) {
      const value: number = changes.length.currentValue;

      this.length = Math.max(value, 0);
      this.updateDisplayedPageIndices();
    }
    if (!_isNil(changes.pageIndex)) {
      const value: number = changes.pageIndex.currentValue;

      this.changePageIndex(value);
    }
    if (!_isNil(changes.pageIndexOptionsCount)) {
      const value: number = changes.pageIndexOptionsCount.currentValue;

      this.pageIndexOptionsCount = Math.max(value, 5);
      this.updateDisplayedPageIndices();
    }
    if (!_isNil(changes.pageSize)) {
      const value: number = changes.pageSize.currentValue;

      this.changePageSize(value);
    }
    if (!_isNil(changes.pageSizeOptions)) {
      this.pageSizeOptions = (changes.pageSizeOptions.currentValue || []).slice();
      this.updateDisplayedPageSizeOptions();
    }
  }

  ngOnDestroy(): void {
  }

  ngOnInit() {
  }

  changePageIndexAndEmit(pageIndex: number) {
    this.changePageIndex(pageIndex);
    this.emitPageEvent();
  }

  changePageSizeAndEmit(pageSize: number) {
    this.changePageSize(pageSize);
    this.changePageIndex(0);
    this.emitPageEvent();
  }

  goToNextPage() {
    this.changePageIndexAndEmit(this.pageIndex + 1);
  }

  goToPreviousPage() {
    this.changePageIndexAndEmit(this.pageIndex - 1);
  }

  private changePageIndex(pageIndex: number) {
    this._lastPageIndexSetting = pageIndex;
    this.pageIndex = Math.max(pageIndex, 0);
    this.updateDisplayedPageIndices();
  }

  private changePageSize(pageSize: number) {
    this.pageSize = Math.max(pageSize, 0);
    this.updateDisplayedPageSizeOptions();
  }

  private emitPageEvent() {
    const pageChangeEvent = new PageChangeEvent({
      pageIndex: this.pageIndex,
      pageSize: this.pageSize
    });

    this.pageChange.emit(pageChangeEvent);
  }

  private updateDisplayedPageIndices() {
    if (this.paginationType === 'offset') {
      this.displayedPageIndicesOptions = [];
      this.pageIndex = this._lastPageIndexSetting;
    } else {
      if (this.totalPagesCount <= 0) {
        this.pageIndex = 0;
      } else if (this.pageIndex > this.totalPagesCount - 1) {
        this.pageIndex = this.totalPagesCount - 1;
      }

      this.displayedPageIndicesOptions = _range(
        this.pageIndex - this.pageIndex % this.pageIndexOptionsCount,
        this.pageIndex - this.pageIndex % this.pageIndexOptionsCount + this.pageIndexOptionsCount
      );
    }
  }

  private updateDisplayedPageSizeOptions() {
    if (!this.pageSize) {
      this.pageSize = this.pageSizeOptions.length !== 0
        ? this.pageSizeOptions[0]
        : DEFAULT_PAGE_SIZE;
    }

    this.displayedPageSizeOptions = this.pageSizeOptions.slice();

    if (this.displayedPageSizeOptions.indexOf(this.pageSize) === -1) {
      this.displayedPageSizeOptions.push(this.pageSize);
    }

    this.displayedPageSizeOptions.sort((a: number, b: number) => a - b);
  }

}
