import { Injectable, NgZone, OnDestroy } from '@angular/core';
import {
  RawParams,
  StateDeclaration,
  StateObject,
  StateOrName,
  StateParams,
  TransitionHookFn,
  TransitionOptions,
  Transition,
  TransitionPromise,
  UIRouter
} from '@uirouter/core';
import _keys from 'lodash-es/keys';
import { processSelfUrlMobile } from '../mobile/mobile-utils';
import { RouteState } from '../global-dto/routeState';
import { deepCopy } from '../utils/copy';
import { GlobalLoadingService } from '../global-loading/global-loading.service';

export type FilterOrStateName = ((value: StateDeclaration) => boolean) | StateOrName;

@Injectable()
export class RouteService implements OnDestroy {
  private static readonly FUTURE_STATE_SUFFIX = '.**';

  private history: RouteState[] = [];
  private fromState: StateDeclaration;

  private readonly historyBackItemCount = 2;

  private _isShowBackButton = true;
  private _requiresAuth = false;
  private successTransitionFn: Function;
  private startTransitionFn: Function;

  constructor(
    private router: UIRouter,
    private ngZone: NgZone,
    private globalLoadingService: GlobalLoadingService,
  ) {
    this.registerOnSuccessTransitionCallback((transition: Transition) => {
      const state = deepCopy(this.getCurrentState());
      this.history.push(state);

      this._requiresAuth = transition.to().data && transition.to().data.requiresAuth === true;
    });

    this.startTransitionFn = this.router.transitionService.onStart(null, () => {
      this.fromState = this.router.stateService.current;
    });
  }

  ngOnDestroy(): void {
    this.successTransitionFn();
    this.startTransitionFn();
  }

  get isShowBackButton(): boolean {
    return this._isShowBackButton && this.history.length >= this.historyBackItemCount;
  }

  set isShowBackButton(newValue: boolean) {
    setTimeout(() => this._isShowBackButton = newValue);
  }

  get requiresAuth(): boolean {
    return this._requiresAuth;
  }

  goBack(): void {
    if (this.history.length >= this.historyBackItemCount) {
      const state = this.history[this.history.length - this.historyBackItemCount];
      this.history.splice(this.history.length - this.historyBackItemCount, this.historyBackItemCount);
      this.goToState(state);
    } else {
      this.history = [];
      history.back();
    }
  }

  go(filterOrStateName: FilterOrStateName, params?: RawParams, options?: TransitionOptions): Promise<StateObject> {
    const state = this.findOrGetState(filterOrStateName);
    return this.stateServiceGo(state, params, options);
  }

  goToState(routeState: RouteState): TransitionPromise | Promise<StateObject> {
    let stateToNavigate: StateOrName = this.findState((state) => state.name === routeState.name);
    if (stateToNavigate) {
      return this.stateServiceGo(stateToNavigate, routeState.params);
    } else {
      routeState.path.split('.').find(pathPart => {
        stateToNavigate = this.findState((state) =>
          state.name.endsWith(RouteService.FUTURE_STATE_SUFFIX) &&
          state.name.includes(pathPart)
        );
        return stateToNavigate != null;
      });
      return this.stateServiceGo(stateToNavigate).then(() => {
        return this.stateServiceGo(routeState.name, routeState.params);
      });
    }
  }

  clear(): void {
    this.history = [];
  }

  removeStateByName(stateName: string): void {
    this.history = this.history.filter((state: RouteState) => state.name !== stateName);
  }

  getStateUrl(filterOrStateName: FilterOrStateName, params?: RawParams): string {
    const state = this.findOrGetState(filterOrStateName);
    const actualParams = params || {};
    const url = this.router.stateService.href(state, actualParams, {absolute: true});
    return processSelfUrlMobile(url);
  }

  getCurrentState(): RouteState {
    const state: StateObject = this.router.stateService.$current;
    const params: StateParams = this.router.stateService.params;
    return {
      name: state.name,
      path: state.toString(),
      params: params,
    };
  }

  registerOnSuccessTransitionCallback(callback: TransitionHookFn): Function {
    return this.successTransitionFn = this.router.transitionService.onSuccess(null, callback);
  }

  getCurrentStateData() {
    return this.router.stateService.current.data;
  }

  findState(filterFn: (value: StateDeclaration) => boolean): StateOrName {
    let state: StateOrName = this.router.stateRegistry.get().find(filterFn);
    if (state && state.name.endsWith(RouteService.FUTURE_STATE_SUFFIX)) {
      state = state.name.substring(0, state.name.length - 3);
    }
    return state;
  }

  stateServiceGo(state: StateOrName,
                 params?: RawParams,
                 options?: TransitionOptions): Promise<StateObject> {
    this.globalLoadingService.startLoading();
    return this.router.stateService
      .go(state, params, options)
      .catch(() => {
        this.ngZone.run(() => {
          this.globalLoadingService.stopLoading();
        });
        return null;
      })
      .then(transactionState => {
        this.ngZone.run(() => {
          this.globalLoadingService.stopLoading();
        });
        return transactionState;
      });
  }

  replace(newState: RouteState) {
    const previousState: RouteState = this.getCurrentState();
    this.router
      .stateService
      .go(newState.name, newState.params, {location: 'replace'})
      .then(() => {
        if (!this.isSameState(previousState, newState)) {
          this.history.pop();
        }
      });
  }

  getFromState(): StateDeclaration {
    return this.fromState;
  }

  private findOrGetState(filterOrStateName: FilterOrStateName): StateOrName {
    return (filterOrStateName instanceof Function) ? this.findState(filterOrStateName) : filterOrStateName;
  }

  private isSameState(firstState: RouteState, secondState: RouteState): boolean {
    const isSameParams: Function = (firstParams: StateParams, secondParams: StateParams): boolean => {
      return firstState
        && secondState
        && _keys(firstParams)
        .every((key: string) => firstParams[key] === secondParams[key]);
    };

    return firstState.name === secondState.name && isSameParams(firstState.params, secondState.params);
  }
}
