<div class="questionary-container">
  <app-questionary-progress-bar
    class="questionary-container__progress-bar-container"
    [percentageOfCompletion]="getPercentageOfCompletion">
  </app-questionary-progress-bar>
  <div *ngIf="isErrorSend" class="text text_bold text_big questionary-container__error">
    {{ errorSendCode | translate }}
  </div>
  <div class="questionary-container__corner-button">
    <button class="button button_flat" (click)="switchInstructionsDisplay()">
      <span *ngIf="activePhase === questionaryPhase.INSTRUCTIONS" class="figure figure_close"></span>
      <span *ngIf="activePhase !== questionaryPhase.INSTRUCTIONS" class="figure figure_help"></span>
    </button>
  </div>
  <div class="centered centered_absolute">
    <app-loader
      *ngIf="loading"
      [size]="70"
      [showLabelKey]="isShowLabelCyclesFormation"
      [labelKey]="isShowLabelCyclesFormation && 'questionary.professional.cyclesFormation'"
      [strokeWidth]="5">
    </app-loader>
  </div>
  <div class="questionary-container__main-content">
    <app-questionary-instructions
      *ngIf="activePhase === questionaryPhase.INSTRUCTIONS && !loading">
    </app-questionary-instructions>
    <div *ngIf="activePhase === questionaryPhase.QUESTIONS && !loading">
      <div
        class="questionary-container__timer"
        [class.questionary-container__timer_msg]="timeIsOver"
        *ngIf="showTimer">
        <app-timer
          *ngIf="!timeIsOver"
          [initialTime]="initialTime"
          (timeIsOverEvent)="timeIsOverEvent()">
        </app-timer>
        <span *ngIf="timeIsOver" class="text text_bold text_alert text_big">
          {{ 'questionary.selfControl.timeIsOver' | translate }}
        </span>
      </div>
      <app-prof-questionary-questions-container>
      </app-prof-questionary-questions-container>
    </div>
    <app-questionary-rewards
      [disabled]="isReadonly"
      [showRewards]="showRewards"
      [selfControlBtn]="true"
      [firstTime]="firstTime"
      [testRewards]="testRewards"
      [dryRun]="dryRun"
      *ngIf="activePhase === questionaryPhase.REWARDS && !loading && !isErrorSend">
    </app-questionary-rewards>
  </div>
</div>
