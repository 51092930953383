import {Component} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UIRouter } from '@uirouter/core';
import { Transition } from '@uirouter/angular';
import { IdpService } from './idp/idp.service';
import { SecurityService } from './security/security.service';
import { ConnectionService } from './connection/connection.service';
import { Logger } from './app-commons/logger/logger.service';
import { environment } from '../environments/environment';
import localeIn from '@angular/common/locales/ru';
import {registerLocaleData} from '@angular/common';
import {IconsService} from './icons.service';
import {MobileAppService} from './mobile/mobile-app.service';
@Component({
  // tslint:disable-next-line
  selector: 'rsmu-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(
    private readonly translate: TranslateService,
    private readonly iconsService: IconsService,
    idpService: IdpService,
    private readonly mobileAppService: MobileAppService,
    private readonly securityService: SecurityService,
    private readonly connectionService: ConnectionService,
    private readonly router: UIRouter) {
    registerLocaleData(localeIn);

    const languages: string[] = ['ru']
      .map(language => language + (environment.gitHash ? ('.' + environment.gitHash) : ''));
    translate.addLangs(languages);
    translate.setDefaultLang(languages[0]);

    this.connectionService.startObservingConnection();

    const unsubscribe = router.transitionService.onBefore({}, (transition: Transition) => {
      if (
        !idpService.hasIdpTicket() &&
        this.securityService.isLoggedIn() &&
        transition.to().data &&
        transition.to().data.requiresAuth &&
        idpService.isIdpActive()
      ) {
        idpService.checkIdpSession().subscribe();
      }
      unsubscribe();
    });


    if (environment.production) {
      Logger.enableProductionMode();
    }

    this.iconsService.registerIcons();

    if (this.mobileAppService.isMobileApp()) {
      this.mobileAppService.checkMobileAppVersion();
    }
  }
}
