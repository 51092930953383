import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ManagedComponent } from '../app-commons/managed-component';
import _get from 'lodash-es/get';
import _isString from 'lodash-es/isString';
import { StateService } from '@uirouter/core';

type RegistrationResult = 'tokenExpired' | 'userExists' | 'internalError' | 'alreadyConfirmed' | 'success';

const registrationErrorsURLParamsMap = new Map<string, RegistrationResult>([
  ['TOKEN_EXPIRE', 'tokenExpired'],
  ['ALREADY_CONFIRMED', 'alreadyConfirmed'],
  ['USER_EXISTS', 'userExists'],
  ['INTERNAL_ERROR', 'internalError'],
  ['success', 'success'],
]);

@Component({
  selector: 'app-registration-result',
  templateUrl: './registration-result.component.html',
  styleUrls: ['./registration-result.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RegistrationResultComponent extends ManagedComponent implements OnInit {

  get backKey(): string {
    return this.isTokenExpired ? 'backToRegistration' : 'back';
  }

  get isTokenExpired(): boolean {
    return this.result === 'tokenExpired';
  }

  get result(): RegistrationResult {
    return this._result;
  }

  private _result: RegistrationResult = 'internalError';

  constructor(private readonly stateService: StateService) {
    super();
  }

  ngOnInit(): void {
    this.initializeResult();
  }

  back(): void {
    this.stateService.go(this.isTokenExpired ? 'root.registration' : 'root.login');
  }

  private initializeResult(): void {
    const errorKey = _get(this.stateService.params, 'type', null);

    if (_isString(errorKey) && registrationErrorsURLParamsMap.has(errorKey)) {
      this._result = registrationErrorsURLParamsMap.get(errorKey);
    }
  }

}
