<div class="prof-questionary-question">
  <h2 class="text text_bold text_h3 prof-questionary-question__mobile-title"
      [class.prof-questionary-question__mobile-title_self-control]="isSelfControl">
    {{ questionaryKeyTitle | translate }}
  </h2>
  <span
    *ngIf="activeQuestionIndex === 0 && !isReadonly"
    class="text text_muted-light text_small prof-questionary-question__mobile-subtitle">
    {{ 'questionary.' + questionaryType + '.pleaseAnswer' | translate }}
  </span>
  <app-questionary-progress-bar
    *ngIf="percentageOfCompletion || percentageOfCompletion === 0"
    class="prof-questionary-question__progress-bar-container-mobile"
    [percentageOfCompletion]="percentageOfCompletion">
  </app-questionary-progress-bar>

  <div class="prof-questionary-question__body">
    <div class="prof-questionary-question__mobile-tablet-description" *ngIf="isFirstTab">
      <h3 class="text text_big text_bold prof-questionary-question__mobile-tablet-description-title">
        {{ 'questionary.specialty' | translate }}
      </h3>
      <div class="text">{{ questionary.title }}</div>
    </div>

    <div class="text text_muted text_h2 text_light prof-questionary-question__count">
      {{ activeQuestionIndex + 1 }} / {{ numberOfQuestions }}
    </div>

    <h3 *ngIf="!isSelfControl" class="prof-questionary-question__title text text_h3">{{ activeQuestion.title }}</h3>
    <h3 *ngIf="isSelfControl"
        class="prof-questionary-question__title text text_h3"
        [innerHtml]="activeQuestion.title | sanitizeBypass">
    </h3>
    <div class="text text_primary text_big prof-questionary-question__note"
         *ngIf="!isReadonly">
      {{ 'questionary.note.' + activeQuestion.questionType | translate }}
    </div>

    <div class="prof-questionary-question__options">
      <app-question-options
        [disabled]="isReadonly || timeIsOver"
        [isSelfControl]="isSelfControl"
        [questionIndex]="activeQuestionIndex"
        [(answer)]="activeQuestionAnswer"
        [options]="activeQuestion.optionAnswers.values"
        [questionType]="activeQuestion.questionType">
      </app-question-options>
    </div>

    <div class="prof-questionary-question__attachment" *ngIf="activeQuestion.allowFileAttachment">
      <label
        class="prof-questionary-question__attachment_attach text_primary"
        tabindex="0"
        (keypress)="inputFile.click()"
        *ngIf="!attachedFile && !isReadonly"
      >
        {{ 'questionary.addFile' | translate }}

        <input
          class="prof-questionary-question__attachment_attach_input"
          #inputFile
          type="file"
          name="file"
          accept="image/jpeg,image/png,application/pdf,application/msword"
          [formControl]="attachFileControl"
          (change)="readAttachedFile($event)"
        >
      </label>

      <div class="prof-questionary-question__attachment_attached" *ngIf="attachedFile">
        <a
          class="link link_normal prof-questionary-question__attachment_attached_download"
          [href]="downloadLink"
          [download]="attachedFile.userFile.name"
        >
          {{ attachedFile.userFile.name }}
        </a>

        <button
          type="button"
          *ngIf="!isReadonly"
          class="button button_text button_text_no-underline prof-questionary-question__attachment_attached_delete"
          (click)="deleteAttachedFile()"
        >
          <svg-icon class="icon icon_16 icon_delete" role="presentation" src="assets/icons/delete-icon.svg"></svg-icon>
        </button>
      </div>
      <app-loader *ngIf="loadingFile" [size]="20" [strokeWidth]="10" [showLabelKey]="false"></app-loader>
    </div>
  </div>

  <div class="prof-questionary-question__navigation">
    <button
      (click)='previousQuestion()'
      class="button button_text button_primary text prof-questionary-question__previous-btn"
      [disabled]='activeQuestionIndex === 0 || timeIsOver'>
      <span class="prof-questionary-question__tablet-desktop-text">
        &lt;
      </span>
      {{ 'questionary.navigation.previous' | translate }}
    </button>
    <button
      class="button button_primary button_raised text prof-questionary-question__next-btn"
      (click)='nextQuestion()'
      [disabled]='getDisabledStatus'
    >
      {{ activeQuestionIndex !== (numberOfQuestions - 1) ? ('questionary.navigation.next' | translate) : ('questionary.navigation.finishSelfControl' | translate ) }}
      <span class="prof-questionary-question__tablet-desktop-text">
        &gt;
      </span>
    </button>
    <button
      *ngIf="timeIsOver"
      class="button button_primary button_raised text prof-questionary-question__finish-self-control-btn"
      (click)="finishSelfControl()">
      {{ 'questionary.navigation.finishSelfControl' | translate }}
      &#10003;
    </button>
  </div>
</div>
