import { Injectable } from '@angular/core';
import { Observable, of, ReplaySubject, Subject } from 'rxjs';
import { map, mergeMap, take, takeUntil, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';

import { ApiService } from '../api/api.service';
import { MobileAppService } from '../mobile/mobile-app.service';
import { StateOrName } from '@uirouter/core';

import { IdpService } from '../idp/idp.service';
import { EducationalActivityTypeEnumSchema, PersonalCabinetResponseSchema } from '@rsmu/portal-api';

export interface CabinetRedirect {
  url: string | null;
  state: StateOrName | null;
  isSpecialist?: boolean;
}

export interface SpecialistCabinetsSettings {
  hasVo: boolean;
  hasSpo: boolean;
  otherCabinetUrl?: string;
}

@Injectable({
  providedIn: 'root'
})
export class CabinetsService {

  private cabinets: ReplaySubject<PersonalCabinetResponseSchema[]>;
  private currentCabinet: PersonalCabinetResponseSchema;
  private cabinetsSubject = new ReplaySubject<Array<PersonalCabinetResponseSchema>>(1);
  private firstDisplay = true;

  private changeCabinets$ = new Subject<void>();

  private readonly environment = environment;
  private readonly noCabinetsState = 'userInit';
  private readonly useRedirect = this.environment.useRedirect;

  constructor(private readonly apiService: ApiService,
              private readonly idpService: IdpService,
              private readonly mobileAppService: MobileAppService) {}

  convertCabinetUrlForNavigation(cabinetUrl: string, isSpecialist: boolean): string {
    let url = cabinetUrl;
    if (isSpecialist) {
      if (this.mobileAppService.isMobileApp()) {
        url = this.mobileAppService.getRedirectCabinetMobileLink(cabinetUrl);
      }
    } else if (this.idpService.isIdpActive()) {
      url = `${this.environment.baseIdpUrl}?sp=${encodeURI(cabinetUrl)}`;
    }
    return url;
  }

  // Nullable. Null if we are already on the correct cabinet
  getRedirectUrlForPriorityCabinet(): Observable<CabinetRedirect | null> {
    if (!this.useRedirect) {
      return of(null);
    }
    return this.getUserCabinets().pipe(map((cabinetsArray: Array<PersonalCabinetResponseSchema>) => {
      const hasCabinets = cabinetsArray && cabinetsArray[0];
      const redirect: CabinetRedirect = {
        state: this.noCabinetsState,
        url: null
      };

      if (hasCabinets) {
        Object.assign(redirect, {
          state: null,
          url: cabinetsArray[0].cabinetUrl,
          isSpecialist: cabinetsArray[0].isSpecialist
        } as CabinetRedirect);

        const currentCabinet = this.findCurrentCabinet(cabinetsArray);
        if (currentCabinet) {
          return null;
        }
      }
      return redirect;
    }));
  }

  getCurrentCabinet(): Observable<PersonalCabinetResponseSchema> {
    if (this.currentCabinet) {
      return of(this.currentCabinet);
    } else {
      return this.getUserCabinets().pipe(map((cabinets) => {
        this.currentCabinet = this.findCurrentCabinet(cabinets);
        return this.currentCabinet || cabinets[0];
      }));
    }
  }

  getUserCabinets(): Observable<Array<PersonalCabinetResponseSchema>> {
    if (!this.cabinets) {
      this.cabinets = new ReplaySubject(1);
      this.loadCabinets().subscribe(null, error => {
        if (this.cabinets) {
          this.cabinets.error(error);
          this.cabinets.complete();
          this.cabinets = null;
        }
      });
    }
    return this.cabinets.asObservable().pipe(take(1));
  }

  getSpecialistCabinetsSettings(): Observable<SpecialistCabinetsSettings> {
    return this.subscribeToCabinetUpdates().pipe(map(
      (cabinets: Array<PersonalCabinetResponseSchema>) => {
        const settings: SpecialistCabinetsSettings = {
          hasVo: false,
          hasSpo: false,
        };
        cabinets.forEach((cabinet: PersonalCabinetResponseSchema) => {
          if (cabinet.isSpecialist) {
            if (cabinet.cabinetType === EducationalActivityTypeEnumSchema.HigherEducation) {
              settings.hasVo = true;
              settings.otherCabinetUrl = cabinet.cabinetUrl;
            } else if (cabinet.cabinetType === EducationalActivityTypeEnumSchema.ProfessionalEducation) {
              settings.hasSpo = true;
              settings.otherCabinetUrl = cabinet.cabinetUrl;
            }
          }
        });
        return settings;
      })
    );
  }

  clearCabinets() {
    if (this.cabinets != null) {
      this.cabinets.complete();
      this.cabinets = null;
      this.currentCabinet = null;
      this.firstDisplay = true;
    }
  }

  isFirstDisplay() {
    return this.firstDisplay;
  }

  cabinetsDisplayed() {
    this.firstDisplay = false;
  }

  subscribeToCabinetUpdates(): Observable<Array<PersonalCabinetResponseSchema>> {
    return this.cabinetsSubject;
  }

  setCabinets(cabinets: EducationalActivityTypeEnumSchema[]): Observable<any> {
    this.changeCabinets$.next();
    return this.apiService.setUserEducationalActivityTypes(cabinets)
      .pipe(
        mergeMap(() => {
          this.clearCabinets();
          return this.getUserCabinets();
        }),
        takeUntil(this.changeCabinets$.asObservable()),
        tap(null, () => this.handleErrorSetCabinets())
      );
  }

  private handleErrorSetCabinets(): void {
    this.clearCabinets();
    this.getUserCabinets().subscribe();
  }

  private loadCabinets(): Observable<PersonalCabinetResponseSchema[]> {
    return this.apiService.getPersonalCabinets()
      .pipe(
        map((res: PersonalCabinetResponseSchema[]) => {
          return this.mobileAppService.isMobileApp() ? res.filter(cabinet => cabinet.isSpecialist) : res;
        }),
        tap((res: PersonalCabinetResponseSchema[]) => {
          console.log('Received cabinets: ' + JSON.stringify(res));
          this.cabinets.next(res);
          this.cabinetsSubject.next(res);
        })
      );
  }

  getCurrentCabinetInstanceType(): string {
    return this.environment.cabinetInstanceType;
  }

  private findCurrentCabinet(cabinets: Array<PersonalCabinetResponseSchema>): PersonalCabinetResponseSchema | null {
    let foundCabinet: PersonalCabinetResponseSchema = null;
    const specialistCabinets = cabinets ? cabinets.filter((cabinet) => cabinet.isSpecialist) : [];
    if (specialistCabinets.length > 0) {
      switch (this.environment.cabinetInstanceType) {
        case 'HigherEducation':
          foundCabinet = specialistCabinets.find(cabinet => cabinet.cabinetType === 'higherEducation');
          break;
        case 'nonMedEducation':
          foundCabinet = specialistCabinets.find(cabinet => cabinet.cabinetType === 'nonMedEducation');
          break;
        case 'ProfessionalEducation':
          foundCabinet = specialistCabinets.find(cabinet => cabinet.cabinetType === 'professionalEducation');
          break;
        default:
          foundCabinet = null;
      }
    }
    return foundCabinet;
  }
}
