import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { hmrBootstrap } from './hmr';
import { initSentry } from './app/sentry/sentrySetup';

declare global {
  interface Window {
    checkUpdateApp?: (linkGoogleStore: string, linkAppleSore: string, restApiUrl: string) => Promise<string>;
  }
}

if (environment.production) {
  enableProdMode();
}

initSentry();

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

function initApp() {
  if (environment.hmr) {
    if (module[ 'hot' ]) {
      hmrBootstrap(module, bootstrap);
    } else {
      console.error('HMR is not enabled for webpack-dev-server!');
      console.log('Are you using the --hmr flag for ng serve?');
    }
  } else {
    bootstrap().catch(err => console.log(err));
  }
}

if (window.checkUpdateApp && environment.linkAppleSore && environment.linkGoogleStore) {
  window.checkUpdateApp(environment.linkGoogleStore, environment.linkAppleSore, environment.baseRestApiUrl)
    .then(val => initApp());
} else {
  initApp();
}


