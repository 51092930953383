import { Injectable } from '@angular/core';
import { ErrorNotificationService } from '../app-commons/error-notification/service/error-notification.service';
import { fromEvent, merge, of, ReplaySubject, Subject } from 'rxjs';
import { distinctUntilChanged, mapTo } from 'rxjs/operators';
import { ErrorNotification, ErrorNotificationPriority } from '../app-commons/error-notification/error-notification-dto';

@Injectable({
  providedIn: 'root'
})
export class ConnectionService {

  private readonly ERROR_NOTIFICATION: ErrorNotification = {
    messageKey: 'server.noConnection.message',
    priority: ErrorNotificationPriority.NO_CONNECTION
  };

  private _haveConnection$ = new ReplaySubject<boolean>(1);
  private lastConnectionState = true;

  constructor(private errorNotificationService: ErrorNotificationService) { }

  startObservingConnection() {
    merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(mapTo(true)),
      fromEvent(window, 'offline').pipe(mapTo(false))
    ).pipe(distinctUntilChanged()).subscribe((haveConnection) => {
      this._haveConnection$.next(haveConnection);
      this.lastConnectionState = haveConnection;
      if (haveConnection) {
        this.errorNotificationService.hideNotification();
      } else {
        this.errorNotificationService.displayNotification(this.ERROR_NOTIFICATION);
      }
    });
  }

  subscribeToConnection(): Subject<boolean> {
    return this._haveConnection$;
  }

  getLastConnectionState(): boolean {
    return this.lastConnectionState;
  }
}
