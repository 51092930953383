import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccessibleLinkDirective } from './accessible-link.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    AccessibleLinkDirective
  ],
  exports: [
    AccessibleLinkDirective
  ]
})
export class AccessibleLinkModule {
}
