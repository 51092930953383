<div class="checkbox-group {{theme}}">
  <div class="checkbox-group__input" *ngIf="searchField">
      <input
        class="text-field checkbox-group__input-element"
        placeholder="{{ 'dropdownTree.input' | translate }}"
        [(ngModel)]="fieldModel"
        (input)="fieldModelChanged()"/>
      <div class="checkbox-group__search">
        <svg-icon
          [title]="'checkbox-group.filter' | translate"
          class="icon icon_14 icon_gray"
          src="assets/icons/search.svg"></svg-icon>
      </div>
    </div>
  <div class="checkbox-group__item" *ngFor="let item of filteredItems">
    <app-checkbox
        [label]="translateLabel ? (item.label | translate) : item.label"
        [itemValue]="item.value"
        [addSign]="addSign"
        [modifications]="'checkbox_success'"
        [(ngModel)]="item.selected"
        (change)="selectItem()"></app-checkbox>
  </div>
  <span *ngIf="filteredItems?.length === 0">{{ 'checkboxGroup.empty' | translate }}</span>
</div>
