<div class="autocomplete__overlay" [appTrapFocus]="isMobile && isOpen">
  <div class="autocomplete__filter" *ngIf="isMobile && !searchDisabled">
    <input class="text-field"
           [placeholder]="'common.search' | translate"
           [ngModel]="fieldModel"
           (ngModelChange)="updateFieldModel($event)"
           (keydown)="keypressHandler($event)"/>
    <button type="button"
            class="button button_text autocomplete__filter-clear-button"
            [title]="'tips.clear' | translate"
            (click)="clearFilter()">
      <span class="figure figure_close" role="presentation"></span>
    </button>
  </div>
  <app-loader class="autocomplete__loader" *ngIf="!filteredItems"></app-loader>
  <ul class="autocomplete__dropdown-menu"
      *ngIf="filteredItems"
      (keydown)="keypressHandler($event)"
      [class.autocomplete__dropdown-menu_wide]="customWidth">
    <li class="autocomplete__dropdown-item"
        [appAutocompleteFocused]="isPressedUpDown && (i == selectedId)"
        *ngFor="let item of filteredItems; trackBy: trackByFn; let i=index;">
      <div
        *ngIf="byGroups && showGroupName(i)"
        class="autocomplete__group-name text text_small text_muted-light">
        <div [class.autocomplete__group-separator]="i !== 0"></div>
        {{ item.groupName | uppercase }}
      </div>
      <button class="autocomplete__button button"
              [tabindex]="searchDisabled ? 0 : -1"
              [title]="item.label"
              type="button"
              [class.autocomplete__button_active]='i == selectedId'
              [class.autocomplete__button_empty]='isClassEmpty(i)'
              (click)="select(i)">{{ item.label }}</button>
    </li>
  </ul>
  <div *ngIf="isMobile" class="autocomplete__button-box">
    <button class="button button_primary button_mobile-wide" (click)="close()">{{ 'tips.close' | translate }}</button>
  </div>
</div>
