import { Component, Injector, OnInit } from '@angular/core';
import { PsyQuestionaryService } from '../psy-questionary.service';
import { QuestionaryService } from "../../questionary.service";
import { QuestionaryRouteParams } from "../../questionary-interface/questionary-route-params";
import { QuestionaryType } from "../../questionary-interface/questionary-type";
import { ProfQuestionaryService } from "../../prof-questionary/prof-questionary.service";
import { UIRouter } from "@uirouter/core";

@Component({
  selector: 'app-questionary-instructions',
  templateUrl: './questionary-instructions.component.html',
  styleUrls: ['./questionary-instructions.component.scss']
})
export class QuestionaryInstructionsComponent implements OnInit {
  currentQuestionaryType: QuestionaryType;
  processedInstructions: string;
  showMoreBtn = true;

  private questionaryService: QuestionaryService<any, any, any>;
  private readonly instructionsLengthLimit = 300;

  constructor(
    private route: UIRouter,
    private injector: Injector,
  ) { }

  ngOnInit(): void {
    const { questionaryType } = this.route.stateService.params as QuestionaryRouteParams;
    this.currentQuestionaryType = questionaryType;

    if (this.currentQuestionaryType === QuestionaryType.PSYCHOLOGICAL) {
      this.questionaryService = this.injector.get(PsyQuestionaryService);
    } else {
      this.questionaryService = this.injector.get(ProfQuestionaryService);
    }

    if (this.instructions && this.instructions.length > this.instructionsLengthLimit) {
      this.processedInstructions = `${this.instructions.slice(0, this.instructionsLengthLimit)}...`;
      this.showMoreBtn = true;
    } else {
      this.processedInstructions = this.instructions;
      this.showMoreBtn = false;
    }
  }

  startTest(): void {
    this.questionaryService.startTest();
  }

  showAllInstructions(): void {
    this.processedInstructions = this.instructions;
    this.showMoreBtn = false;
  }

  isQuestionaryPausedForInstructions(): boolean {
    return this.questionaryService.isQuestionaryPausedForInstructions();
  }

  private get instructions(): string {
    return this.questionaryService.instruction;
  }

}
