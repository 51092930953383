import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ManagedComponent } from '../app-commons/managed-component';
import _get from 'lodash-es/get';
import _isString from 'lodash-es/isString';
import { StateService } from '@uirouter/core';

type PasswordRecoveryResult = 'internalError' | 'success';

const passwordRecoveryURLResultsMap = new Map<string, PasswordRecoveryResult>([
  ['INTERNAL_ERROR', 'internalError'],
  ['success', 'success'],
]);

@Component({
  selector: 'app-password-recovery-result',
  templateUrl: './password-recovery-result.component.html',
  styleUrls: ['./password-recovery-result.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PasswordRecoveryResultComponent extends ManagedComponent implements OnInit {

  get result(): PasswordRecoveryResult {
    return this._result;
  }

  private _result: PasswordRecoveryResult = 'internalError';

  constructor(private readonly stateService: StateService) {
    super();
  }

  ngOnInit(): void {
    this.initializeResult();
  }

  back(): void {
    this.stateService.go('root.login');
  }

  private initializeResult(): void {
    const resultKey = _get(this.stateService.params, 'type', null);

    if (_isString(resultKey) && passwordRecoveryURLResultsMap.has(resultKey)) {
      this._result = passwordRecoveryURLResultsMap.get(resultKey);
    }
  }

}
