import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss']
})
export class TimerComponent implements OnInit {
  @Input() initialTime;
  @Output() timeIsOverEvent = new EventEmitter();
  currentTime;
  formattedTime;

  constructor() { }

  ngOnInit() {
    this.currentTime = this.initialTime;
    this.secToTime(this.currentTime);
    this.startTimer();
  }

  get percents(): number {
    return 100 - Math.floor(this.currentTime * 100 / this.initialTime);
  }


  startTimer() {
    setTimeout(() => {
      if (this.currentTime < 1) {
        this.timeIsOverEvent.emit();
        return;
      } else {
        this.currentTime = this.currentTime - 1;
        this.secToTime(this.currentTime);
        this.startTimer();
      }
    }, 1000);
  }

  secToTime(sec) {
    const hours = Math.floor(sec / 3600);
    const minutes = Math.floor((sec - hours * 3600) / 60);
    const seconds = sec - hours * 3600 - minutes * 60;

    const formattedHours = hours < 10 ? `0${hours}` : hours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

    this.formattedTime = formattedHours === '00' ?
    `${formattedMinutes}:${formattedSeconds}` :
    `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }

}
