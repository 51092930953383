import { Component, OnInit } from '@angular/core';
import { ProfQuestionaryService } from '../prof-questionary.service';
import { QuestionaryType } from '../../questionary-interface/questionary-type';
import { ProfQuestionaryResponseSchema, ProfQuestionResponseDTO } from '@rsmu/portal-api';
import { AnswerForQuestion, AttachedFile } from '../../question-options/question.dto';
import { UntypedFormControl } from '@angular/forms';
import { DialogService } from '../../../dialog/dialog.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-prof-questionary-questions-container',
  templateUrl: './prof-questionary-questions-container.component.html',
  styleUrls: ['./prof-questionary-questions-container.component.scss']
})
export class ProfQuestionaryQuestionsContainerComponent implements OnInit {
  attachFileControl: UntypedFormControl = new UntypedFormControl(null);
  loadingFile = false;

  constructor(
    private questionaryService: ProfQuestionaryService,
    private sanitizer: DomSanitizer,
    private dialogService: DialogService
  ) { }

  get getDisabledStatus(): boolean {
    return this.questionaryService.isDisabled;
  }

  get questionaryType(): QuestionaryType {
    return this.questionaryService.questionaryType;
  }

  get isReadonly(): boolean {
    return this.questionaryService.isReadonly;
  }

  get isSelfControl(): boolean {
    return this.questionaryType === QuestionaryType.SELF_CONTROL;
  }

  get isFirstTab(): boolean {
    return this.activeQuestionIndex === 0;
  }

  get activeQuestion(): ProfQuestionResponseDTO {
    return this.questionaryService.activeQuestion;
  }

  get activeQuestionAnswer(): AnswerForQuestion {
    return this.questionaryService.activeQuestionAnswer;
  }

  set activeQuestionAnswer(answer: AnswerForQuestion) {
    this.questionaryService.activeQuestionAnswer = answer;
  }

  get timeIsOver(): boolean {
    return this.questionaryService.timeIsOver;
  }

  get numberOfQuestions(): number {
    return this.questionaryService.numberOfQuestions;
  }

  get questionary(): ProfQuestionaryResponseSchema {
    return this.questionaryService.questionary;
  }

  get activeQuestionIndex(): number {
    return this.questionaryService.activeQuestionIndex;
  }

  get percentageOfCompletion(): number {
    return this.questionaryService.getPercentageOfCompletion;
  }

  get questionaryKeyTitle(): string {
    return this.questionaryService.questionaryKeyTitle;
  }

  get attachedFile(): AttachedFile {
    return this.activeQuestionAnswer.attachedFile;
  }

  get downloadLink(): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(this.attachedFile.userFile));
  }

  ngOnInit() {
    this.loadAttachmentIfAvailable();
  }

  finishSelfControl(): void {
    this.questionaryService.finishTest();
  }

  nextQuestion(): void {
    this.clearAttachment();
    this.questionaryService.nextQuestion();
    this.loadAttachmentIfAvailable();
  }

  previousQuestion(): void {
    this.questionaryService.previousQuestion();
  }

  readAttachedFile({ target }: Event) {
    const file: File | null = (target as HTMLInputElement).files[0];
    if (!file || file.size > 5242880) {
      this.dialogService.messageTranslate('questionary.fileTooBig').subscribe();
      this.clearAttachment();
      return;
    }
    this.activeQuestionAnswer.attachedFile = {
      questionId: this.activeQuestion.id,
      userFile: file
    };
  }

  deleteAttachedFile() {
    this.clearAttachment();
    this.activeQuestionAnswer.attachedFile = null;
  }

  private loadAttachmentIfAvailable() {
    if (this.activeQuestionAnswer && this.activeQuestionAnswer.fileDownloadData && !this.activeQuestionAnswer.attachedFile) {
      this.loadingFile = true;
      const questionId = this.activeQuestion.id;
      this.questionaryService.loadAttachedFile(this.activeQuestionAnswer.fileDownloadData.downloadUrl, questionId)
        .pipe(finalize(() => this.loadingFile = false))
        .subscribe((file: File) => this.activeQuestionAnswer.attachedFile = { userFile: file, questionId: questionId });
    }
  }

  private clearAttachment(): void {
    this.attachFileControl.setValue(null);
  }
}

