<div class="psy-questionary-container">
  <app-questionary-progress-bar
    class="psy-questionary-container__progress-bar-container"
    [percentageOfCompletion]="getPercentageOfCompletion">
  </app-questionary-progress-bar>

  <div *ngIf="isErrorSend" class="text text_bold text_big psy-questionary-container__error">
    {{ 'questionary.error.send.psychological' | translate }}
  </div>

  <div class="psy-questionary-container__corner-button">
    <button class="button button_flat" (click)="switchInstructionsDisplay()">
      <span *ngIf="activePhase === questionaryPhase.INSTRUCTIONS" class="figure figure_close"></span>
      <span *ngIf="activePhase !== questionaryPhase.INSTRUCTIONS" class="figure figure_help"></span>
    </button>
  </div>

  <app-loader
    class="centered centered_absolute"
    *ngIf="loading"
    [size]="70"
    [showLabelKey]="false"
    [strokeWidth]="5">
  </app-loader>

  <div class="psy-questionary-container__main-content">
    <app-questionary-instructions
      *ngIf="activePhase === questionaryPhase.INSTRUCTIONS && !loading">
    </app-questionary-instructions>

    <app-psy-questionary-questions-container
      *ngIf="activePhase === questionaryPhase.QUESTIONS && !loading">
    </app-psy-questionary-questions-container>

    <app-questionary-results
      *ngIf="activePhase === questionaryPhase.RESULTS && !loading">
    </app-questionary-results>

    <app-questionary-rewards
      *ngIf="activePhase === questionaryPhase.REWARDS && !loading && !isErrorSend"
      [testRewards]="results.zet"
      [dryRun]="dryRun">
    </app-questionary-rewards>
  </div>
</div>
