import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { currentCycleReducer } from './reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('currentCycle', currentCycleReducer)
  ],
  declarations: []
})
export class CurrentCycleStoreModule { }
