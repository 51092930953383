import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { TabComponent } from './tab/tab.component';
import { TabGroupComponent } from './tab-group/tab-group.component';
import { TabGroupOutletDirective } from './tab-group/tab-group-outlet';
import { TabContentDirective } from './tab/tab-content';
import { TabBodyComponent } from './tab-body/tab-body.component';
import { TabBodyHostDirective } from './tab-body/tab-body-host';
import { AppCommonsModule } from '../app-commons/app-commons.module';

@NgModule({
  imports: [
    CommonModule,
    AngularSvgIconModule,
    AppCommonsModule
  ],
  declarations: [
    TabComponent,
    TabContentDirective,

    TabGroupComponent,
    TabGroupOutletDirective,

    TabBodyComponent,
    TabBodyHostDirective
  ],
  exports: [
    TabComponent,
    TabGroupComponent
  ]
})
export class TabsModule { }
