import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CyclesStoreModule } from './cycles-store';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { CurrentCycleStoreModule } from './current-cycle-store';
import { EduPlanStoreModule } from './edu-plan-store';
import { ToursStoreModule } from "./tours-store";

@NgModule({
  imports: [
    CommonModule,
    CyclesStoreModule,
    CurrentCycleStoreModule,
    ToursStoreModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    EduPlanStoreModule
  ],
  declarations: []
})
export class RootStoreModule { }
