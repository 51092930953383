import {
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  Type,
  ViewChild
} from '@angular/core';
import { AbstractQuestionOption } from './abstract-question-option';
import { DynamicComponentDirective } from '../../dynamic-component/dynamic-component.directive';
import { ProfOptionAnswerValueSchema, ProfQuestionTypeEnumSchema, TypeQuestion } from '@rsmu/portal-api';
import { LimitedSelectQuestionComponent } from './limited-select-question/limited-select-question.component';
import { SearchSelectQuestionComponent } from './search-select-question/search-select-question.component';
import { DateQuestionComponent } from './date-question/date-question.component';
import { DecimalQuestionComponent } from './decimal-question/decimal-question.component';
import { IntegerQuestionComponent } from './integer-question/integer-question.component';
import { TextAreaQuestionComponent } from './text-area-question/text-area-question.component';
import { AnswerForQuestion } from './question.dto';

@Component({
  selector: 'app-question-options',
  templateUrl: './question-options.component.html',
})
export class QuestionOptionsComponent implements OnChanges {

  private static readonly MAX_OPTIONS_AT_ONCE_ON_SCREEN = 12;

  @Input() questionType: ProfQuestionTypeEnumSchema | TypeQuestion;
  @Input() options: ProfOptionAnswerValueSchema[];
  @Input() questionIndex: number;
  @Input() isSelfControl: boolean;
  @Input() disabled: boolean;

  @Input()
  get answer(): AnswerForQuestion {
    return this._answer;
  }
  set answer(newValue: AnswerForQuestion) {
    this._answer = newValue;
    this.answerChange.emit(newValue);
  }
  private _answer: AnswerForQuestion;
  @Output() answerChange: EventEmitter<AnswerForQuestion> = new EventEmitter();

  @ViewChild(DynamicComponentDirective, { static: true }) questionView: DynamicComponentDirective;

  private multiSelectAvailable = false;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private changeDetectorRef: ChangeDetectorRef,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.questionIndex || changes.disabled) {
      this.reloadView();
    }
  }

  private reloadView() {
    const questionComponentClass = this.getComponentClass();
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(questionComponentClass);
    const viewContainerRef = this.questionView.viewContainerRef;
    viewContainerRef.clear();
    const componentRef = viewContainerRef.createComponent(componentFactory);
    const component: AbstractQuestionOption =  componentRef.instance;
    component.answer = this.answer;
    component.answerChange.subscribe((newValue) => {
      this.answer = newValue;
    });
    component.disabled = this.disabled;
    component.multiSelectAvailable = this.multiSelectAvailable;
    component.options = this.options;
    component.questionType = this.questionType;
    this.changeDetectorRef.detectChanges();
  }

  private getComponentClass(): Type<AbstractQuestionOption> {
    this.multiSelectAvailable = false;
    switch (this.questionType) {
      case ProfQuestionTypeEnumSchema.Boolean:
        return LimitedSelectQuestionComponent;

      case ProfQuestionTypeEnumSchema.MultipleCustom:
      case ProfQuestionTypeEnumSchema.MultipleEntity:
      case TypeQuestion.Multiple:
        this.multiSelectAvailable = true;
        return this.getSelectComponentByOptions();

      case ProfQuestionTypeEnumSchema.SingleCustom:
      case ProfQuestionTypeEnumSchema.SingleEntity:
      case TypeQuestion.Single:
        return this.getSelectComponentByOptions();

      case ProfQuestionTypeEnumSchema.Date:
        return DateQuestionComponent;

      case ProfQuestionTypeEnumSchema.Integer:
        return IntegerQuestionComponent;

      case ProfQuestionTypeEnumSchema.Decimal:
        return DecimalQuestionComponent;
      case ProfQuestionTypeEnumSchema.Text:

      default:
        return TextAreaQuestionComponent;
    }
  }

  private getSelectComponentByOptions() {
    return this.options.length > QuestionOptionsComponent.MAX_OPTIONS_AT_ONCE_ON_SCREEN && !this.isSelfControl
      ? SearchSelectQuestionComponent
      : LimitedSelectQuestionComponent;
  }
}
