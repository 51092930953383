<div>
  <label class="form-control">
    <span class="form-control__label" *ngIf="label">{{ label }}</span>
    <div class="text-field__wrapper form-control_relative">
      <input class="text-field text-field__pointer-events-disabled"
             [class.ng-invalid]="false"
             type="text"
             [placeholder]="placeholder"
             [disabled]="disabled"
             [(ngModel)]="value"
             (change)="changed()"
             (input)="input()">
      <div
        class="text-field__icon"
        *ngIf="iconSearch">
        <svg-icon
          [title]="'common.search' | translate"
          class="text-field__svg icon icon_14 icon_gray"
          src="assets/icons/search.svg"></svg-icon>
      </div>
    </div>
  </label>
</div>

