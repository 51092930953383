import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ManagedComponent } from '../app-commons/managed-component';
import _get from 'lodash-es/get';
import _isString from 'lodash-es/isString';
import { StateService } from '@uirouter/core';

export type EsiaError = 'notTrustedUser' | 'esiaError';

const ERRORS_KEY_MAP = new Map<string, EsiaError>([
  ['notTrustedUser', 'notTrustedUser'],
  ['esiaError', 'esiaError'],
]);

@Component({
  selector: 'app-esia-error',
  templateUrl: './esia-error.component.html',
  styleUrls: ['./esia-error.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EsiaErrorComponent extends ManagedComponent implements OnInit {
  key: EsiaError = 'esiaError';

  constructor(private readonly stateService: StateService) {
    super();
  }

  ngOnInit(): void {
    const errorKey: string | null = _get(this.stateService.params, 'key', null);

    if (_isString(errorKey) && ERRORS_KEY_MAP.has(errorKey)) {
      this.key = ERRORS_KEY_MAP.get(errorKey);
    }
  }

  toLogin(): void {
    this.stateService.go('root.login');
  }
}
