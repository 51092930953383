<mat-form-field
  *ngIf="preselectedOrg$ | async"
  class="autocomplete"
  appearance="outline"
>
  <mat-label>{{label}}</mat-label>
  <div class="autocomplete__input">
    <input class="autocomplete text-field" type="text"
           matInput
           [placeholder]="label"
           [required]="required"
           [formControl]="formCtrl"
           [matAutocomplete]="auto"
           (click)="firstOpen()"
           #autoCompleteInput
    >
    <button
      style="font-size:inherit; line-height: unset; width: unset; height: unset"
      mat-icon-button
      color="warn"
      type="button"
      *ngIf="!!formCtrl.value"
      (click)="clearEsOrg()"
    >
      <mat-icon
        svgIcon="clear"
      ></mat-icon>
    </button>
    <span
      class="figure"
      [class.figure_arrow-bottom]="!auto.isOpen"
      [class.figure_arrow-top]="auto.isOpen"></span>
    <mat-autocomplete
      #auto="matAutocomplete"
      [displayWith]="getEsOrgName"
      serverSideFilterOptions
      [loadingFn]="orgsFn"
      [offset]="orgsOffset"
      [optionsList$]="eduOrganizations$"
      [textFilterString]="orgsTextFilter"
      (changeOffset)="offsetChanged($event.offset)"
      (optionSelected)="orgControlChanged($event)"
      (closed)="clearOrgsInput()"
    >
      <ng-container *ngIf="orgs$ | async as orgs;">
        <mat-option hidden></mat-option>
        <mat-option class="autocomplete__option"
        *ngFor="let org of orgs"
        [value]="org">
        <div class="autocomplete__option__name text text_medium text_bold">
          {{org.shortName || org.fullName}}
        </div>
        <div *ngIf="org.inn || org.kpp" class="autocomplete__option__additional text text_light" >
          <span *ngIf="org.inn">ИНН: {{org.inn}}</span>
          <span *ngIf="org.inn">КПП: {{org.kpp}}</span>
        </div>
        <div *ngIf="org.fullAddress" class="text_small text_light text_italic">{{org.fullAddress}}</div>
        <mat-divider></mat-divider>
        </mat-option>
      </ng-container>

    </mat-autocomplete>
  </div>
</mat-form-field>