import { Component, Input } from '@angular/core';
import {MyCycleZetDto} from '@rsmu/portal-api';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent {
  @Input() myCycleZet: MyCycleZetDto;

  get availableHours () {
    return this.myCycleZet && this.myCycleZet.zetRequired || 1;
  }

  get masteredHours () {
    return this.myCycleZet && this.myCycleZet.zetCompleted;
  }

  get plannedHours () {
    return this.myCycleZet && this.myCycleZet.zetPlanned;
  }

  get getPlannedHoursInPercent(): number {
    if (this.plannedHours * 100 / this.availableHours > 100) {
      return 100;
    }
    return this.plannedHours * 100 / this.availableHours;
  }
  get getMasteredHoursInPercent(): number {
    if (this.masteredHours * 100 / this.availableHours > 100) {
      return 100;
    }
    return this.masteredHours * 100 / this.availableHours;
  }
}
