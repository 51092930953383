import { Component, ElementRef, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['radio.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioComponent),
      multi: true
    }
  ]
})
export class RadioComponent implements ControlValueAccessor {

  isChecked = false;

  @Input() name: string;
  @Input() value: string;
  @Input() theme: 'accent' | 'success' | 'primary' = 'primary';
  @Input() set checked(isChecked: boolean) {
    this.isChecked = isChecked;
  }
  @Input() disabled = false;
  @Input() html: string;
  @Input() selfControl = false;

  @Output() change = new EventEmitter<string>();

  private readonly keyCodeSpace = 'Space';
  @ViewChild('model', { static: true }) private model: ElementRef;

  onChange = (isChecked) => {};
  onTouched = () => {};

  check(): void {
    this.onChange(this.value);
    this.change.emit(this.value);
  }

  onKeyDown(event: KeyboardEvent): void {
    if (event.code === this.keyCodeSpace) {
      event.preventDefault();
      event.stopPropagation();
      this.model.nativeElement.click();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: string): void {
    this.isChecked = value === this.value;
  }

}
