export function stepAnimation(from: number, to: number, timeout: number, draw: (progress: number) => void, callback: () => void) {
  let state = from;
  let lastDuration = 0;
  requestAnimationFrame(function animateStep(duration) {

    if (duration - lastDuration >= timeout) {
      state++;
      draw(state);
      lastDuration = duration;
    }

    if (state < to) {
      requestAnimationFrame(animateStep);
    } else {
      callback();
    }
  });
}

export function scrollToTop(): void {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
}