<div #processCircle
     *ngIf="percents || percents === 0">
  <div class="progress-circle__point"
       [class.progress-circle__point_success]="color === colorCode.Green"
       [class.progress-circle__point_alert]="color === colorCode.Red"
       [class.progress-circle__point_accent]="color === colorCode.Yellow">
    <div *ngIf="getRewards"
         #zetProgressBox
         class="progress-circle__value text text_small"
         [class.text_success]="color === colorCode.Green"
         [class.text_alert]="color === colorCode.Red"
         [class.text_accent]="color === colorCode.Yellow"></div>
  </div>
  <div *ngIf="getRewards" class="progress-circle__icon">
      <svg-icon class="icon icon_46 topics__icon" src="assets/icons/gift-open.svg"
                title="{{ 'tips.giftOpen' | translate }}"
                [class.icon_success]="color === colorCode.Green"
                [class.icon_alert]="color === colorCode.Red"
                [class.icon_accent]="color === colorCode.Yellow"></svg-icon>
  </div>

  <div class="progress-circle__number"
       [class.progress-circle__number_get-zet]="getRewards">
    <span class="text"
          #percentsBox
          [class.text_success]="color === colorCode.Green"
          [class.text_alert]="color === colorCode.Red"
          [class.text_accent]="color === colorCode.Yellow"
          [class.text_big]="getRewards"
          [class.text_h1]="!getRewards"></span>
    <span class="text"
          [class.text_success]="color === colorCode.Green"
          [class.text_alert]="color === colorCode.Red"
          [class.text_accent]="color === colorCode.Yellow"
          [class.text_small]="getRewards"
          [class.text_bigger]="!getRewards">%</span>
  </div>
  <div #circleElement class="progress-circle__animation"></div>
</div>
