import { AfterContentInit, Component, Input, OnChanges, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { TabGroupOutletDirective } from '../tab-group/tab-group-outlet';

@Component({
  selector: 'app-tab-body',
  templateUrl: './tab-body.component.html',
  styleUrls: ['./tab-body.component.scss']
})
export class TabBodyComponent implements AfterContentInit, OnChanges {

  @Input() content: TemplateRef<any>;
  @Input() active = false;

  @ViewChild(TabGroupOutletDirective, { static: true }) _host: TabGroupOutletDirective;

  ngOnChanges(changes: SimpleChanges): void {
    const activeCng = changes.active;
    const vc = this._host.viewContainer;

    if (activeCng) {
      vc.clear();
    }

    if (activeCng.currentValue) {
      vc.createEmbeddedView(this.content);
    }
  }

  ngAfterContentInit(): void {
    const vc = this._host.viewContainer;
    vc.clear();
    if (this.active) {
      vc.createEmbeddedView(this.content);
    }

  }
}
