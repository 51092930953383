<div class="checkbox-wrap {{ modificationsWrapper }}">
  <label class="checkbox {{ modifications }}"
         tabindex="0"
         (keydown)="onKeyDown($event)"
         [class.checkbox_allow-html]="allowHtmlText"
         title="{{ 'tips.checkbox.' + !!value | translate }}">
    <span class="checkbox__input-container">
      <input class="checkbox__input"
             type="checkbox"
             tabindex="-1"
             [disabled]="disabled"
             [ngModel]="value"
             (ngModelChange)="updateValue($event)"/>
      <span class="checkbox__styled"
            [class.checkbox__styled_smaller]="smallerCheckbox"></span>
    </span>
    <span *ngIf="label" class="checkbox__label text"
          [class.checkbox__label_disabled]="disabled"
          [class.checkbox__label_flex]="itemValue === addSign.sign"
          [class.text_bold]="isBold"
          [class.text_base]="smallerCheckbox"
          [class.text_small]="smallText"
          [class.text_big]="bigText">
      <span *ngIf="!allowHtmlText">{{ label }}</span>
      <span *ngIf="allowHtmlText" [innerHTML]="label | sanitizeBypass"></span>
      <span *ngIf="itemValue === addSign.sign" class="checkbox__multiply">
          x{{addSign.multiply}}
      </span>
      </span>
  </label>
  <ng-content></ng-content>
</div>
