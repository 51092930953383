import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { ConnectionService } from '../connection/connection.service';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';

import ErrorData from './error-data';
import { ErrorOptions, hasGlobalError } from '../utils/http-error-utils';
import { ErrorNotificationService } from '../app-commons/error-notification/service/error-notification.service';
import { DialogService } from '../app-commons/dialog/dialog.service';
import { SentryErrorHandler } from '../sentry/sentryErrorHandler';
import { ErrorNotification, ErrorNotificationPriority } from '../app-commons/error-notification/error-notification-dto';
import { environment } from '../../environments/environment';
import { replaceUuid } from '../utils/replace-uuid';
import _identity from 'lodash-es/identity';

@Injectable()
export class ErrorsInterceptor implements HttpInterceptor {

  private readonly UNDEFINED_EXCEPTION: ErrorOptions = {
    code: 'undefinedException',
    status: 422
  };
  private readonly notificationClosed$ = new ReplaySubject<void>(1);
  private readonly UNKNOWN_ERROR: ErrorNotification = {
    messageKey: 'server.error.message',
    showCloseButton: true,
    closed$: this.notificationClosed$
  };
  private readonly SERVER_UNAVAILABLE_ERROR: ErrorNotification = {
    messageKey: 'server.unavailable.message',
    priority: ErrorNotificationPriority.SERVER_UNAVAILABLE
  };

  private storedRequestsMap = new Map<string, HttpRequest<any>>();

  private get sentryErrorHandler(): SentryErrorHandler {
    return this.injector.get(SentryErrorHandler);
  }

  constructor(
    private readonly errorNotificationService: ErrorNotificationService,
    private readonly connectionService: ConnectionService,
    private readonly dialogService: DialogService,
    private readonly injector: Injector
  ) {
    this.notificationClosed$.subscribe(() => this.displayAssuringMessage());
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isAssetsRequest(req)) {
      req.headers.set('Expires', '0');
    }
    return next.handle(req).pipe(catchError((error: ErrorData) => {
      if (this.connectionService.getLastConnectionState()) {
        return throwError(error);
      } else {
        this.storedRequestsMap.set(req.url, req);
        return this.connectionService.subscribeToConnection().pipe(
          filter(_identity),
          take(1),
          switchMap(() => {
            const storedRequest = this.storedRequestsMap.get(req.url);
            if (storedRequest && storedRequest === req) {
              this.storedRequestsMap.delete(req.url);
              return next.handle(req);
            } else {
              return throwError(error);
            }
          })
        );
      }
    }), tap((response: any) => {
      if (response && response.status && response.status === 200 && response.url && response.url.includes(environment.baseRestApiUrl)) {
        this.errorNotificationService.hideNotification();
      }
    }, (error: ErrorData) => {
      if (!error.status || error.status === 0 || error.status >= 500) {
        this.errorNotificationService.displayNotification(this.SERVER_UNAVAILABLE_ERROR);
      } else if (
        (error.status !== 401 && error.status !== 422 && error.status !== 400 && error.status !== 307) ||
        hasGlobalError(error, this.UNDEFINED_EXCEPTION)) {
        this.errorNotificationService.displayNotification(this.UNKNOWN_ERROR);
        this.sentryErrorHandler.sendCustomError(error, `HttpError for ${replaceUuid(error.url)}. Status: ${error.status}`);
      }
    }));
  }

  private displayAssuringMessage() {
    this.dialogService.messageTranslate('server.error.message.closed').subscribe();
  }

  private isAssetsRequest(req: HttpRequest<any>): boolean {
    // Firefox fires status:0 when load assets from cache
    return req.url.includes(environment.baseWebUrl + 'assets/');
  }
}
