import { Component } from '@angular/core';
import { AbstractQuestionOption } from '../abstract-question-option';

@Component({
  selector: 'app-decimal-question',
  templateUrl: './decimal-question.component.html',
})
export class DecimalQuestionComponent extends AbstractQuestionOption {

  filter = {
    [Symbol.match]: (input: string) => {
      const processedInput = input.replace(',', '.').split('');
      if (processedInput[processedInput.length - 1] === '.') {
        processedInput.pop();
      }
      return isNaN(Number(processedInput.join(''))) ? [''] : [processedInput.join('')];
    }
  };

  constructor() {
    super();
  }
}
