import { AbstractControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export function customValidator(fieldName: string, rule: () => boolean): ValidatorFn {
  return function (group: UntypedFormGroup): ValidationErrors | null {
    if (rule()) {
      customCheckerValidator(() => fieldName)(group);
    } else {
      customIgnoredValidator(() => fieldName)(group);
    }
    return null;
  };
}

export function customCheckerValidator(getTestedElementFunction: () => string): ValidatorFn {
  return function (group: UntypedFormGroup): ValidationErrors | null {
    const elementName: string = getTestedElementFunction();
    const element: AbstractControl = elementName && group.controls[elementName];
    if (element) {
      element.setErrors(element.validator && element.validator(element));
    }
    return null;
  };
}

export function customIgnoredValidator(getIgnoredElementFunction: () => string): ValidatorFn {
  return function (group: UntypedFormGroup): ValidationErrors | null {
    const ignoredElementName: string = getIgnoredElementFunction();
    const ignoredElement = ignoredElementName && group.controls[ignoredElementName];
    if (ignoredElement) {
      ignoredElement.setErrors(null);
    }
    return null;
  };
}
