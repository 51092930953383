import { Component, HostBinding, ViewEncapsulation } from '@angular/core';
import { AccessibleService } from '../accessible/accessible.service';

@Component({
  selector: 'app-accessible-toggle',
  templateUrl: './accessible-toggle.component.html',
  styleUrls: ['./accessible-toggle.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AccessibleToggleComponent {

  @HostBinding('class.accessible-toggle') isHostClass = true;

  get isActiveAccessible(): boolean {
    return this.accessibleService.isActive;
  }

  get isAccessibleToggleEnabled(): boolean {
    return this.accessibleService.isAccessibleToggleEnabled;
  }

  constructor(private readonly accessibleService: AccessibleService) {
  }

  turnOnAccessible(): void {
    this.accessibleService.turnOn();
  }

}
