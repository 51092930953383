import { Actions, ActionTypes } from './actions';
import { State, initialState, eduPlanAdapter } from './state';

export function eduPlanReducer(state = initialState, action: Actions): State {
  switch(action.type) {
    case ActionTypes.LOAD_EDU_PLAN_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null
      };
    }
    case ActionTypes.LOAD_PLAN_WITHOUT_CYCLE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null
      };
    }
    case ActionTypes.LOAD_SUCCESS: {
      return {
        ...state,
        eduPlan: action.payload.eduPlan,
        isLoading: false,
        error: null
      };
    }
    case ActionTypes.LOAD_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      };
    }
    default: {
      return state;
    }
  }
}
