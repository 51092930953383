import { State, eduPlanAdapter } from './state';
import { MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store';
import { CyclesResponseSchema, MyPlan } from '@rsmu/portal-api';

export const getError = (state: State): any => state.error;

export const getIsLoading = (state: State): boolean => state.isLoading;

export const getEduPlan = (state: State): MyPlan => state.eduPlan;

export const selectEduPlanState: MemoizedSelector<object, State> =
  createFeatureSelector<State>('eduPlan');

export const selectEduPlan: MemoizedSelector<object, MyPlan> =
  createSelector(selectEduPlanState, getEduPlan);

export const selectIsLoading: MemoizedSelector<object, boolean> =
  createSelector(selectEduPlanState, getIsLoading);

export const selectError: MemoizedSelector<object, any> =
  createSelector(selectEduPlanState, getError);
