import {
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  Injector,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Renderer2,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { AbstractManagedOverlayConnected } from '../dropdown/managed-overlay-connected';
import { KeyListenerService } from '../../key-listener/key-listener.service';
import { LayoutService } from '../layout/layout.service';
import { Overlay } from '@angular/cdk/overlay';
import _isNil from 'lodash-es/isNil';
import _isString from 'lodash-es/isString';
import { ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import { TooltipComponent } from './tooltip.component';

export type TooltipTrigger = 'hover' | 'click';

@Directive({
  selector: '[appTooltip]'
})
export class TooltipDirective
  extends AbstractManagedOverlayConnected<{} | TooltipComponent>
  implements OnChanges, OnDestroy, OnInit {

  @Input() appTooltip: string | TemplateRef<{}>;
  @Input() theme: string;
  @Input() trigger: TooltipTrigger = 'hover';

  get isClickable(): boolean {
    return this.trigger === 'click';
  }

  get isHovering(): boolean {
    return this.trigger === 'hover';
  }

  get overlayOrigin(): ElementRef<HTMLElement> {
    return this.el;
  }

  get portal(): TemplatePortal<{}> | ComponentPortal<TooltipComponent> {
    return this._portal;
  }
  private _portal: TemplatePortal<{}> | ComponentPortal<TooltipComponent>;

  private globalListenFunc: Function;
  private readonly offsetHost = 5;

  constructor(
    private readonly el: ElementRef<HTMLElement>,
    private readonly renderer: Renderer2,
    private readonly ngZone: NgZone,
    protected readonly injector: Injector,
    protected readonly keyListener: KeyListenerService,
    protected readonly layoutService: LayoutService,
    protected readonly overlay: Overlay,
    protected readonly viewContainer: ViewContainerRef
  ) {
    super(injector, keyListener, layoutService, overlay, viewContainer);
  }

  ngOnChanges({ appTooltip }: SimpleChanges): void {
    if (appTooltip) {
      this.close();
      this.updatePortal(appTooltip.currentValue);
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.globalListenFunc();
  }

  ngOnInit(): void {
    this.ngZone.runOutsideAngular(() => {
      this.globalListenFunc = this.renderer.listen(
        'body',
        'mousemove',
        this.onMouseMove.bind(this)
      );
    });
  }

  onMouseMove(event: MouseEvent): void {
    if (this.isHovering && this.isOpen) {
      const {left, right, top, bottom}: ClientRect | DOMRect = this.el.nativeElement.getBoundingClientRect();
      const isInside: boolean = event.clientX >= left - this.offsetHost
        && event.clientX <= right + this.offsetHost
        && event.clientY >= top - this.offsetHost
        && event.clientY <= bottom + this.offsetHost;

      if (!isInside) {
        this.ngZone.run(() => this.closeTooltip());
      }
    }
  }

  open(): void {
    if (!this.appTooltip) {
      return;
    }
    super.open();
    if (_isString(this.appTooltip)) {
      this.configureComponentTooltip();
    }
  }

  closeTooltip(): void {
    if (this.isOpen) {
      this.close();
    }
  }

  openTooltip(): void {
    if (this.isClosed) {
      this.open();
    }
  }

  @HostListener('click', [ '$event' ])
  openTooltipOnClick(ignoredEvent?: MouseEvent): void {
    if (this.isClickable) {
      this.openTooltip();
    }
  }

  @HostListener('mouseover', [ '$event' ])
  openTooltipOnMouseOver(ignoredEvent?: MouseEvent): void {
    if (this.isHovering && this.isClosed) {
      this.openTooltip();
    }
  }

  private configureComponentTooltip(): void {
    if (!_isNil(this.overlayViewRef)) {
      const componentRef: ComponentRef<TooltipComponent> = this.overlayViewRef as ComponentRef<TooltipComponent>;
      const hoverTooltipComponent: TooltipComponent = componentRef.instance;

      hoverTooltipComponent.type = 'text';
      hoverTooltipComponent.modifications = _isNil(this.theme) ? 'tooltip_white' : this.theme;
      hoverTooltipComponent.message = this.appTooltip as string;
    }
  }

  private updatePortal(currentValue: string | TemplateRef<{}>): void {
    if (_isString(currentValue)) {
      this._portal = new ComponentPortal<TooltipComponent>(TooltipComponent);
    } else {
      const templateRef: TemplateRef<{}> = currentValue as TemplateRef<{}>;
      this._portal = new TemplatePortal<{}>(templateRef, this.viewContainer, this.injector);
    }
  }
}
