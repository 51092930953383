import {Inject, Injectable, OnDestroy} from '@angular/core';
import {ApiService} from '../api/api.service';
import {DOCUMENT} from '@angular/common';
import {StateDeclaration, StateService} from '@uirouter/core';
import {finalize, takeUntil} from 'rxjs/operators';
import {ReplaySubject} from 'rxjs';
import {ProfileService} from '../profile/profile.service';

@Injectable({
  providedIn: 'root'
})
export class IfmoService implements OnDestroy {

  private destroy: ReplaySubject<void> = new ReplaySubject<void>(1);

  constructor(
    private readonly stateService: StateService,
    private readonly apiService: ApiService,
    private readonly profileService: ProfileService,
    @Inject(DOCUMENT) private readonly document: HTMLDocument
  ) {}

  getIfmoAuthUrl(state: StateDeclaration) {
   const stateUrl: string = this.stateService.href(state);
   this.apiService.getIfmoAuthUrl(stateUrl)
     .pipe(
       takeUntil(this.destroy))
     .subscribe(ifmoUrl => {
        this.document.location.href = ifmoUrl;
     }, error => {
        throw error;
     });
  }

  linkIfmoUser(state: string, session_state: string, code: string) {
    this.apiService.linkIfmoUser(code, session_state)
      .pipe(
        takeUntil(this.destroy),
        finalize(() => {
          this.profileService.refreshProfile()
            .pipe(
              takeUntil(this.destroy),
              finalize(() => {
                this.stateService.go('userAccount.ifmoRedirect');
              })).subscribe(() => {});
        }))
      .subscribe(() => {}, error =>  {
      throw error;
    });
  }

  unlinkIfmoUser() {

  }

  setSyncIfmoDataToggle() {

  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }
}
