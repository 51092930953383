import { Component } from '@angular/core';
import { AccessibleService } from './accessible.service';
import { Labeled } from '../switch-set/labeled';
import { AccessibleMode } from '@rsmu/portal-api';

@Component({
  selector: 'app-accessible',
  templateUrl: './accessible.component.html',
  styleUrls: ['./accessible.component.scss'],
})
export class AccessibleComponent {

  readonly cases = [
    {
      label: 'А',
      title: 'accessible.white',
      value: AccessibleMode.White
    },
    {
      label: 'A',
      title: 'accessible.black',
      value: AccessibleMode.Black
    }
  ];

  constructor(
    private accessibleService: AccessibleService,
  ) {
  }

  get accessibleCase(): Labeled {
    return this.cases.find(item => item.value === this.accessibleService.getMode());
  }

  turnOff(): void {
    this.accessibleService.turnOff();
  }

  onChangeMode(curCase: Labeled): void {
    this.accessibleService.setMode(curCase.value);
  }

}
