import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import _isDate from 'lodash-es/isDate';
import { DateObject, DateTime } from 'luxon';

export class DateUtils {

  static DEFAULT_DATE_FMT = 'dd.MM.yyyy';
  static BACKEND_DATE_FMT = 'yyyy-MM-dd';
  static DEFAULT_MIN_DATE: NgbDateStruct = {
    day: 1,
    month: 1,
    year: 1900
  };

  static dateStructFromDate(date: Date): NgbDateStruct {
    return {
      day: date.getUTCDate(),
      month: date.getUTCMonth() + 1,
      year: date.getUTCFullYear()
    };
  }

  static dateObjectToDateStruct(dateObject: DateObject): NgbDateStruct {
    const { year, month, day } = dateObject;

    return { year, month, day };
  }

  static dateObjectToDateTime(dateObject: DateObject): DateTime {
    return DateTime.fromObject(dateObject);
  }

  static datetimeFromDateStruct(obj: NgbDateStruct): DateTime {
    return DateUtils.dateObjectToDateTime({ ...obj, hour: 0, minute: 0, second: 0, millisecond: 0 });
  }

  static formatDateStruct(obj: NgbDateStruct, formatStr: string = DateUtils.DEFAULT_DATE_FMT): string {
    return DateUtils.dateObjectToDateTime(obj).toFormat(formatStr);
  }

  static getTimeDate(date: Date | NgbDateStruct): number {
    const dateToGetTimeOf: Date = _isDate(date)
      ? date as Date
      : new Date((date as NgbDateStruct).year, (date as NgbDateStruct).month - 1, (date as NgbDateStruct).day);

    return dateToGetTimeOf.getTime();
  }

  static isDateStructValid(date: NgbDateStruct): boolean {
    return DateUtils.dateObjectToDateTime(date).isValid;
  }

  static getNgbDateFromString(dateString: string): NgbDate {
    const [ year, month, day ] = dateString.split('.').reverse();
    return new NgbDate(+year, +month, +day);
  }

  static parseDateStruct(str: string, formatStr: string = DateUtils.DEFAULT_DATE_FMT): DateTime | null {
    if (str != null && str.length !== 0) {
      return DateTime.fromFormat(str.trim(), formatStr);
    }

    return null;
  }

}
