<div class="autocomplete {{ theme }}">
  <div class="autocomplete__dropdown" [class.autocomplete__dropdown_disabled]="disabled" #overlayOrigin>
    <div>
        <label
          (click)="onLabelClick($event)"
          class="form-control"
          [class.form-control__left]="leftLabel">
          <span class="form-control__label" *ngIf="label || helpText">
            <span class="text"
                  *ngIf="label"
                  [class.text_bold]="isBold"
                  [class.text_big]="isBig"
                  [class.text_small]="isSmall">
                  {{ label }}
              <span class="form-control__required-mark" *ngIf="requiredMark">{{ requiredMark }}</span>
            </span>
            <span class="form-control__help"
                  *ngIf="helpText"
                  #overlayTooltip="cdkOverlayOrigin"
                  cdkOverlayOrigin>
              <svg-icon class="icon icon_14 form-control__help-icon"
                        title="{{ 'tips.question' | translate }}"
                        src="assets/icons/question.svg"
                        (click)="toggleTooltip($event)"></svg-icon>
              <ng-template cdkConnectedOverlay
                           [cdkConnectedOverlayHasBackdrop]="true"
                           (backdropClick)="isShowedTooltip = false"
                           [cdkConnectedOverlayOrigin]="overlayTooltip"
                           [cdkConnectedOverlayOpen]="isShowedTooltip">
                <div class="text text_small text_primary form-control-tooltip">
                  {{ helpText }}
                </div>
              </ng-template>
            </span>
          </span>

          <span class="autocomplete__input-box">
            <svg-icon *ngIf="iconPin" class="autocomplete__icon" src="assets/icons/pin.svg"></svg-icon>
            <input class="text-field"
                   #input
                   [class.autocomplete__input_contrast]="isButtonFocus"
                   (focus)="onFocus()"
                   (keydown)="keypressHandler($event)"
                   [class.text-field_with-icon]='iconPin'
                   [class.disabled]='disabled'
                   [class.text-field_ignore-disabled]="searchDisabled"
                   [class.text-field_placeholder]="isClassEmpty(selectedId)"
                   (click)="selectInputText($event)"
                   type="text"
                   placeholder="{{ placeholder }}"
                   [ngClass]="{ 'text-field__active': isShowedOverlay,
                               'text-field__no-border': noBorder,
                               'text-field__big-text': bigText }"
                   [(ngModel)]="fieldModel"
                   (input)="fieldModelChanged()"
                   [readonly]="searchDisabled || isMobile" />
          </span>

        </label>
        <button class="autocomplete__toggle toggle"
                [class.autocomplete__toggle_disabled]='disabled'
                type="button"
                (focus)="isButtonFocus = true"
                (blur)="isButtonFocus = false"
                (keydown)="keypressHandler($event)"
                (click)="btnClick()"
                [tabIndex]="searchDisabled ? 0 : -1"
                [disabled]="disabled">
          <span
            class="toggle__chevron"
            [class.toggle__chevron_rotate]="isShowedOverlay"
            [class.toggle__chevron_big]="bigChevron"></span>
        </button>
    </div>
    <ng-template #overlayBox>
      <app-autocomplete-list [isOpen]="isOpen"
                             [isMobile]="isMobile"
                             [searchDisabled]="searchDisabled"
                             [fieldModel]="fieldModel"
                             [customWidth]="customWidth"
                             [isPressedUpDown]="isPressedUpDown"
                             [selectedId]="selectedId"
                             [byGroups]="byGroups"
                             [isShowClassEmpty]="markEmptyValue && enableSelectEmpty"
                             [filteredItems]="filteredItems"
                             (fieldModelUpdate)="fieldModel = $event; fieldModelChanged()"
                             (selectUpdate)="select($event)"
                             (closeEvent)="close()"
                             (keypressHandlerChange)="keypressHandler($event)"></app-autocomplete-list>
    </ng-template>
  </div>
</div>
