import { Component, Input, OnInit } from '@angular/core';
import { StateService, UIRouter } from '@uirouter/core';
import { IOMRedirectService } from '../../iom-redirect/iom-redirect.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-questionary-rewards',
  templateUrl: './questionary-rewards.component.html',
  styleUrls: ['./questionary-rewards.component.scss']
})
export class QuestionaryRewardsComponent implements OnInit {
  @Input() testRewards: number;
  @Input() disabled: boolean;
  @Input() selfControlBtn: boolean;
  @Input() firstTime: boolean;
  @Input() showRewards: boolean;
  @Input() dryRun = false;

  cycleId: string;
  questionaryType: string;
  testIsCompleted: boolean;
  redirectStateName: string;
  fromPlan = false;

  constructor(
    private route: UIRouter,
    private iomRedirectService: IOMRedirectService,
    private stateService: StateService,
  ) { }

  ngOnInit() {
    this.cycleId = this.route.stateService.params.cycleId;
    this.questionaryType = this.route.stateService.params.questionaryType;
    this.testIsCompleted = this.route.stateService.params.completed;
    this.fromPlan = this.route.stateService.params.fromPlan;

    if (this.questionaryType === 'satisfaction' && !this.fromPlan) {
      this.redirectStateName = 'userAccount.portfolio';
    } else if (this.questionaryType === 'satisfaction' && this.fromPlan) {
      localStorage.setItem('masteredTab', 'true');
      this.redirectStateName = 'userAccount.cycleSelect.myPlan';
    } else {
      this.redirectStateName = 'userAccount.cycleSelect.eduTrajectoryHelper';
    }

    if (this.disabled) {
      this.back();
    }
  }

  get showThanksText(): boolean {
    if (this.questionaryType === 'satisfaction' ||
        (this.questionaryType === 'professional' && this.showRewards)) {
      return false;
    }
    return true;
  }

  get getShowSelfControl(): boolean {
    if (this.firstTime) {
      return true;
    }
    return false;
  }

  get testType(): string {
    return this.questionaryType;
  }

  redirectToSelfControl() {
    this.iomRedirectService.redirectToSelfAssessment(this.cycleId);
  }

  back(): void {
    if (this.dryRun) {
      const href = `${environment.cubaUiUrl.replace(/\/#$/, '')}/open?screen=`;
      const screen = this.questionaryType === 'professional'
        ? 'nmifo$ProfessionalSurvey.browse'
        : 'nmifo$PsychologyTest.browse';
      window.location.href = href + screen;
    } else {
      this.stateService.go(
        this.redirectStateName,
        { cycleId: this.cycleId },
        { reload: false }
      );
    }
  }

  showZet(): boolean {
    if (this.questionaryType === 'satisfaction' || this.testIsCompleted) {
      return false;
    }

    if (this.questionaryType === 'professional') {
      return this.showRewards;
    }

    return true;
  }
}
