import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { StateService } from '@uirouter/core';
import { links } from '../../config/app-properties';
import { SecurityService } from '../security/security.service';
import { INavigationItem } from '../submenu/submenu.component';
import { environment } from '../../environments/environment';
import { TourService } from '../tour/tour.service';
import { RouteService } from '../app-routing/route.service';
import { PopupComponent } from '../app-commons/popup/popup.component';
import { CabinetsService } from '../cabinets/cabinets.service';
import {AvailabilityDto, PersonalCabinetResponseSchema} from '@rsmu/portal-api';
import { CyclesResponseSchema } from '@rsmu/portal-api';
import { getSidebarSettings, SidebarSettings } from '../app-commons/sidebar/sidebar-settings';
import _forEach from 'lodash/forEach';
import _isFunction from 'lodash-es/isFunction';
import _isNil from 'lodash-es/isNil';
import { Observable } from 'rxjs';
import { NotificationService } from '../notification/service/notification.service';
import { MessageNotification } from '@rsmu/portal-api';
import {filter, map, take, takeUntil, tap} from 'rxjs/operators';
import { PopupAction } from '../app-commons/popup/interfaces/popup.dto';
import { AccessibleService } from '../app-commons/accessible/accessible.service';
import { KeyListenerService } from '../key-listener/key-listener.service';
import { CyclesService } from '../cycles/cycles.service';
import { EduPlanService } from '../edu-plan/edu-plan.service';
import { MobileAppService } from '../mobile/mobile-app.service';
import { FeatureService } from '../app-commons/feature/feature.service';
import { FeatureKeys } from '../app-commons/feature/feature-keys';
import { ForumService } from '../user-account/forum/forum.service';
import { ManagedComponent } from '../app-commons/managed-component';
import {DomSanitizer} from '@angular/platform-browser';
import {EduElementProspectService} from '../user-account/edu-element-prospect/edu-element-prospect.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends ManagedComponent implements OnInit, OnDestroy {

  readonly popupAction = PopupAction;
  readonly maxNotifications = 3;
  readonly routeTrajectoryHelper = 'userAccount.cycleSelect.eduTrajectoryHelper';
  readonly routeMyPlan = 'userAccount.cycleSelect.myPlan';

  private setupHelpMenuCallback: Function;
  private setupMyCabinetsCallback: Function;

  currentCabinet: string;
  cycles: CyclesResponseSchema[];
  extraOptions: INavigationItem[];
  helpMenuItems: INavigationItem[];
  myCabinets: INavigationItem[];

  headerNavOpened = false;
  selectCycleOpened = false;
  selectCycleIsMyPlan = false;
  helperClicked = false;
  myPlanClicked = false;

  environment = environment;

  notificationsDataSource: Observable<INavigationItem[]>;
  outsideCyclesActive = false;
  eduElementProspectsAvailability$: Observable<AvailabilityDto>;

  @ViewChild('popupHelp', { static: true }) private popupHelp: PopupComponent<{ helpId: string }>;

  private cycleSelectEscapeId: number;
  private headerNavOpenedEscapeId: number;

  get areNotificationsEnabled$(): Observable<boolean> {
    return this.featureService.isFeatureEnabled$(FeatureKeys.NOTIFICATIONS);
  }

  get isActiveTrajectoryHelperRoute(): boolean {
    return this.stateService.current.data.eduTrajectoryHelperRoute;
  }

  get isForumEnabled$(): Observable<boolean> {
    return this.forumService.isForumEnabled$;
  }

  get isShowBackButton(): boolean {
    return this.routeService.isShowBackButton &&
      (this.selectCycleOpened ||
        !this.isLoginPage());
  }

  get sidebarSettings(): SidebarSettings {
    return getSidebarSettings(this.selectCycleIsMyPlan);
  }

  get isActiveAccessible(): boolean {
    return this.accessibleService.isActive;
  }

  get hasVirtualTourId() {
    const virtualTourId: string = this.stateService.$current.data.helpOptions
      ? this.stateService.$current.data.helpOptions.virtualTourId
      : null;
    return virtualTourId;
  }

  get isMyPlanScreen(): boolean {
    return this.stateService.includes('**.myPlan');
  }
  readonly currentCycleId$: Observable<string> = this.cyclesService.getCurrentCycle();

  constructor(
    private readonly securityService: SecurityService,
    private readonly tourService: TourService,
    private readonly mobileAppService: MobileAppService,
    private readonly routeService: RouteService,
    private readonly stateService: StateService,
    private readonly cabinetsService: CabinetsService,
    private readonly notificationService: NotificationService,
    private readonly accessibleService: AccessibleService,
    private readonly keyListener: KeyListenerService,
    private readonly cyclesService: CyclesService,
    private readonly eduPlanService: EduPlanService,
    private readonly featureService: FeatureService,
    private readonly forumService: ForumService,
    private readonly domSanitizer: DomSanitizer,
    private readonly eduElementProspectService: EduElementProspectService
  ) {
    super();
  }

  ngOnInit() {
    this.setupHelpMenu();
    this.setupExtraOptions();
    this.setupMyCabinets();

    this.setupHelpMenuCallback = this.routeService.registerOnSuccessTransitionCallback(this.setupHelpMenu.bind(this));
    this.setupMyCabinetsCallback = this.routeService.registerOnSuccessTransitionCallback(this.setupMyCabinets.bind(this));

    this.securityService.getUserChangedEvent().pipe(filter((userData) => userData != null)).subscribe(() => {
      this.createNotificationsDataSource();
      if (this.securityService.isLoggedIn()) {
        this.eduElementProspectsAvailability$ = this.eduElementProspectService.getEduElementProspectAvailability();
      }
    });

    if (this.securityService.isLoggedIn()) {
      this.createNotificationsDataSource();
      this.eduElementProspectsAvailability$ = this.eduElementProspectService.getEduElementProspectAvailability();
    }

    this.currentCycleId$
      .pipe(takeUntil(this.destroy$))
      .subscribe((currentCycleId: string) => this.outsideCyclesActive = _isNil(currentCycleId));
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.setupHelpMenuCallback();
    this.setupMyCabinetsCallback();
  }

  showHelpIcon() {
    if (this.firstTime() && this.hasVirtualTourId) {
      return true;
    } else {
      return !this.firstTime();
    }
  }

  isActiveRoute(uri: string): boolean {
    return this.stateService.is(uri);
  }

  isLoggedIn(): boolean {
    return this.securityService.isLoggedIn();
  }

  isLoginPage(): boolean {
    return this.stateService.is('root.login');
  }

  isSertificateConfirmationPage(): boolean {
    return this.stateService.is('certificateConfirmation');
  }

  isEduProgramSelectPage(): boolean {
    return this.stateService.is('eduProgramSelect');
  }

  isActivitySelectionPage(): boolean {
    return this.stateService.is('activitySelection');
  }

  logout(): void {
    this.securityService.logout();
  }

  firstTime(): boolean {
    return this.stateService.$current.data.firstTime;
  }

  toggleHeaderNav(): void {
    this.headerNavOpened ? this.closeHeaderNav() : this.openHeaderNav();
    this.closeCycleSelect();
  }

  openHeaderNav(): void {
    this.headerNavOpened = true;
    this.headerNavOpenedEscapeId = this.keyListener.addEscapeListener(() => this.closeHeaderNav());
  }

  eduSectionSelected() {
    this.currentCycleId$.pipe(take(1))
      .subscribe(cycleId => {
        if (!cycleId) {
          this.cyclesService.loadPrevCycleToCurrent();
        }
      });
    this.closeHeaderNav();
  }

  closeHeaderNav(): void {
    this.headerNavOpened = false;
    this.keyListener.removeEscapeListener(this.headerNavOpenedEscapeId);
  }

  turnOnAccessible(): void {
    this.accessibleService.turnOn();
  }

  openSelectCycle(route: string): void {
    if (!this.isActiveRoute(route)) {
      this.cyclesService.removeCurrentCycle();
    }

    this.selectCycleOpened = true;
    this.selectCycleIsMyPlan = route === this.routeMyPlan;
    this.cycleSelectEscapeId = this.keyListener.addEscapeListener(() => this.closeCycleSelect());
  }

  closeCycleSelect(): void {
    this.selectCycleOpened = false;
    this.keyListener.removeEscapeListener(this.cycleSelectEscapeId);
    this.helperClicked = false;
    this.myPlanClicked = false;
  }

  cycleChanged(cycleId: string): void {
    if (this.selectCycleIsMyPlan) {
      // this.eduPlanService.loadEduPlan(cycleId);
      this.stateService.go('userAccount.cycleSelect.myPlan', { cycleId });
    } else {
      this.stateService.go('userAccount.cycleSelect.eduTrajectoryHelper', { cycleId });
    }
    this.closeHeaderNav();
  }

  outCycleSelected(): void {
    // this.eduPlanService.loadEduPlan();
    this.stateService.go('userAccount.cycleSelect.myPlan', { cycleId: null });

    this.cyclesService.removeCurrentCycle();
    this.closeHeaderNav();
  }

  onClickSidebarItem(): void {
    this.cyclesService.removeCurrentCycle();
    this.closeCycleSelect();
    this.closeHeaderNav();
  }

  back(): void {
    if (this.selectCycleOpened) {
      this.closeCycleSelect();
    } else {
      this.routeService.goBack();
      this.closeHeaderNav();
    }
  }

  helperClick(): void {
    this.helperClicked = true;
    this.myPlanClicked = false;
  }

  myPlanClick(): void {
    this.helperClicked = false;
    this.myPlanClicked = true;
  }

  private setupMyCabinets(): void {
    if (this.securityService.isLoggedIn()) {
      this.cabinetsService.getCurrentCabinet().subscribe((cabinet: PersonalCabinetResponseSchema) => {
        this.currentCabinet = cabinet == null ? null : cabinet.name;
        this.loadUserCabinets();
      });
    }
  }

  private loadUserCabinets() {
    this.cabinetsService.subscribeToCabinetUpdates().subscribe((cabinets: Array<PersonalCabinetResponseSchema>) => {
      this.myCabinets = [];
      cabinets.forEach(cabinet => this.myCabinets.push(this.convertCabinetToNavigation(cabinet)));
    });
  }

  private convertCabinetToNavigation(cabinet: PersonalCabinetResponseSchema): INavigationItem {
    let url: string = null;
    let callback: () => any = () => { };
    if (cabinet.name !== this.currentCabinet) {
      callback = null;
      url = this.cabinetsService
        .convertCabinetUrlForNavigation(cabinet.cabinetUrl, cabinet.isSpecialist);
    }
    return {
      callback: callback,
      url: url && this.domSanitizer.bypassSecurityTrustUrl(url),
      label: cabinet.name,
      state: null
    };
  }

  private setupHelpMenu(): void {
    const navigationItems: INavigationItem[] = [];

    const virtualTourId: string = this.stateService.$current.data.helpOptions
      ? this.stateService.$current.data.helpOptions.virtualTourId
      : null;
    if (virtualTourId) {
      navigationItems.push(
        {
          label: 'help.virtualTour',
          callback: () => {
            this.tourService.startTour(virtualTourId);
          }
        }
      );
    }

    navigationItems.push(this.mobileAppService.isMobileApp() ? {
      label: 'help.support',
      callback: () => this.mobileAppService.goToOtherSite(links.loginHelp)
    } : {
      label: 'help.support',
      url: links.loginHelp,
      linkTarget: '_blank'
    });

    const stateHelpId: string | null | ((stateService: StateService) => string | null) =
      this.stateService.$current.data.helpOptions
        ? this.stateService.$current.data.helpOptions.helpId
        : null;
    const helpId: string | null = _isFunction(stateHelpId)
      ? (stateHelpId as (stateService: StateService) => string | null)(this.stateService)
      : stateHelpId as string | null;

    if (!_isNil(helpId)) {
      navigationItems.push(
        {
          label: 'help.f1',
          callback: () => {
            this.popupHelp.show({ helpId });
          }
        }
      );
    }

    navigationItems.push(
      this.mobileAppService.isMobileApp() ?
      {
        label: 'help.userManual',
        callback: () => {
          this.mobileAppService.downloadFile(environment.userManualUrl, 'RSMU-NMIFO-user-manual.pdf');
        }
      } :
      {
        label: 'help.userManual',
        url: environment.userManualUrl,
          isDownloadLink: true,
      });
    this.helpMenuItems = navigationItems;
  }

  private createNotificationsDataSource(): void {
    this.notificationsDataSource = this.notificationService.getUnreadMessages()
      .pipe(map((items: MessageNotification[]) => {
        return items.slice(0, this.maxNotifications);
      }), tap((items: MessageNotification[]) => {
        this.notificationService.markFilteredMessagesAsRead(items);
      }), map((items: MessageNotification[]) => {
        const navigationItems: INavigationItem[] = [];
        _forEach(items, (item: MessageNotification) => {
          navigationItems.push({
            label: item.message,
            notificationImportance: item.importance,
            notificationReceived: item.received
          });
        });
        return navigationItems;
      }));
  }

  private setupExtraOptions(): void {
    this.extraOptions = [
      {
        label: 'header.extraOptions.logout',
        callback: () => {
          this.securityService.logout();
        }
      }
    ];
  }

}
