import { Component, Input } from '@angular/core';
import { QuestionaryResultResponseSchema } from '@rsmu/portal-api';
import { ColorCodeEnumSchema } from '@rsmu/portal-api';


@Component({
  selector: 'app-questionary-results-item',
  templateUrl: './questionary-results-item.component.html',
  styleUrls: ['./questionary-results-item.component.scss']
})
export class QuestionaryResultsItemComponent {
  @Input() result: QuestionaryResultResponseSchema;
  @Input() maxScore: number;
  colorCode = ColorCodeEnumSchema;
  constructor() { }

  get getColor() {
    return this.result.colorCode;
  }

  get score(): number {
    return this.result.score;
  }

  get key(): string {
    return this.result.key;
  }

  get interpretation(): string {
    return this.result.interpretation;
  }

  get transcript(): string {
    return this.result.transcript;
  }

}
