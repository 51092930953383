import { Directive, ElementRef, Inject, Input, NgZone, OnDestroy } from '@angular/core';
import { CdkTrapFocus, FocusTrapFactory } from '@angular/cdk/a11y';
import { DOCUMENT } from '@angular/common';
import { take } from 'rxjs/operators';

@Directive({
  selector: '[appTrapFocus]'
})
export class TrapFocusDirective extends CdkTrapFocus implements OnDestroy {

  private lastFocus: HTMLElement;

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private focusTrapFactory: FocusTrapFactory,
    @Inject(DOCUMENT) private document: Document,
    private ngZone: NgZone,
  ) {
    super(elementRef, focusTrapFactory, document);
  }

  @Input() set appTrapFocus(isEnabled: boolean) {
    this.enabled = isEnabled;
    if (this.enabled) {
      this.createFocusTrapWhenReady();
      this.setInitialFocus();
    } else {
      this.restoreFocus();
    }
  }

  ngOnDestroy(): void {
    this.restoreFocus();
    super.ngOnDestroy();
  }

  private setInitialFocus(): void {
    this.lastFocus = this.document.activeElement as HTMLElement;
    setTimeout(() => {
      this.focusTrap.focusFirstTabbableElementWhenReady();
    });
  }

  private restoreFocus(): void {
    if (this.lastFocus) {
      this.executeWhenReady(() => {
        this.lastFocus.focus();
        this.lastFocus = null;
      });
    }
  }

  private createFocusTrapWhenReady(): void {
    this.executeWhenReady(() => {
      this.focusTrap.destroy();
      this.focusTrap = this.focusTrapFactory.create(this.elementRef.nativeElement, true);
    });
  }

  private executeWhenReady(fn: () => void): Promise<void> {
    return new Promise<void>(() => {
      this.executeOnStable(fn);
    });
  }

  private executeOnStable(fn: () => void): void {
    if (this.ngZone.isStable) {
      fn();
    } else {
      this.ngZone.onStable.asObservable().pipe(take(1)).subscribe(fn);
    }
  }

}
