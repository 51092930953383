import {Directive, ViewContainerRef} from '@angular/core';

/**
 * The purpose of this directive is to expose it's viewContainerRef so that parent component can dynamically render
 * components inside it.
 *
 * This is where actual ngx-charts components end up.
 */
@Directive({
  selector: '[appDynamicComponent]'
})
export class DynamicComponentDirective {

  constructor(
    public viewContainerRef: ViewContainerRef
  ) { }

}
