<app-dropdown *ngIf="!mobile" [theme]="'submenu'">

  <button *ngIf="label"
          class="button button_text submenu__toggle submenu__toggle_arrow  {{ toggleModificator }}"
          type="button"
          appDropdownToggle>
    {{ label }}
  </button>

  <button *ngIf="iconSrc"
          class="button button_text button_primary submenu__toggle {{ toggleModificator }}"
          type="button"
          appDropdownToggle>
    <svg-icon class="icon {{ iconModificator }}"
              src="{{ iconSrc }}"
              role="presentation"></svg-icon>
  </button>

  <button *ngIf="extraOptions"
          class="button button_text submenu__toggle submenu__toggle_dots {{ toggleModificator }}"
          [title]="'tips.hidden.true' | translate"
          type="button"
          appDropdownToggle>
    <span class="figure figure_more"></span>
  </button>

  <div class="submenu__dropdown-menu {{ menuModificator }}"
       [class.submenu__dropdown-menu_extra-options]="extraOptions"
       [class.submenu__dropdown-menu_notifications]="notifications"
       appDropdownContent>
    <div class="popover_theme_submenu"
         *ngIf="!navItemsSource; else dropdownAsyncSubmenu">
      <ng-template [ngTemplateOutlet]="dropdownSubmenu"></ng-template>
    </div>
  </div>

  <ng-template #dropdownAsyncSubmenu>
    <app-popover
      [theme]="'popover_theme_submenu'"
      [position]="'bottom'">
      <ng-template [ngTemplateOutlet]="dropdownSubmenu"></ng-template>
    </app-popover>
  </ng-template>

  <ng-template #dropdownSubmenu>
    <app-loader class="submenu__loader" *ngIf="itemsLoading"></app-loader>
    <div *ngIf="!itemsLoading">
      <div *ngIf="notifications"
           [ngClass]="{ 'submenu__notifications-header_flex': hasNavItems }"
           class="submenu__notifications-header text text_base">
        {{ (hasNavItems ? 'header.notifications' : 'header.notifications.noItems') | translate }}
        <a *ngIf="(hasNavItems)" class="text text_small button button_text button_primary"
           (click)="cleanAllNotifications()">
          {{ 'header.notifications.cleanAll' | translate }}
        </a>
      </div>
      <ul class="submenu-list">
        <li class="submenu-list__item" *ngFor="let item of navItems; trackBy: trackByFn">
          <a *ngIf="item.state != null" [uiSref]="item.state" (click)="clickSubmenuItem()"
             class="link submenu-list__link">{{ item.label | translate }}</a>
          <div uiSref="userAccount.notifications"
               (click)="toggle()"
               class="submenu__notifications-item notification-color_{{ item.notificationImportance }}"
               *ngIf="notifications">
            <div class="text text_small" (click)="stopInnerEvents($event)" [innerHTML]="item.label"></div>
            <div class="submenu__notifications-received text text_small text_muted-light">
              {{ item.notificationReceived | timesAgo | async }}
            </div>
          </div>
          <div *ngIf="notifications" class="submenu__notifications-separator"></div>
          <a *ngIf="item.url != null && !item.isDownloadLink" [attr.href]="item.url" target={{item.linkTarget}}
             (click)="clickSubmenuItem()" class="link submenu-list__link">{{ item.label | translate }}</a>
          <a *ngIf="item.url != null && item.isDownloadLink" [attr.href]="item.url"
             [download]="item.url" target="_blank"
             class="link submenu-list__link">{{ item.label | translate }}</a>
          <a *ngIf="item.callback != null" (click)="item.callback(); toggle(); clickSubmenuItem()"
             class="link submenu-list__link submenu-list__link_callback">{{ item.label | translate }}</a>
        </li>
      </ul>
      <div *ngIf="notifications" class="submenu__notifications-footer">
        <a class="text text_small button button_text button_primary"
           uiSref="userAccount.notifications"
           (click)="toggle()">
          {{ 'header.notifications.showAll' | translate }}
        </a>
      </div>
    </div>
  </ng-template>
</app-dropdown>

<div *ngIf="mobile" class="submenu submenu_accordion">
  <button *ngIf="label && !iconWithLabel"
          class="button button_text submenu__toggle {{ toggleModificator }}"
          [class.submenu__toggle_opened]='accordionOpened'
          type="button"
          (click)="toggleAccordion()">
    {{ label }}
  </button>

  <button *ngIf="iconWithLabel"
          class="button button_text button_primary submenu__toggle {{ toggleModificator }}"
          [class.submenu__toggle_opened]='accordionOpened'
          type="button"
          (click)="toggleAccordion()">
    <svg-icon class="icon {{ iconModificator }}" src="{{ iconSrc }}" role="presentation"></svg-icon>
    <span>{{ label }}</span>
  </button>

  <div class="submenu-accordion" [class.submenu-accordion_opened]='accordionOpened'>
    <ul class="submenu-list" [hidden]="!accordionOpened">
      <li class="submenu-list__item" *ngFor="let item of navItems; trackBy: trackByFn">
        <a *ngIf="item.url && item.state"
           [uiSref]="item.state"
           (click)="clickSubmenuItem()"
           class="link submenu-list__link">
           {{ item.label | translate }}
        </a>
        <a *ngIf="item.url && item.linkTarget"
           [attr.href]="item.url"
           [target]="item.linkTarget"
           (click)="clickSubmenuItem()"
           class="link submenu-list__link">
           {{ item.label | translate }}
        </a>
        <a *ngIf="item.url && !item.linkTarget"
           [attr.href]="item.url"
           (click)="clickSubmenuItem(); startNavigate()"
           class="link submenu-list__link">
           {{ item.label | translate }}
        </a>
        <button
          (click)="item.callback(); clickSubmenuItem();"
          class="link submenu-list__link submenu-list__link_callback"
          *ngIf="item.callback != null">
          {{ item.label | translate }}
        </button>
      </li>
    </ul>
  </div>
</div>
