<div class="multi-select">
  <div class="multi-select__column">
    <button class="multi-select__button" mat-raised-button color="primary"
            (click)="openDialog()" *ngIf="selectedItemsCount$ | async as selectedItemsCount">
      <ng-container *ngIf="selectedItemsCount === 1">
        {{ selectedOneItem }}
      </ng-container>
      <ng-container *ngIf="selectedItemsCount > 1 ">
        {{label}} - {{selectedItemsCount}}
      </ng-container>
    </button>
    <button class="multi-select__button" mat-stroked-button color="primary" (click)="openDialog()" *ngIf="!(selectedItemsCount$ | async)">
      {{label}}
    </button>
  </div>
</div>




