import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {BehaviorSubject, isObservable, Observable, of, Subject} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {Multiselect2DialogComponent} from './multiselect2-dialog/multiselect2-dialog.component';
import {first, map, take, takeUntil, tap} from 'rxjs/operators';
import {Multiselect2DialogSsfComponent} from './multiselect2-dialog-ssf/multiselect2-dialog-ssf.component';


@Component({
  selector: 'app-multiselect2',
  templateUrl: './multiselect2.component.html',
  styleUrls: ['./multiselect2.component.scss']
})
export class Multiselect2Component <T> implements OnInit, OnDestroy {

  get dataSource(): Array<T> | Observable<Array<T>> | ((offset, limit, esOrg) => Observable<Array<T>>) | null {
    return this._dataSource;
  }
  @Input()
  set dataSource(ds: Array<T> | Observable<Array<T>> | ((offset, limit, esOrg) => Observable<Array<T>>) | null) {
    if (ds instanceof Array) {
      this._dataSource = of(ds);
    } else if (isObservable(ds)) {
      this._dataSource = ds;
    } else if (ds instanceof Function) {
      this._serverSideFilter = true;
      this._dataSource = ds;
    }
  }
  private _dataSource: Observable<Array<T>> | ((offset, limit, esOrg) => Observable<Array<T>>) | null;

  @Input()
  disabled = false;

  @Input()
  label = 'label';

  @Input()
  set  itemName (itemName: string) {
    this._itemName = itemName;
  }
  private _itemName = 'name';

  @Input()
  set  itemSecondName (itemName: string) {
    this._itemSecondName = itemName;
  }
  private _itemSecondName = null;

  @Input()
  set  itemValue (itemValue: string) {
    this._itemValue = itemValue;
  }
  private _itemValue = 'id';

  @Input()
  set reset(resetSubj$: Subject<void>) {
    this._resetSubj$ = resetSubj$;
  }
  private _resetSubj$: Subject<void>;

  @Input()
  set selectedItems(selectedItems: T[]) {
    this._selectedItems$.next(selectedItems);
  }
  private _selectedItems$: BehaviorSubject<Array<T>>;
  @Input()
  set selectedItemsStr(selectedItems: string[]) {
    this._selectedItemsStr = selectedItems;
  }
  private _selectedItemsStr: string[];


  @Output()
  selected: EventEmitter<T[]> = new EventEmitter<T[]>();

  @Output()
  selectedArrOfStr: EventEmitter<string[]> = new EventEmitter<string[]>();

  get selectedItemsCount$ (): Observable<number> {
    return this._selectedItems$.asObservable()
      .pipe(
        map(items => items?.length));
  }

  get selectedOneItem$ (): Observable<string> {
    return this._selectedItems$.asObservable()
      .pipe(map(items => {
        if (items?.length > 0) {
          return items[0] && items[0][this._itemName || this._itemSecondName];
        } else {
          return null;
        }
      }));
  }

  @Input()
  set  selectionLimit(selectionLimit: number) {
    this._selectionLimit = selectionLimit;
  }

  protected _dialogComponent = null;
  private _selectionLimit = null;
  private _itemsCache$: BehaviorSubject<Array<T>> = new BehaviorSubject<Array<T>>([]);
  private _serverSideFilter = false;
  private _unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private readonly dialog: MatDialog
  ) {
    this._selectedItems$ = new BehaviorSubject<Array<T>>(null);
  }

  getDialog() {
    if (!this._serverSideFilter) {
      const itemsListCache = this._itemsCache$.getValue();
      if (itemsListCache?.length > 0) {
        this.dataSource = itemsListCache;
      }
      return Multiselect2DialogComponent;
    } else {
      return Multiselect2DialogSsfComponent;
    }
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  ngOnInit(): void {
    this._initComponent();
    this._dialogComponent = this.getDialog();
    this._initSelectedItemsStr();
  }

  nothing(): void {}

  openDialog() {
    const dialogRef = this.dialog.open(this._dialogComponent, {
      data: {
        dataSource: this._dataSource,
        itemName: this._itemName || this._itemSecondName,
        itemValue: this._itemValue,
        selectedItems$: this._selectedItems$,
        selectionLimit: this._selectionLimit,
        serverSideFilter: this._serverSideFilter
      },
      height: '80vh',
      width: '80vw'
    });
    dialogRef.afterClosed()
      .pipe(take(1))
      .subscribe(dialogResult => {
        if (!this._serverSideFilter && dialogResult?.itemsList) {
          this._itemsCache$.next(dialogResult.itemsList);
        }
        if (dialogResult?.confirm) {
          this.selected.emit(this._selectedItems$.getValue());
          this.selectedArrOfStr.emit(this._selectedItems$.getValue().map(el => el && el[this._itemValue]));
        }
      });
  }

  protected _initSelectedItemsStr(): void {
    if (this._selectedItemsStr && this._selectedItemsStr.length > 0) {
      if (isObservable(this._dataSource)) {
        this._dataSource
          .pipe(
            first(),
            tap((items: T[]) =>
              this._selectedItems$.next(this._selectedItemsStr.map((sI) => items.find(it => it[this._itemValue] === sI)))))
          .subscribe((items: T[]) => this._itemsCache$.next(items));
      }
    }
  }

  protected _initComponent(): void {
    if (!this.dataSource) {
      throw new Error ('You have to bind dataSource to multiselect2.component: ' + this.label);
    }
    if (this._resetSubj$) {
      this._resetSubj$.asObservable()
        .pipe(takeUntil(this._unsubscribe$))
        .subscribe(() => {
          this._selectedItems$.next([]);
        });
    }
  }
}
