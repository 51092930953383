import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ImportanceEnum } from '@rsmu/portal-api';
import { Observable } from 'rxjs';
import { filter, finalize, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { DropdownComponent } from '../app-commons/dropdown/dropdown.component';
import { ManagedComponent } from '../app-commons/managed-component';
import _identity from 'lodash-es/identity';
import _isNil from 'lodash-es/isNil';
import { GlobalLoadingService } from '../global-loading/global-loading.service';
import { LayoutService } from '../app-commons/layout/layout.service';
import { LayoutSize } from '../app-commons/layout/layout-breakpoints';
import { NotificationService } from '../notification/service/notification.service';
import {SafeUrl} from '@angular/platform-browser';

export interface INavigationItem {
  label: string;
  state?: string;
  url?: SafeUrl | null;
  isDownloadLink?: boolean;
  callback?: () => any;
  linkTarget?: string;
  notificationImportance?: ImportanceEnum;
  notificationReceived?: string;
}

@Component({
  selector: 'app-submenu',
  templateUrl: './submenu.component.html',
  styleUrls: [ './submenu.component.scss' ]
})
export class SubmenuComponent extends ManagedComponent implements AfterViewInit, OnDestroy, OnInit {

  @ViewChild(DropdownComponent) private dropdown: DropdownComponent<{}>;

  @Input() label: string;
  @Input() iconSrc: string;
  @Input() extraOptions: boolean;
  @Input() navItems: INavigationItem[] = [];
  @Input() navItemsSource: Observable<INavigationItem[]>;
  @Input() mobile = false;
  @Input() iconWithLabel = false;
  @Input() iconModificator: string;
  @Input() toggleModificator: string;
  @Input() menuModificator: string;
  @Input() notifications: boolean;

  @Output() readonly clickItem = new EventEmitter<void>();

  accordionOpened = false;
  itemsLoading = false;

  private previousSize: LayoutSize;

  get hasNavItems(): boolean {
    return this.navItems && this.navItems.length > 0;
  }

  constructor(
    private globalLoadingService: GlobalLoadingService,
    private layoutService: LayoutService,
    private notificationService: NotificationService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.layoutService
      .sizeChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        const currentSize: LayoutSize = this.layoutService.isMobile
          ? LayoutSize.MOBILE
          : LayoutSize.DESKTOP;

        if (this.previousSize !== currentSize) {
          this.previousSize = currentSize;
          if (this.dropdown && this.dropdown.isOpen) {
            this.dropdown.close();
          }
        }
      });
  }

  ngAfterViewInit(): void {
    if (!_isNil(this.dropdown) && !_isNil(this.navItemsSource)) {
      this.dropdown
        .toggled
        .pipe(
          takeUntil(this.destroy$),
          filter(_identity),
          tap(() => {
            this.itemsLoading = true;
          }),
          mergeMap(() => this.navItemsSource.pipe(finalize(() => this.itemsLoading = false)))
        )
        .subscribe((res: INavigationItem[]) => {
          this.navItems = res;
          this.itemsLoading = false;
        });
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  trackByFn(index): number {
    return index;
  }

  toggle(): void {
    this.dropdown.toggle();
  }

  toggleAccordion(): void  {
    this.accordionOpened = !this.accordionOpened;
  }

  clickSubmenuItem(): void {
    this.clickItem.emit();
  }

  startNavigate(): void {
    this.globalLoadingService.startLoading();
  }

  stopInnerEvents($event: MouseEvent): void {
    if ($event.target !== $event.currentTarget) {
      $event.stopPropagation();
    }
  }

  cleanAllNotifications(): void {
    this.notificationService.markAllMessagesAsRead(
      this.hasNavItems ? this.navItems.length : 0,
      this.toggle.bind(this))
    ;
  }
}
