import {Directive, Input, OnDestroy} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {mergeMap, takeUntil} from 'rxjs/operators';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: 'mat-autocomplete[serverSideFilterOptions]'
})
export class AutocompleteServerSideFilterOptionsDirective <T> implements OnDestroy {

  @Input()
  loadingFn: ((offset, limit, textFilterString) => Observable<Array<T>>);
  @Input()
  set offset(offset: number) {
    this._offset = offset;
    if (typeof offset === 'number' && this._offset > 0) {
      this._events$.next();
    }
  }
  private _offset: number;
  @Input()
  optionsList$: BehaviorSubject<T[]>;
  @Input()
  set textFilterString(textFilterString: string) {
    this._textFilterString = textFilterString;
    this.optionsList$.next([]);
    if (typeof textFilterString === 'string' && this._offset === 0) {
      this._events$.next();
    }
  }
  private _events$: Subject<void> = new Subject<void>();
  private _textFilterString: string;
  private _unsubscribe$: Subject<void> = new Subject<void>();

  constructor() {
    setTimeout(() => {
      this._events$
        .pipe(
          takeUntil(this._unsubscribe$),
          mergeMap(() => this.loadingFn(this._offset, 10, this._textFilterString)
        ))
        .subscribe(res => this.optionsList$.next([
          ...this.optionsList$.getValue(), ...res
        ]));
    }, 0);
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }
}
