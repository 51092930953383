<ng-container *ngIf="isAccessibleToggleEnabled">
  <app-accessible class="header__accessible"
                  *ngIf="isActiveAccessible; else accessibleToggleButtonTmpl"></app-accessible>
</ng-container>

<ng-template #accessibleToggleButtonTmpl>
  <button class="accessible-toggle__button"
          (click)="turnOnAccessible()">
    <svg-icon class="icon icon_23_w accessible-toggle__icon" src="assets/icons/eye.svg"></svg-icon>
    <span>{{ 'accessible.link' | translate }}</span>
  </button>
</ng-template>
