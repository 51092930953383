import { Pipe, PipeTransform } from '@angular/core';
import _isString from 'lodash/isString';
import _isObject from 'lodash/isObject';
import _isNumber from 'lodash/isNumber';
import { DateTime } from 'luxon';

@Pipe({
  name: 'luxon'
})
export class LuxonPipe implements PipeTransform {

  transform(value: any, formatTo?: any, formatFrom?: any): any {
    let dt;

    if (!value) {
      return '';
    }

    if (_isString(value)) {
      if (formatFrom) {
        dt = DateTime.fromFormat(value, formatFrom);
      } else {
        dt = DateTime.fromMillis(+value);
        if (!dt.ts) {
          if (value.split('-')[0].length < 4) {
            value = value.split('-').reverse().join('-');
          }
          dt = DateTime.fromISO(value);
        }
      }
    } else if (_isNumber(value)) {
      dt = DateTime.fromMillis(value);
    } else if (_isObject(value)) {
      dt = DateTime.fromObject(value);
    }

    return dt.toFormat(formatTo);
  }

}
