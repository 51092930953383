import { QuestionaryRouteParams } from './questionary-interface/questionary-route-params';
import { EducationalElementTypeEnum, ProfQuestionaryResponseSchema } from '@rsmu/portal-api';
import { QuestionaryType } from './questionary-interface/questionary-type';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ApiService } from '../../api/api.service';
import { tap } from 'rxjs/operators';
import { QuestionaryPsychologicalTest } from '@rsmu/portal-api';
import { QuestionaryPhase } from './questionary-interface/questionary-phase';
import { Injectable } from '@angular/core';

@Injectable()
export abstract class QuestionaryService <Q, A, DA> {

  questionary: ProfQuestionaryResponseSchema;
  psyQuestionary: QuestionaryPsychologicalTest;

  displayAnswers: DA[] = null;

  activePhase: QuestionaryPhase;
  prevPhase: QuestionaryPhase;
  activeQuestionIndex = 0;
  dryRun: boolean;
  isDisabled: boolean;
  questionaryType: QuestionaryType;
  sendProcess = false;
  topicId: string;

  isErrorSend = false;

  protected destroy$ = new Subject<void>();

  protected activeQuestion$ = new BehaviorSubject<Q>(null);
  protected allAnswers: A[] = [];

  protected questionaryId: string;
  protected cycleId: string;
  protected eduElementType: EducationalElementTypeEnum;
  protected selfAssessmentUrl: string;

  protected readonly totalPercent = 100;

  constructor(
    protected apiService: ApiService,
  ) {
  }

  init(params: QuestionaryRouteParams): Observable<any> {
    this.questionaryId = params.questionaryId;
    this.cycleId = params.cycleId;
    this.topicId = params.topicId;
    this.questionaryType = params.questionaryType;
    this.eduElementType = params.eduElementType;
    this.dryRun = params.dryRun;
    this.selfAssessmentUrl = params.selfAssessmentUrl;

    return this.getQuestionaryFromApi()
      .pipe(
        tap(() => this.initListeners())
      );
  }

  switchInstructionsDisplay() {
    if (this.activePhase === QuestionaryPhase.INSTRUCTIONS) {
      if (this.prevPhase) {
        this.activePhase = this.prevPhase;
      } else {
        // start test when closing instructions for the first time
        this.startTest();
      }
    } else {
      this.prevPhase = this.activePhase;
      this.activePhase = QuestionaryPhase.INSTRUCTIONS;
    }
  }

  isQuestionaryPausedForInstructions(): boolean {
    return this.prevPhase != null;
  }

  abstract get numberOfQuestions(): number;

  abstract get activeQuestion(): Q;

  get getPercentageOfCompletion(): number {
    return this.activeQuestionIndex * this.totalPercent / this.numberOfQuestions;
  }

  get isReadonly(): boolean {
    return false;
  }

  get questionaryKeyTitle(): string {
    return `questionary.${this.questionaryType}${this.selfControlLevel}`;
  }

  abstract get instruction(): string;

  destroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  abstract startTest(): void;

  abstract nextQuestion(): void;

  abstract previousQuestion(): void;

  protected abstract initListeners(): void;

  protected abstract sendAnswersToApi(): void;

  protected abstract getQuestionaryFromApi(): Observable<any>;

  private get selfControlLevel(): string {
    if (this.typeIsSelfControl) {
      return this.topicId ? '.second' : '.first';
    } else {
      return '';
    }
  }

  private get typeIsSelfControl(): boolean {
    return this.questionaryType === QuestionaryType.SELF_CONTROL;
  }
}
