export class FeatureKeys {
  static readonly ESIA = 'feature.esia.enable';
  static readonly ANDROID_ESIA = 'feature.esia.mobile.android.enable';
  static readonly IOS_ESIA = 'feature.esia.mobile.ios.enable';
  static readonly FORUM = 'feature.forum.enable';
  static readonly NOTIFICATIONS = 'feature.specialistNotifications.enable';
  static readonly PUSH_NOTIFICATIONS = 'feature.pushNotifications.enable';
  static readonly RATING = 'feature.rating.enable';
  static readonly NMO_SYNC = 'feature.nmoSync.enable';
  static readonly SENTRY_DESKTOP = 'feature.sentry.desktop.enable';
  static readonly SENTRY_ANDROID = 'feature.sentry.android.enable';
  static readonly SENTRY_IOS = 'feature.sentry.ios.enable';
}
