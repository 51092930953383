import { Inject, Injectable, Optional } from '@angular/core';
import { BASE_PATH, Configuration, DefaultService } from '@rsmu/portal-api';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiService extends DefaultService {

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration) {
    super(httpClient, basePath, configuration);
  }

}
