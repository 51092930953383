<app-popup [showClose]="showClose"
           [themeList]="themeList"
           (popupHide)="onConfirmationHide($event)">
  <div class="confirm-popup"
       [class.confirm-popup_minimal]="isMinimal">
    <h3 class="confirm-popup__title"
        [class.confirm-popup__title_minimal]="isMinimal"
        [innerHtml]="message">
    </h3>
    <div class="confirm-popup__actions">
      <button
        *ngFor="let button of buttons"
        [ngClass]="'button confirm-popup__button ' + button.style"
        (click)="onClick(button.action)">
        {{ button.key | translate }}
      </button>
    </div>
  </div>
</app-popup>
