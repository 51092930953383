import { Injectable } from '@angular/core';
import { interval, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { ApiService } from '../../api/api.service';
import { catchError, first, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { MessageNotification } from '@rsmu/portal-api';
import { MessageNotificationLong } from '@rsmu/portal-api';
import _isFunction from 'lodash-es/isFunction';
import { environment } from '../../../environments/environment';

const DEFAULT_OFFSET = 0;

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  readonly notificationsPollingInterval: number = environment.notificationsPollingIntervalMs;

  private notificationsAmountSubject: ReplaySubject<number>;
  private stopPollingSubject: Subject<void> = new Subject<void>();
  private watchers = 0;

  constructor(private apiService: ApiService) { }

  getNotificationsAmountDataSource() {
    this.watchers ++;
    if (!this.notificationsAmountSubject) {
      this.createNotificationsDataSource();
    }
    return this.notificationsAmountSubject;
  }

  stopObserving() {
    if (this.watchers === 0) {
      return;
    }
    this.watchers--;
    if (this.watchers === 0 && this.notificationsAmountSubject != null) {
      this.stopPollingSubject.next();
      this.notificationsAmountSubject = null;
    }
  }

  getUnreadMessages(): Observable<Array<MessageNotification>> {
    return this.apiService.retrievListOfUnreadMessages();
  }

  getMessagesPage(limit: number, offset: number): Observable<Array<MessageNotificationLong>> {
    return this.apiService.retrievFullListMessagesByLimitation(limit, offset);
  }

  markMessagesAsRead(messages: Array<MessageNotificationLong>) {
    const messagesFiltered: Array<MessageNotification> =
      messages.filter(message => message.unread).map(message => message.message);
    this.markFilteredMessagesAsRead(messagesFiltered);
  }

  markAllMessagesAsRead(currentDisplayedMessages = 5, callback?: () => void) {
    this.notificationsAmountSubject.pipe(
      switchMap(((messageCounter: number) => this.apiService
        .retrievFullListMessagesByLimitation(messageCounter + currentDisplayedMessages, DEFAULT_OFFSET))),
      first()
    ).subscribe((messages: Array<MessageNotificationLong>) => {
        this.markFilteredMessagesAsRead(messages.map(message => message.message));

        if (_isFunction(callback)) {
          callback();
        }
      });
  }

  markFilteredMessagesAsRead(messages: Array<MessageNotification>) {
    if (messages.length > 0) {
      this.apiService.markUnreadMessagesAsRead(messages.map((message) => message.id)).subscribe(() => {
        this.updateNotificationsCount();
      });
    }
  }

  private createNotificationsDataSource(): void {
    this.notificationsAmountSubject = new ReplaySubject(1);
      interval(this.notificationsPollingInterval)
        .pipe(
          startWith(0),
          takeUntil(this.stopPollingSubject)
        )
        .subscribe(() => this.updateNotificationsCount());
  }

  private updateNotificationsCount(): void {
    if (this.notificationsAmountSubject) {
      this.apiService
        .retrievNumberUnreadMessages()
        .pipe(catchError(() => of(0)))
        .subscribe((notificationsAmount: number) => {
          if (this.notificationsAmountSubject) {
            this.notificationsAmountSubject.next(notificationsAmount);
          }
        });
    }
  }
}
