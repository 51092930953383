<ng-content></ng-content>
<span class="password-group__btn"
      role="button"
      [attr.tabindex]="tab"
      appAccessibleLink
      (click)="toggle();">
  <svg-icon src="assets/icons/eye.svg"
            class="icon icon_16"
            [class.password-group__btn_toggled]="shown"
            [title]="title | translate"></svg-icon>
</span>
