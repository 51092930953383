<section class="search-select-question">
  <div *ngFor="let dropdown of displayAnswers; let i = index;trackBy:trackByIndex;"
       class="search-select-question__input">
    <app-autocomplete
      class="search-select-question__autocomplete"
      label=""
      itemLabel="title"
      [value]='dropdown'
      placeholder="{{ 'autocomplete.placeholder' | translate }}"
      [items]='options'
      [searchDisabled]='false'
      [disabled]="disabled"
      (valueChange)="answerChanged($event, i)"
      [updateFilterEvent]="updateSubject"
      [filterFunction]="optionsFilter">
    </app-autocomplete>

    <span (click)="deleteAutocomplete(i)"
          *ngIf="isShowDelete"
          class="search-select-question__remove-btn">
      <span class="search-select-question__figure-cross"></span>
    </span>
  </div>
  <button *ngIf="multiSelectAvailable && !disabled"
          class="button button_text text text_big button_primary search-select-question__button"
          (click)="addNewAnswerOption()">
    {{ 'questionary.add' | translate }}
  </button>
</section>
