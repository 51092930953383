import {Injectable, Injector} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import { Observable } from 'rxjs';
import {tap} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {DialogService} from '../app-commons/dialog/dialog.service';

@Injectable()
export class SimpleResponseInterceptor implements HttpInterceptor {
  constructor(private readonly injector: Injector) {
  }

  private get dialogService(): DialogService {
    return this.injector.get(DialogService);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      this.isDelettingOfEducationalElementFromPlan(req) ||
      this.isChangeProfileDataFromProfile(req)
    ) {
      return next.handle(req).pipe(
        tap((res) => {
          if (res instanceof HttpResponse && res.ok && res.body) {
            this.showSimpleResponseDialog(res.body.simpleResponse || res.body);
          }
        })
      );
    } else {
      return next.handle(req);
    }
  }

  private isChangeProfileDataFromProfile (req: HttpRequest<any>): boolean {
    return req.method === 'POST' && req.url.includes(environment.baseRestApiUrl + '/profile') && req.body && !!req.body.email;
  }

  private isDelettingOfEducationalElementFromPlan (req: HttpRequest<any>): boolean {
    return req.method === 'POST' &&
      req.url.includes(environment.baseRestApiUrl + '/educational-elements/application-order-delete/request') ;
  }

  private showSimpleResponseDialog (simpleResponse) {
    if (simpleResponse.code !== 0) {
      simpleResponse.descriptionRu ? this.dialogService.message(simpleResponse.descriptionRu) :
      simpleResponse.descriptionEn ? this.dialogService.message(simpleResponse.descriptionEn) :
      this.dialogService.messageTranslate('set description on backend');
    }
  }

}



