import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { forkJoin, Observable, of } from 'rxjs';

import { environment } from '../../environments/environment';

export class TranslateHttpLoaderWithSpeechModules extends TranslateHttpLoader {

  private cachedDictionary: any;

  constructor(http: HttpClient, prefix?: string, suffix?: string) {
    super(http, prefix, suffix);
  }

  getTranslation(lang: string): any {
    return forkJoin([this.getOriginDictionary(lang),
    ]).pipe(map(res => {
      const [origin] = res;
      this.cachedDictionary = origin;
      return origin;
    }));
  }

  getOriginDictionary(lang: string): Observable<any> {
    return this.cachedDictionary ? of(this.cachedDictionary) : super.getTranslation(lang);
  }
}

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoaderWithSpeechModules(httpClient, environment.translateDictUrl, '.json');
}

export function HttpLoaderFactoryDeps() {
  return [HttpClient];
}
