import {
  ContentChild,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Output,
  TemplateRef
} from '@angular/core';
import { SortType } from './sort-type';

const CELL_TYPES = {
  header: 'header-col',
  data: 'column',
  dataCard: 'column-card'
};

export class BaseCell {
  constructor(columnDef: ColumnDefDirective, elementRef: ElementRef, type: string) {
    const columnClassName = `grid__${type}_${columnDef.nameForCssClass}`;
    elementRef.nativeElement.classList.add(columnClassName);
  }
}

@Directive({
  selector: '[appHeaderCellDef]'
})
export class HeaderCellDefDirective {
  constructor(public template: TemplateRef<any>) {}
}

@Directive({
  selector: '[appCellDef]'
})
export class CellDefDirective {
  constructor(public template: TemplateRef<any>) {}
}

@Directive({
  selector: '[appCardCellDef]'
})
export class CardCellDefDirective {
  constructor(public template: TemplateRef<any>) {}
}

@Directive({
  selector: '[appSortableColumn]'
})
export class SortableColumnDirective implements OnInit {
  // tslint:disable-next-line:no-input-rename
  @Input('appSortableColumn') map: string | ((v: any) => string | number);

  // TODO, this field not presort list before rendering
  @Input() appDefaultSortableColumn = false;

  status: SortType = SortType.NOT_SORTED;

  @HostBinding('class.grid__header-col_sortable')
  sortable = true;

  @HostBinding('class.grid__header-col_sorted_asc')
  get isAsc() {
    return this.status === SortType.ASCENDING;
  }
  @HostBinding('class.grid__header-col_sorted_desc')
  get isDesc() {
    return this.status === SortType.DESCENDING;
  }
  @Output() sortEventEmitter: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {
    if (this.appDefaultSortableColumn) {
      this.status = SortType.ASCENDING;
    }
  }

  @HostListener('click') triggerSortEvent() {
    this.sortEventEmitter.emit();
  }

  addSortListener(listener) {
    this.sortEventEmitter.subscribe(listener);
  }
}

@Directive({
  selector: '[appColumnDef]'
})
export class ColumnDefDirective {
  @Input('appColumnDef')
  get name() { return this._name; }
  set name(name: string) {
    if (!name) {
      return;
    }

    this._name = name;
    this.nameForCssClass = name.replace(/[^a-z0-9_-]/ig, '-');
  }
  private _name: string;

  @ContentChild(HeaderCellDefDirective) header: HeaderCellDefDirective;
  @ContentChild(CellDefDirective) cell: CellDefDirective;
  @ContentChild(CardCellDefDirective) card: CardCellDefDirective;
  @ContentChild(SortableColumnDirective) sortable: SortableColumnDirective;

  nameForCssClass: string;
}

@Directive({
  selector: '[appHeaderCell]'
})
export class HeaderCellDirective extends BaseCell {
  @HostBinding(`class.grid__${CELL_TYPES.header}`) isHeaderCell = true;

  constructor(columnDef: ColumnDefDirective, elementRef: ElementRef) {
    super(columnDef, elementRef, CELL_TYPES.header);
  }
}

@Directive({
  selector: '[appCell]'
})
export class CellDirective extends BaseCell {
  @HostBinding(`class.grid__${CELL_TYPES.data}`) isCell = true;

  constructor(columnDef: ColumnDefDirective, elementRef: ElementRef) {
    super(columnDef, elementRef, CELL_TYPES.data);
  }
}

@Directive({
  selector: '[appCardCell]'
})
export class CardCellDirective {
  @HostBinding(`class.grid__${CELL_TYPES.dataCard}`) isCardCell = true;
}
