import * as Sentry from '@sentry/browser';
import { Event } from '@sentry/types';
import { ErrorHandler, Injectable, Injector, OnDestroy } from '@angular/core';
import { MobileAppService } from '../mobile/mobile-app.service';
import { FeatureService } from '../app-commons/feature/feature.service';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import _identity from 'lodash-es/identity';
import { FeatureKeys } from '../app-commons/feature/feature-keys';

type Platform = 'desktop' | 'android' | 'ios';

@Injectable()
export class SentryErrorHandler extends ErrorHandler implements OnDestroy {

  private static readonly FEATURE_PLATFORM_KEYS: Map<Platform, string> = new Map<Platform, string>([
    ['desktop', FeatureKeys.SENTRY_DESKTOP],
    ['android', FeatureKeys.SENTRY_ANDROID],
    ['ios', FeatureKeys.SENTRY_IOS]
  ]);

  protected readonly destroy$: Subject<void> = new Subject<void>();

  get isAllowedToSendSentryErrors$(): Observable<boolean> {
    if (this.mobileAppService.isMobileAndroidApp()) {
      return this.featureService.isFeatureEnabled$(SentryErrorHandler.FEATURE_PLATFORM_KEYS.get('android'));
    }
    if (this.mobileAppService.isMobileIosApp()) {
      return this.featureService.isFeatureEnabled$(SentryErrorHandler.FEATURE_PLATFORM_KEYS.get('ios'));
    }
    return this.featureService.isFeatureEnabled$(SentryErrorHandler.FEATURE_PLATFORM_KEYS.get('desktop'));
  }

  private get featureService(): FeatureService {
    return this.injector.get(FeatureService);
  }

  private get mobileAppService(): MobileAppService {
    return this.injector.get(MobileAppService);
  }

  constructor(private readonly injector: Injector) {
    super();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  sendCustomError(customError: any, message: string): void {
    this.captureSentryError(
      customError,
      (err) => {
        const event: Event = {
          message: message,
          extra: {
            customError: err
          }
        };
        Sentry.captureEvent(event);
      }
    );
  }

  handleError(error): void {
    if (error) {
      super.handleError(error);
    }

    this.captureSentryError(
      error,
      (err) => {
        let originalError;
        if (err && err.originalError) {
          originalError = err.originalError;
        }
        Sentry.captureException(originalError || err);
      }
    );
  }

  private captureSentryError(error, errorProcessor: (any) => void): void {
    this.isAllowedToSendSentryErrors$
      .pipe(
        takeUntil(this.destroy$),
        filter(_identity)
      )
      .subscribe(() => {
        if (errorProcessor) {
          errorProcessor.apply(this, [error]);
        }
      });
  }

}
