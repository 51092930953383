import { Component } from '@angular/core';
import { AbstractQuestionOption } from '../abstract-question-option';

@Component({
  selector: 'app-integer-question',
  templateUrl: './integer-question.component.html',
})
export class IntegerQuestionComponent extends AbstractQuestionOption {

  filter = /[0-9]*/g;

  constructor() {
    super();
  }
}
