import {
  AfterContentInit,
  AfterViewChecked,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  ViewChild
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import _drop from 'lodash-es/drop';
import _get from 'lodash-es/get';
import _isEmpty from 'lodash-es/isEmpty';
import _isNil from 'lodash-es/isNil';

import {
  CellOutletDirective,
  DataRowOutletDirective,
  HeaderRowOutletDirective,
  RowDefDirective,
  RowHeaderDefDirective
} from '../rows/rows';
import { ColumnDefDirective, SortableColumnDirective } from '../cols/cols';
import { SortType } from '../cols/sort-type';
import { PageChangeEvent, PaginationType } from '../pagination/pagination.component';
import { baseCompare, stringCompare } from '../../utils/compare';

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: [ './grid.component.scss' ]
})
export class GridComponent implements OnInit, AfterContentInit, AfterViewChecked, OnDestroy {

  /**
   * Static data for your table
   */
  @Input()
  get data(): any[] {
    return this._data;
  }
  set data(data: any[]) {
    this._data = data;
  }
  private _data: any[] = [];

  /**
   * Contains an Observable<any> from parent component. Allows you bind your table to REST API
   */
  @Input()
  get dataSource(): Observable<any[]> {
    return this._dataSource;
  }
  set dataSource(source: Observable<any[]>) {
    this._dataSource = source;
  }
  private _dataSource: Observable<any[]>;

  /**
   * @param infiniteScroll
   * Enables infinite scroll
   */
  @Input() infiniteScroll = false;

  /**
   * @param offset
   * provides start position for new items when infinite scroll enabled
   */
  @Input() offset = 0;
  @Input() isEduElementsShown: boolean;
  @Input() theme = '';
  @Input() enableCardCell = false;
  @Input() fixedHeader = false;
  @Input() sortable = false;
  @Input() paginated = false;
  @Input() pageIndex = 0;
  @Input() pageIndexOptionsCount = 5;
  @Input() pageSize = 20;
  @Input() pageSizeOptions = [ 10, 20, 50 ];
  @Input() paginationType: PaginationType = 'offset';
  @Input() stopOffsetSearch = false;
  @Input() total = 0;
  @Input() showWizard = false;

  @Output() readonly pageChange = new EventEmitter<PageChangeEvent>();

  /**
   * Executes everytime when user scrolls window and enabled with infinite scroll
   */
  @Output() scrolled = new EventEmitter();

  @ViewChild('tableEL', { read: ElementRef, static: true }) tableElement: ElementRef<HTMLElement>;
  @ViewChild(HeaderRowOutletDirective, { static: true }) _headerRowOutlet: HeaderRowOutletDirective;
  @ViewChild(DataRowOutletDirective, { static: true }) _dataRowOutlet: DataRowOutletDirective;

  @ContentChildren(ColumnDefDirective) _colDefs: QueryList<ColumnDefDirective>;
  @ContentChildren(RowDefDirective) _rowDef: QueryList<RowDefDirective>;
  @ContentChildren(RowHeaderDefDirective) _rowHeaderDef: QueryList<RowHeaderDefDirective>;

  private readonly dataSourceSub = new Subscription();
  private readonly isIE;
  private readonly tableFixedHeaderRowClass = 'grid__header-row_fixed';
  private readonly tableFixedHeaderWithWizardRowClass = 'grid__header-row_fixed_wizard';
  private readonly tableFixedHeaderRowIEClass = 'grid__header-row_fixed-IE';
  private _fixedHeaderCalculated = false;
  private _sortingInited = false;
  private currentSortingCol: SortableColumnDirective;

  constructor(private readonly renderer: Renderer2) {
    const ua: string = navigator.userAgent;

    /* MSIE used to detect old browsers and Trident used to newer ones */
    this.isIE = ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1;
  }

  ngOnInit() {
  }

  ngAfterContentInit() {
    this.renderHeaderRow();
    if (this.data && this.data.length !== 0) {
      this.renderRows();
    }

    this.observeRenderChanges();
  }

  ngAfterViewChecked() {
    if (!this._sortingInited) {
      this.initSorting();
    }
  }

  ngOnDestroy(): void {
    if (this.dataSourceSub) {
      this.dataSourceSub.unsubscribe();
    }
  }

  renderHeaderRow() {
    const viewContainer = this._headerRowOutlet.viewContainer;

    if (this._rowHeaderDef.first) {
      viewContainer.createEmbeddedView(this._rowHeaderDef.first.template);
    }

    if (CellOutletDirective.cellOutlet) {
      this.renderHeaderCells(CellOutletDirective.cellOutlet.viewContainer);
    }
  }

  renderRows() {
    if (this.sortable) {
      this.sort();
    }

    const viewContainer = this._dataRowOutlet.viewContainer;
    const data = this.infiniteScroll && this.offset ? _drop(this.data, this.offset) : this.data;

    if (_isEmpty(data)) {
      viewContainer.clear();
      return;
    }

    if (!this.infiniteScroll) {
      viewContainer.clear();
    }

    data.forEach((rowData) => {
      viewContainer.createEmbeddedView(this._rowDef.first.template, { $implicit: rowData });

      if (CellOutletDirective.cellOutlet) {
        const vc = CellOutletDirective.cellOutlet.viewContainer;

        this.renderDataCells(vc, rowData);
        this.renderCardCell(vc, rowData);
      }
    });
  }

  renderHeaderCells(viewContainer) {
    this._colDefs.forEach((col) => {
      if (col.header) {
        viewContainer.createEmbeddedView(col.header.template);
      }
    });
  }

  renderDataCells(viewContainer, rowData) {
    this._colDefs.forEach((col) => {
      viewContainer.createEmbeddedView(col.cell.template, {
        $implicit: rowData
      });
    });
  }

  renderCardCell(viewContainer, rowData) {
    const { first } = this._colDefs;

    if (!this.enableCardCell || first == null) {
      return;
    }

    viewContainer.createEmbeddedView(first.card.template, {
      $implicit: rowData
    });
  }

  onPageChange(pageChangeEvent: PageChangeEvent) {
    if (this.paginated) {
      this.pageChange.emit(pageChangeEvent);
    }
  }

  onInfiniteScroll(event) {
    if (this.infiniteScroll) {
      this.scrolled.emit(event);
    }
  }

  @HostListener('window:resize')
  onResize(): void {
    this.recalculateFixedHeader();
  }

  @HostListener('window:scroll')
  onScroll(): void {
    this.recalculateFixedHeader();
  }

  rerenderHeader() {
    const viewContainer = this._headerRowOutlet.viewContainer;

    viewContainer.clear();
    this.renderHeaderRow();
  }

  rerenderForColumnsChange(): void {
    this.clearRows();
    this.clearHeaderRows();
    this.rerenderHeader();
    this.rerenderRows();
  }

  rerenderRows() {
    const viewContainer = this._dataRowOutlet.viewContainer;

    viewContainer.clear();
    this.renderRows();
  }

  clearHeaderRows() {
    this._headerRowOutlet.viewContainer.clear();
  }

  clearRows() {
    this._dataRowOutlet.viewContainer.clear();
  }

  updateSortType(newColSortDir: SortableColumnDirective) {
    if (this.currentSortingCol && this.currentSortingCol !== newColSortDir) {
      this.currentSortingCol.status = SortType.NOT_SORTED;
    }

    this.currentSortingCol = newColSortDir;

    switch (this.currentSortingCol.status) {
      case SortType.NOT_SORTED:
        this.currentSortingCol.status = SortType.ASCENDING;
        break;
      case SortType.ASCENDING:
        this.currentSortingCol.status = SortType.DESCENDING;
        break;
      case SortType.DESCENDING:
        this.currentSortingCol.status = SortType.NOT_SORTED;
        break;
      default:
        this.currentSortingCol.status = SortType.NOT_SORTED;
    }
  }

  private initSorting() {
    if (!this._colDefs) {
      return;
    }

    const sortableList: SortableColumnDirective[] = this._colDefs
      .filter(colDef => colDef.sortable != null)
      .map(colDef => colDef.sortable);

    if (!sortableList.length) {
      return;
    }

    sortableList.forEach(sortable => {
      sortable.addSortListener(() => {
        this.updateSortType(sortable);
        this.rerenderRows();
      });
    });

    const defaultSortingCol = sortableList.find(sortable => sortable.appDefaultSortableColumn);
    if (defaultSortingCol) {
      this.currentSortingCol = defaultSortingCol;
    }

    this._sortingInited = true;
  }

  private sort() {
    if (!this.currentSortingCol || this.currentSortingCol.status === SortType.NOT_SORTED) {
      return;
    }

    const coef = this.currentSortingCol.status === SortType.ASCENDING ? 1 : -1;

    const map: (v: any) => string | any = typeof this.currentSortingCol.map === 'function'
      ? this.currentSortingCol.map
      : it => _get(it, this.currentSortingCol.map as string);

    this.data.sort((a, b) => {
      const aMapped = map(a);
      const bMapped = map(b);

      if (typeof aMapped === 'string') {
        return stringCompare(aMapped, bMapped) * coef;
      } else {
        return baseCompare(aMapped, bMapped) * coef;
      }
    });
  }

  private observeRenderChanges() {
    if (!this.dataSource) {
      return;
    }

    this.dataSourceSub
      .add(
        this.dataSource
          .subscribe((items) => {
            this.data = items;
            this.renderRows();
          })
      );
  }

  private recalculateFixedHeader(): void {
    if (!_isNil(this.tableElement) && this.fixedHeader) {
      const tableElm: HTMLElement = this.tableElement.nativeElement;
      const windowScrollTop: number = window.scrollY || document.documentElement.scrollTop;
      const deviation: number = tableElm.offsetTop - windowScrollTop;

      if (!this.isIE || deviation < 0) {
        this.setStylesForFixedHeader();
      } else {
        this.restoreDefaultsWithoutFixedHeader();
      }
    } else {
      this.restoreDefaultsWithoutFixedHeader();
    }
  }

  private setStylesForFixedHeader(): void {
    if (!this._fixedHeaderCalculated) {
      this._fixedHeaderCalculated = true;

      if (!_isNil(this.tableElement)) {
        const tableElm: HTMLElement = this.tableElement.nativeElement;
        const headerRow: HTMLElement = tableElm.querySelector<HTMLElement>('.grid__header-row');

        if (this.isIE) {
          const headerRowCells: NodeListOf<HTMLTableHeaderCellElement> = tableElm.querySelectorAll('th');
          const tableCells: NodeListOf<HTMLTableDataCellElement> = tableElm.querySelectorAll('td');

          for (let i = 0; i < headerRowCells.length; i++) {
            const headerRowCell: HTMLTableHeaderCellElement = headerRowCells.item(i);

            this.renderer.setStyle(headerRowCell, 'width', `${ Math.abs(headerRowCell.offsetWidth) }px`);
          }
          for (let i = 0; i < tableCells.length; i++) {
            const tableCell: HTMLTableDataCellElement = tableCells.item(i);

            this.renderer.setStyle(tableCell, 'width', `${ Math.abs(tableCell.offsetWidth) }px`);
          }
          this.renderer.addClass(headerRow, this.tableFixedHeaderRowIEClass);
        } else {
          this.renderer.addClass(headerRow, this.tableFixedHeaderRowClass);
        }

        if (this.showWizard) {
          this.renderer.addClass(headerRow, this.tableFixedHeaderWithWizardRowClass);
        }
      }
    }
  }

  private restoreDefaultsWithoutFixedHeader(): void {
    if (this._fixedHeaderCalculated) {
      this._fixedHeaderCalculated = false;

      if (!_isNil(this.tableElement)) {
        const tableElm: HTMLElement = this.tableElement.nativeElement;
        const headerRow: HTMLElement = tableElm.querySelector<HTMLElement>('.grid__header-row');

        if (this.isIE) {
          const headerRowCells: NodeListOf<HTMLTableHeaderCellElement> = tableElm.querySelectorAll('th');
          const tableCells: NodeListOf<HTMLTableHeaderCellElement> = tableElm.querySelectorAll('td');

          for (let i = 0; i < headerRowCells.length; i++) {
            const headerRowCell: HTMLTableHeaderCellElement = headerRowCells.item(i);

            this.renderer.removeStyle(headerRowCell, 'width');
          }
          for (let i = 0; i < tableCells.length; i++) {
            const tableCell: HTMLTableHeaderCellElement = tableCells.item(i);

            this.renderer.removeStyle(tableCell, 'width');
          }
          this.renderer.removeClass(headerRow, this.tableFixedHeaderRowIEClass);
        } else {
          this.renderer.removeClass(headerRow, this.tableFixedHeaderRowClass);
        }

        if (this.showWizard) {
          this.renderer.removeClass(headerRow, this.tableFixedHeaderWithWizardRowClass);
        }
      }
    }
  }
}
