import {Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild} from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import {finalize, map} from 'rxjs/operators';
import _fill from 'lodash-es/fill';
import _find from 'lodash-es/find';
import _head from 'lodash-es/head';
import _keys from 'lodash-es/keys';
import _map from 'lodash-es/map';
import {forkJoin, Observable, of} from 'rxjs';
import {
  AccreditationTypeSchema,
  ApiError,
  CreateCycleStatusEnum,
  DocumentCreationInfoDTO,
  DocumentTypeEnumSchema,
  EducationStuffDTO,
  SpecialitiesDTO
} from '@rsmu/portal-api';
import { TranslateService } from '@ngx-translate/core';
import { dateToday } from '../../utils/date-today';

import { ApiService } from '../../api/api.service';
import { customValidator } from '../../validators/customizable-validator/customizable-validator';
import { FrmrDocumentsService } from '../../user-init/frmr-documents.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DateTime } from 'luxon';
import { ValidationService } from '../../validation/validation.service';
import { DateUtils } from '../datepickers/date-utils';
import { ProfileService } from '../../profile/profile.service';
import {IMG_LINKS_CONFIG} from '../../utils/config-loader';

const minLicenseExamDate: NgbDateStruct = {
  year: 2016,
  month: 1,
  day: 1
};

interface ILabelValue {
  label: string;
  value: any;
}

interface IDocumentIdParams {
  seriesLength: number;
  numberLength: number;
}

enum AddDocFormControls {
  DOCUMENT_TYPE = 'documentType',
  EDU_ORGANIZATION = 'eduOrganization',
  NEW_EDU_ORGANIZATION = 'newEduOrganization',
  PREFERRED_EDU_ORGANIZATION = 'preferredEduOrganization',
  SPECIALITY = 'speciality',
  SERIES = 'series',
  NUMBER = 'number',
  DATE_OF_EXAM = 'dateOfExam',
  DATE_OF_ISSUE = 'dateOfIssue',
  CONFIRM_DATA_ACTUAL = 'confirmDataActual',
  CONFIRM_WARNED = 'confirmWarned',
  PROFESSIONAL_STANDARD = 'professionalStandard',
  PROCEDURE = 'procedure',
}


@Component({
  selector: 'app-add-document',
  templateUrl: './add-document.component.html',
  styleUrls: ['./add-document.component.scss']
})
export class AddDocumentComponent implements OnInit {

  constructor(
    private validationService: ValidationService,
    private formBuilder: UntypedFormBuilder,
    private apiService: ApiService,
    private datePipe: DatePipe,
    private frmrDocumentsService: FrmrDocumentsService,
    private translateService: TranslateService,
    private profileService: ProfileService,
    @Inject(IMG_LINKS_CONFIG)
    private imgLinksConfig: any
  ) {
    this.currentDate = dateToday();

    this.addDocForm = this.formBuilder.group(
      {
        [this.addDocFormControls.DOCUMENT_TYPE]: [DocumentTypeEnumSchema.Certificate, [Validators.required]],
        [this.addDocFormControls.EDU_ORGANIZATION]: ['', Validators.required],
        [this.addDocFormControls.NEW_EDU_ORGANIZATION]: ['', Validators.required],
        [this.addDocFormControls.PREFERRED_EDU_ORGANIZATION]: [''],
        [this.addDocFormControls.SPECIALITY]: ['', [Validators.required]],
        [this.addDocFormControls.DATE_OF_EXAM]: [null, [Validators.required]],
        [this.addDocFormControls.CONFIRM_DATA_ACTUAL]: [false, Validators.requiredTrue],
        [this.addDocFormControls.CONFIRM_WARNED]: [false, Validators.requiredTrue],
      },
      {
        validator: [
          customValidator(this.addDocFormControls.NEW_EDU_ORGANIZATION, () => this.manuallyAddEduOrganization),
          customValidator(this.addDocFormControls.EDU_ORGANIZATION, () => !this.manuallyAddEduOrganization),
        ]
      }
    );
    this.imgLinksConfig = imgLinksConfig;
    this.certImg = this.imgLinksConfig ? this.imgLinksConfig['add-document-specialist-cert'] : null;
  }

  get isMale(): boolean {
    return this.profileService.isMale();
  }

  get speciality(): string {
    return this._getValue('speciality');
  }

  get isDisabledSeriesAndNumber(): boolean {
    return !this.documentType || !this.speciality;
  }

  get documentType(): DocumentTypeEnumSchema {
    return this._getValue('documentType') as DocumentTypeEnumSchema;
  }

  set documentType(newDocumentType: DocumentTypeEnumSchema) {
    let maskSet: IDocumentIdParams;

    if (newDocumentType === DocumentTypeEnumSchema.Licence) {
      maskSet = this.licenseMaskSet;
      this.certImg = this.imgLinksConfig ? this.imgLinksConfig['add-document-accreditation-cert'] : null;
      this.addDocForm.addControl(this.addDocFormControls.PROFESSIONAL_STANDARD, new UntypedFormControl('', Validators.required));
      this.addDocForm.addControl(this.addDocFormControls.PROCEDURE, new UntypedFormControl('', Validators.required));
    } else if (newDocumentType === DocumentTypeEnumSchema.Certificate) {
      maskSet = this.certificateMaskSet;
      this.certImg = this.imgLinksConfig ? this.imgLinksConfig['add-document-specialist-cert']  : null;
      this.addDocForm.removeControl(this.addDocFormControls.PROFESSIONAL_STANDARD);
      this.addDocForm.removeControl(this.addDocFormControls.PROCEDURE);
    } else {
      return;
    }

    this.seriesMask = _fill(Array(maskSet.seriesLength), /\d/);
    this.numberMask = _fill(Array(maskSet.numberLength), /\d/);
  }

  get documentTypeIsLicence(): boolean {
    return this.documentType === DocumentTypeEnumSchema.Licence;
  }

  get documentTypeIsCertificate(): boolean {
    return this.documentType === DocumentTypeEnumSchema.Certificate;
  }

  get formErrors(): string[] {
    return _keys(this.addDocForm.errors);
  }

  get minDateIssue(): NgbDateStruct | null {
    if (!this.addDocForm) {
      return this.minDate;
    }

    const dateOfExamFormControl: AbstractControl = this.addDocForm.get(this.addDocFormControls.DATE_OF_EXAM);
    if (!dateOfExamFormControl) {
      return this.minDate;
    }

    const dateOfExam = dateOfExamFormControl.value
      ? DateTime.fromMillis(dateOfExamFormControl.value).toObject()
      : null;

    return dateOfExam ? {
      year: dateOfExam.year,
      month: dateOfExam.month,
      day: dateOfExam.day
    } : this.minDate;
  }

  get minExamDate(): NgbDateStruct | null {
    return !this.addDocForm || !this.documentTypeIsLicence ? null : minLicenseExamDate;
  }

  get showSerialNumber(): boolean {
    return  this._showSerialNumber;
  }

  get showExamPLace(): boolean {
    return this._showExamPlace;
  }

  readonly currentDate: NgbDateStruct;

  readonly licenseMaskSet = {
    seriesLength: 4,
    numberLength: 8
  };

  readonly certificateMaskSet = {
    seriesLength: 6,
    numberLength: 7
  };

  readonly DocumentTypeEnumSchema = DocumentTypeEnumSchema;
  readonly addDocForm: UntypedFormGroup;

  documentTypes: ILabelValue[] = [];
  specialityList: SpecialitiesDTO[];
  professionalStandardList: EducationStuffDTO[];
  procedureList: EducationStuffDTO[];

  seriesMask: (string | RegExp)[] = [];
  numberMask: (string | RegExp)[] = [];

  certImg: string;
  manuallyAddEduOrganization = false;
  dataLoaded = false;
  isLoading = false;

  private _showSerialNumber = false;
  private _showExamPlace = true;

  @Input() createCycle = false;
  @Input() wizard = false;

  @Output() addDocument = new EventEmitter<void>();
  @Output() cycleCreated: EventEmitter<any> = new EventEmitter;
  @Output() informationPopupOnHelper = new EventEmitter;
  @Output() confirmChangeCycle = new EventEmitter;
  @Output() documentCreatedButNotCycle = new EventEmitter;
  @Output() cycleCreatedNotActivated = new EventEmitter;

  @ViewChild('inputSeriesField', {static: false})
  inputSeriesFieldRef: ElementRef;
  @ViewChild('inputNumberField', {static: false})
  inputNumberFieldRef: ElementRef;

  attemptedToSubmit = false;

  readonly addDocFormControls = AddDocFormControls;
  private readonly minDate = DateUtils.DEFAULT_MIN_DATE;

  private static validatorMaskCompleteFunction(controlValue) {
    if (!controlValue || controlValue.indexOf('_') !== -1) {
      return { required: true };
    } else {
      return null;
    }
  }

  ngOnInit() {
    forkJoin(
      this.apiService.getProfessionalStandarts(),
      this.apiService.getProcedures(),
      this.apiService.getSpecialities())
      .pipe(finalize(() => this.dataLoaded = true))
      .subscribe((res: [EducationStuffDTO[], AccreditationTypeSchema[], SpecialitiesDTO[]]) => {
        [this.professionalStandardList, this.procedureList, this.specialityList] = res;
      });
    if (!this.createCycle) {
      this.addDocForm.removeControl('preferredEduOrganization');
    }
  }

  add(): void {
    this.attemptedToSubmit = true;
    if (!this.addDocForm.invalid) {
      this.isLoading = true;

      const request = this.frmrDocumentsService.addDocument({
        document: this.getFormValue(),
        createCycle: this.createCycle,
        preferredEduOrganizationId: this.createCycle
          ? this.addDocForm.get(this.addDocFormControls.PREFERRED_EDU_ORGANIZATION).value.id
          : null
      });

      request.pipe(finalize(() => this.isLoading = false)).subscribe(
        (res) => {
          this.addDocument.emit();
          if (res.status === CreateCycleStatusEnum.CycleCreatedActivated && !this.wizard) {
            this.informationPopupOnHelper.emit(res.id);
            this.cycleCreated.emit(res.id);
          }
          if (res.status === CreateCycleStatusEnum.CycleCreatedButAnotherCycleActive && !this.wizard) {
            this.confirmChangeCycle.emit({ cycleId: res.id, documentId: res.documentId });
          }
          if (res.status === CreateCycleStatusEnum.ProgrammeNotFoundDocumentCreated && !this.wizard) {
            this.documentCreatedButNotCycle.emit();
          }
          if (res.status === CreateCycleStatusEnum.CycleCreatedNotActivated && !this.wizard) {
            this.cycleCreatedNotActivated.emit(res.id);
          }
        },
        (err: HttpErrorResponse) => {
          this.validationService.setApiErrors(this.addDocForm, err.error as ApiError);
        }
      );
    } else {
      this.markDirtyFields();
    }
  }

  certImgError() {
    this.certImg = null;
  }

  customCertImageSizing(certImg) {
    const customStyle = {
      width: certImg.width,
      top: certImg.top,
      right: certImg.right
    };
    return customStyle;
  }

  serialNumberFocusTabulating($event) {
    if ($event.target.attributes.getNamedItem('ng-reflect-name').value === 'series' &&
      !AddDocumentComponent.validatorMaskCompleteFunction($event.target.value)
    ) {
      this.inputNumberFieldRef.nativeElement.focus();
    }
    if ($event.target.attributes.getNamedItem('ng-reflect-name').value === 'number' &&
      $event.inputType === 'deleteContentBackward' &&
      $event.target.value === ''
    ) {
      this.inputSeriesFieldRef.nativeElement.focus();
    }
  }


  toggleManuallyAddEduOrganization(): void {
    this.manuallyAddEduOrganization = !this.manuallyAddEduOrganization;
  }

  isFieldInvalid(fieldName: string): boolean {
    return this.validationService.hasFieldErrors(this.addDocForm, fieldName, this.attemptedToSubmit);
  }

  onExamDateChange(examDate): void {
    const DATE = new Date(2020, 0, 1).getTime();
    if (this.documentTypeIsCertificate || this.documentTypeIsLicence && !!examDate && (examDate < DATE)) {
      this.addDocForm.addControl(this.addDocFormControls.NUMBER, new UntypedFormControl('', Validators.required));
      this.addDocForm.addControl(this.addDocFormControls.SERIES, new UntypedFormControl('', Validators.required));
      this.addDocForm.addControl(this.addDocFormControls.DATE_OF_ISSUE, new UntypedFormControl('', Validators.required));
      this.addDocForm.addValidators(customValidator(this.addDocFormControls.DATE_OF_ISSUE, () => !this.documentTypeIsLicence));
      this._showSerialNumber = true;
    } else {
      this.addDocForm.removeControl(this.addDocFormControls.NUMBER);
      this.addDocForm.removeControl(this.addDocFormControls.SERIES);
      this.addDocForm.removeControl(this.addDocFormControls.DATE_OF_ISSUE);
      this.addDocForm.removeValidators(customValidator(this.addDocFormControls.DATE_OF_ISSUE, () => !this.documentTypeIsLicence));
      this._showSerialNumber = false;
    }
  }

  specialityChanged(selectedId: string): void {
    this.resetField(this.addDocFormControls.PROFESSIONAL_STANDARD);
    this.resetField(this.addDocFormControls.PROCEDURE);

    const speciality = _find(this.specialityList, (item: SpecialitiesDTO) => item.specialiti.id === selectedId);
    this.documentTypes = !!speciality ? this.transformDocumentTypeForAutocomplete(speciality.documentType) : [];
    const dt = _head(this.documentTypes);
    this.resetField(this.addDocFormControls.DOCUMENT_TYPE);
    if (dt) {
      this.documentType = dt.value;
      this.addDocForm.get(this.addDocFormControls.DOCUMENT_TYPE).setValue(dt.value);
      this.addDocForm.get(this.addDocFormControls.DOCUMENT_TYPE).updateValueAndValidity();
      this.docTypeChanged();
    }
  }

  procedureChanged(procedure): void {
    if (this.documentTypeIsLicence && (procedure === 'periodic')) {
      this.addDocForm.removeControl(this.addDocFormControls.EDU_ORGANIZATION);
      this._showExamPlace = false;
      return;
    }
    this.addDocForm.addControl(this.addDocFormControls.EDU_ORGANIZATION, new UntypedFormControl('', Validators.required));
    this._showExamPlace = true;
  }

  docTypeChanged(): void {
    this.resetField(this.addDocFormControls.SERIES);
    this.resetField(this.addDocFormControls.NUMBER);
    const examDate = this.addDocForm.get(this.addDocFormControls.DATE_OF_EXAM).value;
    this.onExamDateChange(examDate);
    this.procedureChanged(null);
    this.loadStuff();
  }

  getDisableIssue(): boolean {
    if (this.addDocForm.get(this.addDocFormControls.DATE_OF_EXAM).value !== null) {
      const date = this.addDocForm.get(this.addDocFormControls.DATE_OF_EXAM).value;
      const maxDate = DateTime.fromObject(this.currentDate).toMillis();

      return date > maxDate;
    }
  }

  private getFormValue(): DocumentCreationInfoDTO {
    const formattedDocValue = this.addDocForm.getRawValue();
    const doc: DocumentCreationInfoDTO = {
      documentNumber: formattedDocValue.number,
      documentSeries: formattedDocValue.series,
      documentType: formattedDocValue.documentType,
      dateOfExam: this.formatDate(formattedDocValue.dateOfExam),
      speciality: formattedDocValue.speciality,
      procedure: formattedDocValue.procedure,
      professionalStandard: formattedDocValue.professionalStandard,
      educationalOrganizationId: this.manuallyAddEduOrganization ?
        null : formattedDocValue.eduOrganization && formattedDocValue.eduOrganization.id,
      manuallyEnteredOrganizationName: this.manuallyAddEduOrganization ? formattedDocValue.newEduOrganization : null,
      preferredEducationalOrganizationId: null
    };

    if (this.documentTypeIsCertificate) {
      doc.dateOfIssue = this.formatDate(formattedDocValue.dateOfIssue);
    }

    return doc;
  }

  private markDirtyFields(): void {
    _keys(this.addDocForm.controls).forEach((key: string) => {
      const formControl: UntypedFormControl = this.addDocForm.get(key) as UntypedFormControl;
      formControl.markAsDirty();
    });
  }

  private formatDate(date: any): string {
    return this.datePipe.transform(date, 'yyyy-MM-dd');
  }

  private validatorMaskComplete(control: AbstractControl): ValidationErrors | null {
    return AddDocumentComponent.validatorMaskCompleteFunction(control.value);
  }

  private loadStuff(): void {
    const specialityId = this.addDocForm.get(this.addDocFormControls.SPECIALITY).value;
    const isLicence = this.documentType === DocumentTypeEnumSchema.Licence;
    this.professionalStandardList = [];
    this.procedureList = [];
    if (isLicence) {
      if (specialityId) {
        forkJoin(
          this.apiService.getProfessionalStandartsBySpeciality(specialityId),
          this.apiService.getProceduresBySpeciality(specialityId))
          .subscribe((res: [EducationStuffDTO[], AccreditationTypeSchema[]]) => {
            [this.professionalStandardList, this.procedureList] = res;
            if (this.professionalStandardList.length === 1) {
              const control = this.addDocForm.get(this.addDocFormControls.PROFESSIONAL_STANDARD);
              control.setValue(this.professionalStandardList[0].id);
            }
            if (this.procedureList.length === 1) {
              this.addDocForm.get(this.addDocFormControls.PROCEDURE).setValue(this.procedureList[0].id);
            }
          });
      } else {
        forkJoin(
          this.apiService.getProfessionalStandarts(),
          this.apiService.getProcedures())
          .subscribe((res: [EducationStuffDTO[], AccreditationTypeSchema[]]) => {
            [this.professionalStandardList, this.procedureList] = res;

            if (this.professionalStandardList.length === 1) {
              this.addDocForm.get(this.addDocFormControls.PROFESSIONAL_STANDARD).setValue(this.professionalStandardList[0].id);
            }
            if (this.procedureList.length === 1) {
              this.addDocForm.get(this.addDocFormControls.PROCEDURE).setValue(this.procedureList[0].id);
            }
          });
      }
    }
  }

  private _getValue(controlName: string): string {
    return this.addDocForm && this.addDocForm.get(controlName).value;
  }

  private resetField(fieldName: string): void {
    if (this.addDocForm.get(fieldName) && this.addDocForm.get(fieldName).value) {
      this.addDocForm.get(fieldName).reset();
    }
  }

  private transformDocumentTypeForAutocomplete(documentType: DocumentTypeEnumSchema[]): ILabelValue[] {
    let res = [];
    this.translateService.get(' ').subscribe(() => {
      res = _map(documentType, (value: DocumentTypeEnumSchema) => {
        return {
          label: this.translateService.instant(`documentType.${value}`),
          value
        };
      });
    });

    return res;
  }
}
