import { AfterViewInit, Component, ElementRef, Input, NgZone, ViewChild } from '@angular/core';
import { AccessibleMode, ColorCodeEnumSchema } from '@rsmu/portal-api';
import {
  SelfControlColors,
  SelfControlColorsMap,
  SelfControlColorsMapAccessibleBlack,
  SelfControlColorsMapAccessibleWhite
} from './self-control-theme';
import { AccessibleService } from '../../app-commons/accessible/accessible.service';
import { takeUntil } from 'rxjs/operators';
import { ManagedComponent } from '../../app-commons/managed-component';
import { merge } from 'rxjs';
import { stepAnimation } from '../../utils/animation';

@Component({
  selector: 'app-selfcontrol-result',
  templateUrl: './selfcontrol-result.component.html',
  styleUrls: ['./selfcontrol-result.component.scss']
})
export class SelfcontrolResultComponent extends ManagedComponent implements AfterViewInit {

  @Input() result: number;
  @Input() zet: number;
  @Input() color: ColorCodeEnumSchema = ColorCodeEnumSchema.Yellow;
  @Input() bg: SelfControlColors = SelfControlColors.White;
  @Input() getRewards: boolean;

  @ViewChild('circleElement') private circleElement: ElementRef;
  @ViewChild('processCircle') private processCircle: ElementRef;
  @ViewChild('zetProgressBox') private zetProgressBox: ElementRef;
  @ViewChild('percentsBox') private percentsBox: ElementRef;

  percents = 0;
  zetProgress = 0;
  colorCode = ColorCodeEnumSchema;

  constructor(
    private accessibleService: AccessibleService,
    private ngZone: NgZone,
  ) {
    super();
  }

  ngAfterViewInit(): void {

    this.ngZone.runOutsideAngular(() => {
      this.animateResult();
    });
    merge(
      this.accessibleService.changesSwitchOn,
      this.accessibleService.changesMode
    ).pipe(
      takeUntil(this.destroy$),
    )
    .subscribe(() => this.reRender());
  }

  getZetProgress(): string {
    return `+ ${this.zetProgress} ЗЕТ`;
  }

  private reRender(): void {
    this.percents = 0;
    this.zetProgress = 0;
    this.ngZone.runOutsideAngular(() => {
      this.animateResult();
    });
  }

  private circleAnimation(percents: number): void {
    const step = 1;
    const loops = 100 / step;
    const increment = 360 / loops;
    const half = Math.round(loops / 2);

    const bg = this.getColor(this.bg);
    const color = this.getColor(this.color);

    let nextDeg = 0;
    let backgroundImage = '';
    if (percents < half) {
      nextDeg = 90 + (increment * percents);
      backgroundImage = 'linear-gradient(90deg, ' + bg + ' 50%, transparent 50%, transparent), linear-gradient('
        + nextDeg + 'deg, ' + color + ' 50%, ' + bg + ' 50%, ' + bg + ' )';
    } else {
      nextDeg = -90 + (increment * (percents - half));
      backgroundImage = 'linear-gradient(' + nextDeg + 'deg, ' + color
        + ' 50%, transparent 50%, transparent), linear-gradient(270deg, ' + color + ' 50%,  ' + bg + '  50%,  ' + bg + ' )';
    }
    if (this.circleElement) {
      this.circleElement.nativeElement.style.backgroundImage = backgroundImage;
    }
  }

  private animateResult(): void {
    stepAnimation(
      0,
      this.result,
      45,
      (percents) => {
        if (percents) {
          this.percents = percents;
          this.zetProgress = Math.round(this.zet * (this.percents / this.result) * 10) / 10;
          this.circleAnimation(this.percents);
          this.setClassProcessCircle(this.getProcessCircleClassList());

          this.setProgress(this.getZetProgress());
          this.setPercent(this.percents);
        }
      },
      () => {
        this.zetProgress = this.zet;
        this.setProgress(this.getZetProgress());
        this.setPercent(this.result);
      }
    );
  }

  private getColor(key: ColorCodeEnumSchema | SelfControlColors): string {
    if (!this.accessibleService.isActive) {
      return SelfControlColorsMap.get(key);
    } else {
      return this.getAccessibleColor(key);
    }
  }

  private getAccessibleColor(key: ColorCodeEnumSchema | SelfControlColors): string {
    switch (this.accessibleService.getMode()) {
      case AccessibleMode.White:
        return SelfControlColorsMapAccessibleWhite.get(key);
      case AccessibleMode.Black:
        return SelfControlColorsMapAccessibleBlack.get(key);
    }
  }

  private getProcessCircleClassList(): string {
    return `progress-circle progress-circle__${this.percents} progress-circle_${this.bg}`;
  }

  private setPercent(percent: number): void {
    if (this.percentsBox && this.percentsBox.nativeElement) {
      this.percentsBox.nativeElement.innerText = percent;
    }
  }

  private setProgress(progress: string): void {
    if (this.zetProgressBox && this.zetProgressBox.nativeElement) {
      this.zetProgressBox.nativeElement.innerText = progress;
    }
  }

  private setClassProcessCircle(name: string): void {
    if (this.processCircle && this.processCircle.nativeElement) {
      this.processCircle.nativeElement.className = name;
    }
  }
}
