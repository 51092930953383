<div class="datepicker {{ theme }}" #overlayOrigin>
  <div class="datepicker__dropdown-toggle">
    <label class="form-control">
      <span class="form-control__label text text_base datepicker__label-text"
            *ngIf="label != null"
            [class.text_bold]="isBold"
            [class.form-control__label_mobile-hidden]="hideLabelOnMobile">{{ label }}</span>
      <span class="datepicker__input-box">
        <input class="text-field text-field_ignore-disabled datepicker__input"
               type="text"
               placeholder="{{'datepicker.formatRange' | translate}}"
               [class.datepicker__error]="isError"
               [class.datepicker__input_with-close]="canReset"
               [disabled]="disabled"
               [textMask]="{ mask: dateRangeMask }"
               [(ngModel)]="fieldModel"
               (ngModelChange)="inputChange($event)"/>
        <button *ngIf="canReset && fieldModel"
                type="button"
                [title]="'datepicker.reset' | translate"
                class="button datepicker__reset-button"
                [disabled]="disabled"
                (click)="resetDate()">
          <span class="figure figure_close-sm" role="presentation"></span>
        </button>
        <button class="datepicker__toggle toggle" type="button" (click)="toggle()">
          <svg-icon
            class="icon icon_gray icon_16"
            src="assets/icons/calendar.svg"
            title="{{'tips.calendar' | translate}}"></svg-icon>
        </button>
      </span>
    </label>
  </div>

  <ng-template #overlayBox>
    <div class="datepicker__dropdown-menu date-range" [appTrapFocus]="isOpen">
      <div class="date-range-list">
        <ngb-datepicker class="datepicker__picker date-range__item"
                        [disabled]="disabled"
                        [(ngModel)]="fromDate"
                        [startDate]="fromDate"
                        [minDate]="configFromInner.minDate"
                        [maxDate]="configFromInner.maxDate"
                        [outsideDays]="configFromInner.outsideDays"
                        (dateSelect)="onDateSelection($event, 'from')"></ngb-datepicker>
        <ngb-datepicker class="datepicker__picker date-range__item"
                        [disabled]="disabled || !fromDate"
                        [(ngModel)]="toDate"
                        [startDate]="toDate"
                        [minDate]="configToInner.minDate"
                        [maxDate]="configToInner.maxDate"
                        [outsideDays]="configToInner.outsideDays"
                        (dateSelect)="onDateSelection($event, 'to')"></ngb-datepicker>
      </div>
      <div class="date-range__submit-line">
        <button class="button button_primary date-range__submit" (click)="close()">
          {{ 'tips.close' | translate }}
        </button>
      </div>
    </div>
  </ng-template>
</div>
