import { ColorCodeEnumSchema } from '@rsmu/portal-api';

export enum SelfControlColors {
  White = 'White',
  Secondary = 'Secondary'
}

export const SelfControlColorsMap = new Map<SelfControlColors | ColorCodeEnumSchema, string>([
  [SelfControlColors.White, '#FFFFFF'],
  [SelfControlColors.Secondary, '#F9FAFD'],
  [ColorCodeEnumSchema.Green, '#00B740'],
  [ColorCodeEnumSchema.Red, '#FF728E'],
  [ColorCodeEnumSchema.Yellow, '#F7B44A']
]);

export const SelfControlColorsMapAccessibleWhite = new Map<SelfControlColors | ColorCodeEnumSchema, string>([
  [SelfControlColors.White, '#FFFFFF'],
  [SelfControlColors.Secondary, '#C9C9C9'],
  [ColorCodeEnumSchema.Green, '#000'],
  [ColorCodeEnumSchema.Red, '#000'],
  [ColorCodeEnumSchema.Yellow, '#000']
]);

export const SelfControlColorsMapAccessibleBlack = new Map<SelfControlColors | ColorCodeEnumSchema, string>([
  [SelfControlColors.White, '#000'],
  [SelfControlColors.Secondary, '#646464'],
  [ColorCodeEnumSchema.Green, '#FFF'],
  [ColorCodeEnumSchema.Red, '#FFF'],
  [ColorCodeEnumSchema.Yellow, '#FFF']
]);
