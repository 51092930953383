import { Component, Injector, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild, ViewContainerRef } from '@angular/core';
import { BaseselectComponent, DisplayItemsUpdateSource, IMultiselectDisplayItem } from '../baseselect/baseselect.component';
import _filter from 'lodash-es/filter';
import _isNil from 'lodash-es/isNil';
import _map from 'lodash-es/map';
import { PopupAction, PopupHideData } from '../../popup/interfaces/popup.dto';
import { PopupComponent } from '../../popup/popup.component';
import { PopupTheme } from '../../popup/interfaces/popup-theme';
import { KeyListenerService } from '../../../key-listener/key-listener.service';
import { LayoutService } from '../../layout/layout.service';
import { Overlay } from '@angular/cdk/overlay';


@Component({
  selector: 'app-multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: [
    '../baseselect/baseselect.component.scss',
    './multiselect.component.scss'
  ],
})
export class MultiselectComponent extends BaseselectComponent<IMultiselectDisplayItem[], any[]> implements OnChanges, OnDestroy, OnInit {

  @Input() commitAllCheckedAsEmpty = true;
  @Input() selectAllLabelKey: string;
  @Input() limitItems: number;
  @Input() limitItemsText: string;
  @Input() themePopover: string;
  @Input() popupThemes: PopupTheme[] = [PopupTheme.AUTO_HEIGHT];
  @Input() filterWarning: boolean;
  @Input() filterWarningText: string;

  @ViewChild('optionsPopup', { static: true }) optionsPopup: PopupComponent<{}>;

  readonly maxDisplaySelectedItemsCount = 30;
  showAllChecked = false;
  maxSelected = false;

  get committed(): IMultiselectDisplayItem[] {
    return this._commitedItems;
  }
  set committed(value: IMultiselectDisplayItem[]) {
    this._commitedItems = [ ...value ];
  }
  private _commitedItems: IMultiselectDisplayItem[] = [];

  // @ts-ignore
  get isOpen(): boolean {
    return !_isNil(this.optionsPopup) && this.optionsPopup.isOpen;
  }

  set isOpen(isOpen: boolean) {}

  get selected(): IMultiselectDisplayItem[] {
    return this._selected;
  }
  set selected(selected: IMultiselectDisplayItem[]) {
    this._selected = selected;
  }
  private _selected: IMultiselectDisplayItem[] = [];

  constructor(
    protected readonly injector: Injector,
    protected readonly keyListener: KeyListenerService,
    protected readonly layoutService: LayoutService,
    protected readonly overlay: Overlay,
    protected readonly viewContainer: ViewContainerRef,
  ) {
    super(
      injector,
      keyListener,
      layoutService,
      overlay,
      viewContainer
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.delayedSelect = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    if (changes.delayedSelect) {
      this.delayedSelect = true;
    }
  }

  confirmOptions() {
    this.optionsPopup.hide(PopupAction.CONFIRM);
  }

  denyOptions() {
    this.optionsPopup.hide(PopupAction.DENY);
  }

  deselectItemClick($event: Event, item: IMultiselectDisplayItem) {
    $event.stopImmediatePropagation();
    this.deselectItem(item);
  }

  onItemSelectionChange(isSelected: boolean) {
    this.selectItem();
    this.countSelectedItems();
    if (!isSelected) {
      this.showAllChecked = false;
    }
  }

  onOptionsPopupHide<P>({ action }: PopupHideData<P>) {
    if (action === PopupAction.CONFIRM) {
      this.commitSelect();
    }
    this.refreshDisplayedItems();
  }

  selectAll(value: boolean) {
    const selectingItems: (IMultiselectDisplayItem | any)[] = this.isFilterNotBlank
      ? this.displayItems
      : this.values;

    for (let i = 0; i < selectingItems.length; i++) {
      const item = selectingItems[i];

      item.selected = value;
    }
    this.selectItem();
  }

  protected afterDisplayedItemsUpdate(source: DisplayItemsUpdateSource) {
    super.afterDisplayedItemsUpdate(source);
    if (this.isOpen && source === DisplayItemsUpdateSource.FILTER) {
      this.refreshShowAllChecked();
    }
  }

  protected doCommitSelect(preselected?: boolean) {
    if (!preselected && !this.disableSelection) {
      if (this.commitAllCheckedAsEmpty && this.showAllChecked && this.committed.length === this.values.length) {
        this.select.emit([]);
      } else {
        this.select.emit(_map(this.committed, 'value') || []);
      }
    }
  }

  protected doHandlePreselected(preselectedItems: any[]): void {
    if (!_isNil(preselectedItems) && preselectedItems.length > 0) {
      this.values.forEach(displayItem => {
        preselectedItems.forEach(item => {
          if (displayItem.value === item) {
            displayItem.selected = true;
            displayItem.preselected = true;
          }
        });
      });
    }
  }

  protected fetchSelected(): IMultiselectDisplayItem[] {
    return _filter(this.values, { selected: true });
  }

  private countSelectedItems() {
    if (this.limitItems != null) {
      let selectedCount = 0;

      for (let i = 0; i < this.displayItems.length; i++) {
        const item = this.displayItems[i];

        if (item.selected) {
          selectedCount++;
        }
        if (selectedCount >= this.limitItems) {
          this.maxSelected = true;
          return;
        } else {
          this.maxSelected = false;
        }
      }
    }
  }

  private deselectItem(item: IMultiselectDisplayItem) {
    this.onItemSelectionChange(item.selected = false);
  }

  private refreshShowAllChecked() {
    this.showAllChecked = this.displayItems.every(item => item.selected);
  }

  openPopup(): void {
    this.optionsPopup.show();
    this.refreshDisplayedItems();
  }
}
