import {State} from "./state";
import {createFeatureSelector, createSelector, MemoizedSelector} from "@ngrx/store";

export const selectToursState: MemoizedSelector<object, State> =
  createFeatureSelector<State>('tours');

export const selectRestartStep = createSelector(
  selectToursState,
  (state: State, props: any) => {
    return state[props.tourId];
  });
