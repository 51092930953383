<div class="questionary-result-item">
  <p
    class="text text_primary questionary-result-item__number-of-points">
    {{score}}
  </p>
  <p class="text text_big questionary-result-item__category"
     [appPopup]="score === maxScore ? popupDescription : null"
     [class.questionary-result-item__category_max-score]="score === maxScore">
    {{key}}
  </p>
  <p
    class="text text_big questionary-result-item__interpretation"
    [class.questionary-result-item_success]="getColor === colorCode.Green"
    [class.questionary-result-item_alert]="getColor === colorCode.Red"
    [class.questionary-result-item_accent]="getColor === colorCode.Yellow">
    {{interpretation}}
  </p>
</div>

<app-popup #popupDescription>
  <ng-container *ngIf="popupDescription.isShow">
    <h3>{{ key }}</h3>
    <p>{{ transcript }}</p>
  </ng-container>
</app-popup>
