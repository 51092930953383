import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { Sort, SortingSelection } from './sort';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ManagedComponent } from '../managed-component';
import { AccessibleService } from '../accessible/accessible.service';
import { LayoutService } from '../layout/layout.service';
import { PopupComponent } from '../popup/popup.component';
import { EducationalElements } from '@rsmu/portal-api';
import { SecurityService } from '../../security/security.service';
import { LocalStorage } from 'ngx-store';
import {OrderByEnum} from '@rsmu/portal-api';

interface ElementTypeCell {
  name: string;
  field?: keyof EducationalElements | '';
  field1?: keyof EducationalElements;
  field2?: keyof EducationalElements;
  label: string;
}

@Component({
  selector: 'app-sort',
  templateUrl: './sort.component.html',
  styleUrls: ['./sort.component.scss']
})

export class SortComponent extends ManagedComponent implements OnChanges, OnDestroy, OnInit {

  @LocalStorage() preselectedColumns: {} = null;
  @Input() disabled = false;
  @Input() sort: Sort;

  @Input() set resultsCells(results: ElementTypeCell[]) {
    this.resultsCellsToDisplay = results;
    if (this.resultsCellsToDisplay) {
      this.translateResultsCells(this.resultsCellsToDisplay);
    }
  }
  @Input() sortingOptions: string[] = [];
  @Output() readonly sortChange = new EventEmitter<Sort>();
  @Output() changeResultsCells = new EventEmitter<ElementTypeCell[]>();
  @ViewChild('columnSettingsTooltip', { static: true }) private columnSettingsTooltip: PopupComponent<undefined>;

  readonly selectedSort: Sort = {
    sortBy: null,
    orderBy: null
  };

  themeTooltip = 'tooltip-text_big tooltip_white tooltip_flat';
  preselectedItems = ['name', 'speciality', 'specialityName', 'partition', 'organization', 'region', 'dateCycleRange', 'dateRange', 'form', 'eduTechnology', 'chargeTypeBudget', 'chargeTypePaid', 'price', 'zet'];
  preselectedColumnsToShow: string[];
  resultsCellsToDisplay: ElementTypeCell[] = [];
  legendTooltipData = {helpId: 'nmifo$SearchLegend'};
  preselectedDppPk: string[] = [];
  preselectedDppPp: string[] = [];
  preselectedIom: string[] = [];
  preselectedOm: string[] = [];
  elementEduType = 'iqp';

  get isSortDirectionToggleEnabled(): boolean {
    return !this.disabled && !!this.selectedSort.sortBy;
  }

  get sortingSelections(): SortingSelection[] {
    return this._sortingSelections;
  }

  set sortingSelections(value: SortingSelection[]) {
    this._sortingSelections = value;
  }

  get isAccessible(): boolean {
    return this.accessibleService.isActive;
  }

  get isMobile(): boolean {
    return this.layoutService.isMobile;
  }

  private _sortingSelections: SortingSelection[] = [];
  private readonly sortingSelections$ = new Subject<string[]>();

  constructor(
    private readonly securityService: SecurityService,
    private readonly translateService: TranslateService,
    private readonly accessibleService: AccessibleService,
    private readonly layoutService: LayoutService,
  ) {
    super();
  }

  ngOnChanges({sort, sortingOptions}: SimpleChanges): void {
    if (sort && !sort.firstChange) {
      const selectedSort: Sort = sort.currentValue as Sort;

      this.updateSelectedSort(selectedSort);
    }
    if (sortingOptions && !sortingOptions.firstChange) {
      this.updateSortingSelections(sortingOptions.currentValue as string[]);
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngOnInit(): void {
    this.sortingSelections$
      .pipe(
        takeUntil(this.destroy$),
        switchMap((sortingSelections: string[]): Observable<[string, string][]> => {
          if (!Array.isArray(sortingSelections)) {
            return of([]);
          }

          return forkJoin(
            sortingSelections.map(
              (value: string) =>
                this.translateService
                  .get(`common.sort.option.${value}`)
                  .pipe(
                    map((label: string): [string, string] => [label, value])
                  )
            )
          );
        }),
        map(
          (items: [string, string][]): SortingSelection[] =>
            items.map(
              ([label, value]: [string, string]): SortingSelection => ({
                label, value
              })
            )
        )
      )
      .subscribe((sortingSelections: SortingSelection[]): void => {
        this.sortingSelections = sortingSelections;
      });
    if (this.preselectedColumns) {
      this.preselectedColumnsToShow = this.preselectedColumns[this.elementEduType].length
        ? this.preselectedColumns[this.elementEduType]
        : this.preselectedItems;
    } else {
      this.saveColumnsInitialObjectToLocalStorage();
      this.preselectedColumnsToShow = this.preselectedItems;
    }
    this.changeTableColumnsView(this.preselectedColumnsToShow);
    this.updateSelectedSort(this.sort);
    this.updateSortingSelections(this.sortingOptions);
  }

  toggleSortDirection() {
    if (this.isSortDirectionToggleEnabled) {
      this.selectedSort.orderBy = this.selectedSort.orderBy === OrderByEnum.Asc ? OrderByEnum.Desc : OrderByEnum.Asc;
      this.emitSelectedSort();
    }
  }

  onEduTypeChanged(type, cells): void {
    this.elementEduType = type;
    this.translateResultsCells(cells);
    this.resultsCellsToDisplay = cells;
    const preselected = this.preselectedColumns[type];
    this.preselectedColumnsToShow = preselected.length ? preselected : this.preselectedItems;
    const results = this.getResultsCellsToDisplay(this.preselectedColumnsToShow, cells);
    this.emitResultCells(results);
  }

  sortOptionSelected(sortBy: string) {
    if (sortBy !== this.selectedSort.sortBy) {
      this.selectedSort.sortBy = sortBy;
      this.selectedSort.orderBy = this.selectedSort.sortBy === 'rating' ? OrderByEnum.Desc : OrderByEnum.Asc;
      this.emitSelectedSort();
    }
  }

  onCheckboxSelect(event) {
    this.changeTableColumnsView(event);
  }

  getResultsCellsToDisplay(preselected: string[], cells: ElementTypeCell[]): ElementTypeCell[] {
    const resultCells = [];
    cells.forEach((cell) => preselected.forEach((name) => {
      if (cell.name === name) {
        resultCells.push(cell);
      }}));
    return resultCells;
  }

  private changeTableColumnsView(columns: string[]): void {
    const results = this.getResultsCellsToDisplay(columns, this.resultsCellsToDisplay);
    this.savePreselectedColunsToLocalStorage(columns);
    this.preselectedColumnsToShow = columns;
    if (results.length) {
      this.emitResultCells(results);
    }
  }

  private savePreselectedColunsToLocalStorage(columns): void {
    const preselectedFromLocalStorage = this.preselectedColumns;
    preselectedFromLocalStorage[this.elementEduType] = [...columns];
    this.preselectedColumns = preselectedFromLocalStorage;
  }

  private saveColumnsInitialObjectToLocalStorage(): void {
    this.preselectedColumns = this.preselectedByType;
  }

  private emitSelectedSort() {
    if (this.selectedSort.sortBy) {
      this.sortChange.emit(this.selectedSort);
    }
  }

  private translateResultsCells(results) {
    results.forEach((value, i) =>
      this.translateService
        .get(value.label)
        .pipe(
          map((label: string): string => label)
        ).subscribe((res) => (results[i]['labelTranslated'] = res))
    );
  }

  get preselectedByType(): {} {
    return {
      'iqp': this.preselectedDppPk,
      'rp': this.preselectedDppPp,
      'iom': this.preselectedIom ,
      'om': this.preselectedOm,
    };
  }

  private emitResultCells(results: ElementTypeCell[]) {
    this.changeResultsCells.emit(results);
  }

  private resetSort(): void {
    this.selectedSort.sortBy = null;
    this.selectedSort.orderBy = OrderByEnum.Asc;
  }

  private updateSelectedSort(selectedSort: Sort): void {
    this.resetSort();
    if (selectedSort) {
      Object.assign(this.selectedSort, selectedSort);
    }
  }

  private updateSortingSelections(sortingOptions: string[] = []): void {
    this.sortingSelections$.next(sortingOptions);
  }
}


