<div class="datepicker {{ theme }}" #overlayOrigin>
  <div class="datepicker__dropdown-toggle">
    <label class="form-control"
           [class.form-control__left]="leftLabel">
      <span class="form-control__label text datepicker__label-text"
            *ngIf="label != null"
            [class.text_bold]="isBold"
            [class.text_big]="isBig"
            [class.form-control__label_mobile-hidden]="hideLabelOnMobile">
        {{ label }}
      </span>
      <span class="datepicker__input-box">
        <input class="text-field text-field_ignore-disabled datepicker__input"
               type="text"
               placeholder="{{ 'datepicker.format' | translate }}"
               [class.datepicker__input_invalid]="control?.dirty && control?.invalid"
               [class.datepicker__input_with-close]="canReset"
               [disabled]="disabled"
               [textMask]="{ mask: datepickerMask }"
               [(ngModel)]="fieldModel"
               (ngModelChange)="inputChange($event)"/>
        <button *ngIf="canReset && fieldModel"
                type="button"
                [title]="'datepicker.close' | translate"
                class="button datepicker__reset-button"
                (click)="resetDate()"
                [disabled]="disabled">
          <span class="figure figure_close-sm" role="presentation"></span>
        </button>
        <button class="datepicker__toggle toggle"
                type="button"
                (click)="toggle(); $event.preventDefault(); $event.stopPropagation();"
                [disabled]="disabled">
          <svg-icon class="icon icon_gray icon_16_w datepicker__button-icon"
                    src="assets/icons/calendar.svg"
                    title="{{'tips.calendar' | translate}}"></svg-icon>
        </button>
      </span>
    </label>
  </div>

  <ng-template #overlayBox>
    <div class="datepicker__dropdown-menu" [appTrapFocus]="isOpen">
      <ngb-datepicker class="datepicker__picker"
                      #ngbDatepickerModel="ngModel"
                      [disabled]="disabled"
                      [startDate]="selectedDate"
                      [minDate]="config.minDate"
                      [maxDate]="config.maxDate"
                      [(ngModel)]="selectedDate"></ngb-datepicker>
    </div>
  </ng-template>
</div>







