<table
  #tableEL
  class="grid {{ theme }}"
  [class.grid_mode_card]="enableCardCell">
  <ng-content></ng-content>
  <ng-container appHeaderRowOutlet></ng-container>
  <tbody
    infinite-scroll
    [infiniteScrollDistance]="1"
    [infiniteScrollDisabled]="!infiniteScroll"
    (scrolled)="onInfiniteScroll($event)">
    <ng-container appRowOutlet></ng-container>
  </tbody>
</table>
<app-pagination
  *ngIf="paginated"
  [length]="total"
  [pageIndex]="pageIndex"
  [pageIndexOptionsCount]="pageIndexOptionsCount"
  [pageSize]="pageSize"
  [pageSizeOptions]="pageSizeOptions"
  [paginationType]="paginationType"
  [stopOffsetSearch]="stopOffsetSearch"
  (pageChange)="onPageChange($event)"></app-pagination>
