import { State } from './state';
import { MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store';

export const getCurrentCycle = (state: State): string => state.currentCycle;

export const getShowCurrentCycle = (state: State): boolean => state.showCurrentCycle;

export const getLastUsedCycle = (state: State): string => state.lastUsedCycle;

export const selectCurrentCycleState: MemoizedSelector<object, State> =
  createFeatureSelector<State>('currentCycle');

export const selectCurrentCycle: MemoizedSelector<object, string> =
  createSelector(
    selectCurrentCycleState,
    getCurrentCycle
  );

export const selectShowCurrentCycle: MemoizedSelector<object, boolean> =
  createSelector(
    selectCurrentCycleState,
    getShowCurrentCycle
  );

export const selectLastUsedCycle: MemoizedSelector<object, string> =
  createSelector(
    selectCurrentCycleState,
    getLastUsedCycle
  );
