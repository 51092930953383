<div class="paginator__container">
  <div class="paginator__page-size-options">
    <span class="page-size-title">{{ 'pagination.pageSize.title' | translate }}</span>
    <ul class="page-size-options">
      <li class="page-size-options__option paginator__item"
          tabindex="0"
          appAccessibleLink
          role="navigation"
          *ngFor="let pageSizeOption of displayedPageSizeOptions"
          [class.page-size-options__option_selected]="pageSize === pageSizeOption"
          (click)="changePageSizeAndEmit(pageSizeOption)">
        {{ pageSizeOption }}
      </li>
    </ul>
  </div>
  <div class="paginator__page-index-options">
    <ul class="page-indices">
      <li class="page-indices__arrow paginator__item"
          tabindex="0"
          appAccessibleLink
          role="navigation"
          *ngIf="canGoToPreviousPage"
          (click)="goToPreviousPage()">
        {{ 'pagination.buttons.previousPage' | translate }}
      </li>
      <li *ngIf="hasMorePagesToLeft">...</li>
      <li class="page-indices__option paginator__item"
          tabindex="0"
          appAccessibleLink
          role="navigation"
          *ngFor="let pageIndexOption of displayedPageIndicesOptions"
          [class.page-indices__option_selected]="pageIndex === pageIndexOption"
          (click)="changePageIndexAndEmit(pageIndexOption)">
        {{ pageIndexOption + 1 }}
      </li>
      <li *ngIf="hasMorePagesToRight">...</li>
      <li class="page-indices__arrow paginator__item"
          tabindex="0"
          appAccessibleLink
          role="navigation"
          *ngIf="canGoToNextPage"
          (click)="goToNextPage()">
        {{ 'pagination.buttons.nextPage' | translate }}
      </li>
    </ul>
  </div>
</div>
