import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DialogService } from '../dialog.service';
import { DialogType } from '../dialog-type';
import { filter, takeUntil } from 'rxjs/operators';
import { PopupAction, PopupHideData } from '../../popup/interfaces/popup.dto';
import { PopupComponent } from '../../popup/popup.component';
import { DialogButton } from '../dialog-button';
import { DEFAULT_CONFIRM_BUTTONS } from '../default-confirm-buttons';
import { PopupTheme } from '../../popup/interfaces/popup-theme';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-dialog-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class DialogConfirmComponent implements OnInit, OnDestroy {

  message: string;
  buttons: DialogButton[];
  showClose = true;
  themeList = [
    PopupTheme.AUTO_HEIGHT,
    PopupTheme.AUTO_WIDTH_MOBILE
  ];

  isMinimal = false;

  @ViewChild(PopupComponent, { static: true }) popupComponent: PopupComponent<undefined>;

  private unsubscribe$: Subject<void> = new Subject();

  constructor(private dialogService: DialogService) {
  }

  ngOnInit(): void {
    this.dialogService
      .onDestroyDialog
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.popupComponent.hide());

    this.dialogService
      .changes
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(event => event.type === DialogType.CONFIRM)
      )
      .subscribe(event => {
        this.popupComponent.forceClose();
        this.message = event.message;
        const options = event.options;
        this.buttons = (options && options.buttons) ? options.buttons : DEFAULT_CONFIRM_BUTTONS;
        this.showClose = (options && options.showClose !== undefined) ? options.showClose : true;
        this.isMinimal = (options && options.theme === 'minimal');
        this.popupComponent.show();
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }


  onConfirmationHide(event: PopupHideData<undefined>): void {
    this.dialogService.dialogComplete(event.action);
  }

  onClick(action: PopupAction): void {
    this.popupComponent.hide(action);
  }

}
