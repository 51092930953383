import { Component } from '@angular/core';
import { QuestionaryResult } from '@rsmu/portal-api';
import { QuestionaryPhase } from '../../questionary-interface/questionary-phase';
import { PsyQuestionaryService } from '../psy-questionary.service';

@Component({
  selector: 'app-psy-questionary-container',
  templateUrl: './psy-questionary-container.component.html',
  styleUrls: ['./psy-questionary-container.component.scss']
})
export class PsyQuestionaryContainerComponent {

  readonly questionaryPhase = QuestionaryPhase;

  constructor(
    private questionaryService: PsyQuestionaryService,
  ) {
  }

  get dryRun(): boolean {
    return this.questionaryService.dryRun;
  }

  get getPercentageOfCompletion(): number {
    return this.questionaryService.getPercentageOfCompletion;
  }

  get activePhase(): QuestionaryPhase {
    return this.questionaryService.activePhase;
  }

  get results(): QuestionaryResult {
    return this.questionaryService.results;
  }

  get loading(): boolean {
    return this.questionaryService.sendProcess;
  }

  get isErrorSend(): boolean {
    return this.questionaryService.isErrorSend;
  }

  switchInstructionsDisplay() {
    this.questionaryService.switchInstructionsDisplay();
  }
}
