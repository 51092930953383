import { Component, OnInit } from '@angular/core';
import { Transition } from '@uirouter/core';

import { EsiaService } from '../esia.service';

@Component({
  selector: 'app-esia-callback',
  templateUrl: './esia-callback.component.html',
  styleUrls: ['./esia-callback.component.scss']
})
export class EsiaCallbackComponent implements OnInit {

  constructor(
    private transition: Transition,
    private esiaService: EsiaService) { }

  ngOnInit() {
    let error = null;
    let token = null;
    let userExists = null;
    let esiaPersonDataId = null;
    if (this.transition.params()) {
      token = this.transition.params().token;
      userExists = this.transition.params().userExists === 'true';
      error = this.transition.params().error;
      esiaPersonDataId = this.transition.params().esiaPersonDataId;
    }
    if (esiaPersonDataId) {
      this.esiaService.handlePersonDataUpdateCallback(esiaPersonDataId);
    }
    if (token || userExists || error) {
      this.esiaService.handleCallbackData(token, userExists, error);
    }
  }
}
