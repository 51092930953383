<div class="questionary-results" *ngIf="testResults">
  <h2 class="questionary-results__title heading heading_2 heading_light">
    {{ 'questionary.result' | translate }}
  </h2>

  <div class="questionary-results__body">
    <div class="questionary-results__mobile-header">
      <div class="questionary-results__mobile-name text text_bold text_h3">
        {{ psyQuestionary?.title }}
      </div>
      <svg-icon
        class="icon icon_28 icon_primary questionary-results__icon-help"
        title="{{ 'tips.question' | translate }}"
        src="assets/icons/question.svg"
        (click)="showTranscript()">
      </svg-icon>
    </div>

    <div class="questionary-results__transcript" *ngIf="transcriptIsShowed">
      <app-notification-bubble
        (close)="showTranscript()"
        theme="white">
        <app-psy-test-highest-results-transcript [testResults]="testResults.result"
                                                 [maxScore]="maxScore">
        </app-psy-test-highest-results-transcript>
      </app-notification-bubble>
    </div>

    <div *ngFor="let result of testResults.result" class="questionary-results__block">
      <app-questionary-results-item [result]='result' [maxScore]="maxScore"></app-questionary-results-item>
    </div>

    <div class="questionary-results__navigation-panel">
      <button
        (click)="finishTest()"
        class="button button_primary button_raised text">
        {{ 'questionary.navigation.isReady' | translate }}
      </button>
    </div>
  </div>
</div>

