import { initialState, State } from './state';
import { Actions, ActionTypes } from './actions';

export function currentCycleReducer(state = initialState, action: Actions): State {
  const currentCycle = state.currentCycle;
  switch (action.type) {
    case ActionTypes.SET_CURRENT_CYCLE:
      return {
        ...state,
        lastUsedCycle: currentCycle || null,
        currentCycle: action.payload.currentCycle
      };
    case ActionTypes.REMOVE_CURRENT_CYCLE:
      return {
        ...state,
        lastUsedCycle: currentCycle || null,
        currentCycle: null
      };
    case ActionTypes.UPDATE_SHOW_CURRENT_CYCLE:
      return {
        ...state,
        showCurrentCycle: action.payload.showCurrentCycle
      };
    case ActionTypes.LOAD_LAST_TO_CURRENT_CYCLE:
      return {
        ...state,
        currentCycle: state.lastUsedCycle,
      };
    default: {
      return state;
    }
  }
}
