import { NgModule } from '@angular/core';
import { GridComponent } from './grid/grid.component';
import {
  HeaderRowOutletDirective,
  DataRowOutletDirective,
  RowComponent,
  RowDefDirective,
  CellOutletDirective,
  RowHeaderComponent,
  RowHeaderDefDirective
} from './rows/rows';
import {
  HeaderCellDefDirective,
  CellDefDirective,
  ColumnDefDirective,
  HeaderCellDirective,
  CellDirective,
  CardCellDefDirective,
  CardCellDirective,
  SortableColumnDirective
} from './cols/cols';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { PaginationComponent } from './pagination/pagination.component';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { AccessibleLinkModule } from '../app-commons/accessible-link/accessible-link.module';

const EXPORTED_DECLARATIONS = [
  // table component
  GridComponent,

  // row directions
  RowHeaderComponent,
  RowComponent,

  // outlets
  HeaderRowOutletDirective,
  DataRowOutletDirective,
  CellOutletDirective,

  // element styles
  HeaderCellDirective,
  CellDirective,
  CardCellDirective,

  // template def
  RowHeaderDefDirective,
  RowDefDirective,
  HeaderCellDefDirective,
  CellDefDirective,
  CardCellDefDirective,
  ColumnDefDirective,

  SortableColumnDirective,

  PaginationComponent
];

const IMPORTED_MODULES = [
  InfiniteScrollModule,
  CommonModule,
  TranslateModule,
  AccessibleLinkModule,
];

@NgModule({
  declarations: EXPORTED_DECLARATIONS,
  exports: EXPORTED_DECLARATIONS,
  imports: IMPORTED_MODULES
})
export class GridModule { }
