import { Injectable } from '@angular/core';
import {merge, Observable, of} from 'rxjs';
import {CyclesResponseSchema, MyPlan} from '@rsmu/portal-api';
import {ApiService} from '../api/api.service';
import {CyclesService} from '../cycles/cycles.service';
import {map, switchMap} from 'rxjs/operators';
import {IOTElement, MyPlanElement} from '@rsmu/portal-api/model/models';

@Injectable({
  providedIn: 'root'
})
export class EduPlanService {

  constructor(
    private readonly apiService: ApiService,
    private readonly cyclesService: CyclesService
  ) { }

  getEduElementsByTopicId (cycleId: string , topicId: string): Observable<Array<MyPlanElement>> {
    return this.apiService.getElementsForTopic(cycleId, topicId);
  }

  getRequiredAppointedEduElements (completed, cycleId?: string): Observable<Array<IOTElement>> {
    return this.apiService.getIOTRequiredElementsByCycleId (completed, cycleId);
  }

  getRecommendedAppointedEduElements (completed, cycleId?: string): Observable<Array<IOTElement>> {
    return  this.apiService.getIOTRecommendedElementsByCycleId(completed, cycleId);
  }

  getExtraElements (completed: boolean, cycleId?: string): Observable<Array<MyPlanElement>> {
    return this.apiService.getExtraElements(completed, cycleId);
  }

  getDeletedElements (cycleId?: string): Observable<Array<MyPlanElement>> {
    return this.apiService.getExcludedElements(cycleId);
  }

  getCurrentCycleInfo(): Observable<CyclesResponseSchema | {}> {
    return this.cyclesService.getCurrentCycleInfo()
      .pipe(map(currentCycleInfo => !!currentCycleInfo ? currentCycleInfo : of({})));
  }

  getEduPlan(): Observable<MyPlan> {
    return this.cyclesService.getCurrentCycle().pipe(
      switchMap(currentCycleId => {
        return merge(
          of(null),
          !!currentCycleId ? this.apiService.getMyPlanByCycleId(currentCycleId) : of({})
        ); }),
    );
  }
}
