<div class="profile-header">
    <div *ngIf="showIcon" class="profile-header__avatar">
      <div
        *ngIf="userAvatar"
        class="profile-header__avatar-img"
        tourAnchor="profile_greetings"
        [style.background-image]="'url(' + userAvatar + ')' | sanitizeStyle"
        title="{{ 'header.toProfile' | translate }}"></div>
      <svg-icon
        *ngIf="!userAvatar"
        src="assets/icons/user.svg"
        class="icon icon_primary profile-header__avatar-img"
        tourAnchor="profile_greetings"
        title="{{ 'header.toProfile' | translate }}"></svg-icon>
    </div>
    <div class="profile-header__text">
      <div *ngIf="showName" class="profile-header__name text text_big text_bold">{{ currentUser?.firstName + ' ' + currentUser?.lastName }}</div>
      <div *ngIf="showHint" class="profile-header__hint text text_small text_muted-lighter">{{ 'header.toProfile' | translate }}</div>
    </div>
</div>
