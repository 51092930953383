import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { RatingInfo } from '@rsmu/portal-api';

export interface EstimateObject {
  likes?: number;
  dislikes?: number;
  state: RatingInfo;
}

export enum EstimateEnum {
  DEFAULT = 'default',
  LIKE = 'like',
  DISLIKE = 'dislike'
}

@Component({
  selector: 'app-like-dislike',
  templateUrl: './like-dislike.component.html',
  styleUrls: ['./like-dislike.component.scss']
})
export class LikeDislikeComponent implements OnInit, OnChanges {

  @Input() estimates: EstimateObject;
  @Input() size: string;
  @Input() sizeTb: string;
  @Input() inline = false;
  @Input() disabled = false;
  @Output() estimateChange = new EventEmitter<RatingInfo>();

  estimateState: RatingInfo;
  likes: number;
  dislikes: number;

  private readonly defaultSize = 'regular';

  ngOnChanges(changes: SimpleChanges) {
    if (changes.estimates) {
      this.likes = changes.estimates.currentValue ? changes.estimates.currentValue.likes : this.likes;
      this.dislikes = changes.estimates.currentValue ? changes.estimates.currentValue.dislikes : this.dislikes;
      this.estimateState = changes.estimates.currentValue ? changes.estimates.currentValue.state : this.estimateState;
    }
  }

  ngOnInit() {
    this.likes = (this.estimates && this.estimates.likes) ? this.estimates.likes : 0;
    this.dislikes = (this.estimates && this.estimates.dislikes) ? this.estimates.dislikes : 0;
    this.estimateState = (this.estimates && this.estimates.state) ? this.estimates.state : EstimateEnum.DEFAULT;
    this.size = this.size ? this.size : this.defaultSize;
  }

  like(): void {
    if ((this.estimateState === EstimateEnum.DEFAULT) || (this.estimateState === EstimateEnum.DISLIKE)) {
      if (this.estimateState === EstimateEnum.DISLIKE && this.dislikes !== 0) {
        this.dislikes--;
      }
      this.likes++;
      this.estimateState = EstimateEnum.LIKE;
    } else {
      if (this.likes !== 0) {
        this.likes--;
      }
      this.estimateState = EstimateEnum.DEFAULT;
    }
    this.estimateChange.emit(this.estimateState);
  }

  dislike(): void {
    if ((this.estimateState === EstimateEnum.DEFAULT) || (this.estimateState === EstimateEnum.LIKE)) {
      if (this.estimateState === EstimateEnum.LIKE && this.likes !== 0) {
        this.likes--;
      }
      this.dislikes++;
      this.estimateState = EstimateEnum.DISLIKE;
    } else {
      if (this.dislikes !== 0) {
        this.dislikes--;
      }
      this.estimateState = EstimateEnum.DEFAULT;
    }
    this.estimateChange.emit(this.estimateState);
  }
}
