import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ScopeEnum, SimpleResponseDto, UserInfo} from '@rsmu/portal-api';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {EsiaService} from '../esia.service';
import {first, switchMap} from 'rxjs/operators';
import {DialogService} from '../../app-commons/dialog/dialog.service';

@Component({
  selector: 'app-esia-loading-data-dialog',
  templateUrl: './esia-loading-data-dialog.component.html',
  styleUrls: ['./esia-loading-data-dialog.component.scss']
})
export class EsiaLoadingDataDialogComponent implements OnInit {

  static USER_INFO_CONFIG = [
    {fieldName: 'lastName', disabled: true, scopeEnum: ScopeEnum.Lastname},
    {fieldName: 'firstName', disabled: true, scopeEnum: ScopeEnum.Firstname},
    {fieldName: 'middleName', disabled: true, scopeEnum: ScopeEnum.Middlename},
    {fieldName: 'birthDate', disabled: true, scopeEnum: ScopeEnum.Birthdate},
    {fieldName: 'gender', disabled: true, scopeEnum: ScopeEnum.Gender},
    {fieldName: 'email', disabled: false, scopeEnum: ScopeEnum.Email},
    {fieldName: 'phonePrimary', disabled: false, scopeEnum: ScopeEnum.Phone},
  ];

  get disableSubmitButton(): boolean {
    const uInfo = this.userInfoForm.getRawValue();
    return Object.keys(uInfo)
      .map(key => uInfo[key])
      .reduce((acc, curr) => acc && !curr, true);
  }

  get userInfoConfig() {
    return EsiaLoadingDataDialogComponent.USER_INFO_CONFIG;
  }

  userInfoForm: UntypedFormGroup;

  constructor(
    private readonly dialogRef: MatDialogRef<EsiaLoadingDataDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UserInfo & {esiaPersonDataId: string},
    private readonly esiaService: EsiaService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly dialogService: DialogService
  ) {

  }

  ngOnInit(): void {
    this.userInfoForm = this.formBuilder.group(this._getFormGroup(
      EsiaLoadingDataDialogComponent.USER_INFO_CONFIG.map(el => el.fieldName), this.data));
  }

  private _getFormGroup(userInfoConfig: Array<string>, data) {
    const formGroupObject = {};
    userInfoConfig
      .filter(el => !!data[el])
      .forEach(el => formGroupObject[el] = new UntypedFormControl(true));
    return formGroupObject;
  }

  cancel() {
    this.dialogRef.close();
  }

  submitForm() {
    const uInfoF = this.userInfoForm.getRawValue();
    const haveToSendFields = Object.keys(uInfoF)
      .filter(key => uInfoF[key] === true);
    this.esiaService.mergePersonData(
      this.data.esiaPersonDataId,
      haveToSendFields.map(el => this.userInfoConfig.find(uIC => el === uIC.fieldName).scopeEnum)
    ).pipe(
      first(),
      switchMap((res: SimpleResponseDto) => {
        return this.dialogService.message(res.descriptionRu || res.descriptionEn);
      }))
      .subscribe(() => this.dialogRef.close());
  }
}
