import { Action } from '@ngrx/store';
import { CyclesResponseSchema } from '@rsmu/portal-api';

export enum ActionTypes {
  LOAD_REQUEST = '[Cycles] Load Request',
  LOAD_FAILURE = '[Cycles] Load Failure',
  LOAD_SUCCESS = '[Cycles] Load Success',
  CLEAN_CYCLES = '[Cycles] Clean Cycles'
}

export class LoadRequestAction implements Action {
  readonly type = ActionTypes.LOAD_REQUEST;
}
export class LoadFailureAction implements Action {
  readonly type = ActionTypes.LOAD_FAILURE;
  constructor(public payload: { error: string }) {}
}
export class LoadSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_SUCCESS;
  constructor(public payload: { items: CyclesResponseSchema[] }) {}
}

export class CleanCyclesAction implements Action {
  readonly type = ActionTypes.CLEAN_CYCLES;
}

export type Actions =
  | LoadRequestAction
  | LoadFailureAction
  | LoadSuccessAction
  | CleanCyclesAction;
