import { Injectable } from '@angular/core';
import { ApiService } from '../../api/api.service';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, pluck } from 'rxjs/operators';
import { FeatureService } from '../../app-commons/feature/feature.service';
import { FeatureKeys } from '../../app-commons/feature/feature-keys';
import { ForumSettings } from '@rsmu/portal-api';
import {MobileAppService} from '../../mobile/mobile-app.service';

@Injectable({
  providedIn: 'root'
})
export class ForumService {

  private readonly _forumSettings$ = new BehaviorSubject<ForumSettings>({});
  private isInitialized = false;

  get forumUrl$(): Observable<string | null> {
    return this.getForumFeatureProperties().pipe(
      pluck('forumUrl')
    );
  }

  // TODO Нужно разобраться с загрузкой форума в Android
  get isForumEnabled$(): Observable<boolean> {
    return combineLatest<[boolean, string]>([this.isForumFeatureEnabled$, this.forumUrl$])
      .pipe(
        map(([isEnabled, forumUrl]: [boolean, string]) => !this.mobileAppService.isMobileApp() && !!isEnabled && !!forumUrl)
      );
  }

  get isForumFeatureEnabled$(): Observable<boolean> {
    return this.featureService.isFeatureEnabled$(FeatureKeys.FORUM);
  }

  constructor(private readonly apiService: ApiService,
              private readonly featureService: FeatureService,
              private readonly mobileAppService: MobileAppService
  ) {
  }

  getForumFeatureProperties(): Observable<ForumSettings> {
    if (!this.isInitialized) {
      this.isInitialized = true;
      this.apiService.getForumConfigProperties()
        .subscribe((forumSettings: ForumSettings) => {
          this._forumSettings$.next(forumSettings);
        });
    }

    return this._forumSettings$.asObservable();
  }
}
