import { Component, Input, OnInit } from '@angular/core';

export type TooltipType = 'standard' | 'text';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: [ './tooltip.component.scss' ]
})
export class TooltipComponent implements OnInit {

  readonly standardTooltipClass = 'tooltip tooltip_shown';
  readonly textTooltipClass = 'tooltip-text';

  @Input() message = '';
  @Input() modifications: string;
  @Input() type: TooltipType = 'standard';
  @Input() lastX;
  @Input() lastY;

  get isStandard(): boolean {
    return this.type === 'standard';
  }

  get isText(): boolean {
    return this.type === 'text';
  }

  get ngStyle(): any {
    return (this.lastY && this.lastX) ? {
      left: this.lastX + 'px',
      top: this.lastY + 'px'
    } : {};
  }

  get themeClassObject(): { [key: string]: boolean } {
    return {
      [this.modifications]: true,
      [this.standardTooltipClass]: this.isStandard,
      [this.textTooltipClass]: this.isText
    };
  }

  constructor() {
  }

  ngOnInit() {
  }
}
