import {
  initialState,
  cyclesAdapter,
  State
} from './state';
import {
  Actions,
  ActionTypes
} from './actions';

export function cyclesReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.LOAD_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null
      };
    }
    case ActionTypes.LOAD_SUCCESS: {
      return cyclesAdapter.setAll(action.payload.items, {
        ...state,
        isLoading: false,
        error: null,
        isFirstLoading: false,
        cacheTime: new Date().getTime()
      });
    }
    case ActionTypes.LOAD_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        isFirstLoading: false
      };
    }
    case ActionTypes.CLEAN_CYCLES: {
      state = initialState;
      return state;
    }
    default: {
      return state;
    }
  }
}
