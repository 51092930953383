import { Directive, ElementRef, Input, AfterContentInit } from '@angular/core';

@Directive({
  selector: '[appAutoFocus]'
})
export class AutoFocusDirective implements AfterContentInit {

  @Input() appAutoFocus: boolean;

  constructor(private hostElement: ElementRef) {}

  ngAfterContentInit() {
    this.hostElement.nativeElement.focus();
  }

}
