<div *ngIf="inner; else notInnerRating">
  <div class="rating-container__inner">
    <button
      class="rating-container__rating-wrap"
      *ngFor="let value of ratingList"
      [title]="'rating.write' | translate : {rating: value}"
      (click)="writeRatingValue(value)"
      (mouseover)="ratingOver(value)"
      (mouseout)="ratingOut()"
      [disabled]="isDisabled"
    >
      <svg-icon
        class="icon icon_14 rating-container__rating-star"
        [class.icon_light-gray]="value > selectedRating"
        [class.icon_checked]="value <= selectedRating"
        src="assets/icons/star.svg">
      </svg-icon>
      <span class="rating-container__inner-text">{{value}}</span>
    </button>
  </div>
</div>

<ng-template #notInnerRating>
  <app-dropdown
    (toggled)="ratingToggled($event)"
    #dropdown
    theme="popover_theme_rating"
    [disabled]="isDisabled"
  >
    <button
      class="button button_text rating-container__wrap"
      title="{{'tips.estimate' | translate}}"
      appDropdownToggle>
      <span *ngIf="rating > 0 && isSelectedRating" class="rating-container__total">{{ rating }}</span>
      <svg-icon
        class="icon icon_star"
        [ngClass]="{
        'rating-container__rating-pic icon_14': isSelectedRating,
        'icon_light-gray rating-container__rating-not-pic icon_18': !isSelectedRating
        }"
        src="assets/icons/star.svg"
      ></svg-icon>
    </button>
    <div class="rating-container__popup popover__content"
         [appTrapFocus]="dropdown.isOpen"
         appDropdownContent>
      <button
        class="rating-container__rating-wrap"
        *ngFor="let value of ratingList"
        [title]="'rating.write' | translate : {rating: value}"
        (mouseover)="ratingOver(value)"
        (mouseout)="ratingOut()"
        (click)="writeRatingValue(value)"
      >
        <svg-icon
          class="icon icon_16 rating-container__rating-star"
          [class.icon_light-gray]="value > selectedRating"
          [class.icon_star]="value <= selectedRating"
          src="assets/icons/star.svg">
        </svg-icon>
        <span class="rating-container__text">{{value}}</span>
      </button>
    </div>
  </app-dropdown>
</ng-template>

