import {AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {Multiselect2DialogComponent, Multiselect2DialogData} from '../multiselect2-dialog/multiselect2-dialog.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {fromEvent, Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, first, map, mergeMap, switchMap, take, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-multiselect2-dialog-ssf',
  templateUrl: './multiselect2-dialog-ssf.component.html',
  styleUrls: ['./multiselect2-dialog-ssf.component.scss']
})
export class Multiselect2DialogSsfComponent <T> extends Multiselect2DialogComponent <T> implements OnInit, AfterViewInit, AfterViewChecked {

  get itemsList$ (): Observable<T[]> {
    return this._itemsList$.asObservable();
  }

  @ViewChild('selectionList', {static: false})
  selectionListRef: ElementRef;

  private _offsetCounter = 0;
  private _textFilterValue = '';
  private _firstLoading = true;

  constructor(
    protected cdr: ChangeDetectorRef,
    public dialogRef: MatDialogRef<T>,
    @Inject(MAT_DIALOG_DATA) public data: Multiselect2DialogData <T>
  ) {
    super(cdr, dialogRef, data);
  }

  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }

  ngAfterViewInit(): void {
    this.setTextFilter();
    this.setScrollingEventHandler();
    this._itemsList$.asObservable()
      .pipe(
        takeUntil(this._unsubscribe$),
        distinctUntilChanged((prev: T[], cur: T[]) => JSON.stringify(prev) === JSON.stringify(cur)),
        filter(() => this.isBottomOfOptions() || this._firstLoading),
        mergeMap(() => {
          if (this.data.dataSource instanceof Function) {
            return this.data.dataSource(++this._offsetCounter, 10, {complexName: this._textFilterValue});
          }
        }))
      .subscribe(res => {
        const itemsList = this._itemsList$.getValue() || [];
        this._itemsList$.next(itemsList.concat(res));
        if (this._firstLoading) {
          this._firstLoading = false;
        }
      });
  }

  ngOnInit(): void {
    if (this.data.dataSource instanceof Function) {
      this.data.dataSource(0, 10, {})
        .pipe(
          first()
        )
        .subscribe(res =>
          this._itemsList$.next(res));
    }
    const selectedItems = this.data.selectedItems$.getValue();
    if (selectedItems?.length > 0) {
      selectedItems.forEach(it => this.onItemClick(it, true));
    }
    this.dialogRef.backdropClick()
      .pipe(take(1))
      .subscribe(() => this.onCancel());
  }

  setScrollingEventHandler() {
    fromEvent(this.selectionListRef.nativeElement, 'scroll')
      .pipe(
        takeUntil(this._unsubscribe$),
        filter(() => this.isBottomOfOptions()),
        mergeMap(() => {
          if (this.data.dataSource instanceof Function) {
            return this.data.dataSource(++this._offsetCounter, 10, {complexName: this._textFilterValue});
          }
        })
      )
      .subscribe(res => {
        const itemsList = this._itemsList$.getValue() || [];
        this._itemsList$.next(itemsList.concat(res));
      });
  }

  setTextFilter() {
    this.dialogStringControl.valueChanges
      .pipe(
        takeUntil(this._unsubscribe$),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        debounceTime(800),
        map(value => value[this.data.itemName] || value),
        switchMap(str => {
          if (this.data.dataSource instanceof Function) {
            this._offsetCounter = 0;
            this._textFilterValue = str;
            return this.data.dataSource(
              this._offsetCounter, 10, {complexName: this._textFilterValue});
          }
        }))
      .subscribe(res => {
        this._itemsList$.next(res);
      });
  }

  protected isBottomOfOptions() {
    const scrollTop = this.selectionListRef.nativeElement
      .scrollTop;
    const scrollHeight = this.selectionListRef.nativeElement
      .scrollHeight;
    const elementHeight = this.selectionListRef.nativeElement
      .clientHeight;
    return scrollHeight <= scrollTop + elementHeight + 2;
  }
}
