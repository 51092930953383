import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent, Observable, Subject } from 'rxjs';
import {debounceTime, distinctUntilChanged, map, startWith, tap} from 'rxjs/operators';
import { LAYOUT_BREAKPOINT_LIST, LayoutSize } from './layout-breakpoints';
import { DOCUMENT } from '@angular/common';
import { AccessibleService } from '../accessible/accessible.service';
import {MobileAppService} from '../../mobile/mobile-app.service';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  private readonly debounceTime = 10;
  private readonly breakpointList = LAYOUT_BREAKPOINT_LIST;

  private size$ = new BehaviorSubject<LayoutSize>(null);
  private resize$ = new Subject<void>();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private accessibleService: AccessibleService,
    private readonly mobileAppService: MobileAppService
  ) {
    fromEvent(window, 'resize')
      .pipe(debounceTime(this.debounceTime))
      .subscribe(() => {
        this.size$.next(this.findBreakpoint());
        this.resize$.next();
      });

    this.size$.next(this.findBreakpoint());
  }

  get isMobile(): boolean {
    return this.accessibleService.isActive || this.getSize() === LayoutSize.MOBILE;
  }

  get isMobile$(): Observable<boolean> {
    return this.resize$.pipe(
      startWith(this.isMobile || this.isTablet),
      map(() => this.findBreakpoint()),
      map(layout => this.accessibleService.isActive || layout === LayoutSize.MOBILE ||
        (layout === LayoutSize.TABLET && this.mobileAppService.isMobileApp())),
      distinctUntilChanged(),
    );
  }

  get isNotDesktop (): boolean {
    const currentSize = this.getSize();
    return this.accessibleService.isActive || currentSize === LayoutSize.MOBILE || currentSize === LayoutSize.TABLET;
  }

  get isSmallScreen(): boolean {
    return this.getSize() !== LayoutSize.DESKTOP;
  }

  get isTablet(): boolean {
    return this.getSize() === LayoutSize.TABLET;
  }

  get isDesktop(): boolean {
    return this.getSize() === LayoutSize.DESKTOP;
  }

  sizeChanges(): Observable<void> {
    return this.resize$.asObservable();
  }

  private getSize(): LayoutSize {
    return this.size$.getValue();
  }

  private findBreakpoint(): LayoutSize {
    const width = document.querySelector('html').offsetWidth;
    for (const breakpoint of this.breakpointList) {
      if (breakpoint.size <= width) {
        return breakpoint.key;
      }
    }
  }
}
