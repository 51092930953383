import {Actions, ActionTypes} from "./actions";
import {initialState} from "./state";

export function toursReducer(state = initialState, action: Actions) {
  switch(action.type) {
    case ActionTypes.EXIT_TOUR: {
      return {
        ...state,
        [action.payload.tourId]: action.payload.stepNumber
      }
    }
    default: {
      return state;
    }
  }
}
