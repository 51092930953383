import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { environment } from '../../../environments/environment';


export function snilsValidation(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    return environment.validateSnils ? snilsValidateFunction(control) : () => null;
  };
}

export function snilsStringValidateFunction(testedSnis: string): boolean {
  const snilsRegExp: RegExp = /(\d{3})-(\d{3})-(\d{3}) (\d{2})/;
  const parsedString: string[] = testedSnis.match(snilsRegExp);
  let isValid = false;
  if (parsedString && parsedString.length === 5) {
    const firstNumber: number = Number(parsedString[1]);
    const secondNumber: number = Number(parsedString[2]);
    const thirdNumber: number = Number(parsedString[3]);
    const controlNumber: number = Number(parsedString[4]);

    if (firstNumber <= 1 && secondNumber <= 1 && thirdNumber <= 998) {
      isValid = true;
    } else {
      isValid = checkControlNumber(firstNumber, secondNumber, thirdNumber, controlNumber);
    }
  }
  return isValid;
}

export function snilsValidateFunction(control: AbstractControl): ValidationErrors | null {
  if (!control.value) {
    return null;
  }
  return snilsStringValidateFunction(control.value) ? null : { 'invalidSnils': control.value };
}

function checkControlNumber(firstNumber: number, secondNumber: number, thirdNumber: number, controlNumber: number): boolean {
  const processedDigitSum =
    processDigits(thirdNumber, 1) +
    processDigits(secondNumber, 4) +
    processDigits(firstNumber, 7);
  return compareControlNumberAndDigitSum(processedDigitSum, controlNumber);
}

function processDigits(number: number, startPosition: number): number {
  const firstDigit: number = number % 10;
  const secondDigit: number = Math.floor((number / 10) % 10);
  const thirdDigit: number = Math.floor((number / 100) % 10);

  return firstDigit * startPosition + secondDigit * (startPosition + 1) + thirdDigit * (startPosition + 2);
}

function compareControlNumberAndDigitSum(digitSum: number, controlNumber: number): boolean {
  if (digitSum < 100) {
    return controlNumber === digitSum;
  } else if (digitSum < 102) {
    return controlNumber === 0;
  } else {
    return compareControlNumberAndDigitSum(digitSum % 101, controlNumber);
  }
}
