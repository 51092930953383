import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {AbstractControl, UntypedFormControl} from '@angular/forms';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {EsOrg} from '@rsmu/es-rest-client/model/esOrg';
import {OrgControllerService} from '@rsmu/es-rest-client';
import {debounceTime, distinctUntilChanged, map, takeUntil, tap} from 'rxjs/operators';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {AutocompleteChangeOffsetDirective} from '../../directives/autocomplete-change-offset.directive';

@Component({
  selector: 'app-base-select-organization',
  templateUrl: './base-select-organization.component.html',
  styleUrls: ['./base-select-organization.component.scss']
})
export class BaseSelectOrganizationComponent implements OnInit, OnDestroy {

  @ViewChild(AutocompleteChangeOffsetDirective) autocompleteOffsetChange: AutocompleteChangeOffsetDirective;
  eduOrganizations$: BehaviorSubject<EsOrg[]> = new BehaviorSubject<EsOrg[]>([]);
  get formCtrl(): UntypedFormControl { return  this._formCtrl; }
  @Input()
  set formCtrl(control: UntypedFormControl | AbstractControl) {
    this._formCtrl = control as UntypedFormControl;
  }
  private _formCtrl: UntypedFormControl;
  @Input() label: string;
  @Input() required: boolean;
  orgsOffset: number;
  orgsTextFilter: string;
  @Input() orgsType: 'all' | 'dpp';
  @Input() preselectedOrgId: string;
  @Output()
  selectedOrg: EventEmitter<EsOrg | string> = new EventEmitter<EsOrg | string>();
  get orgs$() {
    return this.eduOrganizations$.asObservable() || null;
  }
  get orgsFn(): ((offset, limit, textFilterString) => Observable<Array<EsOrg>>) {
    return this.getEduStuff$.bind(this);
  }
  get preselectedOrg$(): Observable<boolean> {
    return this._loadedValueFromSrv$;
  }


  private _firstOpened = true;
  private _loadedValueFromSrv$: Observable<boolean>;
  private _unsubscribe$: Subject<void> = new Subject<void>();

  constructor(protected readonly orgControllerService: OrgControllerService) { }

  clearEsOrg() {
    this._clearOrgsTextFilter();
    this.selectedOrg.emit('');
  }

  clearOrgsInput() {
    const filterValue = this.formCtrl.value;
    if (typeof filterValue === 'string' || filterValue === null) {
      this._clearOrgsTextFilter();
    }
  }

  firstOpen() {
    if (this._firstOpened) {
      this._clearOrgsTextFilter();
      this._firstOpened = false;
    }
  }

  getEduStuff$ (offset: number, limit: number, stringFilter: string): Observable<EsOrg[]> {
    switch (this.orgsType) {
      case 'all': {
        return this.orgControllerService.findAll(offset, limit, {complexName: stringFilter});

      }
      case 'dpp': {
        return this.orgControllerService.findAllHasDpp(offset, limit, {complexName: stringFilter});
      }
    }
  }

  getEsOrgName = (option: EsOrg) => option?.shortName || option?.fullName;

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  ngOnInit(): void {
    this._initOrganizationField(this.preselectedOrgId);
    this._initOrganizationTextFilter();
  }

  orgControlChanged($event: MatAutocompleteSelectedEvent) {
    this.selectedOrg.emit($event?.option?.value || <null> $event);
  }

  offsetChanged($event): void {
    this.orgsOffset = $event;
  }

  private _clearOrgsTextFilter () {
    this.formCtrl.setValue('');
    this.orgsTextFilter = '';
    this.autocompleteOffsetChange.clearOffset();
  }

  private _initOrganizationTextFilter() {
    setTimeout(() => {
      this.formCtrl.valueChanges.pipe(
        takeUntil(this._unsubscribe$),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        debounceTime(800),
        tap(value => {
          this.orgsTextFilter = value;
          this.autocompleteOffsetChange.clearOffset();
        }),
      ).subscribe();
    }, 0);
  }

  private _initOrganizationField(orgId: string) {
    this._loadedValueFromSrv$ = orgId ? this.orgControllerService.findById(orgId)
      .pipe(map(value => {
          if (value) {
            this.formCtrl.setValue(value);
          }
          return true;
        })
      ) : of(true);
  }
}
