import * as Sentry from '@sentry/browser';
import { Event, EventHint, Integration } from '@sentry/types';
import { environment } from '../../environments/environment';

export function initSentry() {
  Sentry.init({
    dsn: environment.sentryDsn,
    ignoreErrors: [
      'Non-Error exception captured',
      'Non-Error promise rejection captured'
    ],
    enabled: environment.sentryDsn != null && environment.sentryDsn !== '',
    integrations: (integrations: Integration[]): Integration[] => {
      return integrations.filter(i => i.name !== 'TryCatch');
    },
    beforeSend: (event: Event, hint: EventHint) => {
      return dropHttpResponseErrors(event, hint);
    },
    release: 'release_support_5', // when changing this value change it in postbuild.js as well TODO pre-build script?
  });
}

function dropHttpResponseErrors(event: Event, hint: EventHint): Event | null {
  const errorException: Error = hint.originalException instanceof Error && hint.originalException;
  if (
    event.message &&
    event.message.startsWith('Non-Error exception captured') &&
    errorException.name &&
    errorException.name === 'HttpErrorResponse'
  ) {
    return null;
  } else {
    return event;
  }
}
