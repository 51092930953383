import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { Labeled } from './labeled';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-switch-set',
  templateUrl: './switch-set.component.html',
  styleUrls: ['./switch-set.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SwitchSetComponent),
    multi: true
  }]
})
export class SwitchSetComponent implements ControlValueAccessor {

  @Input() disabled: boolean;
  @Input() cases: Labeled[];
  @Input()
  set activeCase(activeCase: Labeled) {
    if (!this.disabled) {
      this._activeCase = activeCase;

      if (this._onChange) {
        this._onChange(activeCase);
      }
    }
  }
  get activeCase(): Labeled {
    return this._activeCase;
  }
  @Output() activeCaseChange = new EventEmitter<Labeled>();

  private _onChange: (value: Labeled) => void;
  private _onTouched: () => void;
  private _activeCase: Labeled;

  writeValue(activeCase: Labeled): void {
    this.activeCase = activeCase;
  }

  registerOnChange(fn: (value: Labeled) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  select(selectedCase: Labeled): void {
    if (!this.disabled && this.activeCase !== selectedCase) {
      this.activeCase = selectedCase;
      this.activeCaseChange.emit(selectedCase);
    }
  }
}
