import { EventEmitter, Input, Output, Directive } from '@angular/core';
import { ProfOptionAnswerValueSchema, ProfQuestionTypeEnumSchema, TypeQuestion } from '@rsmu/portal-api';

import { AnswerForQuestion } from './question.dto';

@Directive()
export abstract class AbstractQuestionOption {

  @Input() options: ProfOptionAnswerValueSchema[];
  @Input() disabled: boolean;
  @Input() multiSelectAvailable: boolean;
  @Input() questionType: ProfQuestionTypeEnumSchema | TypeQuestion;

  @Input()
  get answer(): AnswerForQuestion {
    return this._answer;
  }
  set answer(newValue: AnswerForQuestion) {
    this._answer = newValue;
    this.answerChange.emit(newValue);
  }
  private _answer: AnswerForQuestion;
  @Output() answerChange: EventEmitter<AnswerForQuestion> = new EventEmitter();

  emitChanges() {
    this.answerChange.emit(this.answer);
  }
}
