import { Injectable } from '@angular/core';

import { MobileAppService } from '../mobile/mobile-app.service';
import { SecurityService } from '../security/security.service';
import { ApiService } from '../api/api.service';
import { catchError, filter, finalize, switchMap, tap } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import TokenData from '../security/token-data';
import { FeatureService } from '../app-commons/feature/feature.service';
import { FeatureKeys } from '../app-commons/feature/feature-keys';
import { environment } from '../../environments/environment';
import { StateService } from '@uirouter/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import _identity from 'lodash-es/identity';
import _isString from 'lodash-es/isString';

import _isNil from 'lodash-es/isNil';
import { IdpService } from '../idp/idp.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private signingIn = false;

  get isSigningIn(): boolean {
    return this.signingIn;
  }

  constructor(
    private readonly http: HttpClient,
    private readonly mobileAppService: MobileAppService,
    private readonly idpService: IdpService,
    private readonly securityService: SecurityService,
    private readonly apiService: ApiService,
    private readonly featureService: FeatureService,
    private readonly stateService: StateService
  ) {
  }

  canShowEsiaLink(): Observable<boolean> {
    if (this.mobileAppService.isMobileIosApp()) {
      return this.featureService.isFeatureEnabled$(FeatureKeys.IOS_ESIA);
    }

    if (this.mobileAppService.isMobileAndroidApp()) {
      return this.featureService.isFeatureEnabled$(FeatureKeys.ANDROID_ESIA);
    }

    return this.featureService.isFeatureEnabled$(FeatureKeys.ESIA);
  }

  performIdpLogin(): Observable<TokenData> {
    return of(this.idpService.isIdpActive())
      .pipe(
        filter(_identity),
        tap(() => this.signingIn = true),
        switchMap(() => this.idpService.attemptIdpLogin().pipe(finalize(() => this.signingIn = false)))
      );
  }

  performPasswordLogin(login: string, password: string): Observable<TokenData> {
    this.signingIn = true;
    return this.login(login, password)
      .pipe(catchError((error) => {
        this.signingIn = false;
        return throwError(error);
      }));
  }

  login(userName: string, password: string): Observable<TokenData> {
    return of(this.mobileAppService.isMobileApp())
      .pipe(
        switchMap((isMobileApp: boolean): Observable<TokenData> => {
          if (isMobileApp) {
            return this.idpService.authByIdp(
              userName,
              password,
              environment.baseWebUrl + this.stateService.href('root.login')
            );
          }
          return this.http.post<TokenData>(
            `${ environment.baseAuthUrl }/oauth/token?grant_type=password&username=${ userName }&password=${ password }`,
            {},
            SecurityService.getRequestOptions()
          );
        }),
        catchError(this.handleLoginError()),
        tap((result: TokenData) => {
          this.securityService.useNewToken(result);
        })
      );
  }

  private handleLoginError(): (err: HttpErrorResponse | string) => Observable<never> {
    return (err: HttpErrorResponse | string): Observable<never> => {
      let errorType: 'wrongCredentials' | 'unknown' = 'unknown';

      if (_isString(err)) {
        errorType = (err as string) === 'invalid_credentials' ? 'wrongCredentials' : 'unknown';
      } else {
        errorType = SecurityService.isWrongCredentialsError(err as HttpErrorResponse) ? 'wrongCredentials' : 'unknown';
      }
      return throwError(errorType);
    };
  }

  prepareLoginPage(): void {
    this.signingIn = this.idpService.isIdpActive();
  }
}
