<div class="questionary">
  <div class="questionary__error text text_bold text_big" *ngIf="error">
    <span>{{ 'questionary.error.load' | translate }}</span>
  </div>

  <app-loader
    class="centered centered_absolute"
    *ngIf="loading && !error"
    [size]="70"
    [showLabelKey]="false"
    [strokeWidth]="5"></app-loader>

  <div *ngIf="!loading && !error && !typeIsPsychological">
    <div class="questionary__header-data">
      <h2 class="heading heading_1 heading_light questionary__header-data-survey-name">
        {{ questionaryKeyTitle | translate }}
      </h2>
      <span class="text text_big questionary__header-data-subtitle"
            *ngIf="!isReadonly">
        {{ 'questionary.' + questionaryType + '.pleaseAnswer' | translate }}
      </span>
    </div>
    <div class="questionary__container">
      <app-prof-questionary-container>
      </app-prof-questionary-container>
    </div>
  </div>

  <div *ngIf="!loading && !error && typeIsPsychological">
    <div class="questionary__header-data">
      <h1 class="questionary__title text text_h1 text_light">{{ psyQuestionary?.title }}</h1>
      <span class="text text_big questionary__header-data-subtitle">{{ psyQuestionary?.description }}</span>
    </div>
    <div class="questionary__container">
      <app-psy-questionary-container>
      </app-psy-questionary-container>
    </div>
  </div>
</div>
