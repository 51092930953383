import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import _isNil from 'lodash-es/isNil';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NotificationBubbleService implements OnDestroy {

  protected readonly destroy$ = new Subject<void>();
  private readonly toggleMap = new Map<string, BehaviorSubject<boolean>>();

  constructor() {
  }

  ngOnDestroy(): void {
    this.clearToggles();
    this.destroy$.next();
    this.destroy$.complete();
  }

  clearToggles(): void {
    this.toggleMap.forEach((toggleSubj: BehaviorSubject<boolean>): void => {
      toggleSubj.complete();
    });
    this.toggleMap.clear();
  }

  close(id: string): void {
    this.setToggleState(id, false);
  }

  isOpen(id: string): Observable<boolean> | null {
    return !this.toggleMap.has(id) ? null : this.toggleMap.get(id).asObservable();
  }

  open(id: string): void {
    this.setToggleState(id, true);
  }

  registerBubble(id: string, defaultState = true): void {
    if (!_isNil(id) && !this.toggleMap.has(id)) {
      this.toggleMap.set(id, new BehaviorSubject<boolean>(defaultState));
    }
  }

  setToggleState(id: string, state: boolean): void {
    this.registerBubble(id);
    if (this.toggleMap.has(id)) {
      this.toggleMap.get(id).next(state);
    }
  }

  toggle(id: string): Observable<boolean> {
    return of(this.toggleMap.has(id))
      .pipe(
        switchMap((hasBubble: boolean): Observable<boolean> => {
          if (!hasBubble) {
            return of(false);
          }

          const isOpen$: BehaviorSubject<boolean> = this.toggleMap.get(id);

          if (isOpen$.value) {
            this.close(id);
          } else {
            this.open(id);
          }
          return of(isOpen$.value);
        })
      );
  }
}
