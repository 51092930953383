import {
  AfterViewInit,
  Component,
  ElementRef,
  forwardRef,
  HostListener,
  Inject,
  Injector,
  Input,
  OnInit,
  QueryList,
  ViewChildren,
  ViewContainerRef
} from '@angular/core';
import { DateTime } from 'luxon';
import { NgbDate, NgbDatepicker, NgbDatepickerConfig, NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CustomDatepickerI18n, DATEPICKER_TITLES, NgbDatepickerI18nTitles } from '../datepicker-i18n';
import { dateRangeInputMask } from '../../../constants/date-range-input-mask';
import { Overlay } from '@angular/cdk/overlay';
import { LayoutService } from '../../layout/layout.service';
import { takeUntil } from 'rxjs/operators';
import { deepCopy } from '../../../utils/copy';
import _isEqual from 'lodash-es/isEqual';
import _isNil from 'lodash-es/isNil';
import { DateUtils } from '../date-utils';
import { ManagedOverlayConnected } from '../../dropdown/managed-overlay-connected';
import { KeyListenerService } from '../../../key-listener/key-listener.service';

type RangeDateType = 'from' | 'to';

export interface DateRange {
  dtFromDate: number | null;
  dtToDate: number | null;
}

@Component({
  selector: 'app-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: [ './date-range.component.scss' ],
  providers: [
    { provide: DATEPICKER_TITLES, useClass: CustomDatepickerI18n },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateRangeComponent),
      multi: true
    },
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
  ]
})
export class DateRangeComponent extends ManagedOverlayConnected implements AfterViewInit, ControlValueAccessor, OnInit {

  static DATES_SEPARATOR = `-`;

  @Input() label;
  @Input() placeholder;
  @Input() configFrom: NgbDatepickerConfig = <NgbDatepickerConfig>{};
  @Input() configTo: NgbDatepickerConfig = <NgbDatepickerConfig>{};
  @Input() disabled = false;
  @Input() isBold = false;
  @Input() canReset = false;
  @Input() hideLabelOnMobile = false;
  @Input() theme = '';

  @ViewChildren(NgbDatepicker, { read: ElementRef }) private ngbDatepicker: QueryList<ElementRef<HTMLElement>>;

  readonly configFromInner: NgbDatepickerConfig = <NgbDatepickerConfig>{};
  readonly configToInner: NgbDatepickerConfig = <NgbDatepickerConfig>{};

  fieldModel = '';
  isError = false;
  fromDate: NgbDate | null;
  toDate: NgbDate | null;

  get dateRangeMask(): Array<string | RegExp> {
    return dateRangeInputMask;
  }

  private get dateRange(): DateRange {
    return this._dateRange;
  }

  private set dateRange(newVal: DateRange) {
    let { dtFromDate, dtToDate } = !_isNil(newVal) ? newVal : { dtFromDate: null, dtToDate: null };

    this.setFromDate(dtFromDate);
    this.setToDate(dtToDate);
    this.loadToFieldModel(this.fromDate, this.toDate);

    dtFromDate = !_isNil(this.fromDate) ? DateUtils.dateObjectToDateTime(this.fromDate).valueOf() : null;
    dtToDate = !_isNil(this.toDate) ? DateUtils.dateObjectToDateTime(this.toDate).valueOf() : null;

    Object.assign(this._dateRange, { dtFromDate, dtToDate });
  }

  private readonly _dateRange: DateRange = {
    dtFromDate: null,
    dtToDate: null
  };


  onChange = (value) => {};
  @HostListener('blur') onTouched = () => {};

  constructor(
    protected readonly injector: Injector,
    protected keyListener: KeyListenerService,
    protected readonly layoutService: LayoutService,
    protected readonly overlay: Overlay,
    protected readonly viewContainer: ViewContainerRef,
    @Inject(DATEPICKER_TITLES) private readonly datepickerI18nTitles: NgbDatepickerI18nTitles
  ) {
    super(
      injector,
      keyListener,
      layoutService,
      overlay,
      viewContainer
    );
  }

  ngAfterViewInit(): void {
    this.ngbDatepicker
      .changes
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.reassignDatepickerTitles();
      });
  }

  ngOnInit() {
    this.configureInner();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(dateRangeValue: DateRange): void {
    const previousDateRange: DateRange = deepCopy(this._dateRange);

    this.dateRange = dateRangeValue;
    if (!_isEqual(this.dateRange, previousDateRange)) {
      this.onChange(this.dateRange);
    }
  }

  inputChange(rangeStr: string) {
    const [ date1Str, date2Str ] = rangeStr.split(DateRangeComponent.DATES_SEPARATOR);
    const dateTime1: DateTime | null = DateUtils.parseDateStruct(date1Str);
    const dateTime2: DateTime | null = DateUtils.parseDateStruct(date2Str);
    const date1Valid: boolean = this.dateTimeValid(dateTime1);
    const date2Valid: boolean = this.dateTimeValid(dateTime2);

    this.isError = !dateTime1 && !dateTime2 && (!date1Valid || !date2Valid);

    this.writeValue({
      dtFromDate: date1Valid ? dateTime1.valueOf() : null,
      dtToDate: date2Valid ? dateTime2.valueOf() : null
    });
    this.close();
  }

  resetDate() {
    this.writeValue({
      dtFromDate: null,
      dtToDate: null
    });
    this.close();
  }

  onDateSelection(date: NgbDate, type: RangeDateType) {
    let dtFromDate: number | null = null;
    let dtToDate: number | null = null;

    if (type === 'from' && !_isNil(date)) {
      dtFromDate = DateUtils.dateObjectToDateTime(date).valueOf();
    } else if (!_isNil(this.fromDate)) {
      dtFromDate = DateUtils.dateObjectToDateTime(this.fromDate).valueOf();
    }

    if (type === 'to' && !_isNil(date)) {
      dtToDate = DateUtils.dateObjectToDateTime(date).valueOf();
    } else if (!_isNil(this.toDate)) {
      dtToDate = DateUtils.dateObjectToDateTime(this.toDate).valueOf();
    }

    this.writeValue({ dtFromDate, dtToDate });
  }

  private configureInner() {
    if (!this.configFrom.outsideDays) {
      this.configFrom.outsideDays = 'hidden';
    }
    if (!this.configTo.outsideDays) {
      this.configTo.outsideDays = 'hidden';
    }

    let minDate: NgbDateStruct = this.configFrom.minDate;
    let maxDate: NgbDateStruct = this.configTo.maxDate;

    if (!minDate) {
      minDate = { year: 2009, month: 1, day: 1 };
    }
    if (!maxDate) {
      maxDate = this.getToday();
    }

    if (DateUtils.dateObjectToDateTime(minDate) >= DateUtils.dateObjectToDateTime(maxDate)) {
      maxDate = this.dayNext(minDate);
    }

    this.configFrom.minDate = minDate;
    this.configTo.minDate = this.dayNext(minDate);

    this.configFrom.maxDate = this.dayPrev(maxDate);
    this.configTo.maxDate = maxDate;

    Object.assign(this.configFromInner, this.configFrom);
    Object.assign(this.configToInner, this.configTo);
  }

  private dateTimeValid(dateTime: DateTime): boolean {
    return !!dateTime ? dateTime.invalidReason == null : false;
  }

  private dayNext(date: NgbDateStruct): NgbDateStruct {
    return DateUtils.dateObjectToDateStruct(DateUtils.dateObjectToDateTime(date).plus({ days: 1 }).toObject());
  }

  private dayPrev(date: NgbDateStruct): NgbDateStruct {
    return DateUtils.dateObjectToDateStruct(DateUtils.dateObjectToDateTime(date).plus({ days: -1 }).toObject());
  }

  private getToday(): NgbDateStruct {
    return DateUtils.dateObjectToDateStruct(DateTime.local().toObject());
  }

  private loadToFieldModel(fromTime: NgbDate | null, toTime: NgbDate | null) {
    if (_isNil(fromTime) && _isNil(toTime)) {
      this.fieldModel = ``;
    } else {
      const from: string = !_isNil(fromTime) ? DateUtils.formatDateStruct(fromTime) : '';
      const to: string = !_isNil(toTime) ? DateUtils.formatDateStruct(toTime) : '';

      this.fieldModel = `${ from }${ DateRangeComponent.DATES_SEPARATOR }${ to }`;
    }
  }

  private reassignDatepickerTitles(): void {
    this.ngbDatepicker.forEach((item: ElementRef<HTMLElement>) => {
      const selectMonthTitled: NodeListOf<Element> = item.nativeElement.querySelectorAll('[title=\'Select month\']');
      const selectYearTitled: NodeListOf<Element> = item.nativeElement.querySelectorAll('[title=\'Select year\']');

      for (let i = 0; i < selectMonthTitled.length; i++) {
        const selectMonthTitledElement: Element = selectMonthTitled[i];

        selectMonthTitledElement.setAttribute('aria-label', this.datepickerI18nTitles.getSelectMonthTitle());
        selectMonthTitledElement.setAttribute('title', this.datepickerI18nTitles.getSelectMonthTitle());
      }

      for (let i = 0; i < selectYearTitled.length; i++) {
        const selectYearTitledElement: Element = selectYearTitled[i];

        selectYearTitledElement.setAttribute('aria-label', this.datepickerI18nTitles.getSelectYearTitle());
        selectYearTitledElement.setAttribute('title', this.datepickerI18nTitles.getSelectYearTitle());
      }
    });
  }

  private setConfigToMinDate(date: NgbDate | null = null) {
    if (_isNil(date)) {
      return;
    }
    this.configToInner.minDate = date;
  }

  private setFromDate(dtFromDate: number | null = null) {
    let fromDate: NgbDate | null = null;

    if (!_isNil(dtFromDate)) {
      const { year, month, day } = DateTime.fromMillis(dtFromDate).toObject();

      fromDate = new NgbDate(year, month, day);
      this.setConfigToMinDate(fromDate);
    } else {
      const { year, month, day } = this.getToday();

      this.setConfigToMinDate(new NgbDate(year, month, day));
    }

    this.fromDate = fromDate;
  }

  private setToDate(dtToDate: number | null = null) {
    let toDate: NgbDate | null = null;

    if (!_isNil(dtToDate)) {
      const { year, month, day } = DateTime.fromMillis(dtToDate).toObject();

      toDate = new NgbDate(year, month, day);
      if (!_isNil(this.fromDate) && !toDate.after(this.dayPrev(this.fromDate))) {
        toDate = null;
      }
    }

    this.toDate = toDate;
  }
}
