import { Subject } from 'rxjs';

export interface ErrorNotification {
  messageKey: string;
  priority?: number;
  showCloseButton?: boolean;
  closed$?: Subject<void>;
}

export enum ErrorNotificationPriority {
  SERVER_UNAVAILABLE = 10,
  NO_CONNECTION = 20
}
