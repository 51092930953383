<div
  *ngFor="let answer of options"
  class="limited-select-question__item"
  [class.limited-select-question__item_many]="options.length > 2"
  [class.limited-select-question_self-control]="isSelfControl()"
  [class.limited-select-question_radio]="!multiSelectAvailable">
  <app-checkbox
    *ngIf="multiSelectAvailable"
    class="limited-select-question__checkbox"
    label="{{ answer.title }}"
    modifications="checkbox_success checkbox_align_top"
    [value]="optionSelected(answer)"
    [disabled]="disabled"
    [allowHtmlText]="isSelfControl()"
    (valueChange)="setAnswer($event, answer)">
  </app-checkbox>
  <app-radio
    *ngIf="!multiSelectAvailable"
    class="limited-select-question__radio"
    theme="success"
    [html]="isSelfControl() && answer.title"
    [selfControl]="isSelfControl()"
    [disabled]="disabled"
    [checked]="optionSelected(answer)"
    (change)="setSingleAnswer(answer)">
    {{ isSelfControl() ? '' : answer.title }}
  </app-radio>
</div>
