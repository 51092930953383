import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icon-popup',
  templateUrl: './icon-popup.component.html',
  styleUrls: ['./icon-popup.component.scss']
})
export class IconPopupComponent implements OnInit {

  @Input() icon: string;
  @Input() text: string;

  get isOpen(): boolean {
    return this._isOpen;
  }
  set isOpen(isOpen: boolean) {
    this._isOpen = isOpen;
  }
  private _isOpen = false;

  constructor() {
  }

  ngOnInit() {
  }

}
