<div class="instructions">
  <h2 class="instructions__title heading heading_2 heading_light">{{ 'questionary.instruction' | translate }}</h2>
  <p class="instructions__description text text_medium text_big">
    <app-notification-bubble [closeButton]="false" class="instructions__notification-mobile" theme="white" modificator="notification-bubble__icon_higher">
      {{ processedInstructions }}
      <button
        (click)="showAllInstructions()"
        *ngIf="showMoreBtn"
        class=" button button_text button_primary">
        {{ 'eduElementView.more' | translate }}
      </button>
    </app-notification-bubble>

    <app-notification-bubble [closeButton]="false" class="instructions__notification-tablet-desktop" theme="white">
      {{ processedInstructions }}
      <button
        (click)="showAllInstructions()"
        *ngIf="showMoreBtn"
        class=" button button_text button_primary">
        {{ 'eduElementView.more' | translate }}
      </button>
    </app-notification-bubble>
  </p>
  <button (click)="startTest()" *ngIf="!isQuestionaryPausedForInstructions()" class="button button_primary text instructions__start-btn">
    {{ 'questionary.navigation.startTest' | translate }}
  </button>
</div>
