import {Component, Input} from '@angular/core';
import {BaseSelectOrganizationComponent} from '../base-select-organization/base-select-organization.component';
import {OrgControllerService} from '@rsmu/es-rest-client';
import {EducationStuffDTO} from '@rsmu/portal-api';
import {EsOrg} from '@rsmu/es-rest-client/model/esOrg';

@Component({
  selector: 'app-add-dpp-select-organization',
  templateUrl: './add-dpp-select-organization.component.html',
  styleUrls: ['./add-dpp-select-organization.component.scss']
})
export class AddDppSelectOrganizationComponent extends BaseSelectOrganizationComponent {
  @Input()
  profileOrgs: EducationStuffDTO[];

  constructor(
    protected readonly orgControllerService: OrgControllerService
  ) {
    super(orgControllerService);
  }

  getEsOrgName = (option: EsOrg & EducationStuffDTO) => option?.shortName || option?.fullName || option?.name;
}
