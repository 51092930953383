import { Injectable, NgZone, SecurityContext } from '@angular/core';
import * as Hopscotch from 'hopscotch';
import _forEach from 'lodash/forEach';
import _includes from 'lodash/includes';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorage } from 'ngx-store';
import * as tourDefinitionFacadeDesktopModule from './tours.json';
import * as tourDefinitionFacadeMobileModule from './tours.mobile.json';
import { RouteService } from '../app-routing/route.service';
import { DomSanitizer } from '@angular/platform-browser';
import { select, Store } from '@ngrx/store';
import { RootStoreState } from '../root-store';
import { ToursStoreSelectors } from '../root-store/tours-store';
import { ExitTourAction } from '../root-store/tours-store/actions';
import { finalize, first, takeUntil, tap } from 'rxjs/operators';
import { LayoutService } from '../app-commons/layout/layout.service';
import { Subject } from 'rxjs';
import { remToPx } from '../utils/rem-to-px';
import { FocusTrap, FocusTrapFactory } from '@angular/cdk/a11y';
import { AccessibleService } from '../app-commons/accessible/accessible.service';
import { UserService } from '../user/user.service';

export interface ITourFacade {
  /**
   * Starts the tour if it's not marked as viewed
   */
  start(startIndex: number);

  /**
   * Exposes the target of the first step
   */
  getFirstStepAnchor(startIndex: number);

  getLastStepIndex();
}

interface ITourStepDefinitionFacade {
  /**
   * ngx-translate key for step title
   */
  title?: string;
  /**
   * ngx-translate key for step text
   */
  text: string;
  /**
   * The step will be attached to HTML element that has tourAnchor attribute equal to this value.
   * If not provided then the value will be constructed from the value of 'text' field using following algorithm:
   *  - remove 'tour.' from the beginning
   *  - replace dots with underscores
   * E.g. if text is 'tour.profile.settings' then tourAnchor value will be 'profile_settings'.
   */
  tourAnchor?: string;
  /**
   * To what side of the element the bubble shall be placed
   */
  placement: 'left' | 'top' | 'right' | 'bottom';
  /**
   * The step message will be moved along X-axis for a given number of rem
   */
  xOffset?: number;
  /**
   * The step message will be moved along Y-axis for a given number of rem
   */
  yOffset?: number;
  /**
   * State name that will be transitioned to on moving to next step
   */
  onNextNavigateTo?: string;
  /**
   * State name that will be transitioned to on moving to previous step
   */
  onPrevNavigateTo?: string;

  scrollToTop?: boolean;

}

class TourFacade implements ITourFacade {

  constructor(private delegatee: TourDefinition) {}

  start(startIndex: number) {
    Hopscotch.startTour(this.delegatee, startIndex);
  }

  getFirstStepAnchor(startIndex: number): string {
    return getStartIndex(this.delegatee.steps, startIndex);
  }

  getLastStepIndex(): number {
    return this.delegatee.steps.length - 1;
  }

}

enum TourNavigationDirection {
  NEXT, PREV
}

enum ViewportWidth {
  mobile = 'mobile',
  desktop = 'desktop'
}

@Injectable({
  providedIn: 'root'
})
export class TourService {

  @LocalStorage() private viewedTours: string[] = null; // TODO remove and use ngrx store instead

  private next: string;
  private back: string;
  private done: string;
  private closeTooltip: string;

  private tourMap: Map<string, Map<ViewportWidth, ITourFacade>> = new Map();
  private focusTrap: FocusTrap;

  private readonly INTERVAL_TIME: number = 500;
  private readonly metaTourId = 'Meta';

  // ---- Fields accessed by tours -----
  // Used in OnSuccessTransitionCallback to determine that state transition is expected and tour shall not be interrupted
  private targetState: string;
  private unregisterTransitionCallback: Function;
  private skipMetaTour = false;
  private currentNavigationDirection: TourNavigationDirection;
  private currentTargetStepIndex: number;
  private currentItemSelector: string;
  private highlightBox: HTMLElement;
  private currentViewportWidth: ViewportWidth;
  private unsubscribeFromViewChanges$: Subject<void>;
  // -- End of fields access by tours --

  constructor(private translateService: TranslateService,
              private layoutService: LayoutService,
              private accessibleService: AccessibleService,
              private routeService: RouteService,
              private domSanitizer: DomSanitizer,
              private store: Store<RootStoreState.RootState>,
              private ngZone: NgZone,
              private focusTrapFactory: FocusTrapFactory,
              private readonly userService: UserService,
  ) {
    this.unsubscribeFromViewChanges$ = new Subject();
  }

  async startTourIfNotViewed(tourId: string): Promise<void> {
    try {
      await this.init();

      const canonicalTourId: string = this.providedToCanonicalTourId(tourId);

      if (!this.wasAlreadyViewed(canonicalTourId)) {
        await this.doStartTour(canonicalTourId);
      }
    } catch (e) {
      console.error(e);
    }
  }

  async startTour(tourId: string): Promise<void> {
    try {
      await this.init();

      const canonicalTourId: string = this.providedToCanonicalTourId(tourId);

      await this.doStartTour(canonicalTourId);
    } catch (e) {
      console.error(e);
    }
  }

  exitTourSkipMeta(): void {
    this.skipMetaTour = true;
    this.exitTour();
    this.skipMetaTour = false;
  }

  private async init(): Promise<void> {
    await this.translateService.get(' ').pipe(
      tap(() => {
        this.next = this.getSanitizedTranslation('common.tour.next');
        this.back = this.getSanitizedTranslation('common.tour.back');
        this.done = this.getSanitizedTranslation('common.tour.done');
        this.closeTooltip = this.getSanitizedTranslation('common.tour.closeTooltip');
      })
    ).toPromise();
  }

  private onViewChange(): void {
    const newViewPort: ViewportWidth = this.layoutService.isMobile
      ? ViewportWidth.mobile
      : ViewportWidth.desktop;

    if (newViewPort !== this.currentViewportWidth) {
      this.restartCurrentTour();
    } else {
      this.positionHighlightBox();
    }
  }

  private restartCurrentTour(): void {
    const currentTourId: string = Hopscotch.getCurrTour().id;
    this.exitTourSkipMeta();
    this.store.dispatch(new ExitTourAction({
      tourId: currentTourId,
      stepNumber: 0
    }));

    this.doStartTour(currentTourId);
  }

  private exitTour(): void {
    Hopscotch.endTour(true); // triggers onExitTour
  }

  private highlightOn(): void {
    this.highlightOff();

    const overlay: HTMLElement = document.createElement('div') as HTMLElement;
    overlay.classList.add('virtual-tour-overlay');

    this.highlightBox = document.createElement('div') as HTMLElement;
    this.highlightBox.classList.add('virtual-tour-highlight');

    this.positionHighlightBox();

    overlay.appendChild(this.highlightBox);

    const body: HTMLElement = document.querySelector('body') as HTMLElement;
    body.appendChild(overlay);
  }

  private get bubbleBox(): HTMLElement {
    return document.querySelector('.hopscotch-bubble');
  }

  private get nextButton(): HTMLElement {
    return this.bubbleBox.querySelector('.next');
  }

  private destroyFocusTrap(): void {
    if (this.focusTrap) {
      this.focusTrap.destroy();
    }
  }

  private setFocus(): void {
    this.destroyFocusTrap();
    this.focusTrap = this.focusTrapFactory.create(this.bubbleBox);
    this.nextButton.focus();
  }

  // noinspection JSMethodCanBeStatic
  private highlightOff(): void {
    const overlay: Element = document.querySelector('.virtual-tour-overlay');
    if (overlay) {
      overlay.parentNode.removeChild(overlay);
    }
  }

  /**
   * Only use this method to get the translation. Do not use translateService directly. Angular will not take care of data sanitization,
   * it has to be done manually.
   *
   * @param key translation key
   */
  private getSanitizedTranslation(key: string): string {
    return this.domSanitizer.sanitize(SecurityContext.HTML, this.translateService.instant(key));
  }

  private positionHighlightBox(): void {
    setTimeout(() => {
      const currentItem: HTMLElement = document.querySelector(this.currentItemSelector) as HTMLElement;

      if (currentItem) {
        const rect: ClientRect | DOMRect = currentItem.getBoundingClientRect();

        // We make the highlight box a bit bigger than the element rect
        const boxPadding = remToPx(1);

        this.highlightBox.style.left   = rect.left + window.pageXOffset - boxPadding + 'px';
        this.highlightBox.style.top    = rect.top  + window.pageYOffset - boxPadding + 'px';
        this.highlightBox.style.width  = rect.right  - rect.left + 2 * boxPadding + 'px';
        this.highlightBox.style.height = rect.bottom - rect.top + 2 * boxPadding + 'px';
      }
    }, 0);
  }

  private async doStartTour(canonicalTourId: string): Promise<void> {
    if (this.isStepsNotExists(canonicalTourId)) {
      return;
    }
     await this.userService.onSettingChanges$('userTips')
       .pipe(
         tap((res: boolean) =>  {
           if (res) {
             this.enableTours(canonicalTourId);
           }})
       ).toPromise();
  }

  private proceedToStep(): void {
    Hopscotch.showStep(this.currentTargetStepIndex);
  }

  private async enableTours(canonicalTourId) {
    this.markTourAsViewed(canonicalTourId);
    this.currentViewportWidth = this.layoutService.isMobile
      ? ViewportWidth.mobile
      : ViewportWidth.desktop;

    const isHaveTour: boolean = this.tourMap.has(canonicalTourId);
    const isHaveTourForCurrentViewport: boolean = isHaveTour &&
      this.tourMap
        .get(canonicalTourId)
        .has(this.currentViewportWidth);

    if (!isHaveTour || !isHaveTourForCurrentViewport) {
      await this.createTour(canonicalTourId);
    }

    this.store.pipe(
      select(ToursStoreSelectors.selectRestartStep, { tourId: canonicalTourId }),
      first()
    ).subscribe((startIndex: number) => {
      const currentTour: ITourFacade = this.tourMap &&
        this.tourMap.get(canonicalTourId) &&
        this.tourMap
          .get(canonicalTourId)
          .get(this.currentViewportWidth);

      const interval: NodeJS.Timer = setInterval(() => {
        // Before starting the tour we need to wait for first target element to appear
        if (!startIndex) {
          startIndex = 0;
        }
        if (document.querySelector(currentTour.getFirstStepAnchor(startIndex))) {
          clearInterval(interval);
          this.currentTargetStepIndex = startIndex;
          currentTour.start(startIndex);
        } else {
          startIndex++;
          if (startIndex > currentTour.getLastStepIndex()) {
            clearInterval(interval);
          }
        }
      }, this.INTERVAL_TIME);
    });
  }

  private proceedToStepWithStateTransition(): void {
    this.ngZone.run(() => {
      this.routeService.go(this.targetState).then(
        () => {
          // Zero timeout to make sure CSS is applied (otherwise bubble positioning may be incorrect)
          setTimeout(() => {
            this.proceedToStep();
            this.targetState = null;
          }, 0);
        }
      );
    });
  }

  // noinspection JSMethodCanBeStatic
  /**
   * The step will be attached to HTML element that has tourAnchor attribute equal to this value.
   * If not provided then the value will be constructed from the value of 'text' field using following algorithm:
   *  - remove 'tour.' from the beginning
   *  - replace dots with underscores
   * E.g. if text is 'tour.profile.settings' then tourAnchor value will be 'profile_settings'.
   */
  private buildTourAnchorSelector(step: ITourStepDefinitionFacade): string {
    const tourAnchor: string =
      step.tourAnchor
        ? step.tourAnchor
        : step.text.replace(/^(tour\.)/, '').replace(/\./g, '_');

    return `[tourAnchor=${tourAnchor}]`;
  }

  /**
   * Meta tour is a tour about tour functionality itself. It is shown after completion of each tour except meta tour.
   */
  private startMetaTour(): void {
    setTimeout(() => {
      this.doStartTour(this.metaTourId);
    }, 0);
  }

  /**
   * Upon first entering a page that has a tour the user will be presented with that tour.
   * Once the tour is marked as viewed it won't be shown when user visits the page again (however, user still can start it manually).
   */
  private markTourAsViewed(canonicalTourId: string): void {
    if (!this.viewedTours) {
      this.viewedTours = [];
    }
    if (!this.viewedTours.includes(canonicalTourId)) {
      this.viewedTours.push(canonicalTourId);
    }
  }

  private onExitTour(): void {
    this.highlightOff();
    this.unregisterTransitionCallback();
    this.destroyFocusTrap();
    this.unsubscribeFromViewChanges$.next();
    this.storeRestartStep();
  }

  private onExitTourStartMeta(): void {
    this.onExitTour();

    if (!this.skipMetaTour) {
      if (this.layoutService.isMobile) {
        window.scrollTo(0, 0);
      }
      this.startMetaTour();
    }
  }

  private storeRestartStep(): void {
    const tourId: string = Hopscotch.getCurrTour().id;
    let restartStep: number = this.currentTargetStepIndex;
    if (restartStep === Hopscotch.getCurrTour().steps.length - 1) {
      restartStep = 0;
    }

    this.store.dispatch(new ExitTourAction({
      tourId: tourId,
      stepNumber: restartStep
    }));
  }

  private async createTour(canonicalTourId: string): Promise<void> {
    const tourViewportMap: Map<ViewportWidth, TourFacade> = this.tourMap.has(canonicalTourId)
      ? this.tourMap.get(canonicalTourId)
      : new Map();

    const currentTourDefinition: ITourStepDefinitionFacade[] = this.layoutService.isNotDesktop
      ? tourDefinitionFacadeMobileModule[canonicalTourId]
      : tourDefinitionFacadeDesktopModule[canonicalTourId];

    const currentTour: TourDefinition = await this.getTourDefinition(canonicalTourId, currentTourDefinition);
    tourViewportMap.set(this.currentViewportWidth, new TourFacade(currentTour));

    this.tourMap.set(canonicalTourId, tourViewportMap);
  }

  private async getTourDefinition(canonicalTourId: string, steps: ITourStepDefinitionFacade[]): Promise<TourDefinition> {
    const tourDelegatee: TourDefinition = {
      id: canonicalTourId,
      showPrevButton: true,
      steps: [],
      smoothScroll: false, // slow on weak CPU
      i18n: {
        nextBtn: this.next,
        prevBtn: this.back,
        doneBtn: this.done,
        closeTooltip: this.closeTooltip,
        stepNums: null
      },
      skipIfNoElement: false,
      onStart: () => {
        // Here we register a state transition callback to close the tour if user navigates away
        this.unregisterTransitionCallback = this.routeService.registerOnSuccessTransitionCallback(() => {
          // Some tours can trigger state transitions so we need to differentiate between that and user navigating away
          const currentState: string = this.routeService.getCurrentState().name;
          if (this.targetState !== currentState) { // this.targetState is set whenever tour triggers state transition
            this.exitTourSkipMeta();
          }
        });
        // Register on resize event so that highlight box is correctly placed even if user is changing zoom
        this.layoutService
          .sizeChanges()
          .pipe(
            takeUntil(this.unsubscribeFromViewChanges$),
          )
          .subscribe(() => this.onViewChange());
        this.accessibleService
          .changesSwitchOn
          .pipe(
            takeUntil(this.unsubscribeFromViewChanges$),
          )
          .subscribe(() => this.onViewChange());
      },
      onError: () => {
        // This callback is called when we attempt to navigate to a step that targets an element that doesn't exist in the DOM (this is an
        // expected situation as some tour steps may be 'dynamic' depending on whether certain elements are present on the page).
        // In this case we navigate to the subsequent existing step.
        const thisTour: TourDefinition = tourDelegatee;
        setTimeout(() => {
          if (
            this.currentNavigationDirection === TourNavigationDirection.NEXT &&
            this.currentTargetStepIndex < thisTour.steps.length - 1
          ) {
            (thisTour.steps[this.currentTargetStepIndex].onNext as Function)();
          } else if (
            this.currentNavigationDirection === TourNavigationDirection.PREV &&
            this.currentTargetStepIndex > 0
          ) {
            (thisTour.steps[this.currentTargetStepIndex].onPrev as Function)();
          } else {
            this.exitTour();
          }
        }, 0);
      }
    };

    if (canonicalTourId === this.metaTourId) {
      tourDelegatee.onClose = () => {
        this.onExitTour();
      };
      tourDelegatee.onEnd = () => {
        this.onExitTour();
      };
    } else {
      tourDelegatee.onClose = () => {
        this.onExitTourStartMeta();
      };

      tourDelegatee.onEnd = () => {
        this.onExitTourStartMeta();
      };
    }

    tourDelegatee.steps = await this.createTourSteps(steps);
    return tourDelegatee;
  }

  private createTourSteps(stepArray: ITourStepDefinitionFacade[]): Promise<StepDefinition[]> {
    return new Promise((resolve, reject) => {
      this.translateService.get(' ').subscribe(() => {
        const stepImplArray: StepDefinition[] = [];

        _forEach(stepArray, (step: ITourStepDefinitionFacade, index: number) => {
          let translatedTitle: string = null;
          if (step.title) {
            translatedTitle = this.getSanitizedTranslation(step.title);
          }
          const translatedText: string = this.getSanitizedTranslation(step.text);

          const tourAnchorSelector = this.buildTourAnchorSelector(step);

          let onNext: CallbackNameNamesOrDefinition;
          let onPrev: CallbackNameNamesOrDefinition;

          if (index < stepArray.length - 1) {
            if (step.onNextNavigateTo) {
              onNext = () => {
                this.currentNavigationDirection = TourNavigationDirection.NEXT;
                this.currentTargetStepIndex = index + 1;
                this.targetState = step.onNextNavigateTo;
                this.proceedToStepWithStateTransition();
              };
            } else {
              onNext = () => {
                this.currentNavigationDirection = TourNavigationDirection.NEXT;
                this.currentTargetStepIndex = index + 1;
                this.proceedToStep();
              };
            }
          }

          if (index > 0) {
            if (step.onPrevNavigateTo) {
              onPrev = () => {
                this.currentNavigationDirection = TourNavigationDirection.PREV;
                this.currentTargetStepIndex = index - 1;
                this.targetState = step.onPrevNavigateTo;
                this.proceedToStepWithStateTransition();
              };
            } else {
              onPrev = () => {
                this.currentNavigationDirection = TourNavigationDirection.PREV;
                this.currentTargetStepIndex = index - 1;
                this.proceedToStep();
              };
            }
          }

        const stepImpl: StepDefinition = {
          title: translatedTitle, // Can be null in which case no title will be shown
          content: translatedText, // Should not be null
          target: tourAnchorSelector,
          placement: step.placement as placementTypes,
          // Note that offsets are determined upon tour construction and therefore will not be recalculated if user resizes the browser
          xOffset: step.xOffset ? remToPx(step.xOffset) : 0,
          yOffset: step.yOffset ? remToPx(step.yOffset) : 0,
          multipage: true,
          onPrev: onPrev,
          onNext: onNext,
          onShow: () => {
            this.currentItemSelector = tourAnchorSelector;
            this.highlightOn();
            this.setFocus();
          }
        };
        stepImplArray.push(stepImpl);
      });
      resolve(stepImplArray);
    }, () => reject());
  });
  }

  private wasAlreadyViewed(canonicalTourId: string): boolean {
    return this.viewedTours && _includes(this.viewedTours, canonicalTourId);
  }

  /**
   * Hopscotch's internal tour id shall use alphanumerics, underscores, and/or hyphens only and start with a letter.
   * To avoid abstraction leak we convert the provided tour name (which can be any string) to Hopscotch-specific tour id format by replacing
   * all non-alphanumeric non-underscore non-hyphen characters with corresponding character codes.
   */
  private providedToCanonicalTourId(providedTourId: string): string {
    let canonicalTourId: string = providedTourId.replace(/[^\w-]/g, (character: string) => {
      return String(character.charCodeAt(0));
    });

    if (/^[^a-zA-Z]/.test(canonicalTourId)) {
      canonicalTourId = 'x' + canonicalTourId;
    }

    return canonicalTourId;
  }

  private isStepsNotExists(canonicalTourId: string ): boolean {
    const currentTourDefinition: ITourStepDefinitionFacade[] = this.currentViewportWidth === ViewportWidth.mobile
      ? tourDefinitionFacadeMobileModule[canonicalTourId]
      : tourDefinitionFacadeDesktopModule[canonicalTourId];

    return !currentTourDefinition
      || !currentTourDefinition.length;
  }
}

function getStartIndex(steps: StepDefinition[], startIndex: number): string {
  const firstStep: StepDefinition =  steps[startIndex] || steps[0];

  if (!firstStep) {
    return null;
  }
  return firstStep.target as string;
}
