import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import {BehaviorSubject, EMPTY, Observable, throwError} from 'rxjs';
import {catchError, filter, finalize, switchMap, take, tap} from 'rxjs/operators';

import ErrorData from '../errors/error-data';
import { SecurityService } from './security.service';
import { RedirectService } from '../redirect/redirect.service';
import { authHeaderName } from '../../config/app-properties';
import {MobileAppService} from '../mobile/mobile-app.service';
import {IdpService} from '../idp/idp.service';
import {GlobalLoadingService} from '../global-loading/global-loading.service';
import {environment} from '../../environments/environment';

@Injectable()
export class SecurityInterceptor implements HttpInterceptor {

  isRefreshingToken = false;
  tokenRefreshSubject = new BehaviorSubject<string>(undefined);

  constructor(private redirectService: RedirectService,
              private securityService: SecurityService,
              private mobileAppService: MobileAppService,
              private globalLoadingService: GlobalLoadingService,
              private idpService: IdpService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const reqWithAuth = req.headers.has(authHeaderName) ? req : this.reqWithNewHeader(req, this.securityService.getAuthHeader());
    return next.handle(reqWithAuth).pipe(catchError((error: ErrorData) => {
      if (error.status === 401 && error.error && error.error.error) {
        if (error.error.error.includes('unauthorized')) {
          this.redirectService.toLogin(true);
          return throwError(error);
        } else if (this.isHaveToHandleInvalidToken(error)) {
          return this.handleTokenUpdate(req, next)
            .pipe(
              catchError((err) => {
                this.goToLoginPage();
                return throwError(error);
              })
            );
        }
      }
      return throwError(error);
    }));
  }

  private isHaveToHandleInvalidToken (err: ErrorData): boolean {
    return err.error.error_description.includes('Invalid access token') ||
      err.error.error_description.includes('Access token expired');
  }

  private handleTokenUpdate(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isRefreshingToken) {
      return this.tokenRefreshSubject.pipe(
        filter(newAuthHeader => newAuthHeader != null),
        take(1),
        switchMap((newAuthHeader) => next.handle(this.reqWithNewHeader(req, newAuthHeader)))
      );
    } else {
      this.isRefreshingToken = true;
      this.tokenRefreshSubject.next(undefined);
      return this.securityService.refreshToken(this.mobileAppService.isMobileApp()).pipe(
        switchMap(() => {
          const newAuthHeader = this.securityService.getAuthHeader();
          this.tokenRefreshSubject.next(newAuthHeader);
          this.isRefreshingToken = false;
          return next.handle(this.reqWithNewHeader(req, newAuthHeader));
        }),
        catchError(e => {
          this.isRefreshingToken = false;
          return throwError(e);
        }),
      );
    }
  }

  private reqWithNewHeader(req: HttpRequest<any>, header: string) {
    if (this.skipSettingAuthHeader(req.url)) { return req; }
    const newHeader = {};
    newHeader[authHeaderName] = header;
    return req.clone({ setHeaders: newHeader });
  }

  private skipSettingAuthHeader(url: string): boolean {
    return url.includes(environment.orgApiUrl);
  }

  private goToLoginPage() {
    this.securityService.useNewToken(null);
    this.redirectService.toLogin(true);
    this.globalLoadingService.clear();
  }
}
