<table class="registration">
  <td class="registration__cell">
    <app-loader *ngIf="isLoadingRegions || isLoadingCitizenships || isLoadingEsiaData"
                class="registration-form-loader"
                [size]="50"
                [showLabelKey]="false"
                [strokeWidth]="5">
    </app-loader>
    <div *ngIf='getRegionsServerError || getCitizenshipsServerError' class="text text_big text_alert text_bold">
      {{ 'server.error.failedToLoadData' | translate }}
    </div>
    <div class="iam-screen iam-screen_wide">
      <header class="iam-screen__header iam-screen__header_center registration__header">
        <a title="{{ 'login.help' | translate }}" href="{{ helpUrl }}" target="_blank" rel="noopener" class="registration__help-button text text_primary">
          <svg-icon class="icon icon_28" title="{{ 'tips.question' | translate }}" src="assets/icons/question.svg"></svg-icon>
        </a>
        <h2 class="heading heading_1 heading_light heading_1-smaller-sm heading_bold-sm" translate="registration.title"></h2>
      </header>

      <form class="registration__form"
            *ngIf="!isLoadingRegions &&
                  !isLoadingCitizenships &&
                  !isLoadingEsiaData &&
                  !getRegionsServerError &&
                  !getCitizenshipsServerError &&
                  !getEsiaDataServerError"
            [formGroup]="registrationForm"
            (ngSubmit)="createUser()"
            appScrollAndFocus>
        <div *ngIf="error" class="error-message error-message_color_alert">{{ errorMsg }}</div>
        <section class="iam-screen__section" *ngIf="registrationForm.invalid && registrationForm.dirty && registrationForm.errors">
          <div class="error-message error-message_color_alert iam-screen__error">
            <div *ngFor="let error of formErrors" translate="validation.registrationForm.{{ error }}"></div>
          </div>
        </section>
        <section class="iam-screen__section iam-screen__section_padding-t-0">
          <div class="iam-screen__input-group iam-screen__input-group_mobile">
            <div class="iam-screen__input">
              <app-autocomplete [formControlName]="registrationFormEnum.CITIZENSHIP_ID"
                                [items]="citizenship"
                                [isBig]="true"
                                [leftLabel]="true"
                                [searchDisabled]="false"
                                [class.ng-invalid]="citizenshipInvalid"
                                theme="autocomplete_theme_field-transparent"
                                label="{{ 'registration.userData.citizenship' | translate }}"
                                placeholder="{{ 'registration.userData.citizenship.placeholder' | translate }}"
                                itemLabel="name"
                                itemValue="id"
                                (valueChange)="OnCitizenshipChange($event)"
                                appAutoFocus
              ></app-autocomplete>
              <div class="error-message error-message_color_alert iam-screen__error" *ngIf="citizenshipInvalid">
                <div *ngFor="let error of citizenshipErrors" translate="validation.citizenship.{{ error }}"></div>
              </div>
            </div>
          </div>
          <div class="iam-screen__input-group iam-screen__input-group_desktop">
            <div class="iam-screen__input iam-screen__input-group_first_section">
              <app-autocomplete [formControlName]="registrationFormEnum.CITIZENSHIP_ID"
                                [items]="citizenship"
                                [isBig]="true"
                                [leftLabel]="true"
                                [searchDisabled]="false"
                                [class.ng-invalid]="citizenshipInvalid"
                                theme="autocomplete_theme_field-transparent"
                                label="{{ 'registration.userData.citizenship' | translate }}"
                                placeholder="{{ 'registration.userData.citizenship.placeholder' | translate }}"
                                itemValue="id"
                                itemLabel="name"
                                (valueChange)="OnCitizenshipChange($event)"
                                appAutoFocus
              ></app-autocomplete>
              <div class="error-message error-message_color_alert iam-screen__error" *ngIf="citizenshipInvalid">
                <div *ngFor="let error of citizenshipErrors" translate="validation.citizenship.{{ error }}"></div>
              </div>
            </div>
          </div>
          <div class="iam-screen__input-group iam-screen__input-group_desktop">
            <div class=iam-screen__input iam-screen__input-group_first_section>
              <label class="form-control form-control__left">
                <span class="form-control__label text text_big" translate="registration.userData.insNumber"></span>
                <span *ngIf="!isWorkingInRussianFederation && !isCitizenOfRussianFederation"
                      class="form-control__label text text_small">{{"registration.userDate.notObligatory" | translate}}</span>
                <input  [class.ng-invalid]="snilsInvalid"
                        (appElementCreated)="afterLoad($event)"
                        [textMask]="{ mask: snilsMask }"
                        [formControlName]="registrationFormEnum.SNILS"
                        class="text-field text-field_transparent"
                        type="tel"
                        placeholder="{{ 'registration.userData.insNumber.placeholder' | translate }}"/>
              </label>
              <div class="error-message error-message_color_alert iam-screen__error" *ngIf="snilsInvalid">
                <div *ngFor="let error of snilsErrors" translate="validation.snils.{{ error }}"></div>
              </div>
            </div>
          </div>
          <div class="iam-screen__input-group" *ngIf="showIsWorkingInRFCheckpoint()">
            <div class="iam-screen__input registration__checkbox-input">
              <app-checkbox
                            [bigText]="true"
                            [formControlName]="registrationFormEnum.WORKING_IN_RUSSIAN_FEDERATION"
                            modifications="checkbox_align_top"
                            label="{{ 'registration.userData.workingInRussianFederation' | translate }}"
                            (valueChange)="OnWorkingInRFChange($event)"
              >
              </app-checkbox>
              <div class="error-message error-message_color_alert iam-screen__error" *ngIf="termsAndConditionsInvalid">
                <div *ngFor="let error of termsAndConditionsErrors" translate="validation.termsAndCondition.{{ error }}"></div>
              </div>
            </div>
          </div>
          <div class="iam-screen__input-group" *ngIf="getIsWorkingInRussianFederation">
            <div class="iam-screen__input">
                <app-autocomplete [formControlName]="registrationFormEnum.REGION_ID"
                                  [items]="regions"
                                  [isBig]="true"
                                  [leftLabel]="true"
                                  [searchDisabled]="false"
                                  [class.ng-invalid]="regionInvalid"
                                  theme="autocomplete_theme_field-transparent"
                                  label="{{ 'registration.userData.region' | translate }}"
                                  placeholder="{{ 'registration.userData.region.placeholder' | translate }}"
                                  itemLabel="name"
                                  itemValue="id"></app-autocomplete>
              <div class="error-message error-message_color_alert iam-screen__error" *ngIf="regionInvalid">
                <div *ngFor="let error of regionErrors" translate="validation.region.{{ error }}"></div>
              </div>
            </div>
          </div>
          <div class="iam-screen__input-group iam-screen__input-group_mobile">
            <div class="iam-screen__input">
              <label class="form-control form-control__left">
                <span class="form-control__label text text_big" translate="registration.userData.insNumber"></span>
                <span *ngIf="!isWorkingInRussianFederation && !isCitizenOfRussianFederation"
                      class="form-control__label text text_small">{{"registration.userDate.notObligatory" | translate}}</span>
                <input  [class.ng-invalid]="snilsInvalid"
                        (appElementCreated)="afterLoad($event)"
                        [textMask]="{ mask: snilsMask }"
                        [formControlName]="registrationFormEnum.SNILS"
                        class="text-field text-field_transparent"
                        type="tel"
                        placeholder="{{ 'registration.userData.insNumber.placeholder' | translate }}"
                        appAutoFocus/>
              </label>
              <div class="error-message error-message_color_alert iam-screen__error" *ngIf="snilsInvalid">
                <div *ngFor="let error of snilsErrors" translate="validation.snils.{{ error }}"></div>
              </div>
            </div>
          </div>
        </section>
        <section class="iam-screen__section iam-screen__section_padding-t-0">
          <div class="iam-screen__input-group">
            <div class="iam-screen__input">
              <label class="form-control form-control__left">
                <span class="form-control__label text text_big" translate="registration.userData.lastname"></span>
                  <input [formControlName]="registrationFormEnum.LAST_NAME"
                         class="text-field text-field_transparent text-field_capitalize"
                         type="text"
                         placeholder="{{ 'registration.userData.lastname.placeholder' | translate }}"/>
              </label>
              <div class="error-message error-message_color_alert iam-screen__error" *ngIf="lastnameInvalid">
                <div *ngFor="let error of lastnameErrors" translate="validation.lastname.{{ error }}"></div>
              </div>
            </div>
            <div class="iam-screen__input">
              <label class="form-control form-control__left">
                <span class="form-control__label text text_big" translate="registration.userData.name"></span>
                  <input [formControlName]="registrationFormEnum.FIRST_NAME"
                         class="text-field text-field_transparent text-field_capitalize"
                         type="text"
                         placeholder="{{ 'registration.userData.name.placeholder' | translate }}"/>
              </label>
              <div class="error-message error-message_color_alert iam-screen__error" *ngIf="nameInvalid">
                <div *ngFor="let error of nameErrors" translate="validation.name.{{ error }}"></div>
              </div>
            </div>
            <div class="iam-screen__input">
              <label class="form-control form-control__left">
                <span class="form-control__label text text_big" translate="registration.userData.patronymic"></span>
                  <input [formControlName]="registrationFormEnum.MIDDLE_NAME"
                         class="text-field text-field_transparent text-field_capitalize"
                         type="text"
                         placeholder="{{ 'registration.userData.patronymic.placeholder' | translate }}"/>
              </label>
              <div class="error-message error-message_color_alert iam-screen__error" *ngIf="patronymicInvalid">
                <div *ngFor="let error of patronymicErrors" translate="validation.patronymic.{{ error }}"></div>
              </div>
            </div>
          </div>
          <div class="iam-screen__input-group">
            <div class="iam-screen__input iam-screen__input_container">
              <div class="iam-screen__input-group iam-screen__input-group_gender iam-screen__input-group_40 iam-screen__input-group_50-mobile">
                <div class="iam-screen__input_gender">

                  <div class="registration__gender">
                    <div class="registration__gender-label text text_big">{{ 'registration.userData.gender' | translate }}</div>
                    <div class="registration__gender-radios">
                      <app-radio [formControlName]="registrationFormEnum.GENDER"
                                 class="registration__gender-radio-item"
                                 value="male"
                                 name="gender">
                        {{ 'registration.userData.male' | translate }}
                      </app-radio>
                      <app-radio [formControlName]="registrationFormEnum.GENDER"
                                 class="registration__gender-radio-item"
                                 value="female"
                                 name="gender">
                        {{ 'registration.userData.female' | translate }}
                      </app-radio>
                    </div>
                  </div>

                  <div class="error-message error-message_color_alert iam-screen__error" *ngIf="genderInvalid">
                    <div *ngFor="let error of genderErrors" translate="validation.gender.{{ error }}"></div>
                  </div>
                </div>
              </div>
              <div class="iam-screen__input-group iam-screen__input-group_60 iam-screen__input-group_50-mobile">
                <div class="iam-screen__input">
                    <app-datepicker [formControlName]="registrationFormEnum.DATE_OF_BIRTH"
                                    [maxDate]="maxDate"
                                    [minDate]="minDate"
                                    [isBig]="true"
                                    [leftLabel]="true"
                                    theme="datepicker_theme_field-transparent"
                                    label="{{ 'registration.userData.birthday' | translate }}"
                                    placeholder="{{ 'registration.userData.birthday.placeholder' | translate }}"></app-datepicker>
                  <div class="error-message error-message_color_alert iam-screen__error" *ngIf="birthDateInvalid">
                    <div *ngFor="let error of birthDateErrors">
                      {{ 'validation.datepicker.' + error | translate: {value: registrationForm.controls.dateOfBirth.errors[error]} }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="iam-screen__input-group">
            <div class="iam-screen__input">
              <label class="form-control form-control__left">
                <span class="form-control__label text text_big" translate="registration.userData.email"></span>
                  <input [class.ng-invalid]="emailInvalid"
                         [formControlName]="registrationFormEnum.EMAIL"
                         class="text-field text-field_transparent"
                         type="text"
                         (focusout)="onEmailInputFocusOut()"
                         placeholder="{{ 'registration.userData.email.placeholder' | translate }}"/>
              </label>
              <div class="error-message error-message_color_alert iam-screen__error" *ngIf="emailInvalid">
                <div *ngFor="let error of emailErrors" translate="validation.email.{{ error }}"></div>
              </div>
            </div>
          </div>
          <div class="iam-screen__input-group">
            <div class="iam-screen__input">
              <label class="form-control form-control__left">
                <span class="form-control__label text text_big" translate="registration.userData.phoneNumber"></span>
                  <input *ngIf="isWorkingInRussianFederation" #phoneField [formControlName]="registrationFormEnum.PHONE"
                         [class.ng-invalid]="phoneNumberInvalid"
                         [textMask]="{ mask: phoneMask }"
                         type="tel"
                         class="text-field text-field_transparent field-phone"
                         placeholder="{{ 'registration.userData.phoneNumber.placeholder' | translate }}"
                         (click)="phoneClick()"
                         (blur)="phoneBlur()"
                         (focus)="phoneFocus()"/>
                  <input *ngIf="!isWorkingInRussianFederation" #phoneField [formControlName]="registrationFormEnum.PHONE"
                       [class.ng-invalid]="phoneNumberInvalid"
                       type="tel"
                       class="text-field text-field_transparent field-phone"
                         (input)="maskingForaingersPhone()"
                         (focus)="maskingForaingersPhone()"
                  />
              </label>
              <div class="error-message error-message_color_alert iam-screen__error" *ngIf="phoneNumberInvalid">
                <div *ngFor="let error of phoneNumberErrors" translate="validation.phoneNumber.{{ error }}"></div>
              </div>
            </div>
          </div>
        </section>
        <section class="iam-screen__section iam-screen__section_padding-t-0">
          <div class="iam-screen__input-group iam-screen__input-group_checkbox">
            <div class="iam-screen__input">
                <app-checkbox [smallText]="true"
                              [formControlName]="registrationFormEnum.ACCEPT_TERMS_AND_CONDITIONS"
                              modifications="checkbox_align_top checkbox_text-left checkbox_text-tiny-sm"
                              label="{{ 'registration.userData.termsAndConditions' | translate }}">
              </app-checkbox>
              <div class="error-message error-message_color_alert iam-screen__error" *ngIf="termsAndConditionsInvalid">
                <div *ngFor="let error of termsAndConditionsErrors" translate="validation.termsAndCondition.{{ error }}"></div>
              </div>
            </div>

            <div class="iam-screen__input">
                <app-checkbox [formControlName]="registrationFormEnum.ACCEPT_READ"
                              [smallText]="true"
                              [label]="'registration.userData.acceptRead' | translate"
                              modifications="checkbox_align_top checkbox_text-left checkbox_text-tiny-sm">
                </app-checkbox>
              <a class="button button_text button_primary button_size-sm registration__terms-link"
                  [href]="termsLink"
                  [download]="('registration.userData.downloadTermsAndConditions' | translate) + '.pdf'">
                {{ 'registration.userData.downloadUserTerms' | translate }}
              </a>
              <div class="error-message error-message_color_alert iam-screen__error" *ngIf="acceptReadInvalid">
                <div *ngFor="let error of acceptReadErrors" translate="validation.acceptRead.{{ error }}"></div>
              </div>
            </div>
          </div>
        </section>
        <section class="iam-screen__section iam-screen__section_footer">
          <div class="registration-footer">
            <div class="registration-footer__col registration-footer__col_captcha">
              <div *ngIf="!isMobile"
                   class="registration-footer__col registration-footer__captcha"
                   appYandexCaptcha
                   (captchaToken)="captchaHandler($event)"
                   >
              </div>
              <input hidden [formControlName]="registrationFormEnum.Y_CAPTCHA_RESPONSE"/>
              <div class="error-message error-message_color_alert iam-screen__error" *ngIf="captchaInvalid">
                <div *ngFor="let error of captchaErrors" translate="validation.captcha.{{ error }}"></div>
              </div>
            </div>
            <div class="registration-footer__col registration-footer__col_submit">
              <div class="form-footer">
                <app-loader *ngIf="isLoading" class="form-footer__loader"
                            [size]="20"
                            [showLabelKey]=false
                            [strokeWidth]="10"></app-loader>
                <button *ngIf="!isLoading"
                        [disabled]="registrationForm.invalid"
                        class="button button_primary button_high form-footer__button"
                        type="submit"
                        translate="registration.submit"></button>
              </div>
            </div>
          </div>
        </section>
      </form>
    </div>
  </td>
</table>

