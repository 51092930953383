import { Injectable, Renderer2, Inject, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})

export class HtmlDocumentService {

  private renderer: Renderer2;

  constructor(rendererFactory: RendererFactory2, @Inject(DOCUMENT) private document: HTMLDocument) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  setOverflowStyle(style) {
    if (this.body) {
      this.renderer.setStyle(this.body, 'overflow', style);
    }
  }

  addClass(classString: string): void {
    this.renderer.addClass(this.html, classString);
  }

  removeClass(classString: string): void {
    this.renderer.removeClass(this.html, classString);
  }

  private get html(): HTMLElement {
    return this.document.getElementsByTagName('html')[0] as HTMLElement;
  }

  private get body(): HTMLElement {
    return this.document.body;
  }
}
