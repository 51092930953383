import { Inject, Injectable } from '@angular/core';

import {IomRedirectResponseSchema, MyPlanElement, RequiredTopicsResponseSchema, SelfAssessmentUrl} from '@rsmu/portal-api';
import { ApiService } from '../../api/api.service';
import { DOCUMENT } from '@angular/common';
import { StateService, UIRouter } from '@uirouter/core';
import { Observable, of} from 'rxjs';
import {filter, map, mergeMap, take} from 'rxjs/operators';
import { DialogService } from '../dialog/dialog.service';
import { LayoutService } from '../layout/layout.service';
import { PopupAction } from '../popup/interfaces/popup.dto';
import {GlobalLoadingService} from '../../global-loading/global-loading.service';
import {PopupComponent} from '../popup/popup.component';
import {ScheduleElement} from '@rsmu/portal-api/model/scheduleElement';
import {MobileAppService} from '../../mobile/mobile-app.service';

interface TopicResponseElement extends MyPlanElement, RequiredTopicsResponseSchema {
}

@Injectable({
  providedIn: 'root'
})
export class IOMRedirectService {

  selfAssessment = <SelfAssessmentUrl>{};

  private openingElement: TopicResponseElement;

  constructor(
    private apiService: ApiService,
    private uiRouter: UIRouter,
    private stateService: StateService,
    private dialogService: DialogService,
    private layoutService: LayoutService,
    private readonly mobileAppService: MobileAppService,
    private globalLoadingService: GlobalLoadingService,
    @Inject(DOCUMENT) private document: HTMLDocument) { }

  redirectToSelfAssessment(cycleId: string, topicId?: string) {
    this.selfAssessment.cycleId = cycleId;
    this.selfAssessment.topicId = topicId;

    this.selfAssessment.redirectUrl = this.uiRouter.stateService.href(
      'userAccount.cycleSelect.eduTrajectoryHelper.selfAssessmentResults',
      { cycleId, topicId },
      { absolute: true }
    );
    this.stateService.go('questionary', {
      cycleId,
      topicId,
      questionaryType: 'self-control',
      selfAssessmentUrl: encodeURIComponent(this.selfAssessment.redirectUrl)
    });
  }

  getIomNotSupportsMobile(supportsMobile: boolean): boolean {
    return this.layoutService.isSmallScreen && !supportsMobile;
  }

  getIomRequiresVrEquip(requredVrEquip: boolean): boolean {
    return this.layoutService.isSmallScreen && requredVrEquip;
  }

  startAllIomRedirectionDialogs(element: TopicResponseElement & ScheduleElement, popup: PopupComponent<Observable<string>>): boolean {
    if (this.getIomRequiresVrEquip(element.requiredVrEquip)) {
      this.dialogService.messageTranslate('dialog.requireVR')
        .pipe(
          take(1),
          map(() => null))
        .subscribe();
      return false;
    }
    if (this.getIomNotSupportsMobile(element.supportsMobileDevices)) {
      this.dialogService.confirmTranslate('dialog.notSupportedMobile', {
        showClose: true,
        theme: undefined,
        buttons: [
          { key: 'dialog.continueRedirect', action: PopupAction.CONFIRM, style: 'button' },
          { key: 'dialog.closePopup', action: PopupAction.DENY, style: 'button button_primary' }
        ]
      })
        .pipe(
          take(1),
          filter(value => value === PopupAction.CONFIRM)
        )
        .subscribe(() => {
          popup.show(this.getIomLink(element.id || element.elementId));
        });
    } else {
      popup.show(this.getIomLink(element.id || element.elementId));
    }
  }

  getIomLink(iomId: string): Observable<string> {
    let url;
    return this.apiService.getIomRedirectLink(iomId, window.location.href).pipe(
      mergeMap((res: IomRedirectResponseSchema) => {
          url = res.url;
          return of(url);
        }),
      map(() => url)
    );
  }

  redirectToIom(id: string): Observable<string> {
    return this.dialogService.openURL(this.getIomLink(id));
  }

  goToIom(redirectIomLink: string | null) {
    if (this.mobileAppService.isMobileApp()) {
      return this.dialogService.openURL(redirectIomLink);
    } else {
      window.open(redirectIomLink, '_system');
      return of(null);
    }
  }

}
