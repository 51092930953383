import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateRangeComponent } from './date-range/date-range.component';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { TextMaskModule } from 'angular2-text-mask';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { TranslateModule } from '@ngx-translate/core';
import { OverlayModule } from '@angular/cdk/overlay';
import { TrapFocusModule } from '../trap-focus/trap-focus.module';

@NgModule({
  imports: [
    CommonModule,
    NgbDatepickerModule,
    FormsModule,
    TrapFocusModule,
    OverlayModule,
    AngularSvgIconModule,
    TextMaskModule,
    TranslateModule,
  ],
  declarations: [
    DateRangeComponent,
    DatepickerComponent
  ],
  exports: [
    DateRangeComponent,
    DatepickerComponent
  ]
})
export class DatepickersModule {
}
