import { Component, Host, Input, OnInit } from '@angular/core';
import { PopupComponent } from '../popup.component';

@Component({
  selector: 'app-popup-header',
  templateUrl: './popup-header.component.html',
  styleUrls: ['./popup-header.component.scss']
})
export class PopupHeaderComponent<T> implements OnInit {

  @Input() hideDefaultHeader = true;
  @Input() showClose = true;

  constructor(
    @Host() private popup: PopupComponent<T>,
  ) {
  }

  ngOnInit(): void {
    if (this.hideDefaultHeader) {
      this.popup.hideDefaultHeader();
    }
  }

  hide(): void {
    this.popup.hide();
  }

}
