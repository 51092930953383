import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import {Observable, Subject} from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {CyclesResponseSchema, CycleStatusInfoResponseSchema, EducationalElementTypeEnum, EntityIdAndNameSchema} from '@rsmu/portal-api';
import { SidebarSettings } from './sidebar-settings';
import { CyclesService } from '../../cycles/cycles.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnDestroy {
  @Input() isMyPlanScreen: boolean;
  @Input() menuItems: EntityIdAndNameSchema[];
  @Input() menuTitle;
  @Input() sidebarSettings: SidebarSettings;
  @Input() useForTour = false;

  @Output() addCycle = new EventEmitter<void>();
  @Output() clickItem = new EventEmitter<void>();

  get eduElementTypes () {
    return EducationalElementTypeEnum;
  }

  currentCycleInfo$: Observable<CyclesResponseSchema>;

  private cycles: CyclesResponseSchema[];
  private destroy$: Subject<void> = new Subject();

  constructor(private cyclesService: CyclesService) {
    cyclesService.getCycles().pipe(takeUntil(this.destroy$)).subscribe((cycles: CyclesResponseSchema[]) => {
      this.cycles = cycles;
    });

    this.currentCycleInfo$ = this.cyclesService.getCurrentCycleInfo();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onClick(): void {
    this.clickItem.next();
  }

  onAddCycle(): void {
    this.addCycle.next();
  }

  shouldShowTrajectory(): boolean {
    return this.sidebarSettings.showTrajectory && this.cycles && this.cycles.length > 1 && this.checkLearningByIot();
  }

  checkLearningByIot() {
    let iotCount = 0;
    this.cycles.forEach((cycle: CyclesResponseSchema) => {
      if (cycle.learningByIot) {
        iotCount++;
      }
    });
    return iotCount >= 2;
  }
}
