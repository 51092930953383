import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[appTabBodyHost]'
})
export class TabBodyHostDirective {
  constructor(
    public viewContainerRef: ViewContainerRef
  ) {}
}
