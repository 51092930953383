import { Component, Input, ViewEncapsulation } from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-multi-line-text',
  templateUrl: './multi-line-text.component.html',
  styleUrls: ['./multi-line-text.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MultiLineTextComponent {
  constructor(readonly domSanitizer: DomSanitizer) {
  }

  @Input() text: string;

  @Input() htmlText: string;

  @Input() markFirstLine = false;

  get textParts(): string[] {
    if (!(this.htmlText || this.text)) {
      return;
    }
    return !!this.htmlText ? this.htmlText.split('\n') : this.text.split('\n');
  }
}
