import {
  EntityAdapter,
  createEntityAdapter,
  EntityState
} from '@ngrx/entity';
import { CyclesResponseSchema } from '@rsmu/portal-api';

export const cyclesAdapter: EntityAdapter<CyclesResponseSchema> =
  createEntityAdapter<CyclesResponseSchema>({
    selectId: (model: CyclesResponseSchema) => model.id,
    sortComparer: (a: CyclesResponseSchema, b: CyclesResponseSchema): number =>
      a.name.localeCompare(b.name)
  });

export interface State extends EntityState<CyclesResponseSchema> {
  isLoading?: boolean;
  error?: any;
  isFirstLoading: boolean;
  cacheTime: number;
}

export const initialState: State = cyclesAdapter.getInitialState({
  isLoading: false,
  error: null,
  isFirstLoading: true,
  cacheTime: 0
});
