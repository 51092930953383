import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../environments/environment';
import TokenData from '../security/token-data';
import {catchError, concatMap, filter, map, switchMap, tap} from 'rxjs/operators';
import { objectToFormData } from '../utils/http-utils';
import ErrorData from '../errors/error-data';
import { interval, Observable, of, throwError } from 'rxjs';
import { RouteService } from '../app-routing/route.service';
import { StateService } from '@uirouter/core';
import _isNil from 'lodash-es/isNil';
import { MobileAppService } from '../mobile/mobile-app.service';
import { SecurityService } from '../security/security.service';
import { AccessibleService } from '../app-commons/accessible/accessible.service';
import { AccessibleMode } from '@rsmu/portal-api';
import { Logger } from '../app-commons/logger/logger.service';

export class IdpTokenRequest {
  idp_ticket: string;
}

export class IdpLogoutRequest {
  token: string;
}

export interface IdpLogoutResponse {
  location: string;
}

export class IdpPingRequest {
  idp_session_id: string;
}

export interface IdpAuthRequest {
  username: string;
  password: string;
  serviceProviderUrl: string;
  useMobile?: boolean;
  responseType?: 'client-ticket' | 'server-ticket';
  useAccessible: boolean;
  accessibleMode: AccessibleMode;
}

export interface IdpAuthResponse {
  serviceProviderUrl?: string;
  errorCode?: string;
}

export class IdpTicketRequest {
  idpSession: string;
}

export class IdpTicketResponse {
  idpTicket: string;
}

@Injectable({
  providedIn: 'root'
})
export class IdpService {

  private readonly logger = new Logger('IdpService');

  private readonly IDP_TICKET_PARAM = '#idp_ticket=';
  private readonly PING_TIMEOUT = environment.pingIntervalMs;

  private idpTicket;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly securityService: SecurityService,
    private readonly mobileAppService: MobileAppService,
    private readonly routeService: RouteService,
    private readonly stateService: StateService,
    private readonly accessibleService: AccessibleService
  ) {
    if (this.isIdpActive()) {
      interval(this.PING_TIMEOUT).pipe(filter(() => this.routeService.requiresAuth)).subscribe(() => {
        this.checkIdpSession().subscribe();
      });
    }

    if (this.mobileAppService.isMobileApp()) {
      securityService.getLogoutDataEvent().subscribe((ignoredLogoutData: IdpLogoutResponse) => {
        this.securityService.clearTwinCabinetToken();
      });
    }
  }

  storeIdpTicket(url: string): void {
    const idpTicket = this.extractIdpTicket(url);

    if (!_isNil(idpTicket)) {
      this.idpTicket = idpTicket;
    }
  }

  // noinspection JSMethodCanBeStatic
  extractIdpTicket(url: string): string | null {
    const ticketIndex = url.indexOf(this.IDP_TICKET_PARAM);
    if (ticketIndex !== -1) {
      return url.substring(ticketIndex + this.IDP_TICKET_PARAM.length);
    }
    return null;
  }

  attemptIdpLogin(): Observable<TokenData> {
    if (this.hasIdpTicket()) {
      return this.getTokenByIdpTicket(this.idpTicket)
        .pipe(
          tap((token: TokenData) => {
            this.idpTicket = null;
            this.securityService.useNewToken(token);
          })
        );
    } else {
      if (this.securityService.isLoggedIn()) {
        return this.checkIdpSession().pipe(
          catchError((): Observable<null> => throwError(null)),
          switchMap(() => of(this.securityService.getToken())),
        );
      } else {
        return throwError(null);
      }
    }
  }

  hasIdpTicket(): boolean {
    return this.idpTicket != null;
  }

  isIdpActive(): boolean {
    return this.securityService.isIdpActive();
  }

  checkIdpSession(): Observable<boolean | never> {
    if (!this.securityService.isLoggedIn()) {
      return of(false);
    }

    const url = `${ environment.baseAuthUrl.replace(/\/$/, '') }/idp/ping`;
    const body: IdpPingRequest = { idp_session_id: this.securityService.getToken().idp_session_id };
    const bodyString: string = objectToFormData(body);
    const requestConfig = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };
    return this.httpClient.post<boolean>(url, bodyString, requestConfig)
      .pipe(
        map(res => {
          return true;
        }),
        catchError((error: ErrorData): Observable<boolean | never> => {
          if (error.status === 401) {
            this.securityService.logout();
            return throwError(null);
          }
          return of(true);
        })
      );
  }

  authByIdp(username: string, password: string, serviceProviderUrl: string): Observable<TokenData> {
    const authUrl = `${ environment.baseIdpUrl.replace(/\/$/, '') }/auth`;
    const idpAuthRequest: IdpAuthRequest = {
      username,
      password,
      serviceProviderUrl,
      responseType: 'client-ticket',
      useMobile: true,
      useAccessible: this.accessibleService.isActive,
      accessibleMode: this.accessibleService.getMode() || AccessibleMode.White
    };
    return this.httpClient
      .post<IdpAuthResponse>(
        authUrl,
        idpAuthRequest,
        {
          headers: {
            'Content-Type': 'application/json; charset=utf-8'
          },
          observe: 'body',
          params: {
            type: 'custom'
          }
        }
      )
      .pipe(
        switchMap(({ errorCode, serviceProviderUrl: serviceProviderToAuth }: IdpAuthResponse): Observable<TokenData> => {
          if (!serviceProviderToAuth && errorCode) {
            return throwError(errorCode);
          }
          this.storeIdpTicket(serviceProviderToAuth);
          if (!this.hasIdpTicket()) {
            return throwError('invalid_params');
          }

          return this.getTokenByIdpTicket(this.idpTicket)
            .pipe(
              tap((ignoredToken: TokenData) => {
                this.idpTicket = null;
              })
            );
        })
      );
  }

  getTicket(idpTicketRequest: IdpTicketRequest): Observable<IdpTicketResponse> {
    const tokenUrl = environment.baseIdpUrl.replace(/\/$/, '') + '/ticket';
    return this.httpClient.post<IdpTicketResponse>(tokenUrl, idpTicketRequest);
  }

  private getTokenByIdpTicket(idpTicket: string): Observable<TokenData> {
    const tokenUrl = environment.baseAuthUrl + '/idp/token';
    const requestOptions = SecurityService.getRequestOptions();
    const body: IdpTokenRequest = { idp_ticket: idpTicket };
    const bodyString = objectToFormData(body);

    return this.httpClient.post<TokenData>(tokenUrl, bodyString, requestOptions);
  }
}
