import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { cyclesReducer } from './reducer';
import { EffectsModule } from '@ngrx/effects';
import { CyclesStoreEffects } from './effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('cycles', cyclesReducer),
    EffectsModule.forFeature([CyclesStoreEffects])
  ],
  declarations: [],
  providers: [CyclesStoreEffects]
})
export class CyclesStoreModule { }
