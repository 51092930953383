<div class="psy-questionary-question">
  <h2 class="text text_bold text_h3 psy-questionary-question__mobile-title">
    {{ 'questionary.psychological' | translate }}
  </h2>
  <span
    *ngIf="activeQuestionIndex === 0"
    class="text text_muted-light text_small psy-questionary-question__mobile-subtitle">
    {{ psyQuestionary.description }}
  </span>

  <app-questionary-progress-bar
    *ngIf="percentageOfCompletion || percentageOfCompletion === 0"
    class="psy-questionary-question__progress-bar-container-mobile"
    [percentageOfCompletion]="percentageOfCompletion">
  </app-questionary-progress-bar>

  <div class="psy-questionary-question__body">
    <div class="psy-questionary-question__mobile-tablet-description" *ngIf="activeQuestionIndex === 0">
      <h3 class="text text_bold psy-questionary-question__mobile-tablet-description-title">
        {{ 'questionary.specialty' | translate }}
      </h3>
      <span class="text">{{ psyQuestionary.title }}</span>
    </div>
    <div class="text text_muted text_h2 text_light psy-questionary-question__count">
      {{ activeQuestionIndex + 1 }} / {{ numberOfQuestions }}
    </div>
    <h3 class="psy-questionary-question__title text text_h3">{{ activeQuestion.questionText }}</h3>

    <div class="psy-questionary-question-container">
      <ng-container *ngIf="!isShowAutocomplete">
        <div class="psy-questionary-question-container__limited-question" *ngFor="let answer of activeQuestionAnswers">
          <app-checkbox
            *ngIf="isMultipleChoice()"
            [label]="answer.name"
            [ngModel]="isChecked(answer)"
            modifications="checkbox_success checkbox_align_top"
            (valueChange)="writeMultipleAnswer($event, answer)">
          </app-checkbox>

          <app-radio
            *ngIf="!isMultipleChoice()"
            [checked]="isChecked(answer)"
            [value]="answer.id"
            (change)="writeSingleAnswer(answer)"
            [name]="activeQuestion.questionId"
            theme="success">
            {{ answer.name }}
          </app-radio>
        </div>
      </ng-container>
      <ng-container *ngIf="isShowAutocomplete">

        <div class="psy-questionary-question__select-line" *ngFor="let dropdown of displayAnswers; let i = index;">
          <app-autocomplete
            class="psy-questionary-question__autocomplete"
            label=""
            itemLabel="name"
            itemValue="id"
            [ngModel]="displayAnswers[i]"
            (ngModelChange)="changeValueAnswer($event, i)"
            placeholder="{{ 'autocomplete.placeholder' | translate }}"
            [items]="activeQuestionAnswers"
            [filterFunction]="optionsFilter"
            [updateFilterEvent]="changesAnswers"
            [searchDisabled]='false'>
          </app-autocomplete>

          <span (click)="deleteAutocomplete(i)"
                class="psy-questionary-question__remove-btn">
            <span class="psy-questionary-question__figure-cross"></span>
          </span>
        </div>

        <button
          *ngIf="isMultipleChoice()"
          class="button button_text text text_big button_primary psy-questionary-question-container__add-dropdown-btn"
          (click)="addNewAutocomplete()">
          {{ 'questionary.add' | translate }}
        </button>
      </ng-container>
    </div>
  </div>

  <div class="psy-questionary-question__navigation">
    <button
      (click)='previousQuestion()'
      class="button button_text button_primary text psy-questionary-question__previous-btn"
      [disabled]='activeQuestionIndex === 0'>
      <span class="psy-questionary-question__tablet-desktop-text">&lt;</span>
      {{ 'questionary.navigation.previous' | translate }}
    </button>
    <button
      class="button button_primary button_raised text psy-questionary-question__next-btn"
      (click)="nextQuestion()"
      [disabled]="disabledStatus">
      {{ 'questionary.navigation.next' | translate }}
      <span class="psy-questionary-question__tablet-desktop-text">&gt;</span>
    </button>
  </div>
</div>

