import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/api/api.service';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import * as cyclesActions from './actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CyclesResponseSchema } from '@rsmu/portal-api';

@Injectable()
export class CyclesStoreEffects {
  constructor(
    private apiService: ApiService,
    private actions$: Actions
  ) {}

  loadRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<cyclesActions.LoadRequestAction>(
        cyclesActions.ActionTypes.LOAD_REQUEST
      ),
      switchMap((ignoredAction: Action): Observable<Action> =>
        this.apiService
          .getCycles()
          .pipe(
            map(
              (items: CyclesResponseSchema[]) => {
                return new cyclesActions.LoadSuccessAction({
                  items
                });
              }
            ),
            catchError(error => of(new cyclesActions.LoadFailureAction({ error })))
          )
      )
    ));
}
