import { Component, EventEmitter, Input, Output } from '@angular/core';
import {ACCORDION_ANIMATIONS} from '../../user-account/accordion/accordion-animation';

@Component({
  selector: 'app-edu-view-dropdown',
  templateUrl: './edu-view-dropdown.component.html',
  styleUrls: ['./edu-view-dropdown.component.scss'],
  animations: ACCORDION_ANIMATIONS
})
export class EduViewDropdownComponent {

  private _isShowed: boolean;
  @Input()
  get isShowed(): boolean {
    return this._isShowed;
  };
  set isShowed(value: boolean) {
    this._isShowed = value;
    this.isShowedChange.emit(value);
  }
  @Output() isShowedChange = new EventEmitter<boolean>();

  @Input() title: string;

  toggleIsShowed(): void {
    this.isShowed = !this.isShowed;
  }

}
