<div class="range-slider">
  <div class="range-slider__values">
    <label class="range-slider__value">
      {{ 'common.from' | translate }}
      <input class="text-field text-field__number"
             type="number"
             required
             id="range-min-value"
             [(ngModel)]="minValue"
             [ngModelOptions]="{ updateOn: 'blur' }"
             (ngModelChange)="modelChanged()"
             appMinNumber
             appMaxNumber
             [min]="options.floor"
             [max]="maxValue"
             [step]="options.step">
    </label>
    <label class="range-slider__value">
      {{ 'common.to' | translate }}
      <input class="text-field text-field__number"
             type="number"
             required
             id="range-max-value"
             [(ngModel)]="maxValue"
             (ngModelChange)="modelChanged()"
             appMinNumber
             appMaxNumber
             [min]="minValue"
             [max]="options.ceil"
             [step]="options.step">
    </label>
  </div>
  <ngx-slider [(value)]="minValue"
              [(highValue)]="maxValue"
              [options]="options"
              (userChangeEnd)="onUserChangeEnd($event)"></ngx-slider>
</div>
