import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { EduSpecificsType } from './cell-icon-enums';
import _isNil from 'lodash-es/isNil';

@Component({
  selector: 'app-cell-icon',
  templateUrl: './cell-icon.component.html',
  styleUrls: ['./cell-icon.component.scss']
})
export class CellIconComponent implements OnChanges, OnInit {

  @Input() value: any;
  @Input() splitby: string;
  @Input() theme = '';
  @Input() themeContainer = '';
  @Input() type: 'CHARGE_TYPE' | 'EDU_TECHNOLOGY' | '' = '';
  @Input() elementType: string;
  @Input() excludedValues: ReadonlyArray<string> = [];
  processedValue: string[];

  constructor() { }

  ngOnChanges({ value, splitby, excludedValues }: SimpleChanges): void {
    if (
      (!_isNil(value) && !value.firstChange) ||
      (!_isNil(splitby) && !splitby.firstChange) ||
      (!_isNil(excludedValues) && !excludedValues.firstChange)
    ) {
      this.parseValue(value.currentValue);
    }
  }

  ngOnInit() {
    this.parseValue(this.value);
  }

  parseValue(value: string): void {
    if (value) {
      this.processedValue = value
        .split(this.splitby)
        .filter((part: string) => !this.excludedValues.some((valueToExclude: string) => valueToExclude === part));
    }
  }

  getIconClass(parsedValue: string): string {
    switch (parsedValue) {
      case 'Бесплатная':
      case 'budget':
        return 'icon_free';

      case 'paid_foms':
      case 'Платная':
      case 'paid':
        return 'icon_paid';

      default:
        return '';
    }
  }

  getIconName(parsedValue: string): string {
    switch (parsedValue) {
      case EduSpecificsType.INTERNSHIP:
        return 'assets/icons/edu-specifics-internship.svg';
      case EduSpecificsType.NETWORK:
        return 'assets/icons/edu-specifics-network.svg';
      case EduSpecificsType.SIMULATION:
        return 'assets/icons/edu-specifics-simulation.svg';
      case EduSpecificsType.REMOTE_DOT_AND_EO:
        return 'assets/icons/edu-specifics-dotAndEo.svg';
      case EduSpecificsType.OUTBOUND:
        return '';
      case 'Бесплатная':
      case 'budget':
        return 'assets/icons/free.svg';
      case 'Платная':
      case 'paid':
        return 'assets/icons/paid.svg';
      case 'paid_foms':
        return 'assets/icons/paidbutnot.svg';
      default:
        return null;
    }
  }

}
