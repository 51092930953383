import { Component, ElementRef, HostBinding, Input, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: [ './password.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class PasswordComponent implements OnInit {

  @HostBinding('class.password-group') readonly baseCss = true;

  @Input() tab = 0;

  private _shown = false;
  private input: HTMLInputElement;

  get shown(): boolean {
    return this._shown;
  }

  get title(): string {
    return `common.password.${ this._shown ? 'hide' : 'show' }`;
  }

  constructor(private readonly el: ElementRef<HTMLElement>,
              private readonly renderer: Renderer2) {
  }

  ngOnInit(): void {
    this.input = this.el.nativeElement.querySelector('input');
    if (!this.input) {
      throw new Error(`No input element found!`);
    }

    this.renderer.addClass(this.input, 'password-group__input');

    this._shown = this.input.type === 'text';
  }

  toggle() {
    this._shown = !this._shown;
    this.input.setAttribute('type', this._shown ? 'text' : 'password');
  }

}
