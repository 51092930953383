import { ConnectionPositionPair } from '@angular/cdk/overlay';

export enum Placement {
  BOTTOM_LEFT = 'bottom-left',
  BOTTOM_RIGHT = 'bottom_right',
  TOP_LEFT = 'top-left',
  TOP_RIGHT = 'top-right',
  BOTTOM = 'bottom'
}

export const POSITION_MAP = new Map<Placement, ConnectionPositionPair>([
  [
    Placement.BOTTOM_LEFT,
    {
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'top',
      offsetX: 0,
      offsetY: 0,
    }
  ],
  [
    Placement.BOTTOM_RIGHT,
    {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'top',
      offsetX: 0,
      offsetY: 0,
    }
  ],
  [
    Placement.TOP_LEFT,
    {
      originX: 'start',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'bottom',
      offsetX: 0,
      offsetY: 0,
    }
  ],
  [
    Placement.TOP_RIGHT,
    {
      originX: 'end',
      originY: 'top',
      overlayX: 'end',
      overlayY: 'bottom',
      offsetX: 0,
      offsetY: 0,
    }
  ],
  [
    Placement.BOTTOM,
    {
      originX: 'center',
      originY: 'bottom',
      overlayX: 'center',
      overlayY: 'top',
      offsetX: 0,
      offsetY: 0
    }
  ]
]);

export const POSITION_LIST: ConnectionPositionPair[] = Array.from(POSITION_MAP, item => item[1]);
