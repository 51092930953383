export enum LayoutSize {
  MOBILE,
  TABLET,
  DESKTOP
}

export const LAYOUT_BREAKPOINTS_MAP = new Map<LayoutSize, number>([
  [LayoutSize.DESKTOP, 1334],
  [LayoutSize.TABLET, 768],
  [LayoutSize.MOBILE, 0],
]);

export const LAYOUT_BREAKPOINT_LIST = Array.from(LAYOUT_BREAKPOINTS_MAP, item => {
  return {
    key: item[0],
    size: item[1]
  };
});
