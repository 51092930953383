import { Injectable, InjectionToken } from '@angular/core';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

const I18N_VALUES = {
  'ru': {
    weekdays: ['П', 'В', 'С', 'Ч', 'П', 'С', 'В'],
    months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
    monthsFull: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
    selectYear: 'Выберите год',
    selectMonth: 'Выберите месяц'
  }
};

export interface NgbDatepickerI18nTitles {
  getSelectMonthTitle(): string;

  getSelectYearTitle(): string;
}

export const DATEPICKER_TITLES = new InjectionToken<NgbDatepickerI18nTitles>('NgbDatepickerI18nTitles');

@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n implements NgbDatepickerI18nTitles {

  private readonly defaultLocale = 'ru';

  constructor() {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this.defaultLocale].weekdays[weekday - 1];
  }

  getWeekdayLabel(weekday: number, width?: import('@angular/common').TranslationWidth): string {
    return I18N_VALUES[this.defaultLocale].weekdays[weekday - 1];
  }

  getMonthShortName(month: number, year?: number): string {
    return I18N_VALUES[this.defaultLocale].months[month - 1];
  }

  getMonthFullName(month: number, year?: number): string {
    return I18N_VALUES[this.defaultLocale].monthsFull[month - 1];
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    const {day, month, year} = date;
    return `${day}-${month}-${year}`;
  }

  getSelectMonthTitle(): string {
    return I18N_VALUES[this.defaultLocale].selectMonth;
  }

  getSelectYearTitle(): string {
    return I18N_VALUES[this.defaultLocale].selectYear;
  }

}
