<app-loader [hidden]="!toShowSpinner" class="centered centered_absolute"></app-loader>
<app-accessible-toggle class="login-accessible-toggle" *ngIf="!toShowSpinner"></app-accessible-toggle>
<section class="login" [hidden]="toShowSpinner">
  <img class="login-background-left" alt="" src='assets/icons/Background_1.svg' role="presentation" />
  <img class="login-background-right" alt="" src='assets/icons/Background_2.svg' role="presentation" />
  <table class="login-wrapper">
    <tr>
      <td class="login-wrapper__cell">
        <form class="iam-screen iam-screen_login" [formGroup]="loginForm" (ngSubmit)="onLogin()">
          <header class="iam-screen__header login__header">
            <div class="login__logo-container" [title]="'header.logo-title' | translate">
              <div class="login__logo-wrapper">
                <svg-icon class="login__logo icon icon_primary-dark"
                          src='assets/icons/logo_nmfio_web_part_1.svg'></svg-icon>
                <svg-icon class="login__logo icon icon_logo login__logo_animated"
                          src='assets/icons/logo_nmfio_web_part_2.svg'></svg-icon>
              </div>
            </div>

            <a title="{{ 'login.help' | translate }}"
               tabindex="11"
               href="{{ helpUrl }}"
               target="_blank"
               rel="noopener"
               class="login__help-button text text_primary">
              <svg-icon class="icon icon_28" src="assets/icons/question.svg"></svg-icon>
            </a>
            <h2 class="heading heading_1 heading_light heading_1-smaller-sm heading_bold-sm login__title">
              {{ 'login.title' | translate }}
            </h2>
          </header>

          <div class="login__errors error-message error-message_color_alert"
               *ngIf="isShowGlobalErrors || !!specificMessageKey">
            <div *ngIf="!!specificMessageKey"
                 translate="validation.loginForm.{{ specificMessageKey }}"></div>
            <ng-container *ngIf="isShowGlobalErrors">
              <div *ngFor="let error of formErrors"
                   translate="validation.loginForm.{{ error }}"></div>
            </ng-container>
          </div>

          <div class="tab-group">
            <button
              [class.tab-group__tab_active]="!isEmailModeToggle"
              class="tab-group__tab"
              type="button"
              (mouseenter)="changeTabStyleOnMouse($event)"
              (mouseleave)="changeTabStyleOnMouse($event)"
              (click)="setIsEmailModeToggle(false)"
            >
              <span class="tab-group__label">{{ 'login.loginModeTab.snils' | translate }}</span>
            </button>
            <button
              [class.tab-group__tab_active]="isEmailModeToggle"
              class="tab-group__tab"
              type="button"
              (click)="setIsEmailModeToggle(true)"
              (mouseenter)="changeTabStyleOnMouse($event)"
              (mouseleave)="changeTabStyleOnMouse($event)"
            ><span class="tab-group__label">{{'login.loginModeTab.email' | translate }}</span>
            </button>
          </div>

          <div class="iam-screen__input">
            <label class="form-control form-control__left">
              <input [title]="'login.userNameField.title' | translate"
                     [textMask]="!isEmailModeToggle ? { mask: snilsMask } : {mask: false}"
                     [formControlName]="loginFormControls.USER_NAME"
                     class="text-field text-field_transparent"
                     tabindex="12"
                     name="login"
                     [attr.type]="isEmailModeToggle ? 'email' : 'tel'"
                     placeholder="{{(isEmailModeToggle ? 'login.userNameField.emailPlaceholder' : 'login.userNameField.snilsPlaceholder') | translate }}"
                     required
                     appAutoFocus/>
            </label>
            <div class="error-message error-message_color_alert iam-screen__error" *ngIf="userName.invalid && userName.dirty">
              {{ (isEmailModeToggle ? 'validation.userName.invalidEmail' : 'validation.userName.invalidSnils') | translate }}
            </div>
          </div>

          <div class="iam-screen__input">
            <label class="form-control form-control__left">
              <span class="form-control__label text text_big text_base-sm">{{ 'login.password.label' | translate }}</span>
              <app-password [tab]="13">
                <input [title]="'login.password.title' | translate"
                       [formControlName]="loginFormControls.PASSWORD"
                       class="text-field text-field_transparent"
                       tabindex="13"
                       name="password"
                       type="password"
                       placeholder=""
                       required
                       autocomplete="off"/>
              </app-password>
            </label>
            <div class="error-message error-message_color_alert iam-screen__error" *ngIf="password.invalid && password.dirty">
              <span *ngFor="let error of passwordErrors" translate="validation.password.{{error}}"></span>
            </div>
          </div>
          <div class="login__auth-extra-link-container text text_small-sm text_primary">
            <a uiSref="root.registration"
               tabindex="14"
               uiSrefActive="active"
               class="login__auth-extra-link login__auth-extra-link_sign-up">
              {{ 'login.signUpLink' | translate }}
            </a>
            <a uiSref="root.passwordRecovery"
               tabindex="15"
               uiSrefActive="active"
               [title]="'login.restorePassword' | translate"
               class="login__auth-extra-link login__auth-extra-link_recovery">
              {{ 'login.forgotPasswordLink' | translate }}
            </a>
          </div>

          <div class="login__auth-actions">
            <div class="login__actions-section">
              <a uiSref="root.registration"
                 [title]="'login.signUpLink' | translate"
                 uiSrefActive="active"
                 class="button button_basic button_stroked button_high login__action-button login__register-link">
                {{ 'login.signUpLink' | translate }}
              </a>
              <button [title]="'login.submitButton' | translate"
                      tabindex="17"
                      type="submit"
                      class="button button_primary button_high login__action-button"
                      translate="login.submitButton">
              </button>
            </div>

            <div class="login__esia-link-container" *ngIf="canShowEsiaLink">
              <button class="login__esia-link"
                      type="button"
                      (click)="onEsiaLogin()"
                      tabindex="16"
                      [title]="'login.esiaSignUpTitle' | translate">
                <span class="login__esia-text">{{ 'login.esiaSignUpLink' | translate }}</span>
                <svg-icon src="assets/icons/state-services.svg"
                          [title]="'login.esiaSignUpLinkImgAlt' | translate"
                          class="login__esia-img icon icon_primary-dark"></svg-icon>
              </button>
            </div>
          </div>

        </form>
      </td>
    </tr>
  </table>
</section>
