import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { EduElements } from '@rsmu/portal-api';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'eduElNames'
})
export class EduElNamesPipe implements PipeTransform {

  constructor(private translateService: TranslateService) {}

  transform(value: EduElements, args?: any): any {
    return this.translateService.get(' ').pipe(map(() => {
      const resultArray = [];
      Object.keys(value).forEach(key => {
        if (value[key]) {
          resultArray.push(value[key] + ' ' + this.translateService.instant(`eduElementType.short.${key}`));
        }
      });
      return resultArray.join(', ').replace(/(.+)(, )(.+)$/, `$1 ${this.translateService.instant('common.and')} $3`);
    }));
  }
}
