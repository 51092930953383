import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopupComponent } from './popup.component';
import { PopupDirective } from './popup.directive';
import { TranslateModule } from '@ngx-translate/core';
import { OverlayModule } from '@angular/cdk/overlay';
import { PopupHeaderComponent } from './popup-header/popup-header.component';
import { TrapFocusModule } from '../trap-focus/trap-focus.module';

@NgModule({
  imports: [
    CommonModule,
    OverlayModule,
    TranslateModule,
    TrapFocusModule,
  ],
  declarations: [
    PopupComponent,
    PopupDirective,
    PopupHeaderComponent,
  ],
  exports: [
    PopupComponent,
    PopupDirective,
    PopupHeaderComponent,
  ]
})
export class PopupModule {
}
