import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import _isNil from 'lodash-es/isNil';
import { ChangeContext } from '@angular-slider/ngx-slider';
import { deepCopy } from '../../utils/copy';

export interface NumberRange {
  max: number;
  min: number;
}

export interface RangeSliderOptions {
  floor: number;
  ceil: number;
  step: number;
  hideLimitLabels?: boolean;
  hidePointerLabels?: boolean;
}

const defaultCfg: RangeSliderOptions = {
  floor: 1,
  ceil: 100,
  step: 10,
  hideLimitLabels: true,
  hidePointerLabels: true
};

@Component({
  selector: 'app-range-slider',
  templateUrl: './range-slider.component.html',
  styleUrls: ['./range-slider.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RangeSliderComponent {

  @Input()
  get minValue() { return this._minValue; }
  set minValue(value: number) {
    this._minValue = _isNil(value) ? 0 : value;
    this.minValueChange.emit(this._minValue);
  }
  _minValue = 1;

  @Input()
  get maxValue() { return this._maxValue; }
  set maxValue(value: number) {
    this._maxValue = _isNil(value) ? 0 : value;
    this.maxValueChange.emit(this._maxValue);
  }
  _maxValue = 100;

  @Input()
  get options() { return this._options; }
  set options(config) {
    this._options = { ...defaultCfg, ...config};
  }
  private _options: RangeSliderOptions = deepCopy(defaultCfg);

  @Output() maxValueChange = new EventEmitter<number>();
  @Output() minValueChange = new EventEmitter<number>();
  @Output() changed = new EventEmitter<NumberRange>();

  onUserChangeEnd(changeInfo: ChangeContext): void {
    this.changed.emit({
      min: this.minValue,
      max: this.maxValue
    });
  }

  modelChanged(): void {
    this.changed.emit({
      min: this.minValue,
      max: this.maxValue
    });
  }
}
