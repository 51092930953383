import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { PopupComponent } from '../popup/popup.component';
import _isEmpty from 'lodash-es/isEmpty';
import _isArray from 'lodash-es/isArray';
import { DropdownComponent } from '../dropdown/dropdown.component';
import { EntityIdAndNameSchema } from '@rsmu/portal-api';
import { trackByIdFn } from '../../utils/track-by-fn';

export interface NodeSelection {
  id: string;
  values: EntityIdAndNameSchema[];
}

export interface DropdownTreeNode {
  id: string;
  name: string;
  values?: EntityIdAndNameSchema[];
}

@Component({
  selector: 'app-dropdown-tree',
  templateUrl: './dropdown-tree.component.html',
  styleUrls: ['./dropdown-tree.component.scss']
})

export class DropdownTreeComponent implements OnInit {

  readonly trackByIdFn = trackByIdFn;

  @Input() items: DropdownTreeNode[];
  @Input() value: NodeSelection;
  @Input() defaultValue: string;

  @Output() valueChange: EventEmitter<NodeSelection> = new EventEmitter<NodeSelection>();

  @ViewChild('treeDrop', { static: true }) treeDrop: DropdownComponent<{}>;
  @ViewChild('popupAdd', { static: true }) popupAdd: PopupComponent<{}>;

  valuesToSelect: EntityIdAndNameSchema[] = [];
  preselectedValues: string[] = [];

  selectedNode: DropdownTreeNode;
  preselectedIndex: number;
  selectedValuesId: string[] = [];

  get selectedError(): boolean {
    if (!this.defaultValue) {
      return _isEmpty(this.selectedValuesId);
    }
  }

  constructor() {
  }

  ngOnInit(): void {
    this.refreshSelectedNode();
    this.sortItems();
  }

  select(itemToSelect: DropdownTreeNode): void {
    if (this.hasValues(itemToSelect)) {
      this.preselectedIndex = this.items.findIndex((item: DropdownTreeNode) => item.id === itemToSelect.id);
      this.valuesToSelect = itemToSelect.values;
      this.preselectedValues = this.value.values.map(v => v.id);

      this.showPopup();
    } else {
      this.preselectedIndex = -1;
      this.valuesToSelect = [];
      this.preselectedValues = [];

      this.selectedNode = itemToSelect;
      this.setValuesAndEmit([]);
      this.sortItems();

      this.treeDrop.close();
    }
  }

  onCheckboxSelect(selectedValuesId: string[]): void {
    this.selectedValuesId = selectedValuesId;
  }

  save(): void {
    if (this.selectedError) {
      this.refreshSelectedNode();
      return;
    }

    if (this.defaultValue && _isEmpty(this.selectedValuesId)) {
      this.selectedNode =  this.items.find(i => i.id === this.defaultValue);
      this.setValuesAndEmit([]);
    } else {
      this.selectedNode = this.items[this.preselectedIndex];
      this.setValuesAndEmit(this.selectedNode.values.filter(v => this.selectedValuesId.some(value => value === v.id)));
      this.sortItems();
    }
    this.closePopup();
  }

  // noinspection JSMethodCanBeStatic
  hasValues(item: DropdownTreeNode): boolean {
    return _isArray(item.values) && item.values.length > 0;
  }

  closePopup(): void {
    this.popupAdd.hide();
  }

  private showPopup(): void {
    this.popupAdd.show();
  }

  private refreshSelectedNode(): void {
    this.selectedNode = this.items.find(item => item.id === this.value.id);
  }

  private sortItems(): void {
    this.items.sort((a: DropdownTreeNode, b: DropdownTreeNode) => {
      if (a.id === this.value.id) {
        return -1;
      } else if (b.id === this.value.id) {
        return 1;
      } else {
        return a.name.localeCompare(b.name);
      }
    });
  }

  private setValuesAndEmit(values: EntityIdAndNameSchema[]): void {
    this.value = {
      id: this.selectedNode.id,
      values: _isArray(values) ? values : []
    };
    this.valueChange.emit(this.value);
  }
}
