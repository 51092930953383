<div class="popover">
  <div class="popover__body {{ theme }}"
      [class.popover__body_arrow_top]="hasArrow && (position === 'bottom' || position === 'bottom-left')"
      [class.popover__body_arrow_bottom]="hasArrow && (position === 'top' || position === 'top-left')">
    <div class="popover__wrap"
        [class.popover__wrap_align_center]="position === 'bottom' || position === 'top'"
        [class.popover__wrap_right]="position === 'right'">
      <div class="popover__content"
          [class.popover__content_align_center]="position === 'bottom' || position === 'top'">
        <button class="popover__close" (click)="close()" [title]="'tips.close' | translate">
          <span class="figure figure_close popover__close-icon" role="presentation"></span>
        </button>
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
