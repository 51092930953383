import { animate, AnimationMetadata, state, style, transition, trigger } from '@angular/animations';

const ANIMATION_TIMINGS = `350ms ease-out`;
const ANIMATION_LEAVE_TIMINGS = `200ms ease-out`;

export const ACCORDION_ANIMATIONS: AnimationMetadata[] = [
  trigger(
    'isHidden',
    [
      state('true', style({ height: '0' })),
      state('false', style({ height: '*' })),
      transition('* => *', animate(ANIMATION_TIMINGS))
    ]
  ),
  trigger(
    'hiddenLeave',
    [
      transition(':enter', [
        style({ height: '0', overflow: 'hidden', padding: '0' }),
        animate(ANIMATION_LEAVE_TIMINGS, style({
          height: '*',
          overflow: 'hidden',
          // When using auto-styles ('*') we should only use longhand properties
          // due to inconsistent behavior of Window.getComputedStyle method in
          // different browsers
          paddingTop: '*',
          paddingRight: '*',
          paddingBottom: '*',
          paddingLeft: '*'
        })),
      ]),
      transition(':leave', [
        animate(ANIMATION_LEAVE_TIMINGS, style({
          height: 0,
          overflow: 'hidden',
          paddingTop: 0,
          paddingRight: 0,
          paddingBottom: 0,
          paddingLeft: 0
        }))
      ])
    ]
  )
];
