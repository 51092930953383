<div class="password-recovery-result password-recovery-result_result">
  <img role="presentation" class="iam-background-left" alt="" src='assets/icons/Background_1.svg'/>
  <img role="presentation" class="iam-background-right" alt="" src='assets/icons/Background_2.svg'/>
  <div class="iam-screen">
    <header class="password-recovery-result__header">
      <div class="password-recovery-result__title-wrapper">
        <h2 class="heading heading_1 heading_light heading_1-smaller-sm heading_bold-sm">
          {{ 'passwordRecoveryResult.title' | translate }}
        </h2>
      </div>

      <div class="password-recovery-result__text-result">
        <span class="text text_big text_base-sm">{{('passwordRecoveryResult.' + result) | translate }}</span>
        <div
          class="password-recovery-result__actions-section password-recovery-result__actions-section_right password-recovery-result__actions-section_left-tablet">
          <button
            (click)="back()"
            class="password-recovery-result__button button button_primary">
            {{ 'passwordRecoveryResult.backToLogin' | translate }}
          </button>
        </div>
      </div>
    </header>
  </div>
</div>



