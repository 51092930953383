import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DateTime } from 'luxon';

@Injectable()
export class DatepickerParserFormatter extends NgbDateParserFormatter {
  parse(value: string): NgbDateStruct {
    if (value) {
      return (({day, month, year}) => ({day, month, year}))(DateTime.fromFormat(value, 'dd.MM.yyyy'));
    }
    return null;
  }

  format(date: NgbDateStruct): string {
    if (date) {
      return DateTime.fromObject(date).toFormat('dd.MM.yyyy');
    }
    return '';
  }
}
