import { Component } from '@angular/core';
import { AbstractQuestionOption } from '../abstract-question-option';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-date-question',
  templateUrl: './date-question.component.html',
})
export class DateQuestionComponent extends AbstractQuestionOption {

  get date(): number {
    return this._date;
  }
  set date(timestamp: number) {
    this._date = timestamp;
    this.answer.enteredText = this.datePipe.transform(timestamp, 'yyyy-MM-dd');
    this.emitChanges();
  }
  private _date: number;

  minDate: NgbDateStruct;
  maxDate: NgbDateStruct;

  constructor(private datePipe: DatePipe) {
    super();
    const dt = DateTime.local();
    const { day, month, year } = dt.plus({ years: 100 });
    this.minDate = {
      day: 1,
      month: 1,
      year: dt.minus({ years: 100 }).year
    };
    this.maxDate = {
      day,
      month,
      year
    };
  }

}
