import { MemoizedSelector, createSelector } from '@ngrx/store';
import { CyclesStoreSelectors } from './cycles-store';

export const selectIsLoading: MemoizedSelector<object, boolean> =
  createSelector(
    CyclesStoreSelectors.getIsLoading,
    (cyclesLoading: boolean) => {
      return cyclesLoading;
    }
  );

export const selectError: MemoizedSelector<object, any> =
  createSelector(
    CyclesStoreSelectors.getError,
    (cyclesError: any) => {
      return cyclesError;
    }
  );
