<div *ngIf="processedValue">
  <ng-container *ngFor="let item of processedValue">
    <div class="cell-icon {{ themeContainer }}" *ngIf="getIconName(item)">
      <svg-icon class="icon {{ theme }} {{ getIconClass(item) }}"
                src="{{ getIconName(item) }}"
                title="{{ 'tips.titleType.' + elementType + '.' + item | translate }}"
                [class.icon_18]="type === 'CHARGE_TYPE' || item === 'R' || item === 'N'"
                [class.icon_22]="type === 'EDU_TECHNOLOGY' && item !== 'R' && item !== 'N'"></svg-icon>
    </div>
  </ng-container>
</div>
