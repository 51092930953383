<div class="password-recovery" [class.password-recovery_result]="isRequestSuccess !== null">
  <img role="presentation" class="iam-background-left" alt="" src='assets/icons/Background_1.svg' />
  <img role="presentation" class="iam-background-right" alt="" src='assets/icons/Background_2.svg' />
  <div class="iam-screen">
    <header class="password-recovery__header">

      <div class="password-recovery__title-wrapper">
        <div *ngIf="isRequestSuccess" class="password-recovery__title-image-wrapper">
          <svg-icon class="password-recovery__title-image icon icon_black"
                    role="presentation"
                    src='assets/icons/locker.svg'></svg-icon>
        </div>

        <h2 class="heading heading_1 heading_light heading_1-smaller-sm heading_bold-sm">
          {{ title | translate }}
        </h2>
      </div>
      <div class="password-recovery__text-result" *ngIf="isRequestSuccess">
        <span class="text text_big text_base-sm">{{ 'passwordRecovery.successMessage' | translate: user }}</span>
        <div class="password-recovery__actions-section password-recovery__actions-section_right password-recovery__actions-section_left-tablet">
          <button
            (click)="toLogin()"
            class="password-recovery__button button button_primary">
            {{ 'passwordRecovery.login' | translate }}
          </button>
        </div>
      </div>
      <div class="password-recovery__text-result" *ngIf="isRequestSuccess === false">
        <span class="text text_big text_base-sm">{{ (getIsEmailModeToggle ? 'passwordRecovery.unsuccessMessageEmail' : 'passwordRecovery.unsuccessMessage') | translate }}</span>
        <div class="password-recovery__actions-section password-recovery__actions-section_right password-recovery__actions-section_left-tablet">
          <button
            (click)="toLogin()"
            class="password-recovery__button button button_primary">
            {{ 'passwordRecovery.back' | translate }}
          </button>
        </div>
      </div>
    </header>
    <form
      class="password-recovery__form"
      [formGroup]="passwordRecoveryForm"
      (ngSubmit)="onFormSubmit()"
      *ngIf="isRequestSuccess === null">
      <div class="iam-screen__input">
        <div class="tab-group">
          <button
            [class.tab-group__tab_active]="!getIsEmailModeToggle"
            class="tab-group__tab"
            type="button"
            (click)="setIsEmailModeToggle(false)"
            (mouseenter)="changeTabStyleOnMouse($event)"
            (mouseleave)="changeTabStyleOnMouse($event)"
          >
            <span class="tab-group__label">{{ 'passwordRecovery.userNameField.label' | translate }}</span>
          </button>
          <button
            [class.tab-group__tab_active]="getIsEmailModeToggle"
            class="tab-group__tab"
            type="button"
            (click)="setIsEmailModeToggle(true)"
            (mouseenter)="changeTabStyleOnMouse($event)"
            (mouseleave)="changeTabStyleOnMouse($event)"
          ><span class="tab-group__label">{{'passwordRecovery.emailNameField.label' | translate }}</span>
          </button>
        </div>

        <label class="form-control form-control__left">
          <input class="text-field text-field_transparent"
                name="SNILS"
                type="{{getIsEmailModeToggle ? 'email' : 'tel'}}"
                placeholder="{{ (getIsEmailModeToggle ? 'passwordRecovery.emailNameField.placeholder' : 'passwordRecovery.userNameField.placeholder') | translate }}"
                formControlName="userName"
                [textMask]="!getIsEmailModeToggle ? { mask: snilsMask } : {mask: false}"
                required
                appAutoFocus />
        </label>
        <div class="error-message error-message_color_alert" *ngIf="userName.invalid && userName.touched && !userName.pristine">
          {{ (getIsEmailModeToggle ? 'validation.userName.invalidEmail' : 'validation.userName.invalidSnils') | translate }}</div>
      </div>
      <div *ngIf="!isMobile" appYandexCaptcha (captchaToken)="captchaHandler($event)" class="password-recovery__captcha">
        
      </div>

      <input hidden formControlName="captcha"/>

      <div *ngIf="passwordRecoveryForm.invalid && passwordRecoveryForm.dirty">
        <div class="error-message error-message_color_alert"
             *ngIf="passwordRecoveryForm.errors && passwordRecoveryForm.errors['undefinedError']">
          {{ 'validation.unknown' | translate }}
        </div>
        <div class="error-message error-message_color_alert"
             *ngIf="passwordRecoveryForm.errors && passwordRecoveryForm.errors['notFound']">
          {{ 'validation.userName.notFound' | translate }}
        </div>
        <div class="error-message error-message_color_alert"
             *ngIf="passwordRecoveryForm.errors && passwordRecoveryForm.errors['captchaIsNotVerified']">
          {{ 'validation.userName.captchaIsNotVerified' | translate }}
        </div>
      </div>

      <div class="password-recovery__actions-section">
        <button class="button button_primary button_high button_block"
                type="submit"
                [disabled]="passwordRecoveryForm.invalid">
          {{ 'passwordRecovery.submitButton' | translate }}
        </button>
      </div>
    </form>
  </div>
</div>



