import { Component } from '@angular/core';
import { AbstractQuestionOption } from '../abstract-question-option';

@Component({
  selector: 'app-text-area-question',
  templateUrl: './text-area-question.component.html',
  styleUrls: ['./text-area-question.component.scss']
})
export class TextAreaQuestionComponent extends AbstractQuestionOption {

  get title(): string {
    return this.answer.enteredText;
  }
  set title(newValue: string) {
    this.answer.enteredText = newValue;
    this.emitChanges();
  }

  constructor() {
    super();
  }
}
