<div class="markers-zet {{ theme }}">
  <span class="markers-zet__text text text_bold">
    <ng-content></ng-content>
  </span>
  <span class="markers__label text text_regular text_tiny" *ngIf="label">
    {{ label }}
  </span>
  <div>
    <div class="markers-zet__marker"
         role="tooltip"
         [appTooltip]="markersZetHint"
         [trigger]="triggers"
         (click)="$event.stopPropagation();">
      <span *ngIf="!mastered"
            [attr.aria-label]="'trainingProgram.zet.included' | translate"
            class="figure figure_checkmark-arris"></span>
      <span *ngIf="mastered"
            [attr.aria-label]="'trainingProgram.zet.mastered' | translate"
            class="figure figure_checkmark-arris-double"><span></span></span>
    </div>
    <ng-template #markersZetHint>
      <div [ngClass]="themeTooltip">
        <div *ngIf="!mastered">{{ 'trainingProgram.zet.included' | translate }}</div>
        <div *ngIf="mastered">{{ 'trainingProgram.zet.mastered' | translate }}</div>
      </div>
    </ng-template>
  </div>
</div>
