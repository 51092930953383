<div class="like-dislike"
     [class.like-dislike_inline]="inline"
     [class.like-dislike_small]='size === "small"'
     [class.like-dislike_regular]='size === "regular"'
     [class.like-dislike_big]='size === "big"'
     [class.like-dislike_largeTb]='sizeTb === "large"'>
  <button
    class="button button_text like-dislike__button"
    [disabled]="disabled"
    (click)="like()"
    title="{{'tips.like' | translate}}">
    <svg-icon
      class="icon like-dislike__icon like-dislike__icon_like"
      [class.like-dislike__icon_active]='estimateState === "like"'
      [class.icon_12]='size === "small"'
      [class.icon_14]='size === "regular"'
      [class.icon_18]='size === "big"'
      [class.icon_22_tb]='sizeTb === "large"'
      src="assets/icons/like.svg"></svg-icon>
    <span
      *ngIf="estimates && estimates.likes !== undefined"
      class="like-dislike__text"
      [class.like-dislike__text_large-tb]='sizeTb === "large"'>
    </span>
</button>
  <button
    class="button button_text like-dislike__button"
    [disabled]="disabled"
    (click)="dislike()"
    title="{{'tips.dislike' | translate}}">
    <svg-icon
      class="icon like-dislike__icon like-dislike__icon_dislike"
      [class.like-dislike__icon_active-dislike]='estimateState === "dislike"'
      [class.icon_12]='size === "small"'
      [class.icon_14]='size === "regular"'
      [class.icon_18]='size === "big"'
      [class.icon_22_tb]='sizeTb === "large"'
      src="assets/icons/dislike.svg"></svg-icon>
    <span
      *ngIf="estimates && estimates.dislikes !== undefined"
      class="like-dislike__text"
      [class.like-dislike__text_large-tb]='sizeTb === "large"'>
    </span>
</button>
</div>
