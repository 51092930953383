import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalLoadingService {

  private readonly switchSubject = new BehaviorSubject<boolean>(false);
  private loadingRequestsCount = 0;

  constructor() {
    this.clear();
  }

  clear(): void {
    this.switchSubject.next(false);
    this.loadingRequestsCount = 0;
  }

  getLoadingState(): Observable<boolean> {
    return this.switchSubject.asObservable();
  }

  getCurrentState(): boolean {
    return this.switchSubject.getValue();
  }

  startLoading(): void {
    if (this.loadingRequestsCount === 0) {
      this.switchSubject.next(true);
    }
    this.loadingRequestsCount++;
  }

  stopLoading(): void {
    if (this.loadingRequestsCount > 0) {
      this.loadingRequestsCount--;
      if (this.loadingRequestsCount === 0) {
        this.clear();
      }
    }
  }

}
