<div class="multiselect-dialog">
  <div class="multiselect-dialog__search-string">
    <mat-form-field class="multiselect-dialog__search-string" appearance="outline">
      <mat-label>{{ 'eduElementProspect.search.searchByName' | translate }}</mat-label>
      <input [formControl]="dialogStringControl" matInput type="text">
      <mat-icon class="multi-selectdialog__search-string__icon" color="primary"  matSuffix svgIcon="search"></mat-icon>
    </mat-form-field>
  </div>
  <div class="multiselect-dialog__chips-list" *ngIf="preselectedItems">
    <mat-chip-listbox multiple="true">
      <mat-chip-row
        class="multiselect-dialog__chips-list__item"
        highlighted
        (removed)="onChipRemove(preselectedItem)" *ngFor="let preselectedItem of preselectedItems">
        <span class="multiselect-dialog__chips-list__item__text">{{preselectedItem[data.itemName]}}</span>
        <button matChipRemove>
          <mat-icon svgIcon="closed"></mat-icon>
        </button>
      </mat-chip-row>
    </mat-chip-listbox>
  </div>

  <div class="multiselect-dialog__items">

    <div #selectionList class="multiselect-dialog__items-list">
      <ng-container *ngIf="asyncQueryComplete$ | async else loader">
        <ng-container *ngIf="filteredItems$ | async as items"
                      [ngTemplateOutlet]="itemsList"
                      [ngTemplateOutletContext]="{items: items}"
        >
        </ng-container>
      </ng-container>
    </div>

  </div>


  <div class="multiselect-dialog__actions">
    <div class="multiselect-dialog__actions__item">
      <button mat-stroked-button color="primary" (click)="onCancel()">
        {{'common.multiselect.popup.deny' | translate}}</button>
    </div>
    <div class="multiselect-dialog__actions__item">
      <button mat-raised-button color="primary" (click)="onConfirm()">
        {{'common.multiselect.popup.confirm' | translate}}</button>
    </div>
  </div>
</div>

<ng-template #itemsList let-items='items'>
  <mat-selection-list [multiple]="false">
    <div class="multiselect-dialog__item" *ngFor="let item of items">
      <mat-list-item
        [disabled]="!isCheckBoxEnabled"
        (click)="onItemClick(item, isCheckBoxEnabled)"
      >
        <span class="multiselect-dialog__item__text">{{item[data.itemName]}}</span>
      </mat-list-item>
      <mat-divider></mat-divider>
    </div>
  </mat-selection-list>
</ng-template>

<ng-template #loader>
  <div class="loader">
    <mat-spinner
      color="primary"
      [strokeWidth]="2"
    ></mat-spinner>
  </div>
</ng-template>
