export interface State {
  currentCycle: string | null;
  lastUsedCycle: string | null;
  showCurrentCycle: boolean;
}

export const initialState: State = {
  currentCycle: undefined,
  showCurrentCycle: true,
  lastUsedCycle: null
};
