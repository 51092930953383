import { Component } from '@angular/core';
import _maxBy from 'lodash-es/maxBy';
import { QuestionaryPsychologicalTest, QuestionaryResult } from '@rsmu/portal-api';
import { PsyQuestionaryService } from '../../psy-questionary.service';

@Component({
  selector: 'app-questionary-results',
  templateUrl: './questionary-results.component.html',
  styleUrls: ['./questionary-results.component.scss']
})
export class QuestionaryResultsComponent {

  transcriptIsShowed = true;
  private _maxScore: number;

  constructor(
    private questionaryService: PsyQuestionaryService,
  ) { }

  get maxScore(): number {
    if (!this._maxScore) {
      this._maxScore = _maxBy(this.testResults.result, 'score').score;
    }

    return this._maxScore;
  }

  get testResults(): QuestionaryResult {
    return this.questionaryService.results;
  }

  get psyQuestionary(): QuestionaryPsychologicalTest {
    return this.questionaryService.psyQuestionary;
  }

  finishTest(): void {
    this.questionaryService.finishTest();
  }

  showTranscript(): void {
    this.transcriptIsShowed = !this.transcriptIsShowed;
  }

}
