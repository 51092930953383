import { Directive, HostListener, Input } from '@angular/core';
import { PopupComponent } from './popup.component';
import { DialogService } from '../dialog/dialog.service';
import { ManagedComponent } from '../managed-component';
import { PopupAction } from './interfaces/popup.dto';
import {switchMap, takeUntil, tap} from 'rxjs/operators';
import {isObservable, Observable, of} from 'rxjs';

@Directive({
  selector: '[appPopup]'
})
export class PopupDirective<T> extends ManagedComponent {
  @Input('appPopup') appPopup: PopupComponent<T>;
  @Input() popupData: T;
  @Input() toShowWarning: boolean | Observable<boolean> = false;
  @Input() warningMessage: string;

  constructor(private readonly dialogService: DialogService) {
    super();
  }

  @HostListener('click') showPopup() {
    if (this.appPopup) {
      if (!this.toShowWarning) {
        this.appPopup.show(this.popupData);
        return;
      }

      const toShowWarning$ = isObservable(this.toShowWarning) ? this.toShowWarning : of(this.toShowWarning);
      toShowWarning$
        .pipe(
          takeUntil(this.destroy$),
          switchMap(showWarning => {
            if (showWarning) {
             return this.dialogService.confirm(this.warningMessage, {
                buttons: [{
                  key: 'dialog.cancel',
                  style: 'button_flat button_base-size',
                  action: PopupAction.DENY
                }, {
                  key: 'common.popup.next',
                  style: 'button_primary button_base-size confirm-popup__button_no-margin',
                  action: PopupAction.CONFIRM
                }],
                showClose: false,
                theme: 'minimal'
              });
            } else {
              return of(PopupAction.CONFIRM);
            }
          }))
        .subscribe((action) => {
          if (action === PopupAction.CONFIRM) {
            this.appPopup.show(this.popupData);
            return;
          }
        });
    }
  }
}
