import { Injectable } from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {MatIconRegistry} from '@angular/material/icon';

@Injectable({
  providedIn: 'root'
})
export class IconsService {

  constructor(
    private readonly domSanitizer: DomSanitizer,
    private readonly matIconRegistry: MatIconRegistry
  ) { }

  registerIcons(): void {
    this.matIconRegistry.addSvgIcon('search', this.domSanitizer
      .bypassSecurityTrustResourceUrl('./assets/icons/search.svg'));
    this.matIconRegistry.addSvgIcon('closed', this.domSanitizer
      .bypassSecurityTrustResourceUrl('./assets/icons/closed.svg'));
    this.matIconRegistry.addSvgIcon('clear', this.domSanitizer
      .bypassSecurityTrustResourceUrl('./assets/icons/clear.svg'));
    this.matIconRegistry.addSvgIcon('filter', this.domSanitizer
      .bypassSecurityTrustResourceUrl('./assets/icons/filter.svg'));
    this.matIconRegistry.addSvgIcon('delete-icon', this.domSanitizer
      .bypassSecurityTrustResourceUrl('./assets/icons/delete-icon.svg'));
    this.matIconRegistry.addSvgIcon('download-icon', this.domSanitizer
      .bypassSecurityTrustResourceUrl('./assets/icons/download.svg'));
    this.matIconRegistry.addSvgIcon('sorting', this.domSanitizer
      .bypassSecurityTrustResourceUrl('./assets/icons/sorting.svg'));
    this.matIconRegistry.addSvgIcon('asc', this.domSanitizer
      .bypassSecurityTrustResourceUrl('./assets/icons/asc.svg'), {viewBox: '0 0 10 10'});
    this.matIconRegistry.addSvgIcon('desc', this.domSanitizer
      .bypassSecurityTrustResourceUrl('./assets/icons/desc.svg'), {viewBox: '0 0 10 10'});
  }


}
